import { InputField } from '@shared-ui/components'
import constraintsFromSchema from '@shared-ui/utils/form/constraintsFromSchema'
import type { ResellerCreate } from '@shared/lib/validation/schemas'
import { ResellerCreateSchema } from '@shared/lib/validation/schemas'
import { Field, Formik, useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { useSubmitRmaStore } from '../../../submit-rma-store'

export type AddSellerFormType = ResellerCreate

interface AddSellerFormProps {
  setSubmitForm: (submitForm: () => Promise<void>) => void
  handleSubmit: (values: AddSellerFormType) => void
}

export function AddSellerForm({ setSubmitForm, handleSubmit }: AddSellerFormProps) {
  const { seller } = useSubmitRmaStore()

  const initialValues = useMemo((): AddSellerFormType => {
    if (seller?.id) {
      return {
        name: '',
        suggestedAddress: '',
        website: '',
      }
    }

    return {
      name: seller?.name || '',
      suggestedAddress: seller?.address1 || '',
      website: seller?.website || '',
    }
  }, [seller])

  return (
    <Formik initialValues={initialValues} validationSchema={ResellerCreateSchema} onSubmit={handleSubmit}>
      <Form setSubmitForm={setSubmitForm} />
    </Formik>
  )
}

function Form({ setSubmitForm }: { setSubmitForm: AddSellerFormProps['setSubmitForm'] }) {
  const { submitForm, values } = useFormikContext<AddSellerFormType>()
  const constraints = useMemo(() => constraintsFromSchema(ResellerCreateSchema, values), [values])

  useEffect(() => {
    setSubmitForm(submitForm)
  }, [submitForm, setSubmitForm])

  return (
    <form>
      <Field
        data-testid="submit-rma-step-2-new-seller-name-input"
        label="Seller Name"
        name="name"
        component={InputField}
        maxLength={constraints.name.max?.param}
      />

      <Field
        data-testid="submit-rma-step-2-new-seller-address-input"
        label="Address"
        name="suggestedAddress"
        component={InputField}
        maxLength={constraints.suggestedAddress.max?.param}
      />

      <Field
        data-testid="submit-rma-step-2-new-seller-website-input"
        label="Website"
        name="website"
        component={InputField}
        maxLength={constraints.website.max?.param}
      />
    </form>
  )
}
