import { ClosePrimaryIcon } from '@ubnt/icons'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Grid } from './Grid'
import { CustomLink, Row, Text13 } from './SprintShared'
import { Div } from './Div'

export const FileUploadItem = ({
  file,
  name,
  justView,
  onRemove,
  errors,
}: {
  file: File
  name: string
  onRemove?: () => void
  errors?: string[]
  justView?: boolean
}) => {
  const objectURL = useMemo(() => {
    return URL.createObjectURL(file)
  }, [file])

  return (
    <>
      <FileItemGridContainer $container justify="space-between" wrap="nowrap" $alignItems="center">
        <Div className="truncate">{name}</Div>

        <CloseIconWrapper>
          {!justView && onRemove && <ClosePrimaryIcon actionable size="small" onClick={() => onRemove()} />}
        </CloseIconWrapper>

        {justView && (
          <CustomLink href={objectURL} style={{ paddingLeft: '20px' }}>
            View
          </CustomLink>
        )}
      </FileItemGridContainer>

      {errors && (
        <Row>
          <Text13 block color="danger">
            {errors ? ` ${errors.join(', ')}` : ''}
          </Text13>
        </Row>
      )}
    </>
  )
}

const FileItemGridContainer = styled(Grid)`
  height: 40px;
  padding: ${(props) => `0 ${props.theme.spacing.m}`};
  background: #f9f9fc;
  border-radius: 3px;
`

const CloseIconWrapper = styled.div`
  margin-bottom: 3px;
`
