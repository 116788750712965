import { Column } from '@shared-ui/components'
import { ScreenSizeProvider } from '@shared-ui/contexts'
import { useNavigate, useNestedPath } from '@shared-ui/hooks'
import type { ReactNode } from 'react'
import { useCallback, useEffect } from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import Sidebar from '../../dashboard/sidebar'
import { SubmitRmaService } from '../submit-rma-service'
import { useSubmitRmaStore } from '../submit-rma-store'
import type { SubmitRmaStep } from '../submit-rma-types'
import { SubmitRmaBackground } from './submit-rma-background'
import { AddSeller } from './steps/add-seller/add-seller'
import { VerifyShippingAddress } from './steps/verify-shipping-address'
import { AddShippingAddress } from './steps/add-shipping-address'
import { ConfirmInformation } from './steps/confirm-information'
import { DescribeIssue } from './steps/describe-issue'
import { SearchHardware } from './steps/search-hardware'
import { SelectSeller } from './steps/select-seller'
import { SelectShippingAddress } from './steps/select-shipping-address'

const stepMap: Record<SubmitRmaStep, () => JSX.Element | null> = {
  addSeller: AddSeller,
  selectSeller: SelectSeller,
  confirmInformation: ConfirmInformation,
  describeIssue: DescribeIssue,
  selectShippingAddress: SelectShippingAddress,
  searchHardware: SearchHardware,
  addShippingAddress: AddShippingAddress,
  editShippingAddress: AddShippingAddress,
  verifyShippingAddress: VerifyShippingAddress,
}

export function SubmitRmaLogin() {
  return (
    <ScreenSizeProvider>
      <SubmitRmaBackground />
      <SubmitRmaContent />
    </ScreenSizeProvider>
  )
}

export function SubmitRma() {
  const nested = useNestedPath()
  const navigate = useNavigate()

  const handleCloseSidebar = useCallback(() => navigate(nested('')), [navigate, nested])

  return (
    <ScreenSizeProvider>
      <SubmitRmaBackground />
      <SubmitRmaContent />
      <Route path={nested('/:sidebarParam')}>
        <Sidebar onClose={handleCloseSidebar} />
      </Route>
    </ScreenSizeProvider>
  )
}

function SubmitRmaContent() {
  const { view } = useSubmitRmaStore()

  useEffect(() => {
    return SubmitRmaService.reset
  }, [])

  const RmaStepComponent = stepMap[view.curr]

  const isEmbededPopup = view.curr === 'addSeller' || view.curr === 'addShippingAddress'
  if (isEmbededPopup) {
    return (
      <SubmitContainerEmbedded>
        <RmaStepComponent />
      </SubmitContainerEmbedded>
    )
  }

  return (
    <SubmitRmaContainer>
      <RmaStepComponent />
    </SubmitRmaContainer>
  )
}

export function SubmitRmaContainer({ children }: { children: ReactNode }) {
  return (
    <Column>
      <SubmitContainerHolder>
        <SubmitContainer>{children}</SubmitContainer>
      </SubmitContainerHolder>
    </Column>
  )
}

const SubmitContainerEmbedded = styled.div`
  display: flex;
  height: 100%;
  padding: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  & > * {
    width: 420px;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
`

const SubmitContainerHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 60px 253px;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0;
  }
`
