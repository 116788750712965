import { UserAccountIcon } from '@ubnt/icons'
import { cssVariables, Text } from '@ubnt/ui-components'
import type { TicketTabPermission } from 'rma-shared/types/permissions'
import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { getFlagPath } from '../../utils/flags'
import { customerAddress, phoneNumber } from '../../utils/format'
import { Card } from '../Card'
import { Container } from '../Container'
import { Flex } from '../Flex'
import { Heading } from '../Heading'
import { MailIcon } from '../icons/MailIcon'
import { PhoneIcon } from '../icons/PhoneIcon'
import { Image, RemoteImage } from '../Image'
import { Link } from '../Link'
import { SidePanelCard } from '../SprintShared'
import type { Shared_Ticket_CustomerFragment as Ticket } from './__generated__/Customer'

const publicUrl = process.env.PUBLIC_URL || ''

type SubmittedBy = NonNullable<Ticket['submittedBy']>

const getPicture = (party: SubmittedBy): string | null => {
  if (party.__typename === 'Company') {
    return party.logo
  }

  if (party.__typename === 'Customer') {
    return party.picture
  }

  return null
}

type CustomerProps = {
  className?: string
  ticket: Ticket
}

export const Customer = ({ className, ticket }: CustomerProps) => {
  if (!ticket.submittedBy) {
    return null
  }

  const picture = getPicture(ticket.submittedBy)
  const defaultAddress = ticket.shipmentAddress
  const { email } = defaultAddress

  return (
    <SidePanelCard className={className}>
      <div className="flex column justify-center align-center p-20">
        {picture && <RemoteImage width="120px" height="120px" url={picture} />}
        {!picture && <UserAccountIcon size="large" width="120px" height="120px" />}

        <div className="flex justify-center my-14 width-100">
          <Text size="header-s" truncate title={defaultAddress.name} className="block">
            {defaultAddress.name}
          </Text>
        </div>
      </div>

      <Container $padding={[0, cssVariables['spacing-xl'], cssVariables['spacing-xl']]}>
        <Card
          marginTop={theme.spacing.xxl}
          rows={[
            ...(email
              ? [
                  {
                    icon: <MailIcon />,
                    text: (
                      <Link truncate href={`mailto:${email}`}>
                        {email}
                      </Link>
                    ),
                  },
                ]
              : []),
            ...(() => {
              if (!defaultAddress || !defaultAddress.phoneNumber || !defaultAddress.country) {
                return []
              }

              const tel = phoneNumber(defaultAddress.phoneNumber)

              return [
                {
                  icon: <PhoneIcon />,
                  text: <Link href={`tel:${tel}`}>{tel}</Link>,
                },
                {
                  icon: <Image src={getFlagPath(defaultAddress.country, publicUrl)} />,
                  text: (
                    <Text size="body">
                      {customerAddress({ address: defaultAddress, displayName: false }).join(' ')}
                    </Text>
                  ),
                },
              ]
            })(),
          ]}
        />
      </Container>
    </SidePanelCard>
  )
}

const HeadingContainer = styled(Flex).attrs<{ $column?: boolean }>({ $column: true, alignCenter: true })`
  padding: ${(props) => props.theme.spacing.xl} 5% 0;
`

export const CustomerTab = {
  id: 'customer',
  path: '/customer',
  icon: <UserAccountIcon size="navigation" omitSvgMargin />,
  component: Customer,
  permission: 'ticket-tab-customer' as TicketTabPermission,
}
