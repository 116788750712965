import { Radio, Text } from '@ubnt/ui-components'
import { useSubmitRmaStore } from '../submit-rma-store'

export function DevicePartsReturn({ sku, partSku }: { sku: string; partSku: string }) {
  const { submitAsReplacementPart } = useSubmitRmaStore()

  function toggleReplacementPart(checked: boolean) {
    useSubmitRmaStore.setState({
      submitAsReplacementPart: checked,
    })
  }

  return (
    <div className="flex column s-8">
      <Text size="body" weight="bold">
        Select the item you&apos;d like to replace.
      </Text>

      <div className="inline-flex">
        <Radio
          id="main-item"
          checked={!submitAsReplacementPart}
          onChange={(event) => submitAsReplacementPart && toggleReplacementPart(!event.currentTarget.checked)}
        >
          {sku}
        </Radio>
      </div>

      <div className="inline-flex">
        <Radio
          id="mount-only"
          width={0}
          checked={submitAsReplacementPart}
          onChange={(event) => !submitAsReplacementPart && toggleReplacementPart(event.currentTarget.checked)}
        >
          {partSku} Only
        </Radio>
      </div>
    </div>
  )
}
