import { NoInternetIconL } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'
import { Link, Vertical } from '../../components'
import type { PortalType } from '../../utils/isPortal'
import isPortal, { getPortal } from '../../utils/isPortal'

const Mailto: Record<PortalType, string> = {
  RMA: 'rma@ui.com',
  VAR: 'var@ui.com',
  DIRECT: 'directrma@ui.com',
  FAE: '',
  '': '',
}

export const PageForbidden = () => {
  if (isPortal('FAE')) {
    return <PageForbiddenFae />
  }

  return (
    <Vertical $centerH $centerV $flex>
      <Icon />
      <Text size="header-xs" weight="bold" color="primary" style={{ marginBottom: '13px', lineHeight: '24px' }}>
        Cannot Connect to Portal
      </Text>
      <Text size="body" color="primary" centered>
        Please refresh the page.
        <br />
        If you still can&apos;t connect, <Link href={`mailto:${Mailto[getPortal()]}`}>contact Ubiquiti Support</Link>.
      </Text>
    </Vertical>
  )
}

const PageForbiddenFae = () => {
  return (
    <Vertical $centerH $centerV $flex>
      <Icon />
      <Text size="header-xs" weight="bold" color="primary" style={{ marginBottom: '13px', lineHeight: '24px' }}>
        Cannot Connect to Portal
      </Text>
      <Text size="body" color="primary" centered>
        Please refresh the page. If you still can&apos;t connect, contact Ubiquiti
        <br />
        Support in your UID app.
      </Text>
    </Vertical>
  )
}

const Icon = styled(NoInternetIconL)`
  margin-bottom: 13px;

  width: 54px;
  height: 54px;
  color: #777;
`
