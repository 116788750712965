import styled from 'styled-components'
import { TrashIcon } from '@ubnt/icons'
import type { ChangeEvent } from 'react'
import { UploadButton } from '@ubnt/ui-components'
import { TICKET_ATTACHMENT_VALID_FILE_TYPES, TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES } from '@shared/tickets'
import { useMemo } from 'react'
import { UploadIcon } from './icons/UploadIcon'
import type { FileUploadPlaceholder } from './FileUploadV2'

export const UploadButtonStyled = styled(UploadButton)`
  display: block;
  width: 64px;
  height: 64px;
  background: #f9f9fc;
  border-radius: 3px;
  > div {
    width: 100%;
    height: 100%;
    > span {
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
      > span {
        width: 100%;
        height: 100%;
        display: block;
        > div {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          & :hover {
            svg > path {
              fill: #006fff;
            }
          }
        }
      }
    }
  }

  margin-right: 11px;

  &:last-child {
    margin-right: 0;
  }
`

const ImagePreviewContainer = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
  cursor: pointer;
  > img {
    border-radius: 3px;
    width: 64px;
    height: 64px;
  }
  &:hover {
    > div {
      display: flex !important;
    }
  }

  margin-right: 11px;

  &:last-child {
    margin-right: 0;
  }
`

const HoverOverlay = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  border-radius: 3px;
  width: 64px;
  height: 64px;
  > svg {
    color: #ffffff;
    margin: 0 !important;
  }
`

export type UploadFileType = 'default' | 'images'

interface FileUploadItemProps {
  file: FileUploadPlaceholder
  maxFiles: number
  fileType?: UploadFileType
  onRemove?: () => void
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  justView?: boolean
}

export const FileUploadItemV2 = ({
  file,
  onRemove,
  handleFileChange,
  maxFiles,
  fileType,
  justView,
}: FileUploadItemProps) => {
  const objectURL = useMemo(() => {
    return file?.file ? URL.createObjectURL(file.file) : undefined
  }, [file])

  const handleClick = () => {
    if (justView) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: objectURL,
      }).click()
    } else if (onRemove) {
      onRemove()
    }
  }

  const acceptFileTypes =
    fileType && fileType === 'images' ? TICKET_ATTACHMENT_VALID_IMAGE_FILE_TYPES : TICKET_ATTACHMENT_VALID_FILE_TYPES

  return (
    <>
      {file?.file ? (
        <ImagePreviewContainer onClick={handleClick}>
          <img alt="" src={objectURL} />
          {!justView && (
            <HoverOverlay>
              <TrashIcon />
            </HoverOverlay>
          )}
        </ImagePreviewContainer>
      ) : (
        <UploadButtonStyled onChange={handleFileChange} accept={acceptFileTypes.join(',')} multiple={maxFiles > 1}>
          <UploadIcon />
        </UploadButtonStyled>
      )}
    </>
  )
}
