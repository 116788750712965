import type React from 'react'
import styled from 'styled-components'

import type { Spacing } from '../styles/theme'
import { Flex } from './Flex'

export type Props = React.ComponentProps<typeof Flex> & {
  marginTop?: Spacing
}

// TODO: Allow not to center; rename marginTop to verticalMargin.
export const MessageContainer = styled(Flex).attrs({ center: true })<Props>`
  ${(props) => (props.full ? '' : `margin: ${props.theme.spacing[props.marginTop || 'l']} 0;`)}
`
