// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../generated/graphql'

export type RateTicketFragment = { readonly __typename?: 'Ticket' } & Pick<
  Types.Ticket,
  'id' | 'status' | 'supportByName'
> & { readonly rating: Types.Maybe<{ readonly __typename?: 'Rating' } & Pick<Types.Rating, 'rating' | 'feedback'>> }

export type RateExperienceMutationVariables = Types.Exact<{
  input: Types.RateExperienceInput
}>

export type RateExperienceMutation = { readonly __typename?: 'Mutation' } & {
  readonly rateExperience: Types.Maybe<
    { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'> & {
        readonly rating: Types.Maybe<{ readonly __typename?: 'Rating' } & Pick<Types.Rating, 'rating' | 'feedback'>>
      }
  >
}

export const RateTicketFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RateTicket' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
        ],
      },
    },
  ],
}
export const RateExperienceDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RateExperience' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RateExperienceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rateExperience' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rating' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type RateExperienceMutationFn = Apollo.MutationFunction<RateExperienceMutation, RateExperienceMutationVariables>

/**
 * __useRateExperienceMutation__
 *
 * To run a mutation, you first call `useRateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateExperienceMutation, { data, loading, error }] = useRateExperienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRateExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<RateExperienceMutation, RateExperienceMutationVariables>,
) {
  return Apollo.useMutation<RateExperienceMutation, RateExperienceMutationVariables>(
    RateExperienceDocument,
    baseOptions,
  )
}
export type RateExperienceMutationHookResult = ReturnType<typeof useRateExperienceMutation>
export type RateExperienceMutationResult = Apollo.MutationResult<RateExperienceMutation>
export type RateExperienceMutationOptions = Apollo.BaseMutationOptions<
  RateExperienceMutation,
  RateExperienceMutationVariables
>
