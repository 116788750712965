import { AuthService } from '@shared-ui/auth/auth-service'
import { Buttons, InnerTitle, PaddedContainer } from '@shared-ui/components'
import { Button, Text } from '@ubnt/ui-components'

export const LoginModal = () => {
  const onLogIn = async () => {
    await AuthService.login()
  }

  const onSignUp = async () => {
    await AuthService.redirectToRegister()
  }

  return (
    <PaddedContainer $padding="28px" $margin={[0, 0, '32px', 0]}>
      <InnerTitle color="primary" data-testid="not-logged-in-get-started-heading">
        Let&apos;s get started!
      </InnerTitle>

      <Text size="body" color="primary" data-testid="not-logged-in-message">
        Log in to your SSO or create a new Ubiquiti account to request RMA.
      </Text>

      <Buttons>
        <Button variant="tertiary" onClick={onSignUp} data-testid="sign-up-btn">
          Sign Up
        </Button>

        <Button variant="primary" onClick={onLogIn} data-testid="log-in-btn">
          Log In
        </Button>
      </Buttons>
    </PaddedContainer>
  )
}
