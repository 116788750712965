import { cssVariables, Text } from '@ubnt/ui-components'
import styled from 'styled-components'
import { formatDate } from '../../utils/time'
import { Attachment } from '../customer-support/Attachment'

export const Message = ({
  sender,
  body,
  attachments,
  submittedAt,
}: {
  sender: { name: string; email?: string; picture?: string | null; isYou?: boolean }
  body: string
  attachments?: {
    name: string
    url?: string
    size?: number
    type?: string
  }[]
  submittedAt: Date
}) => {
  const senderName = sender.name || sender.email

  return (
    <Wrap>
      <Image $isYou={!!sender.isYou}>
        {sender.picture ? (
          <img src={sender.picture} alt="Sender logo" />
        ) : (
          <>{(sender.name || sender.email || '').substring(0, 1).toUpperCase()}</>
        )}
      </Image>
      <Content>
        <Title>
          <Text weight="bold" size="body" color="primary" truncate title={senderName}>
            {sender.isYou ? 'You' : senderName}
          </Text>
          <SentAt>{formatDate(submittedAt)}</SentAt>
        </Title>
        {body}
        {!!attachments?.length && (
          <Attachments>
            {attachments?.map((attachment, idx) => (
              <Attachment attachment={attachment} key={idx} />
            ))}
          </Attachments>
        )}
      </Content>
    </Wrap>
  )
}

const Image = styled.div<{ $isYou: boolean }>`
  width: 32px;
  height: 32px;
  background-color: ${({ $isYou }) =>
    $isYou ? cssVariables.motifs.light.uBlue03 : cssVariables.motifs.light.neutral05};
  flex-shrink: 0;
  margin-right: ${cssVariables['spacing-s']};
  border-radius: 32px;
  text-align: center;
  color: ${cssVariables['white-0']};
  font-weight: ${cssVariables['font-weight-bold']};
  font-size: ${cssVariables['font-size-header-xs']};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 32px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Wrap = styled.div`
  padding: ${cssVariables['spacing-l']};
  background-color: ${cssVariables['white-0']};
  margin-bottom: ${cssVariables['spacing-s']};
  display: flex;
  border-radius: 8px;
`

const Content = styled.div`
  white-space: break-spaces;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Title = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: ${cssVariables['spacing-xs']};
`

const SentAt = styled(Text).attrs({ color: 'tertiary' })`
  justify-self: end;
  white-space: nowrap;
  margin-left: ${cssVariables['spacing-s']};
`

const Attachments = styled.div`
  margin-top: ${cssVariables['spacing-s']};
`
