// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../generated/graphql'

import type { Shared_TicketFragment } from '../../../../../../../packages/rma-frontend-shared/components/Ticket/__generated__/Ticket'
import type { Shared_Ticket_DetailsFragment } from '../../../../../../../packages/rma-frontend-shared/components/Ticket/__generated__/Details'
import type { RateTicketFragment } from '../Ticket/__generated__/Rate'

export type TicketQueryVariables = Types.Exact<{
  id: Types.Scalars['TicketId']
}>

export type TicketQuery = { readonly __typename?: 'Query' } & {
  readonly ticket: Types.Maybe<
    { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id' | 'supportByUbiquiti'> &
      Shared_TicketFragment &
      Shared_Ticket_DetailsFragment &
      RateTicketFragment
  >
}

export const TicketDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Ticket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Ticket' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Ticket_Details' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RateTicket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportByUbiquiti' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Ticket_Details' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nsProductId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Device' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'soldBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flow' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByReseller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByUbiquiti' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerFailureCategoryId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shippingCompany' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nsError' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shipmentAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'country' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'warehouseAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'replacementTrackingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerShippingCompany' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerTrackingNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerTrackingGenerated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyFiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'canCancelTicket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nsTicketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'offerUiSupport' } },
          { kind: 'Field', name: { kind: 'Name', value: 'escalatedToUi' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEndCustomer' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Device' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Device' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnifiCare' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSellerInactive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRefurbished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warrantyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RateTicket' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Ticket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rating' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
        ],
      },
    },
  ],
}

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(baseOptions: Apollo.QueryHookOptions<TicketQuery, TicketQueryVariables>) {
  return Apollo.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, baseOptions)
}
export function useTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>) {
  return Apollo.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, baseOptions)
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>
export type TicketQueryResult = Apollo.QueryResult<TicketQuery, TicketQueryVariables>
