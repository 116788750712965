import * as yup from 'yup'

import type { NetsuiteFailureCategoryId, NetsuiteProductId, TicketId } from '../../../types/brands'
import { validateMacSerial } from '../mac-serial'
import { DEFAULT_FK_MAX_LENGTH, TOO_LONG } from './const'

export const TicketIdSchema = yup.string() as yup.StringSchema<TicketId | undefined>

export const FailureCategoryIdSchema = yup.string() as yup.StringSchema<NetsuiteFailureCategoryId | undefined>

export const NetsuiteProductIdSchema = yup.string().max(DEFAULT_FK_MAX_LENGTH, TOO_LONG) as yup.StringSchema<
  NetsuiteProductId | undefined
>

export const DeviceSerialSchema = yup.string().when('$validateMacSerialFormat', {
  is: (val) => !!val,
  then: yup.string().test('is-valid-serial', 'Invalid mac/serial', (serial) => !serial || validateMacSerial(serial)),
})

export const STAR_COUNT = 5

const POSSIBLE_RATING_VALUES = [0, 1, 2, 3, 4, 5]

export const RatingSchema = yup
  .number()
  .min(0)
  .max(STAR_COUNT)
  .test({
    name: 'is-valid-rating',
    message: 'Invalid rating, possible relative values: ${values}',
    params: { stars: STAR_COUNT, values: JSON.stringify(POSSIBLE_RATING_VALUES) },
    test: (rating) => {
      return rating === null || rating === undefined || POSSIBLE_RATING_VALUES.includes(rating)
    },
  })
