export const formatDate = (date: Date | string, showDay = true) => {
  return new Date(date).toLocaleString('en-US', {
    hour12: true,
    year: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    day: showDay ? 'numeric' : undefined,
  })
}

export const formatDatePretty = (date: string | Date, showDay = true) =>
  (typeof date === 'string' ? new Date(date) : date).toLocaleDateString('en', {
    year: 'numeric',
    month: 'short',
    day: showDay ? 'numeric' : undefined,
  })

export const formatDateShort = (date: string | Date) =>
  (typeof date === 'string' ? new Date(date) : date).toLocaleDateString('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

export const formatDateTime = (date: string | Date) =>
  (typeof date === 'string' ? new Date(date) : date).toLocaleString('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })

const getMilliseconds = (milliseconds: number) => milliseconds % 1000
const getSeconds = (milliseconds: number) => Math.floor((milliseconds / 1000) % 60)
const getMinutes = (milliseconds: number) => Math.floor((milliseconds / (1000 * 60)) % 60)
const getHours = (milliseconds: number) => Math.floor((milliseconds / (1000 * 60 * 60)) % 24)
const getDays = (milliseconds: number) => Math.floor(milliseconds / (1000 * 60 * 60 * 24))

const getMillisecondsBetween = (d1: string, d2: string) => new Date(d1).getTime() - new Date(d2).getTime()

export const getDuration = (d1: string, d2: string) => {
  const ms = getMillisecondsBetween(d1, d2)

  return {
    ms: ms < 0 ? 0 : getMilliseconds(ms),
    s: ms < 0 ? 0 : getSeconds(ms),
    m: ms < 0 ? 0 : getMinutes(ms),
    h: ms < 0 ? 0 : getHours(ms),
    d: ms < 0 ? 0 : getDays(ms),
  }
}

export const timeElapsedFrom = (from: string, to: string = new Date().toISOString()) => {
  const d = getDuration(to, from)

  return (
    [
      d.d && `${d.d} day${d.d > 1 ? 's' : ''}`,
      d.h && `${d.h} hour${d.h > 1 ? 's' : ''}`,
      d.m && `${d.m} minute${d.m > 1 ? 's' : ''}`,
    ]
      .filter(Boolean)
      .join(' ') || 'less than a minute'
  )
}

export const timeElapsedFromEx = (from: string, to: string = new Date().toISOString()) => {
  const d = getDuration(to, from)

  return (
    [
      d.d && `${d.d} day${d.d > 1 ? 's' : ''}`,
      d.h && `${d.h} hour${d.h > 1 ? 's' : ''}`,
      d.m && `${d.m} minute${d.m > 1 ? 's' : ''}`,
      d.s && `${d.s} second${d.s > 1 ? 's' : ''}`,
    ]
      .filter(Boolean)
      .join(' ') || 'lass than a second'
  )
}

export const pad = (v: number) => `${v}`.padStart(2, '0')

export const timeElapsedTimer = (from: Date) => {
  const ms = new Date().getTime() - from.getTime()

  return `${pad(getDays(ms))}:${pad(getHours(ms))}:${pad(getMinutes(ms))}:${pad(getSeconds(ms))}`
}

export const getUtcLocale = (date: Date) => {
  const timeDiff = date.getTimezoneOffset() * 60000
  const adjustedDate = new Date(date.valueOf() + timeDiff)

  return adjustedDate.toLocaleDateString('en-US')
}
