import { cssVariables } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import styled from 'styled-components'
import { TimeIcon } from './CommonIcons'
import { Grid } from './Grid'
import { SidePanelCard } from './SprintShared'

interface StepperProps<T> {
  className?: string
  items: T[]
  renderIcon?: (item: T, index: number) => ReactNode
  renderStep?: (item: T, index: number) => ReactNode
}

export const Stepper = <T extends unknown>({ className, items, renderIcon, renderStep }: StepperProps<T>) => (
  <StepperContainer className={className}>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid $item $xs={12} key={index}>
        <Grid $container wrap="nowrap">
          <StepMarkers $item $container $alignItems="center" direction="column" wrap="nowrap">
            <Icons>{renderIcon ? renderIcon(item, index) : <TimeIconStyled />}</Icons>
          </StepMarkers>
          <StepContent $item>{renderStep ? renderStep(item, index) : <>Step #{index + 1}</>}</StepContent>
        </Grid>
      </Grid>
    ))}
  </StepperContainer>
)

const StepperContainer = styled(SidePanelCard)`
  padding: ${cssVariables['spacing-l']} ${cssVariables['spacing-xxl']};
  padding-bottom: 0;
  overflow-y: auto;
`

const Icons = styled.div`
  z-index: 1;
`

const StepMarkers = styled(Grid)`
  width: 16px;
  position: relative;
  padding-right: ${(props) => props.theme.spacing.xl};
`

const StepContent = styled(Grid).attrs({ $item: true })`
  padding-bottom: ${(props) => props.theme.spacing.xl};
  min-height: 80px;
`

const TimeIconStyled = styled(TimeIcon)`
  background-color: ${(props) => props.theme.colors.white};
`
