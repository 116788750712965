import { Text, cssVariables } from '@ubnt/ui-components'
import styled from 'styled-components'

export type CustomStepperStep = {
  title: string
  text: string
}

type StepperCircleVariant = 'primary' | 'secondary'

type StepperCircleArgs = {
  variant: StepperCircleVariant
}

export const CustomStepper = ({ steps, variant }: { steps: CustomStepperStep[]; variant: StepperCircleVariant }) => {
  return (
    <div className="flex flex-column grid-column">
      {steps.map((step, index) => (
        <div key={index} className="flex flex-column relative ml-12 pl-24 mb-20">
          <StepperCircle variant={variant}>{index + 1}</StepperCircle>
          <Text size="header-xs" color="primary" weight="bold" className="mb-8">
            {step.title}
          </Text>
          <Text size="body">{step.text}</Text>
        </div>
      ))}
    </div>
  )
}

const StepperCircle = styled.div<StepperCircleArgs>`
  position: absolute;
  left: -12px;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  color: white;
  font-size: 12px;
  font-weight: 700;

  background-color: ${(props) => {
    if (props.variant === 'primary') {
      return cssVariables['blue-3']
    }

    return '#50565E'
  }};
`
