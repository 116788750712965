import { Text } from '@ubnt/ui-components'
import type { ReactNode } from 'react'

// it's not a real `label` ;(
export function CustomLabel({ label, extra, children }: { label: string; extra?: string; children?: ReactNode }) {
  return (
    <div className="flex mb-4">
      <div className="flex-1">
        <Text size="body" color="primary" weight="bold" className="mr-4">
          {label}
        </Text>

        {extra && (
          <Text size="body" color="secondary">
            {extra}
          </Text>
        )}
      </div>

      <div className="flex align-center">{children}</div>
    </div>
  )
}
