import { ArrowLeftPrimaryIcon, ClosePrimaryIcon } from '@ubnt/icons'
import type { ModalProps } from '@ubnt/ui-components'
import { Button, Modal, Text } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Loading } from './Loading'
import { SeparatorLine3 } from './SprintShared'

interface ModalStandardProps extends ModalStyledProps {
  title: ReactNode
  titleLine?: boolean
  contentLoading?: boolean
  onRequestBack?: () => void
}
export default function ModalStandard({
  actions,
  children,
  onRequestBack,
  onRequestClose,
  title,
  titleLine,
  background,
  loading,
  error,
  contentLoading,
  height,
}: ModalStandardProps) {
  return (
    <ModalStyled
      isOpen
      size="small"
      actions={actions}
      onRequestClose={onRequestClose}
      background={background}
      loading={loading}
      error={error}
      height={height}
      header={
        <Header>
          <div className="flex justify-center align-center" style={{ minHeight: '52px' }}>
            {onRequestBack && (
              <AbsoluteButtonLeft onClick={onRequestBack} variant="default">
                <ArrowLeftPrimaryIcon />
              </AbsoluteButtonLeft>
            )}

            <div className="flex justify-center align-center flex-1">
              <Title>{title}</Title>
            </div>

            <AbsoluteButtonRight onClick={onRequestClose} variant="default">
              <ClosePrimaryIcon />
            </AbsoluteButtonRight>
          </div>
          <HeaderSeparatorLine />
        </Header>
      }
    >
      {contentLoading && (
        <div className="flex column justify-center align-center flex-1">
          <Loading />
        </div>
      )}

      {!contentLoading && (
        <>
          {children}

          {titleLine && <SeparatorLine3 />}
          {!titleLine && <br />}

          {error && (
            <div className="flex justify-center" style={{ marginTop: '24px' }}>
              <Text color="danger">{error}</Text>
            </div>
          )}
          {loading && (
            <div className="footer">
              <Loading />
            </div>
          )}
        </>
      )}
    </ModalStyled>
  )
}

const AbsoluteButtonLeft = styled(Button)`
  position: absolute;
  left: -8px;

  &:hover {
    background: none;
  }
`

const AbsoluteButtonRight = styled(Button)`
  position: absolute;
  right: -8px;

  &:hover {
    background: none;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px 24px 32px;
`

const Title = styled(Text).attrs({
  truncate: true,
})`
  max-width: 300px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 16px;
`

const HeaderSeparatorLine = styled.div`
  height: 1px;
  background: #d6d9e2;
`

interface ModalStyledProps extends ModalProps {
  background?: string
  loading?: boolean
  error?: string
}
export const ModalStyled = styled(Modal)<ModalStyledProps>`
  overflow-x: initial;
  overflow-y: initial;

  footer {
    display: ${(props) => (props.loading ? 'none' : 'flex')};
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #f1f2f4;
  }

  div.footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #f1f2f4;
  }

  footer > div > * {
    margin-left: 8px;
  }

  ${(props) =>
    props.background &&
    css`
      background-position: right top !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      background: url(${props.background});
      background-color: #ffffff;
    `}
`
