import type { NotificationId } from 'rma-shared/types/brands'
import type { NotificationDataFragment } from './__generated__/Notifications'

let notifications: NotificationDataFragment[] = []
let notificationsMarkedUnseen: Record<number, boolean> = {}

export const set = (notificationsNew: NotificationDataFragment[]) => {
  notifications = notificationsNew
}

export const markUnseen = (notificationId: NotificationId) => {
  notificationsMarkedUnseen[notificationId] = true
}

export const close = () => {
  const unseenIds = notifications
    .filter((entry) => !entry.seenAt && !notificationsMarkedUnseen[entry.id])
    .map((entry) => entry.id)

  notifications = []
  notificationsMarkedUnseen = {}

  return unseenIds
}
