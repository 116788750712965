import type { StatusBallVariant } from '@ubnt/ui-components'
import { TicketStatus } from 'rma-shared/tickets'

type TextColor = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger'

export const NeutralTicketStatus = [
  TicketStatus.Submitted,
  TicketStatus.DistributorIdentified,
  TicketStatus.Processing,
  TicketStatus.Closed,
]

export const YellowTicketStatus = [TicketStatus.Accepted, TicketStatus.Backordered]
export const RedTicketStatus = [TicketStatus.ReturnCancelled, TicketStatus.Declined, TicketStatus.Expired]
export const GreenTicketStatus = [
  TicketStatus.InFulfilment,
  TicketStatus.Shipped,
  TicketStatus.CreditIssued,
  TicketStatus.Completed,
]

export const getStatusTextVariant = (status: TicketStatus): TextColor =>
  // TODO: FIXME
  /* eslint-disable no-nested-ternary */
  GreenTicketStatus.includes(status)
    ? 'success'
    : YellowTicketStatus.includes(status)
    ? 'warning'
    : RedTicketStatus.includes(status)
    ? 'danger'
    : NeutralTicketStatus.includes(status)
    ? 'secondary'
    : 'primary'
/* eslint-enable no-nested-ternary */

export const getStatusBallVariant = (status: TicketStatus): StatusBallVariant =>
  // TODO: FIXME
  /* eslint-disable no-nested-ternary */
  GreenTicketStatus.includes(status)
    ? 'success'
    : YellowTicketStatus.includes(status)
    ? 'warning'
    : RedTicketStatus.includes(status)
    ? 'danger'
    : NeutralTicketStatus.includes(status)
    ? 'neutral'
    : 'info'
/* eslint-enable no-nested-ternary */

export const PulsatingStatus = YellowTicketStatus
