import styled from 'styled-components'
import { cssVariables, Table } from '@ubnt/ui-components'

export const TableWithStickyHeader = styled(Table)`
  thead {
    position: sticky;
    top: calc(${cssVariables['app-header-height-classic']} + 1px);
    background: ${cssVariables['white-0']};
    z-index: 1;
  }
` as typeof Table
