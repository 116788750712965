import styled from 'styled-components'
import { cssVariables, TextArea } from '@ubnt/ui-components'

export const ChatWrap = styled.div<{ $hasTabsAbove: boolean }>`
  height: calc(100vh - ${({ $hasTabsAbove }) => ($hasTabsAbove ? '226px' : '178px')});
  display: flex;
  flex-direction: column;
`
export const MessagesWrap = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`

export const ComposeWrap = styled.div`
  background-color: ${cssVariables['white-0']};
  padding: ${cssVariables['spacing-l']};
  margin-bottom: ${cssVariables['spacing-l']};
  border-radius: 8px;
  box-shadow: 0px 12px 48px 0px rgba(33, 35, 39, 0.12);
`

export const ComposeActions = styled.div`
  margin-top: ${cssVariables['spacing-s']};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TextAreaStyled = styled(TextArea)`
  margin-top: 0;

  textarea {
    resize: vertical;
    min-height: 44px;
    height: 44px;
    max-height: 200px;
  }
`

export const LoadingStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AttachmentsWrap = styled.div`
  margin-top: ${cssVariables['spacing-s']};
`
