import type { FlowType, TicketStep } from '@shared/tickets'
import { TicketFlowSteps, TicketStatus, TICKET_STATUS_LABELS, TICKET_STEP_LABELS } from '@shared/tickets'
import { LogIcon } from '@ubnt/icons'
import { cssVariables, Loader, Text } from '@ubnt/ui-components'
import type { ReactChild, ReactNode } from 'react'
import { useMemo } from 'react'
import { CanceledByYou } from 'rma-shared/tickets/constants'
import type { TicketId } from 'rma-shared/types/brands'
import type { TicketTabPermission } from 'rma-shared/types/permissions'
import type { TicketStatusEntry } from 'rma-shared/types/ticket-status'
import type { UserNames } from 'rma-shared/types/users'
import styled from 'styled-components'
import { useQuery } from '../../hooks'
import isPortal from '../../utils/isPortal'
import { formatDate } from '../../utils/time'
import { FailedIcon, InactiveIcon, PendingIcon, SuccessIcon } from '../CommonIcons'
import { LoaderContainer } from '../Containers'
import { Stepper } from '../Stepper'

type Status = 'inactive' | 'pending' | 'success' | 'failed'

type Step = {
  type: TicketStep
  label: ReactNode
  subLabel: ReactNode
  status: Status
}

export const Log = ({ ticket }: { ticket: { id: TicketId } }) => {
  const { data, loading } = useQuery('ticketLog', { ticketId: ticket.id })

  const steps = useMemo(() => {
    if (!data) {
      return []
    }

    return getStatuses(data.statuses, data.updatedByNames, data.flowType)
  }, [data])

  if (loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <Stepper
      items={steps}
      renderIcon={(item) => getIcon(item)}
      renderStep={(item) => (
        <>
          <Label>{item.label}</Label>
          <SubLabel>{item.subLabel}</SubLabel>
        </>
      )}
    />
  )
}

const formatLines = (lines: Maybe<string | ReactChild>[]) =>
  // eslint-disable-next-line react/no-array-index-key
  lines.filter(Boolean).map((line, index) => <div key={index}>{line}</div>)

const getStatuses = (statuses: TicketStatusEntry[], updatedByNames: UserNames, flowType: FlowType) => {
  const flowSteps = TicketFlowSteps[flowType]
  const steps = flowSteps.map(
    (step): Step => ({ type: step, label: TICKET_STEP_LABELS[step], subLabel: '', status: 'inactive' }),
  )

  switch (flowType) {
    case 'ADVANCED':
      return fillStepsAdvanced(statuses, steps)

    default:
      return fillStepsBasic(statuses, updatedByNames, steps)
  }
}

const setStepValues = (
  steps: Step[],
  ticketStep: TicketStep,
  status?: Status,
  subLabel?: ReactNode,
  label?: string,
) => {
  const step = steps.find((entry) => entry.type === ticketStep)
  if (!step) {
    return
  }

  step.status = status || step.status
  step.subLabel = subLabel || step.subLabel
  step.label = label || step.label
}

const fillStepsBasic = (statuses: TicketStatusEntry[], updatedByNames: UserNames, steps: Step[]) => {
  for (const entry of statuses) {
    switch (entry.status) {
      case TicketStatus.Submitted:
        setStepValues(steps, 'Submitted', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Confirmed', 'pending')
        setStepValues(
          steps,
          'Confirmed',
          'pending',
          'Pending',
          TICKET_STATUS_LABELS[TicketStatus.DistributorIdentified],
        )
        break

      case TicketStatus.Accepted: {
        const subLabel = isPortal('RMA')
          ? formatLines([formatDate(entry.createdAt), entry.notes])
          : formatLines([
              `Confirmed by ${updatedByNames[entry.updatedBy] || 'Unknown'}`,
              formatDate(entry.createdAt),
              entry.notes,
            ])

        setStepValues(steps, 'Confirmed', 'success', subLabel, TICKET_STATUS_LABELS[TicketStatus.Accepted])
        setStepValues(steps, 'PendingReceipt', 'pending')
        break
      }

      case TicketStatus.ReturnCancelled:
      case TicketStatus.Declined: {
        const notes = entry.notes === CanceledByYou && !isPortal('RMA') ? undefined : entry.notes
        const lastPending = getLastPending(steps)
        if (lastPending) {
          setStepValues(steps, lastPending, 'failed', formatLines([formatDate(entry.createdAt), notes]), 'Canceled')
        }
        break
      }

      case TicketStatus.Expired:
        setStepValues(
          steps,
          'Confirmed',
          'failed',
          formatLines([formatDate(entry.createdAt), entry.notes]),
          TICKET_STATUS_LABELS[TicketStatus.Expired],
        )
        setStepValues(steps, 'PendingReceipt', 'inactive')
        break

      case TicketStatus.Processing:
        setStepValues(
          steps,
          'PendingReceipt',
          'success',
          formatLines([formatDate(entry.createdAt), entry.notes]),
          'Received',
        )
        setStepValues(steps, 'Testing', 'pending', formatLines([formatDate(entry.createdAt), entry.notes]))
        break

      case TicketStatus.Backordered:
        setStepValues(steps, 'Testing', 'success', formatDate(entry.createdAt))
        setStepValues(
          steps,
          'InFulfilment',
          'pending',
          formatDate(entry.createdAt),
          TICKET_STATUS_LABELS[TicketStatus.Backordered],
        )
        break

      case TicketStatus.InFulfilment:
        setStepValues(steps, 'Testing', 'success')
        setStepValues(steps, 'InFulfilment', 'pending', formatLines([formatDate(entry.createdAt), entry.notes]))
        break

      case TicketStatus.Shipped:
        setStepValues(steps, 'InFulfilment', 'success')
        setStepValues(steps, 'Shipped', 'success', formatLines([formatDate(entry.createdAt)]))
        break

      case TicketStatus.CreditIssued:
        setStepValues(steps, 'InFulfilment', 'success', formatDate(entry.createdAt))
        setStepValues(
          steps,
          'Shipped',
          'success',
          formatLines([formatDate(entry.createdAt), entry.notes]),
          'Credit Issued',
        )
        break

      default:
        break
    }
  }

  return steps
}

const fillStepsAdvanced = (statuses: TicketStatusEntry[], steps: Step[]) => {
  for (const entry of statuses) {
    switch (entry.status) {
      case TicketStatus.Submitted:
        setStepValues(steps, 'Submission', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Confirmation', 'pending')
        break

      case TicketStatus.Accepted:
        setStepValues(steps, 'Confirmation', 'success', formatDate(entry.createdAt), 'Approved')
        setStepValues(steps, 'Initiation', 'pending')
        break

      case TicketStatus.InitiatingAdvancement:
        setStepValues(steps, 'Initiation', 'pending', formatDate(entry.createdAt))
        break
      case TicketStatus.ReceivedInitiatingAdvancement:
        setStepValues(steps, 'Initiation', 'pending', formatDate(entry.createdAt))
        setStepValues(steps, 'Receiving', 'success', formatDate(entry.createdAt), 'Received')
        break

      case TicketStatus.InFulfilment:
        setStepValues(steps, 'Initiation', 'success')
        setStepValues(steps, 'Fulfillment', 'pending', formatDate(entry.createdAt))
        break
      case TicketStatus.ReceivedFulfillment:
        setStepValues(steps, 'Initiation', 'success')
        setStepValues(steps, 'Fulfillment', 'pending', formatDate(entry.createdAt))
        setStepValues(steps, 'Receiving', 'success', formatDate(entry.createdAt), 'Received')
        break

      case TicketStatus.Backordered:
        setStepValues(steps, 'Initiation', 'success')
        setStepValues(steps, 'Confirmed', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Fulfillment', 'pending', undefined, 'Backordered')
        break
      case TicketStatus.ReceivedBackordered:
        setStepValues(steps, 'Initiation', 'success')
        setStepValues(steps, 'Confirmed', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Fulfillment', 'pending', undefined, 'Backordered')
        setStepValues(steps, 'Receiving', 'success', formatDate(entry.createdAt), 'Received')
        break

      case TicketStatus.CreditIssued:
        setStepValues(steps, 'Fulfillment', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Shipping', 'success', formatDate(entry.createdAt), 'Credit Issued')
        setStepValues(steps, 'Receiving', 'pending')
        break

      case TicketStatus.ShippedPendingReceipt:
        setStepValues(steps, 'Fulfillment', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Shipping', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Receiving', 'pending')
        break

      case TicketStatus.Closed:
        setStepValues(steps, 'Shipping', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Receiving', 'success', formatDate(entry.createdAt), 'Received')
        break

      case TicketStatus.Completed:
      case TicketStatus.Shipped:
        setStepValues(steps, 'Fulfillment', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Shipping', 'success', formatDate(entry.createdAt))
        setStepValues(steps, 'Receiving', 'success', formatDate(entry.createdAt), 'Received')
        break

      case TicketStatus.ReturnCancelled:
      case TicketStatus.Declined: {
        const notes = entry.notes === CanceledByYou && !isPortal('RMA') ? undefined : entry.notes
        const lastPending = getLastPending(steps)
        if (lastPending) {
          setStepValues(steps, lastPending, 'failed', formatLines([formatDate(entry.createdAt), notes]), 'Canceled')
        }
        break
      }

      default:
        break
    }
  }

  return steps
}

const getIcon = (step: Step) => {
  switch (step.status) {
    case 'success':
      return <SuccessIcon />
    case 'failed':
      return <FailedIcon />
    case 'pending':
      return <PendingIcon />
    case 'inactive':
    default:
      return <InactiveIcon />
  }
}

const getLastPending = (steps: Step[]) => {
  let currentStep: TicketStep | null = null
  for (let n = 0; n < steps.length; n += 1) {
    const step = steps[n]
    if (step.status === 'pending') {
      currentStep = step.type
    }
  }

  return currentStep
}

const Label = styled(Text)`
  font-size: ${cssVariables['font-size-header-xs']};
  line-height: 18px;
  margin-bottom: 4px;
`

const SubLabel = styled(Text).attrs({ color: 'tertiary' })`
  font-size: ${cssVariables['font-size-caption']};
  line-height: 14px;
  display: block;
`

const LogIconCustom = styled(LogIcon)`
  width: 26px;
  height: 26px;
  margin: -2px;
`

export const LogTab = {
  id: 'log',
  path: '/log',
  icon: <LogIconCustom size="navigation" />,
  component: Log,
  permission: 'ticket-tab-log' as TicketTabPermission,
}
