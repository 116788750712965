import type { CountryCode } from 'libphonenumber-js/max'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { haveStatesEnabled } from 'rma-shared/lib/constants'
import * as yup from 'yup'
import { ADDRESS_MAX_LENGTH, DEFAULT_VARCHAR_MAX_LENGTH, REQUIRED, TOO_LONG, VALID_EMAIL, VALID_PHONE } from './const'

const InvalidZip = 'ZIP/Postal code does not match the selected country.'

const country = yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG)

export const ShippingAddressInputSchema = yup
  .object({
    firstName: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    lastName: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    companyName: yup.string().max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG).notRequired(),
    phoneNumber: yup
      .string()
      .required(REQUIRED)
      .max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG)
      .test('is-valid-number', VALID_PHONE, (phone) => {
        if (!phone) {
          return false
        }

        return parsePhoneNumberFromString(phone)?.isValid() === true
      }),
    email: yup.string().email(VALID_EMAIL).required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    country: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    address1: yup.string().required(REQUIRED).max(ADDRESS_MAX_LENGTH, TOO_LONG),
    city: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    state: yup
      .string()
      .max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG)
      .when('country', (value: typeof country.type, schema: yup.StringSchema<string>) =>
        haveStatesEnabled(value as CountryCode) ? schema.required(REQUIRED) : schema.notRequired().default(''),
      ),
    zipcode: yup
      .string()
      .required(REQUIRED)
      .max(11)
      .ensure()
      .defined()
      .when('country', (value: CountryCode, schema: yup.StringSchema<string>) => {
        if (value === 'GE') {
          return schema.test('valid-ge-zip', InvalidZip, isGeorgiaCountryZip)
        }

        return schema
      })
      .when('state', (value: string, schema: yup.StringSchema<string>) => {
        if (value === 'GA') {
          return schema.test('valid-ga-zip', InvalidZip, isGeorgiaStateZip)
        }

        return schema
      }),
  })
  .required()

type ShippingAddressInputRaw = yup.InferType<typeof ShippingAddressInputSchema>

export type ShippingAddressInput = ShippingAddressInputRaw & { companyName: string }

export const validateShippingAddressInput = (input: ShippingAddressInput) => ShippingAddressInputSchema.validate(input)

const isGeorgiaCountryZip = (zip?: string | null) => {
  if (isGeorgiaStateZip(zip)) {
    return false
  }

  return true
}

function isGeorgiaStateZip(zip?: string | null) {
  if (!zip) {
    return false
  }

  const zipKey = Number(zip.substring(0, 2))

  return zipKey >= 30 && zipKey <= 39 && zip.length === 5
}
