import { cssVariables } from '@ubnt/ui-components'
import { FieldArray, Formik } from 'formik'
import type { FormikHelpers } from 'formik/dist/types'
import type { Dispatch, FC, SetStateAction } from 'react'
import { memo, useCallback, useRef, useState } from 'react'
import { TICKET_ATTACHMENT_VALID_FILE_TYPES } from 'rma-shared/tickets'
import type { ChatId, ChatMessageId } from 'rma-shared/types/brands'
import styled from 'styled-components'
import { AttachmentIcon } from '@ubnt/icons'
import { MAX_ZD_COMMENT_LENGTH } from '@shared/lib/constants/index'
import { Button } from '../Button'
import { Text } from '../Text'
import type { UploadingFiles } from './Chat'
import { useSendMessageMutation } from './__generated__/Chat'
import { useUploadChatFilesMutation } from './__generated__/ChatForm'
import { AttachmentsWrap, ComposeActions, ComposeWrap, TextAreaStyled } from './layout'
import { Attachment } from '../customer-support/Attachment'

const fileId = () => Math.random().toString(36).substr(2, 9)

const MAX_MESSAGE_LENGTH = 2500
const MAX_FILE_SIZE = 10485760 // 10MB

type Values = { files: { file: File; id: string }[] }

interface ChatFormProps {
  chatId: ChatId
  parentId?: ChatMessageId | null
  setUploadingFiles: Dispatch<SetStateAction<UploadingFiles[]>>
  isInitialLoading: boolean
}
export const ChatForm: FC<ChatFormProps> = memo(({ chatId, setUploadingFiles, isInitialLoading }) => {
  const textAreaEl = useRef<HTMLTextAreaElement | null>(null)
  const [body, setBody] = useState('')
  const [submitError, setSubmitError] = useState(false)
  const [invalidFiles, setInvalidFiles] = useState<{ id: string; file: File; error: string }[]>([])
  const fileUploadRef = useRef<HTMLInputElement>(null)

  const handleWrongFileRemove = useCallback(
    (id: string) => {
      setInvalidFiles(invalidFiles.filter((invalidFile) => invalidFile.id !== id))
    },
    [invalidFiles],
  )

  const [sendMessage] = useSendMessageMutation()
  const [uploadChatFiles] = useUploadChatFilesMutation()

  const [filesAdded, setFilesAdded] = useState(0)

  const onSubmit = useCallback(
    async ({ files }: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
      try {
        const response = await sendMessage({
          variables: {
            chatId,
            input: {
              content: body,
            },
          },
        })
        const messageId = response.data?.chat.sendMessage.id

        if (!messageId) {
          setSubmitting(false)

          return
        }

        setBody('')
        resetForm()
        setSubmitting(false)

        setUploadingFiles((prev) => [
          ...prev,
          {
            id: messageId,
            files: files.map(({ file }) => ({
              name: file.name,
              loading: true,
            })),
          },
        ])

        setFilesAdded(0)
        try {
          await Promise.all(
            files.map(async (file, fileIndex) => {
              await uploadChatFiles({
                variables: {
                  chatId,
                  messageId,
                  files: [{ file: file.file }],
                },
              })
              setUploadingFiles((prev) => {
                const existing = prev.find((entry) => entry.id === messageId)
                if (existing) {
                  existing.files[fileIndex].loading = false
                }

                return [...prev]
              })
            }),
          )
        } finally {
          setUploadingFiles((prev) => [...prev.filter((entry) => entry.id !== messageId)])
        }
      } catch (err) {
        setSubmitError(true)
      }
    },
    [body, sendMessage, chatId, setUploadingFiles, uploadChatFiles],
  )

  const onUploadClick = useCallback(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click()
    }
  }, [fileUploadRef])

  const maxFilesError =
    filesAdded + invalidFiles.length > 10 ? 'It is allowed to upload up to 10 files per chat message' : undefined

  return (
    <ComposeWrap>
      <Formik
        initialValues={{
          files: [] as { file: File; id: string }[],
        }}
        validateOnMount
        onSubmit={onSubmit}
      >
        {({ handleSubmit, resetForm, isSubmitting, values, isValid }) => {
          const addedFiles = values['files'] || []
          const addedWrongTypeFiles: { id: string; error: string; file: File }[] = []
          const hasFiles = addedFiles.length > 0
          const messageLength = body.trim().length
          const hasFilesWithEmptyText = hasFiles && !invalidFiles.length && messageLength === 0

          const isDisabled =
            isInitialLoading ||
            isSubmitting ||
            !isValid ||
            !((messageLength <= MAX_MESSAGE_LENGTH && messageLength > 0) || hasFilesWithEmptyText)

          if (addedFiles.length > 0 && filesAdded === 0) {
            resetForm()
          }

          return (
            <form
              onSubmit={(event) => {
                event.preventDefault()
                if (!isDisabled) {
                  handleSubmit()
                }
              }}
            >
              <FieldArray
                name="files"
                render={({ push, remove }) => {
                  const handleFileDrop = (addingFiles: globalThis.File[]) => {
                    let newFilesCount = 0
                    const newFiles = Array.from(addingFiles).filter((file) => {
                      if (!TICKET_ATTACHMENT_VALID_FILE_TYPES.includes(file.type)) {
                        addedWrongTypeFiles.push({
                          id: fileId(),
                          file,
                          error: 'Only .jpg, .png, .pdf files allowed for upload.',
                        })
                        return false
                      }

                      if (file.size > MAX_FILE_SIZE) {
                        addedWrongTypeFiles.push({ id: fileId(), file, error: 'File size must not exceed 10MB.' })
                        return false
                      }

                      return true
                    })

                    newFiles
                      .map((file) => ({ file, id: fileId() }))
                      .forEach((file) => {
                        push(file)
                        // eslint-disable-next-line no-plusplus
                        newFilesCount++
                      })
                    setFilesAdded(filesAdded + newFilesCount)
                    setInvalidFiles([...invalidFiles, ...addedWrongTypeFiles])
                  }

                  const handleRemoveFile = (index: number) => {
                    remove(index)
                    setFilesAdded(filesAdded - 1)
                  }

                  return (
                    <>
                      <input
                        accept={TICKET_ATTACHMENT_VALID_FILE_TYPES.join(',')}
                        type="file"
                        id="file"
                        ref={fileUploadRef}
                        style={{ display: 'none' }}
                        multiple
                        onChange={(e) => {
                          if (e.target.files) {
                            handleFileDrop([...e.target.files])
                          }
                        }}
                      />
                      <TextAreaStyled
                        disabled={isSubmitting}
                        value={body}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setBody(e.currentTarget.value)}
                        maxLength={MAX_MESSAGE_LENGTH}
                        variant="secondary"
                        full
                        placeholder="Type your message here"
                        onKeyUp={() => {
                          if (textAreaEl.current && textAreaEl.current.scrollHeight > textAreaEl.current.clientHeight) {
                            textAreaEl.current.style.height = `${textAreaEl.current.scrollHeight}px`
                          }
                        }}
                        ref={textAreaEl}
                      />
                      {(hasFiles || !!invalidFiles.length) && (
                        <AttachmentsWrap>
                          {addedFiles.map((fileEntry, index) => (
                            <Attachment
                              key={fileEntry.id}
                              attachment={{ name: fileEntry.file.name, size: fileEntry.file.size }}
                              onRemoveFile={() => handleRemoveFile(index)}
                            />
                          ))}
                          {invalidFiles.map((fileEntry) => (
                            <Attachment
                              key={fileEntry.id}
                              attachment={{
                                name: fileEntry.file.name,
                                size: fileEntry.file.size,
                                error: fileEntry.error,
                              }}
                              onRemoveFile={() => handleWrongFileRemove(fileEntry.id)}
                            />
                          ))}
                        </AttachmentsWrap>
                      )}
                      <ComposeActions>
                        <Button
                          variant="primary"
                          size="small"
                          type="submit"
                          disabled={isDisabled}
                          loader={isSubmitting ? 'loading' : undefined}
                        >
                          Send
                        </Button>

                        <AttachButtonWrap>
                          <UploadButton Icon={AttachmentIcon} onClick={onUploadClick} title="Upload files" />
                        </AttachButtonWrap>

                        <Text color="tertiary" size="caption">
                          {body.length} / {MAX_ZD_COMMENT_LENGTH} characters
                        </Text>
                      </ComposeActions>
                      {(submitError || maxFilesError) && (
                        <Text color="danger" size="body" style={{ marginTop: cssVariables['spacing-s'] }}>
                          {submitError ? 'Message sending failed. Please try again later.' : maxFilesError}
                        </Text>
                      )}
                    </>
                  )
                }}
              />
            </form>
          )
        }}
      </Formik>
    </ComposeWrap>
  )
})

const UploadButton = styled(Button).attrs(() => ({
  variant: 'tertiary',
  size: 'small',
  type: 'button',
}))`
  margin-left: ${cssVariables['spacing-xs']};
  padding: 0 ${cssVariables['spacing-xxs']};
`

const AttachButtonWrap = styled.div`
  flex-grow: 1;
  display: flex;
`
