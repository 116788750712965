import { UCareLogoBadge } from '@shared-ui/components'
import { useRequest } from '@shared-ui/hooks'
import { InfoIconL } from '@ubnt/icons'
import { Button, Checkbox, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import { getReplacementPartSku } from 'rma-shared/lib/utils/device'
import { theme } from 'styles/theme'
import type { DeviceId } from '@shared/types/brands'
import { SubmitRmaService } from '../submit-rma-service'
import { useSubmitRmaStore } from '../submit-rma-store'
import { DevicePartsReturn } from './device-parts-return'
import { ReturnConfirmation } from './return-confirmation'
import { NotMyDevice } from './not-my-device'
import { RmaDevicePanel } from './rma-device-panel'
import { StepHeading } from './step-heading'
import { SubmitContainer } from './submit-container'
import { UCareGradient } from './u-care'
import { UCareBadge } from './u-care-badge'

export function DeviceFound() {
  const { device, currSku, submitAsReplacementPart, isInputMac, supportsUICare } = useSubmitRmaStore()
  const [notMyDevice, setNotMyDevice] = useState(false)
  const [haveUICare, setHaveUICare] = useState(false)

  const [updateDeviceKit, { loading: updateDeviceKitLoading, error }] = useRequest('updateDeviceKit')
  const [updateReplacementPart, { loading: updateReplacementPartLoading }] = useRequest('updateReplacementPart')
  const [updateDeviceAsUICare, { loading: updateAsUICareLoading }] = useRequest('updateDeviceAsUICare')

  const handleNotMyDevice = () => {
    setNotMyDevice(true)

    useSubmitRmaStore.setState({
      kit: null,
      kits: [],
      kitDevices: [],
    })
  }

  const handleNotMyDeviceBack = () => {
    setNotMyDevice(false)
  }

  if (!device) {
    return null
  }

  if (notMyDevice) {
    return (
      <SubmitContainer>
        <NotMyDevice onBack={handleNotMyDeviceBack} />
      </SubmitContainer>
    )
  }

  const isLoading = updateDeviceKitLoading || updateReplacementPartLoading || updateAsUICareLoading
  const isNextDisabled = !SubmitRmaService.canStartFlow() || isLoading
  const replacementPartSku = getReplacementPartSku(device)
  const showReplacementPartSku = !!replacementPartSku

  const handleNext = async () => {
    const { kit, kitReturnType, kitDevices } = useSubmitRmaStore.getState()

    try {
      if (kit && kit.id !== -1) {
        const result = await updateDeviceKit({
          deviceId: device.id,
          kitSku: kit.sku,
          kitReturnType,
          kitDevices: kitDevices.map((entry) => entry.deviceId) as DeviceId[],
        })
        SubmitRmaService.processDevice(result.device, { supportsUICare: result.supportUICare })
      } else if (submitAsReplacementPart) {
        const result = await updateReplacementPart({ deviceId: device.id })
        SubmitRmaService.processDevice(result.device, { supportsUICare: result.supportUICare })
      } else if (haveUICare) {
        const result = await updateDeviceAsUICare({ deviceId: device.id })
        SubmitRmaService.processDevice(result.device, { supportsUICare: true, manualUICare: true })
      }

      SubmitRmaService.startFlow()
    } catch (err) {
      //
    }
  }

  return (
    <SubmitContainer>
      {device.isUnifiCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column relative p-16">
        <StepHeading showStepper heading="Is this your device?" />

        <Text size="body" color="secondary" className="mt-8">
          Please ensure the model and {isInputMac ? 'MAC address' : 'serial number'} match your device.
        </Text>

        <RmaDevicePanel device={device} skuOverride={currSku} />

        {showReplacementPartSku && (
          <div className="mt-12">
            <DevicePartsReturn sku={device.name} partSku={replacementPartSku} />
          </div>
        )}

        {!device.isUnifiCare && !device.isAdvancedFlowAvailable && (
          <div className="mt-4">
            <Button variant="link" onClick={handleNotMyDevice} inlineWrap className="p-0">
              <Text size="body" color="info">
                This is not my device
              </Text>
            </Button>
          </div>
        )}

        <ReturnConfirmation />

        {supportsUICare && (
          <div className="flex column mt-24 mb-25">
            <UICareConfirmation value={haveUICare} onChange={(newValue) => setHaveUICare(newValue)} />

            {(!device.mac || !device.dateCode) && haveUICare && (
              <div className="flex mt-8 p-8 border-radius bg-gray">
                <div className="mr-8">
                  <InfoIconL width={20} height={20} variant="fill" fill="blue" color={theme.colors.primary} />
                </div>
                <Text size="body">
                  Since we couldn&apos;t identify your device based on the MAC ID, there might be a delay in processing
                  your RMA request. Our support team might reach out to you to speed up the process.
                </Text>
              </div>
            )}
          </div>
        )}

        {error && (
          <div className="mt-12">
            <Text color="danger" size="body">
              {error}
            </Text>
          </div>
        )}

        <div className="flex justify-end mt-20 gap-8">
          <Button variant="tertiary" onClick={SubmitRmaService.reset}>
            Back
          </Button>

          <Button
            variant="primary"
            onClick={handleNext}
            disabled={isNextDisabled}
            loader={isLoading ? 'dots' : undefined}
          >
            Next
          </Button>
        </div>
      </div>
    </SubmitContainer>
  )
}

export function UICareConfirmation({ value, onChange }: { value: boolean; onChange: (newValue: boolean) => void }) {
  return (
    <div className="flex column gap-8">
      <Text size="body" weight="bold">
        Does this device have UI Care protection?
      </Text>

      <div className={`flex align-center p-8 border border-radius ${value ? `border-blue bg-uicare-light` : ''}`}>
        <UCareLogoBadge />

        <Text size="body" className="flex-1 ml-12">
          UI Care protection
        </Text>

        <Checkbox id="have-ui-care" checked={value} onChange={(event) => onChange(event.currentTarget.checked)} />
      </div>
    </div>
  )
}
