// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type MessageFragment = { readonly __typename?: 'Message' } & Pick<
  Types.Message,
  'id' | 'canManage' | 'content' | 'important' | 'sentByMe' | 'sentAt' | 'readAt' | 'updatedAt' | 'removedAt'
> & {
    readonly sentBy:
      | ({ readonly __typename?: 'Company' } & Pick<Types.Company, 'name'> & { picture: Types.Company['logo'] })
      | ({ readonly __typename?: 'Customer' } & Pick<Types.Customer, 'email' | 'picture'> & {
            name: Types.Customer['fullname']
          })
    readonly thread: Types.Maybe<
      { readonly __typename?: 'MessageThread' } & Pick<
        Types.MessageThread,
        'importantCount' | 'replyCount' | 'users' | 'lastSentAt' | 'unreadMessageCount'
      >
    >
    readonly files: ReadonlyArray<{ readonly __typename?: 'File' } & Pick<Types.File, 'id' | 'filename' | 'url'>>
  }

export type ThreadMessagesQueryVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  parentId: Types.Scalars['ChatMessageId']
}>

export type ThreadMessagesQuery = { readonly __typename?: 'Query' } & {
  readonly chat: Types.Maybe<
    { readonly __typename?: 'Chat' } & Pick<Types.Chat, 'id'> & {
        readonly message: { readonly __typename?: 'Message' } & Pick<Types.Message, 'id'> & {
            readonly messages: Types.Maybe<ReadonlyArray<{ readonly __typename?: 'Message' } & MessageFragment>>
          }
      }
  >
}

export type GetMessageQueryVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  messageId: Types.Scalars['ChatMessageId']
}>

export type GetMessageQuery = { readonly __typename?: 'Query' } & {
  readonly chat: Types.Maybe<
    { readonly __typename?: 'Chat' } & Pick<Types.Chat, 'id'> & {
        readonly message: { readonly __typename?: 'Message' } & MessageFragment
      }
  >
}

export type SendMessageMutationVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  input: Types.MessageInput
}>

export type SendMessageMutation = { readonly __typename?: 'Mutation' } & {
  readonly chat: { readonly __typename?: 'ChatMutations' } & {
    readonly sendMessage: { readonly __typename?: 'Message' } & Pick<Types.Message, 'id'>
  }
}

export type SaveMessageMutationVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  messageId: Types.Scalars['ChatMessageId']
  input: Types.EditMessageInput
}>

export type SaveMessageMutation = { readonly __typename?: 'Mutation' } & {
  readonly chat: { readonly __typename?: 'ChatMutations' } & {
    readonly message: { readonly __typename?: 'MessageMutations' } & {
      readonly edit: { readonly __typename?: 'Message' } & Pick<Types.Message, 'id' | 'content' | 'important'>
    }
  }
}

export type ReadMessagesMutationVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  parentId: Types.Maybe<Types.Scalars['ChatMessageId']>
}>

export type ReadMessagesMutation = { readonly __typename?: 'Mutation' } & {
  readonly chat: { readonly __typename?: 'ChatMutations' } & Pick<Types.ChatMutations, 'readMessages'>
}

export type OnMessageSentSubscriptionVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
}>

export type OnMessageSentSubscription = { readonly __typename?: 'Subscription' } & {
  readonly messageSent: { readonly __typename?: 'Message' } & MessageFragment
}

export type OnMessageUpdatedSubscriptionVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
}>

export type OnMessageUpdatedSubscription = { readonly __typename?: 'Subscription' } & {
  readonly messageUpdated: { readonly __typename?: 'Message' } & MessageFragment
}

export type OnThreadMessageSentSubscriptionVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  parentId: Types.Scalars['ChatMessageId']
}>

export type OnThreadMessageSentSubscription = { readonly __typename?: 'Subscription' } & {
  readonly threadMessageSent: { readonly __typename?: 'Message' } & MessageFragment
}

export type OnThreadMessageUpdatedSubscriptionVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  parentId: Types.Scalars['ChatMessageId']
}>

export type OnThreadMessageUpdatedSubscription = { readonly __typename?: 'Subscription' } & {
  readonly threadMessageUpdated: { readonly __typename?: 'Message' } & MessageFragment
}

export type OnThreadMessageRemovedSubscriptionVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  parentId: Types.Scalars['ChatMessageId']
}>

export type OnThreadMessageRemovedSubscription = { readonly __typename?: 'Subscription' } & {
  readonly threadMessageRemoved: { readonly __typename?: 'Message' } & MessageFragment
}

export type ChatUpdatedSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['Int']
  fetchActive: Types.Maybe<Types.Scalars['Boolean']>
  fetchUnread: Types.Maybe<Types.Scalars['Boolean']>
  filter: Types.Maybe<Types.Scalars['JSON']>
}>

export type ChatUpdatedSubscription = { readonly __typename?: 'Subscription' } & {
  readonly chatUpdated: { readonly __typename?: 'Chat' } & Pick<
    Types.Chat,
    'id' | 'lastMessageSentAt' | 'messageCount' | 'name' | 'properties' | 'unreadMessageCount'
  >
}

export const MessageFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const ThreadMessagesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ThreadMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'messageId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'messages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useThreadMessagesQuery__
 *
 * To run a query within a React component, call `useThreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadMessagesQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useThreadMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<ThreadMessagesQuery, ThreadMessagesQueryVariables>,
) {
  return Apollo.useQuery<ThreadMessagesQuery, ThreadMessagesQueryVariables>(ThreadMessagesDocument, baseOptions)
}
export function useThreadMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThreadMessagesQuery, ThreadMessagesQueryVariables>,
) {
  return Apollo.useLazyQuery<ThreadMessagesQuery, ThreadMessagesQueryVariables>(ThreadMessagesDocument, baseOptions)
}
export type ThreadMessagesQueryHookResult = ReturnType<typeof useThreadMessagesQuery>
export type ThreadMessagesLazyQueryHookResult = ReturnType<typeof useThreadMessagesLazyQuery>
export type ThreadMessagesQueryResult = Apollo.QueryResult<ThreadMessagesQuery, ThreadMessagesQueryVariables>
export const GetMessageDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'messageId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useGetMessageQuery(baseOptions: Apollo.QueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
  return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, baseOptions)
}
export function useGetMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>,
) {
  return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, baseOptions)
}
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>
export type GetMessageLazyQueryHookResult = ReturnType<typeof useGetMessageLazyQuery>
export type GetMessageQueryResult = Apollo.QueryResult<GetMessageQuery, GetMessageQueryVariables>
export const SendMessageDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessageInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendMessage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'message' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>,
) {
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, baseOptions)
}
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>
export const SaveMessageDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditMessageInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'messageId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edit' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'message' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'important' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type SaveMessageMutationFn = Apollo.MutationFunction<SaveMessageMutation, SaveMessageMutationVariables>

/**
 * __useSaveMessageMutation__
 *
 * To run a mutation, you first call `useSaveMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMessageMutation, { data, loading, error }] = useSaveMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      messageId: // value for 'messageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveMessageMutation, SaveMessageMutationVariables>,
) {
  return Apollo.useMutation<SaveMessageMutation, SaveMessageMutationVariables>(SaveMessageDocument, baseOptions)
}
export type SaveMessageMutationHookResult = ReturnType<typeof useSaveMessageMutation>
export type SaveMessageMutationResult = Apollo.MutationResult<SaveMessageMutation>
export type SaveMessageMutationOptions = Apollo.BaseMutationOptions<SaveMessageMutation, SaveMessageMutationVariables>
export const ReadMessagesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReadMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'readMessages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'parentId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type ReadMessagesMutationFn = Apollo.MutationFunction<ReadMessagesMutation, ReadMessagesMutationVariables>

/**
 * __useReadMessagesMutation__
 *
 * To run a mutation, you first call `useReadMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessagesMutation, { data, loading, error }] = useReadMessagesMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useReadMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<ReadMessagesMutation, ReadMessagesMutationVariables>,
) {
  return Apollo.useMutation<ReadMessagesMutation, ReadMessagesMutationVariables>(ReadMessagesDocument, baseOptions)
}
export type ReadMessagesMutationHookResult = ReturnType<typeof useReadMessagesMutation>
export type ReadMessagesMutationResult = Apollo.MutationResult<ReadMessagesMutation>
export type ReadMessagesMutationOptions = Apollo.BaseMutationOptions<
  ReadMessagesMutation,
  ReadMessagesMutationVariables
>
export const OnMessageSentDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnMessageSent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageSent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useOnMessageSentSubscription__
 *
 * To run a query within a React component, call `useOnMessageSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageSentSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useOnMessageSentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnMessageSentSubscription, OnMessageSentSubscriptionVariables>,
) {
  return Apollo.useSubscription<OnMessageSentSubscription, OnMessageSentSubscriptionVariables>(
    OnMessageSentDocument,
    baseOptions,
  )
}
export type OnMessageSentSubscriptionHookResult = ReturnType<typeof useOnMessageSentSubscription>
export type OnMessageSentSubscriptionResult = Apollo.SubscriptionResult<OnMessageSentSubscription>
export const OnMessageUpdatedDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnMessageUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'messageUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useOnMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageUpdatedSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useOnMessageUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<OnMessageUpdatedSubscription, OnMessageUpdatedSubscriptionVariables>,
) {
  return Apollo.useSubscription<OnMessageUpdatedSubscription, OnMessageUpdatedSubscriptionVariables>(
    OnMessageUpdatedDocument,
    baseOptions,
  )
}
export type OnMessageUpdatedSubscriptionHookResult = ReturnType<typeof useOnMessageUpdatedSubscription>
export type OnMessageUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnMessageUpdatedSubscription>
export const OnThreadMessageSentDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnThreadMessageSent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threadMessageSent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useOnThreadMessageSentSubscription__
 *
 * To run a query within a React component, call `useOnThreadMessageSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnThreadMessageSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnThreadMessageSentSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnThreadMessageSentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnThreadMessageSentSubscription,
    OnThreadMessageSentSubscriptionVariables
  >,
) {
  return Apollo.useSubscription<OnThreadMessageSentSubscription, OnThreadMessageSentSubscriptionVariables>(
    OnThreadMessageSentDocument,
    baseOptions,
  )
}
export type OnThreadMessageSentSubscriptionHookResult = ReturnType<typeof useOnThreadMessageSentSubscription>
export type OnThreadMessageSentSubscriptionResult = Apollo.SubscriptionResult<OnThreadMessageSentSubscription>
export const OnThreadMessageUpdatedDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnThreadMessageUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threadMessageUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useOnThreadMessageUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnThreadMessageUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnThreadMessageUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnThreadMessageUpdatedSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnThreadMessageUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnThreadMessageUpdatedSubscription,
    OnThreadMessageUpdatedSubscriptionVariables
  >,
) {
  return Apollo.useSubscription<OnThreadMessageUpdatedSubscription, OnThreadMessageUpdatedSubscriptionVariables>(
    OnThreadMessageUpdatedDocument,
    baseOptions,
  )
}
export type OnThreadMessageUpdatedSubscriptionHookResult = ReturnType<typeof useOnThreadMessageUpdatedSubscription>
export type OnThreadMessageUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnThreadMessageUpdatedSubscription>
export const OnThreadMessageRemovedDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnThreadMessageRemoved' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threadMessageRemoved' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useOnThreadMessageRemovedSubscription__
 *
 * To run a query within a React component, call `useOnThreadMessageRemovedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnThreadMessageRemovedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnThreadMessageRemovedSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useOnThreadMessageRemovedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnThreadMessageRemovedSubscription,
    OnThreadMessageRemovedSubscriptionVariables
  >,
) {
  return Apollo.useSubscription<OnThreadMessageRemovedSubscription, OnThreadMessageRemovedSubscriptionVariables>(
    OnThreadMessageRemovedDocument,
    baseOptions,
  )
}
export type OnThreadMessageRemovedSubscriptionHookResult = ReturnType<typeof useOnThreadMessageRemovedSubscription>
export type OnThreadMessageRemovedSubscriptionResult = Apollo.SubscriptionResult<OnThreadMessageRemovedSubscription>
export const ChatUpdatedDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'ChatUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fetchActive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fetchUnread' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchActive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fetchActive' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fetchUnread' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fetchUnread' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'messageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'properties' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useChatUpdatedSubscription__
 *
 * To run a query within a React component, call `useChatUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatUpdatedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *      fetchActive: // value for 'fetchActive'
 *      fetchUnread: // value for 'fetchUnread'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChatUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<ChatUpdatedSubscription, ChatUpdatedSubscriptionVariables>,
) {
  return Apollo.useSubscription<ChatUpdatedSubscription, ChatUpdatedSubscriptionVariables>(
    ChatUpdatedDocument,
    baseOptions,
  )
}
export type ChatUpdatedSubscriptionHookResult = ReturnType<typeof useChatUpdatedSubscription>
export type ChatUpdatedSubscriptionResult = Apollo.SubscriptionResult<ChatUpdatedSubscription>
