import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import type { PossibleTypesMap, TypePolicies } from '@apollo/client/cache/inmemory/policies'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { logError, logInfo } from 'rma-shared/lib/log'
import { AuthService } from '../auth/auth-service'

type Options = {
  uri: string
  wsUri: string
  possibleTypes?: PossibleTypesMap
  typePolicies?: TypePolicies
}

let cache: InMemoryCache | null

export const createApolloClient = (options: Options) => {
  cache = new InMemoryCache({ possibleTypes: options.possibleTypes, typePolicies: options.typePolicies })

  return new ApolloClient({
    link: ApolloLink.from([
      new RetryLink({
        delay: {
          initial: 300,
          max: 10 * 1000,
          jitter: true,
        },
        attempts: {
          max: 2,
          retryIf: async (err: { statusCode: number }) => {
            if (err.statusCode === 401) {
              await AuthService.getValidAccessToken()

              return true
            }

            return !!err
          },
        },
      }),
      onError(({ graphQLErrors, networkError }) => {
        // TODO: Modal?
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            logInfo(
              `[GraphQL error]: Message: ${message}, Location: ${locations?.toString() ?? ''}, Path: ${
                path?.toString() ?? ''
              }`,
            ),
          )
        }
        if (networkError) {
          logError('[Network error]:', networkError)
          if (networkError.message !== 'Failed to fetch' && networkError.message !== 'Failed to load') {
            // setServiceStatus('unavailable')
          }
        }
      }),
      setContext(async () => {
        const authorization = await AuthService.getValidAccessToken()

        return { headers: { authorization } }
      }),
      ApolloLink.split(
        ({ query }) => {
          const definition = getMainDefinition(query)

          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        },
        new WebSocketLink({
          uri: options.wsUri,
          options: {
            reconnect: true,
            connectionParams: async () => {
              const authorization = await AuthService.getValidAccessToken()

              return { authorization }
            },
            connectionCallback(error: Error[]) {
              if (!error || error.length === 0) {
                // setServiceStatus('connected')
              }
            },
          },
        }),
        createUploadLink({ uri: options.uri }),
      ),
    ]),
    cache,
  })
}

export function updateCacheItem(id: string, fieldsMap: Record<string, string | boolean>) {
  if (!cache) {
    return
  }

  const fields: Record<string, () => string | boolean> = {}
  for (const fieldsKey in fieldsMap) {
    if (Object.prototype.hasOwnProperty.call(fieldsMap, fieldsKey)) {
      fields[fieldsKey] = () => {
        return fieldsMap[fieldsKey]
      }
    }
  }

  cache.modify({
    id,
    fields,
  })
}
