import type { CountryCode } from 'libphonenumber-js'
import type { FlowType } from 'rma-shared/tickets'
import { TicketStatus } from 'rma-shared/tickets'

export type TrackingNumberType = 'show' | 'partial' | null

export const getTrackingNumberType = ({
  country,
  status,
  flow,
  isUnifiCare,
  isSupportedByUI,
}: {
  country: CountryCode
  flow: FlowType
  status: TicketStatus
  isUnifiCare: boolean
  isSupportedByUI: boolean
}): TrackingNumberType => {
  if (!isSupportedByUI) {
    return null
  }

  const externallyManagedCountries: CountryCode[] = ['IN', 'BR']
  const isExternallyManagedCountry = externallyManagedCountries.includes(country)

  if (flow === 'ADVANCED' || isUnifiCare) {
    if (status !== TicketStatus.ShippedPendingReceipt || isExternallyManagedCountry) {
      return null
    }
  }

  if (flow === 'BASIC') {
    if (status !== TicketStatus.Accepted) {
      return null
    }

    if (isExternallyManagedCountry) {
      return 'partial'
    }
  }

  return 'show'
}
