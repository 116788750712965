import type { CountryCode} from 'libphonenumber-js/min';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import * as yup from 'yup'
import { haveStatesEnabled } from '../../constants'
import { DEFAULT_VARCHAR_MAX_LENGTH, REQUIRED, TOO_LONG, VALID_PHONE } from './const'

const country = yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG)

export const AddressInputSchema = yup
  .object({
    address: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    city: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    state: yup
      .string()
      .max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG)
      .when('country', (value: typeof country.type, schema: yup.StringSchema<string>) =>
        haveStatesEnabled(value as CountryCode) ? schema.required(REQUIRED) : schema.notRequired().nullable(),
      ),
    country,
    zipcode: yup.string().required(REQUIRED).max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG),
    phoneNumber: yup
      .string()
      .required(REQUIRED)
      .max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG)
      .test('is-valid-number', VALID_PHONE, (phone) => {
        if (!phone) {
          return false
        }

        return parsePhoneNumberFromString(phone)?.isValid() === true
      }),
  })
  .required()

export type AddressInput = yup.InferType<typeof AddressInputSchema>

export const validateAddressInput = (input: AddressInput) => AddressInputSchema.validate(input)
