import { Count, cssVariables } from '@ubnt/ui-components'
import { useMemo } from 'react'
import { Link, Route, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NotificationIconL } from '@ubnt/icons'
import getBaseUrl from '../../utils/getBaseUrl'
import { useNotificationAddedSubscription, useNotificationsFetchQuery } from './__generated__/Notifications'

export const NotificationsHeaderButton = () => {
  const { data, refetch } = useNotificationsFetchQuery()
  const location = useLocation()

  const baseUrl = useMemo(getBaseUrl, [location])

  useNotificationAddedSubscription({
    onSubscriptionData() {
      void refetch()
    },
  })

  const numNotifications = data?.notificationsFetch.filter((entry) => !entry.seenAt).length || 0

  return (
    <Route>
      <NotificationHolder className={numNotifications <= 0 ? 'inactive' : undefined} to={`${baseUrl}/notifications/`}>
        <NotificationIconL variant="fill" size="original" />
        {numNotifications > 0 && <NotificationCounter type="danger" value={numNotifications} position="topRight" />}
      </NotificationHolder>
    </Route>
  )
}

const NotificationHolder = styled(Link)`
  position: relative;
  padding: 0 5px;
  margin-right: ${cssVariables['spacing-xl']};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.inactive > svg > * {
    fill: #525461;
  }

  &:hover > svg > * {
    fill: #338cff;
  }
`

const NotificationCounter = styled(Count)`
  margin-right: 5px;
  height: 19px;
  min-width: 18px;
  border: 2px solid #f6f6f8;
  border-radius: 18px;
`
