import { Buttons, Loading } from '@shared-ui/components'
import { useRequest } from '@shared-ui/hooks'
import type { ShippingAddress } from '@shared/types/address'
import { Button, Text } from '@ubnt/ui-components'
import { useEffect } from 'react'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { StepHeading } from '../step-heading'
import { SubmitContainer } from '../submit-container'
import { UCareGradient } from '../u-care'
import { UCareBadge } from '../u-care-badge'

export function VerifyShippingAddress() {
  const { shippingAddress, shippingAddressValidated, device } = useSubmitRmaStore()

  const [validateShippingAddress, { loading, error }] = useRequest('validateAddress')

  const isUnifiCare = !!device?.isUnifiCare

  useEffect(() => {
    async function doFetch() {
      if (!shippingAddress) {
        return
      }

      const result = await validateShippingAddress({
        address: shippingAddress,
      })

      useSubmitRmaStore.setState({
        shippingAddressValidated: result.address,
      })
    }

    void doFetch()
  }, [shippingAddress])

  useEffect(() => {
    if (error) {
      SubmitRmaService.setInvalidShippingAddress(true)
      SubmitRmaService.backToFlow()
    }
  }, [error])

  const onBack = () => {
    useSubmitRmaStore.setState({
      shippingAddressValidated: null,
    })
    SubmitRmaService.backToFlow()
  }

  const onNext = () => {
    SubmitRmaService.next()
  }

  return (
    <SubmitContainer>
      {isUnifiCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column p-16 mt-6 relative">
        <StepHeading heading="Where should we send your replacement?" />

        <Text size="body" color="secondary" className="mb-32">
          Please verify your shipping address.
        </Text>

        {loading && <Loading />}

        {!loading && (
          <div className="flex column gap-8">
            <div className="flex gap-24">
              <Text size="body" weight="bold" className="flex-1">
                Entered Address
              </Text>

              <Text size="body" weight="bold" className="flex-1">
                Courier&apos;s Suggested Address
              </Text>
            </div>

            <div className="flex gap-24 mb-12">
              <div className="flex-1 border-bottom" />
              <div className="flex-1 border-bottom" />
            </div>

            <AddressCompareItem
              addressA={shippingAddress}
              addressB={shippingAddressValidated}
              addressKey="address1"
              label="Address"
            />
            <AddressCompareItem
              addressA={shippingAddress}
              addressB={shippingAddressValidated}
              addressKey="city"
              label="City"
            />
            <AddressCompareItem
              addressA={shippingAddress}
              addressB={shippingAddressValidated}
              addressKey="state"
              label="State"
            />
            <AddressCompareItem
              addressA={shippingAddress}
              addressB={shippingAddressValidated}
              addressKey="country"
              label="Country"
            />
            <AddressCompareItem
              addressA={shippingAddress}
              addressB={shippingAddressValidated}
              addressKey="zipcode"
              label="ZIP Code"
            />
            <AddressCompareItem
              addressA={shippingAddress}
              addressB={shippingAddressValidated}
              addressKey="phoneNumber"
              label="Phone Number"
            />
          </div>
        )}

        <Buttons>
          <Button variant="tertiary" onClick={onBack}>
            Back
          </Button>

          <Button variant="primary" disabled={loading} onClick={onNext}>
            Next
          </Button>
        </Buttons>
      </div>
    </SubmitContainer>
  )
}

function AddressCompareItem({
  addressA,
  addressB,
  addressKey,
  label,
}: {
  addressA: ShippingAddress | null
  addressB: ShippingAddress | null
  addressKey: keyof ShippingAddress
  label: string
}) {
  if (!addressA || !addressB) {
    return null
  }

  const valueA = addressA[addressKey] || '-'
  const valueB = addressB[addressKey] || '-'

  return (
    <div className="flex gap-24">
      <AddressItem type={label} value={valueA} color={valueA === valueB ? 'primary' : 'danger'} />
      <AddressItem type={label} value={valueB} color={valueA === valueB ? 'primary' : 'success'} />
    </div>
  )
}

function AddressItem({
  type,
  value,
  color = 'primary',
}: {
  type: string
  value: unknown
  color?: 'primary' | 'danger' | 'success'
}) {
  return (
    <div className="flex column flex-1">
      <Text size="body" color="tertiary">
        {type}
      </Text>
      <Text size="body" color={color}>
        {String(value)}
      </Text>
    </div>
  )
}
