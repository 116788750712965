import { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'

const useNestedPath = () => {
  const { url } = useRouteMatch()

  return useCallback(
    (subpath: string) => {
      return `${url}${subpath}`
    },
    [url],
  )
}

export default useNestedPath
