import { ClosePrimaryIcon } from '@ubnt/icons'
import type { FileId } from 'rma-shared/types/brands'
import type { AttachmentFile } from '../utils/file'

export const Attachments = ({
  attachments,
  attachmentsRemoved,
  onRemove,
  readonly,
}: {
  attachments: readonly AttachmentFile[]
  attachmentsRemoved?: FileId[]
  onRemove?: (file: AttachmentFile) => void
  readonly?: boolean
}) => {
  return (
    <div className="flex flex-column mt-10 gap-8">
      {attachments.map((file) => {
        if (attachmentsRemoved && attachmentsRemoved.includes(file.id)) {
          return null
        }

        return (
          <div
            key={file.id}
            className="flex space-between align-center height-36 px-12 py-8 bg-lighter-blue border-radius-8 break-all"
          >
            <div className="border-box truncate">
              <a href={file.url} className="font-13" title={file.filename}>
                {file.filename}
              </a>
            </div>

            {onRemove && !readonly && (
              <div className="border-box">
                <ClosePrimaryIcon actionable size="small" onClick={() => onRemove(file)} />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
