import { cssVariables, Text } from '@ubnt/ui-components'
import type { DropdownBaseProps } from '@ubnt/ui-components/Dropdown/types'
import type { FieldProps } from 'formik'
import get from 'lodash.get'
import type { FC } from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'
import { Dropdown } from '../SprintShared'

// Imported from PLM project and adjusted.

type LimitedDropdownProps = Omit<DropdownBaseProps, 'onBlur' | 'forwardedRef'>
type DropdownFieldProps = LimitedDropdownProps & FieldProps

export const DropdownField: FC<DropdownFieldProps> = ({ form, field, options = [], ...rest }) => {
  const error = (get(form.touched, field.name) && get(form.errors, field.name)) || ''

  const handleChange: DropdownBaseProps['onChange'] = useCallback(
    (e, newValue) => {
      // Random bug in Formik
      // https://github.com/formik/formik/issues/2266
      // Wrapping call to setFieldTouched in set timeout provided as a workaround until fixed.
      setTimeout(() => {
        form.setFieldValue(field.name, newValue)
      }, 0)
    },
    [field.name, form],
  )

  return (
    <Wrapper>
      <Dropdown
        options={options}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        onChange={handleChange}
        onBlur={field.onBlur}
        invalid={!!error}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(rest as LimitedDropdownProps)}
      />
      {error && <Text color="danger">{error}</Text>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: calc(${cssVariables['spacing-xl']} + 20px); // +20px, because label is absolutely offset by -20px

  &:last-child {
    margin-bottom: 0;
  }
`

export const DropdownField2: FC<DropdownFieldProps> = ({ form, field, options = [], disabled, ...rest }) => {
  const error = (get(form.touched, field.name) && get(form.errors, field.name)) || ''

  const handleChange: DropdownBaseProps['onChange'] = useCallback(
    (e, newValue) => {
      // Random bug in Formik
      // https://github.com/formik/formik/issues/2266
      // Wrapping call to setFieldTouched in set timeout provided as a workaround until fixed.
      setTimeout(() => {
        form.setFieldValue(field.name, newValue)
      }, 0)
    },
    [field.name, form],
  )

  return (
    <Wrapper>
      <Dropdown
        searchable
        options={options}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        onChange={(option, newValue) => {
          if (!disabled) {
            handleChange(option, newValue)
          }
        }}
        onBlur={field.onBlur}
        invalid={!!error}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(rest as LimitedDropdownProps)}
      />
      {error && <Text color="danger">{error}</Text>}
    </Wrapper>
  )
}
