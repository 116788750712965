import styled, { keyframes } from 'styled-components'
import LoaderPng from '../../assets/loader.png'

const spinAnimation = keyframes`
  to { 
      transform:rotate(360deg); 
  }`

export const LoaderIcon = styled.img.attrs({ src: LoaderPng })`
  display: block;
  width: 80px;
  height: 80px;
  animation-name: ${spinAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`
