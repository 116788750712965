import { Button, Text } from '@ubnt/ui-components'
import type { NetsuiteCompanyId } from 'rma-shared/types/brands'
import type { UserContext } from 'rma-shared/types/users'
import { AccountMenu, AccountMenu_Default, Avatar_Default, Divider } from '@ubnt/ui-components/aria'
import styled from 'styled-components'
import { AuthService } from '../../auth/auth-service'
import { useAuthStore } from '../../auth/auth-store'
import isPortal from '../../utils/isPortal'
import { CompanySelect } from './CompanySelect'
import { useFetchMyCompanyQuery } from './__generated__/AccountSection'

export const AccountSection = () => {
  const { user, status } = useAuthStore()

  if (!user || status !== 'connected') {
    return null
  }

  return <AccountSectionAvailable user={user} />
}

const AccountSectionAvailable = ({ user }: { user: UserContext }) => {
  const { data } = useFetchMyCompanyQuery({ skip: isPortal('RMA') })

  const onCompanyChange = async (nsCompanyId: NetsuiteCompanyId) => {
    await AuthService.changeCompany(nsCompanyId)

    window.location.reload()
  }

  const companies = (data?.myCompanies ?? []).filter((company) => company.entityType === 'reseller')
  const hasMultipleCompanies = companies.length > 1
  const selectedCompany = data?.myCompany

  return (
    <>
      <AccountMenu.Root>
        <AccountMenu.Trigger>
          <Avatar_Default alt="User profile image" size="large" image={user.picture} />
        </AccountMenu.Trigger>
        <StyledAccountMenu_Default
          actionButtons={
            <>
              {!!selectedCompany && (
                <>
                  {hasMultipleCompanies ? (
                    <>
                      <Divider orientation="horizontal" variant="solid" />
                      <CompanySelect
                        companies={companies}
                        value={selectedCompany?.entityId}
                        onChange={onCompanyChange}
                      />
                      <Divider orientation="horizontal" variant="solid" />
                    </>
                  ) : (
                    <Text size="body" weight="bold">
                      {selectedCompany?.name}
                    </Text>
                  )}
                </>
              )}
              <Button
                variant="tertiary"
                onClick={() => {
                  void AuthService.logout()
                }}
              >
                Sign Out
              </Button>
            </>
          }
          alt="User profile image"
          emailAddress={user.email}
          name={user.fullname}
          image={user.picture}
        />
      </AccountMenu.Root>
    </>
  )
}

const StyledAccountMenu_Default = styled(AccountMenu_Default)`
  footer hr {
    display: none;
  }
`
