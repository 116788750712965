import { Text } from '@shared-ui/components'
import { useSubmitRmaStore } from '../submit-rma-store'
import { SubmitRmaFlow } from '../submit-rma-types'
import { SubmitStepper } from './submit-stepper'

export function StepHeading({ heading, showStepper = true }: { heading?: string; showStepper?: boolean }) {
  const { flowIndex, totalSteps, device, isProofOfPurchaseNeeded } = useSubmitRmaStore()

  const currentStep = totalSteps ? flowIndex + 1 : 0
  const currentTotalSteps =
    totalSteps ||
    (device?.isSoldByUbiquiti && !isProofOfPurchaseNeeded
      ? SubmitRmaFlow.withoutSeller.length
      : SubmitRmaFlow.default.length)
  const max = currentTotalSteps + 1

  return (
    <div className="flex space-between mt-8">
      <Text size="header-xs" weight="bold" color="primary">
        {heading}
      </Text>

      {currentTotalSteps && showStepper && (
        <div className="flex align-center" style={{ width: '83px' }}>
          <SubmitStepper step={currentStep} maxSteps={max} />
        </div>
      )}
    </div>
  )
}
