import type { EntityToastProps } from '@ubnt/ui-components'
import { EntityToast, ToastContext } from '@ubnt/ui-components'
import { useContext, useCallback } from 'react'

export const useToast = () => {
  const { createNotification, removeNotification } = useContext(ToastContext)

  const showNotification = useCallback(
    ({ duration = 5000, onClose, ...rest }: EntityToastProps) => {
      let handleClose = onClose ?? undefined

      if (onClose === undefined) {
        handleClose = (e: React.MouseEvent, toastId?: string) => {
          if (toastId != null) {
            removeNotification(toastId)
          }
        }
      }

      createNotification(<EntityToast duration={duration} onClose={handleClose} {...rest} />)
    },
    [createNotification, removeNotification],
  )

  return { showNotification, removeNotification }
}
