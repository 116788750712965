import cssVariables from '@ubnt/ui-components/styles/variables'
import { css } from 'styled-components'

export const borderBottom = ({ color = cssVariables['color-primary'] }: { color?: string } = {}) => css`
  && {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${color};
      content: '';
      transform: scaleX(1);
      transition: transform 100ms cubic-bezier(0.25, 0, 0.25, 1);
    }
  }
`

export const truncate = css`
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
