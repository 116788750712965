import type { HTMLProps, RefObject, SyntheticEvent } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'

export type VideoProps = Omit<HTMLProps<HTMLVideoElement>, 'as'> & {
  className?: string
  src: string
  startFrom?: number
  pauseAfter?: number
  pauseBefore?: number
  right?: string
}

export const Video = forwardRef(
  ({ className, src, startFrom, pauseAfter, pauseBefore, right, ...video }: VideoProps, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
      if (startFrom === undefined || !videoRef.current) {
        return
      }

      videoRef.current.currentTime = startFrom
    }, [startFrom, videoRef.current])

    useImperativeHandle(ref, () => videoRef.current)

    const handleTimeUpdate = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
      if (video.onTimeUpdate) {
        video.onTimeUpdate(e)
      }

      const el = videoRef.current
      if (!el) {
        return
      }

      if (pauseAfter) {
        if (el.currentTime >= pauseAfter) {
          el.pause()
        }
      }

      if (pauseBefore) {
        if (el.currentTime > el.duration - pauseBefore) {
          el.pause()
        }
      }
    }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading, jsx-a11y/media-has-caption
      <VideoStyled className={className} $right={right} {...video} ref={videoRef} onTimeUpdate={handleTimeUpdate}>
        <source src={src} type="video/mp4" />
      </VideoStyled>
    )
  },
)

export interface VideoSegment {
  start: number
  end: number
}

export function SegmentedVideo({
  src,
  segments,
  segmentIndex,
}: {
  src: string
  segments: VideoSegment[]
  segmentIndex: number
}) {
  const [segment, setSegment] = useState(segments[0])
  const ref = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const newSegment = segments[segmentIndex]
    if (newSegment === segment) {
      return
    }

    if (ref.current) {
      ref.current.currentTime = newSegment.start

      if (ref.current.paused) {
        void ref.current.play()
      }
    }

    setSegment(newSegment)
  }, [segmentIndex])

  const handleTimeUpdate = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    const element = event.currentTarget
    if (element.paused) {
      return
    }

    const end = segment.end < 0 ? element.duration + segment.end : segment.end
    if (element.currentTime >= end) {
      element.currentTime = end
      element.pause()
    }
  }

  return (
    <VideoStyled ref={ref} onTimeUpdate={handleTimeUpdate} autoPlay muted>
      <source src={src} type="video/mp4" />
    </VideoStyled>
  )
}

export const rewindVideo = ({
  videoRef,
  fps = 30,
  fromEnd = false,
}: {
  videoRef: RefObject<HTMLVideoElement>
  fps?: number
  fromEnd?: boolean
}) => {
  const el = videoRef.current
  if (!el) return

  if (fromEnd) {
    if (!el.duration) return // Duration unknown.

    el.currentTime = el.duration
  }

  const interval = fps * 2

  const intervalId = setInterval(() => {
    if (el.currentTime === 0) {
      clearInterval(intervalId)
      el.pause()
    } else {
      el.currentTime -= interval / 1000
    }
  }, interval)
}

const VideoStyled = styled.video<{ $right?: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: ${(props) => props.$right};
`
