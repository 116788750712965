import NotificationsSidebar from '@shared-ui/components/notifications/NotificationsSidebar'
import { DetailsTab, LogTab, MessagingTab, Ticket as TicketShared, useTicketStore } from '@shared-ui/components/Ticket'
import { useQuery } from '@shared-ui/hooks'
import type { TicketId } from '@shared/types/brands'
import { StarIcon } from '@ubnt/icons'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTicketQuery } from './__generated__/Ticket'
import { Rate } from './Ticket/Rate'

export const RateTab = {
  id: 'rate',
  path: '/rate',
  icon: <StarIcon size="navigation" />,
  component: Rate,
}

export interface UrlParam {
  sidebarParam: string
}

interface SidebarProps {
  onClose: () => void
}

const RmaDetailsTab = {
  ...DetailsTab,
  props: {
    canCancelTicket: true,
  },
}

export default function Sidebar({ onClose }: SidebarProps) {
  const { sidebarParam } = useParams<UrlParam>()

  if (sidebarParam === 'notifications') {
    return <NotificationsSidebar onClose={onClose} />
  }

  return <TicketSidebar ticketId={sidebarParam} onClose={onClose} />
}

function TicketSidebar({ ticketId, onClose }: { ticketId: TicketId; onClose: () => void }) {
  const { data, loading, error, refetch } = useTicketQuery({
    variables: { id: ticketId },
  })

  const { data: ticketData, loading: ticketLoading } = useQuery('rmaTicketDetails', {
    ticketId,
  })

  const isLoading = loading || ticketLoading

  useEffect(() => {
    if (ticketData) {
      useTicketStore.setState(ticketData)
    }
  }, [ticketData])

  const tabs = useMemo(() => {
    const showMessagingTab =
      data?.ticket?.supportByUbiquiti === false || ticketData?.ticketSupport?.type === 'already-disputed'

    return [RmaDetailsTab, LogTab, showMessagingTab ? MessagingTab : null, RateTab].filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
      <T extends unknown>(tab: T): tab is NonNullable<T> => !!tab,
    )
  }, [data, ticketData])

  return (
    <TicketShared
      tabs={tabs}
      loading={isLoading}
      error={error}
      ticket={data?.ticket ?? undefined}
      refetchTicket={refetch}
      onClose={onClose}
    />
  )
}
