import styled from 'styled-components'
import { theme } from '../styles/theme'

const StyledHref = styled.a`
  text-decoration: none;
  color: ${theme.colors.link};
  word-break: break-all;

  &:hover,
  &:active {
    text-decoration: underline;
    color: #338cff;
  }
  &:visited {
    color: #0059cc;
  }
`

export default StyledHref
