import { Button } from '@ubnt/ui-components'
import { Text_Augmented } from '@ubnt/ui-components/aria'
import { useState } from 'react'
import type { TicketSupport } from 'rma-shared/types/tickets'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'
import { ContactSupportAboutTicket } from './contact-support'
import { TicketDetailsSupportPanel } from './ticket-details-support-panel'
import { useTicketStore } from './ticket-store'

export function TicketDetailsSupport({
  ticket,
  ticketSupport,
  refetchTicket,
}: {
  ticket: Ticket
  ticketSupport: TicketSupport
  refetchTicket: () => void
}) {
  const [isModalVisible, setModalVisibility] = useState(false)

  if (ticketSupport.type === 'already-disputed') {
    return null
  }

  const { title, info } = getContent(ticketSupport)

  return (
    <>
      <TicketDetailsSupportPanel
        title={<>{title}</>}
        message={
          <>
            <Text_Augmented>{info}</Text_Augmented>

            <div className="mt-8">
              <Button full={false} variant="inline" onClick={() => setModalVisibility(true)}>
                Get Support
              </Button>
            </div>
          </>
        }
      />

      {isModalVisible && (
        <ContactSupportAboutTicket
          ticketId={ticket.id}
          supportedByName={ticket.supportByName}
          onDone={() => {
            setModalVisibility(false)
            refetchTicket()
            useTicketStore.setState({
              ticketSupport: null,
            })
          }}
          onClose={() => setModalVisibility(false)}
          disputeType={ticketSupport.type === 'declined' ? 'declination' : 'standard'}
        />
      )}
    </>
  )
}

function getContent(ticketSupport: TicketSupport): { title: string; info: JSX.Element | string } {
  switch (ticketSupport.type) {
    case 'backordered':
      return {
        title: 'Your RMA replacement item is backordered.',
        info:
          'We’re working to get it shipped to you as soon as possible. If you have any questions or need assistance in the meantime, please don’t hesitate to reach out to us via the link below.',
      }

    case 'declined':
      if (ticketSupport.isSupportedByUI) {
        return {
          title: 'Your RMA request was declined.',
          info: ticketSupport.reason ? (
            <>
              After careful review, we’re unable to approve your request at this time due to the following reason:{' '}
              <Text_Augmented color="red-06">{ticketSupport.reason}</Text_Augmented>. If you have questions or believe
              there’s been an error, please reach out to us via the link below
            </>
          ) : (
            'After careful review, we’re unable to approve your request at this time. If you have questions or believe there’s been an error, please reach out to us via the link below'
          ),
        }
      }

      return {
        title: 'Dispute a Declined RMA Request',
        info: `We see that your request was declined. If you would like to dispute the reseller's decision, contact
          us to share more details about your situation so we can recommend next steps.`,
      }

    default:
      return {
        info: '',
        title: '',
      }
  }
}
