import type { ModalProps } from '@ubnt/ui-components'
import { Modal } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Loading } from './Loading'
import { InnerTitle, Row, SeparatorLine3 } from './SprintShared'
import { Text } from './Text'

interface ModalMinimalProps extends ModalStyledProps {
  title: ReactNode
  titleLine?: boolean
  subtitle?: ReactNode
  background?: string
}

export function ModalMinimal({
  actions,
  children,
  onRequestClose,
  title,
  titleLine,
  subtitle,
  background,
  loading,
  error,
}: ModalMinimalProps) {
  return (
    <ModalStyled
      isOpen
      size="small"
      actions={actions}
      onRequestClose={onRequestClose}
      background={background}
      loading={loading}
      error={error}
    >
      <InnerTitle>{title}</InnerTitle>
      {subtitle && <Text size="header-xs">{subtitle}</Text>}

      {titleLine && <SeparatorLine3 />}
      {!titleLine && <br />}

      <div className="content">{children}</div>

      {error && (
        <Row $centerH style={{ marginTop: '24px' }}>
          <Text color="danger">{error}</Text>
        </Row>
      )}

      {loading && (
        <div className="footer">
          <Loading />
        </div>
      )}
    </ModalStyled>
  )
}

interface ModalStyledProps extends ModalProps {
  background?: string
  loading?: boolean
  error?: string
}
export const ModalStyled = styled(Modal)<ModalStyledProps>`
  overflow-x: initial !important;
  overflow-y: initial !important;
  width: 420px;

  & > div {
    overflow: initial !important;
    margin-top: 0;
  }

  footer {
    display: ${(props) => (props.loading ? 'none' : 'flex')};
    padding: 20px;
  }

  div.content {
    font-size: 14px;
  }

  div.footer {
    display: flex;
    justify-content: center;
  }

  footer > div > * {
    margin-left: 8px;
  }

  ${(props) =>
    props.background &&
    css`
      background-position: right top !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      background: url(${props.background});
      background-color: #ffffff;
    `}
`
