import * as yup from 'yup'

import type { TicketId } from '../../../types/brands'
import type { ValidationInput } from '../utils'
import { RatingSchema, TicketIdSchema } from './common'
import { REQUIRED, TOO_LONG } from './const'

export const RateExperienceInputSchema = yup
  .object({
    ticketId: TicketIdSchema.required(),
    // moreThan 0 is a way to force value as required since 0 otherwise is considered valid value
    rating: RatingSchema.moreThan(0).required(REQUIRED),
    feedback: yup.string().max(1000, TOO_LONG).defined().ensure(),
  })
  .required()

export type RateExperienceInput = Omit<yup.InferType<typeof RateExperienceInputSchema>, 'ticketId'> & {
  ticketId: TicketId
}

export const validateRateExperienceInput = (
  input: ValidationInput<RateExperienceInput>,
  options: yup.ValidateOptions = { abortEarly: false },
) => RateExperienceInputSchema.validate(input, options)
