import { Helmet } from 'react-helmet'
import { getPortalTitle } from '../utils/isPortal'

export const Title = ({ title }: { title: string }) => (
  <Helmet>
    <title>
      {title} {getPortalTitle()}
    </title>
  </Helmet>
)
