import { useAuthStore } from '@shared-ui/auth/auth-store'
import { UniFiCareLogo } from '@shared-ui/components'
import { SuccessIcon } from '@shared-ui/components/CommonIcons'
import type { Requests } from '@shared/requests'
import { Button, Modal, Text } from '@ubnt/ui-components'
import { UCareGradient } from '../SubmitRma/components/u-care'

export default function Confirmation({
  data,
  onViewTicket,
  onClose,
}: {
  data: Requests['ticketInfo']['output']
  onViewTicket: () => void
  onClose: () => void
}) {
  const { user } = useAuthStore()

  const { advancedFlowAvailable } = data
  const { isUnifiCare } = data.device
  const showAlternativeText = user && !user.hasCompany && !data.supportByUbiquiti

  return (
    <Modal
      isOpen
      size="small"
      contentClassName="p-0"
      onRequestClose={onClose}
      header={<></>}
      footer={
        <div className="flex gap-8 p-20">
          <Button variant="tertiary" wrapClassName="width-100 flex-1" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" wrapClassName="width-100 flex-1" onClick={onViewTicket}>
            View Ticket
          </Button>
        </div>
      }
    >
      <>
        {!isUnifiCare && (
          <div className="flex column align-center px-20 mt-24 relative">
            <SuccessIcon width={46} height={46} />

            <Text size="body" weight="bold" color="primary" centered className="mt-20">
              Your RMA request will be handled by
              <br />
              {data.ticket.supportByName}
            </Text>

            {showAlternativeText && (
              <Text size="body" color="secondary" centered className="mt-8">
                Please allow them two business days to respond to your RMA. If there is no response from the reseller in
                that window, a link to Ubiquiti Support will appear in your ticket details. Click it to connect with an
                agent who will expedite your RMA.
              </Text>
            )}

            {advancedFlowAvailable && (
              <>
                <Text size="body" color="secondary" centered className="mt-8">
                  Your confirmation email and shipping label will be sent to you shortly. If you do not receive it
                  within one business day, please check your Spam folder.
                </Text>
                <Text size="body" color="secondary" centered className="mt-8">
                  You do not need to return your old device until you receive your replacement.
                </Text>
              </>
            )}

            <Text size="body" color="secondary" centered className="mt-16">
              Ticket No. {data.ticket.id}
            </Text>
          </div>
        )}

        {isUnifiCare && (
          <>
            <UCareGradient />

            <div className="flex column align-center px-20 mt-24 relative">
              <div className="relative" style={{ width: '96px', height: '96px' }}>
                <UniFiCareLogo className="absolute" />
                <div className="absolute bottom right" style={{ padding: '11px' }}>
                  <SuccessIcon width={30} height={30} />
                </div>
              </div>

              <Text size="body" weight="bold" color="primary" centered className="mt-20">
                You&apos;re Our Top Priority!
              </Text>
              <Text size="body" color="secondary" centered className="mt-8">
                As a UI Care member, you can rest assured that we will do everything we can to streamline your product
                replacement. To start, we will automatically designate your ticket as a high priority.
              </Text>
              <Text size="body" color="secondary" centered className="mt-16">
                Ticket No. {data.ticket.id}
              </Text>
            </div>
          </>
        )}
      </>
    </Modal>
  )
}
