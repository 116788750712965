// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

import type { MessageFragment } from './Chat'

export type Shared_Chat_MessagesQueryVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
}>

export type Shared_Chat_MessagesQuery = { readonly __typename?: 'Query' } & {
  readonly chat: Types.Maybe<
    { readonly __typename?: 'Chat' } & {
      readonly messages: ReadonlyArray<{ readonly __typename?: 'Message' } & MessageFragment>
    }
  >
}

export const Shared_Chat_MessagesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Shared_Chat_Messages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'messages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'message' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'message' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Message' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'canManage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'important' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'picture' },
                        name: { kind: 'Name', value: 'logo' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Customer' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'name' },
                        name: { kind: 'Name', value: 'fullname' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'picture' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sentByMe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sentAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'readAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'importantCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replyCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'users' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSentAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unreadMessageCount' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useShared_Chat_MessagesQuery__
 *
 * To run a query within a React component, call `useShared_Chat_MessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShared_Chat_MessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShared_Chat_MessagesQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useShared_Chat_MessagesQuery(
  baseOptions: Apollo.QueryHookOptions<Shared_Chat_MessagesQuery, Shared_Chat_MessagesQueryVariables>,
) {
  return Apollo.useQuery<Shared_Chat_MessagesQuery, Shared_Chat_MessagesQueryVariables>(
    Shared_Chat_MessagesDocument,
    baseOptions,
  )
}
export function useShared_Chat_MessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Shared_Chat_MessagesQuery, Shared_Chat_MessagesQueryVariables>,
) {
  return Apollo.useLazyQuery<Shared_Chat_MessagesQuery, Shared_Chat_MessagesQueryVariables>(
    Shared_Chat_MessagesDocument,
    baseOptions,
  )
}
export type Shared_Chat_MessagesQueryHookResult = ReturnType<typeof useShared_Chat_MessagesQuery>
export type Shared_Chat_MessagesLazyQueryHookResult = ReturnType<typeof useShared_Chat_MessagesLazyQuery>
export type Shared_Chat_MessagesQueryResult = Apollo.QueryResult<
  Shared_Chat_MessagesQuery,
  Shared_Chat_MessagesQueryVariables
>
