import type { DropdownOption } from '@ubnt/ui-components'
import { useMemo } from 'react'
import type { NetsuiteCompanyId } from 'rma-shared/types/brands'
import type { Company } from '../../generated/graphql'
import { Dropdown } from '../SprintShared'

export const CompanySelect = ({
  companies,
  value,
  onChange,
}: {
  companies?: readonly Pick<Company, 'name' | 'entityId'>[]
  value?: NetsuiteCompanyId | null
  onChange(id: NetsuiteCompanyId): void
}) => {
  const options = useMemo<DropdownOption[]>(
    () =>
      (companies ?? []).map((company) => ({
        value: company.entityId,
        label: company.name,
      })),
    [companies],
  )

  return (
    <>
      <Dropdown
        value={value ?? ''}
        options={options}
        width="100%"
        fontSize="body"
        onChange={(_, newValue) => {
          onChange(newValue as NetsuiteCompanyId)
        }}
        placeholder="Select company"
        variant="secondary"
      />
    </>
  )
}
