import { ErrorBoundary } from '@shared-ui/components/ErrorBoundary'
import { useQuery } from '@shared-ui/hooks'
import useNavigate from '@shared-ui/hooks/useNavigate'
import useNestedPath from '@shared-ui/hooks/useNestedPath'
import type { TicketId } from '@shared/types/brands'
import { useCallback } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Confirmation from './Confirmation'
import { History } from './History'
import { Navigation } from './Navigation'
import Sidebar from './sidebar'
import { Tickets } from './Tickets'
import { TicketsWithUnsentItemsModal } from './TicketsWithUnsentItemsModal'

interface DashboardUrlParams {
  sidebarParam?: Maybe<string>
}

export const Dashboard = () => {
  const navigate = useNavigate()
  const nested = useNestedPath()
  const history = useHistory<DashboardUrlParams>()

  const sidebarParam = history.location.state?.sidebarParam
  const ticketId = sidebarParam && sidebarParam !== 'notifications' ? (sidebarParam as TicketId) : null

  const handleSidebarCloseTickets = useCallback(() => navigate(nested('/tickets')), [navigate, nested])
  const handleSidebarCloseHistory = useCallback(() => navigate(nested('/history')), [navigate, nested])

  return (
    <>
      <Navigation />
      <ErrorBoundary>
        <Switch>
          <Route path={nested('/tickets')}>
            <Tickets />
            <Route path={nested('/tickets/:sidebarParam')}>
              <Sidebar onClose={handleSidebarCloseTickets} />
            </Route>
          </Route>
          <Route path={nested('/history')}>
            <History />
            <Route path={nested('/history/:sidebarParam')}>
              <Sidebar onClose={handleSidebarCloseHistory} />
            </Route>
          </Route>
          <Route path="*">
            <Redirect to={nested('/tickets')} />
          </Route>
        </Switch>
      </ErrorBoundary>

      {ticketId && <DashboardTicketConfirmation ticketId={ticketId} />}

      <TicketsWithUnsentItemsModal />
    </>
  )
}

const DashboardTicketConfirmation = ({ ticketId }: { ticketId: TicketId }) => {
  const nested = useNestedPath()
  const history = useHistory<DashboardUrlParams>()

  const { data } = useQuery('ticketInfo', {
    ticketId,
  })

  const showConfirmation = ticketId && data

  const handleConfirmationViewTicket = useCallback(() => {
    history.replace(nested(`/tickets/${ticketId as string}`), { ...history.location.state, sidebarParam: null })
  }, [history, nested, ticketId])

  const handleConfirmationClose = useCallback(() => {
    history.replace(nested('/'), { ...history.location.state, sidebarParam: null })
  }, [history, nested])

  return (
    <>
      {showConfirmation && (
        <Confirmation data={data} onViewTicket={handleConfirmationViewTicket} onClose={handleConfirmationClose} />
      )}
    </>
  )
}
