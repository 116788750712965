import { withIcon } from './withIcon'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BoxIcon = withIcon(({ forwardedRef, isActive, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 20 22" ref={forwardedRef} {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1L19 6.67994V14.9564L10 20.6364L1 14.9564V6.67994L10 1Z"
        stroke={isActive ? '#006FFF' : '#7E8190'}
      />
      <path d="M9.99994 20.3316V11.9542L18.9999 6.67993" stroke={isActive ? '#006FFF' : '#7E8190'} />
      <path d="M10 11.9542L1 6.67993" stroke={isActive ? '#006FFF' : '#7E8190'} />
      <path d="M5.50006 9.51991L14.9092 3.83997" stroke={isActive ? '#006FFF' : '#7E8190'} />
    </svg>
  )
})
