import { RemoteImage } from '@shared-ui/components'
import formatSerial from '@shared/lib/utils/format/serial'
import { Text } from '@ubnt/ui-components'
import type { Device } from 'rma-shared/types/devices'

export function RmaDevicePanelSmall({ device, skuOverride }: { device: Device; skuOverride?: string }) {
  const mac = formatSerial(device.mac || '')
  const sku = skuOverride || device.name

  return (
    <div className="flex border border-radius p-8">
      <div className="width-32 height-32">
        <RemoteImage url={device.productImage} alt={sku} />
      </div>

      <div className="flex flex-1 ml-16 mr-8 align-center space-between">
        <Text size="body" color="secondary">
          {sku}
        </Text>

        <Text size="body" color="tertiary">
          {mac || 'None'}
        </Text>
      </div>
    </div>
  )
}
