import { NavigationTabs } from '@shared-ui/components/NavigationTabs'
import { OldStyleSubNavigation } from '@shared-ui/components/SubNavigation'
import { useNavigate } from '@shared-ui/hooks'
import useNestedPath from '@shared-ui/hooks/useNestedPath'
import { Button } from '@ubnt/ui-components'
import { useMemo } from 'react'

export const Navigation = () => {
  const navigate = useNavigate()
  const nested = useNestedPath()

  const centralTabs = useMemo(
    () => [
      { id: nested('/tickets'), label: <span data-testid="rma-dashboard-open-tickets-tab">Open Tickets</span> },
      { id: nested('/history'), label: <span data-testid="rma-dashboard-history-tab">History</span> },
    ],
    [nested],
  )

  return (
    <OldStyleSubNavigation
      center={<NavigationTabs tabs={centralTabs} />}
      right={
        <Button
          data-testid="request-rma-btn"
          variant="primary"
          onClick={() => {
            navigate('/submit')
          }}
        >
          Request RMA
        </Button>
      }
    />
  )
}
