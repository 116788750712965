import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import type { NotificationData } from 'rma-shared/types/notifications'
import { FixedSidePanel, Loader, Text, Vertical } from '..'
import getBaseUrl from '../../utils/getBaseUrl'
import NotificationItem from './NotificationItem'
import * as NotificationService from './NotificationsService'
import { useNotificationsFetchQuery, useNotificationsMarkAsReadMutation } from './__generated__/Notifications'

interface NotificationsSidebarProps {
  onClose: () => void
}

export default function NotificationsSidebar({ onClose }: NotificationsSidebarProps) {
  const { data, loading, error } = useNotificationsFetchQuery()
  const [markAsRead] = useNotificationsMarkAsReadMutation()
  const location = useLocation()

  const numNotifications = data?.notificationsFetch.filter((entry) => !entry.seenAt).length || 0

  useEffect(() => {
    if (data?.notificationsFetch) {
      NotificationService.set([...data.notificationsFetch])
    }
  }, [data])

  useEffect(() => {
    return () => {
      void (async () => {
        const unseenIds = NotificationService.close()

        if (unseenIds.length === 0) {
          return
        }

        try {
          await markAsRead({
            variables: {
              ids: unseenIds,
            },
          })
        } catch (err) {
          //
        }
      })()
    }
  }, [markAsRead])

  const baseUrl = useMemo(getBaseUrl, [location])

  return (
    <FixedSidePanel in onClose={onClose} header={<>Notifications {numNotifications > 0 && `(${numNotifications})`}</>}>
      {data &&
        !loading &&
        data.notificationsFetch.map((entry) => {
          return (
            <NotificationItem
              key={entry.id}
              notificationId={entry.id}
              ticketId={entry.ticketId}
              createdAt={entry.createdAt}
              seenAt={entry.seenAt}
              data={entry.data as NotificationData}
              baseUrl={baseUrl}
            />
          )
        })}

      {data && data.notificationsFetch.length === 0 && (
        <Vertical $centerH $centerV $flex>
          <Text size="body" color="secondary">
            No new notifications
          </Text>
        </Vertical>
      )}

      {error && (
        <Vertical $centerH $centerV $flex>
          <Text centered color="danger">
            {error}
          </Text>
        </Vertical>
      )}

      {loading && (
        <Vertical $centerH $centerV $flex>
          <Loader />
        </Vertical>
      )}
    </FixedSidePanel>
  )
}
