import axios from 'axios'

/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */

type FetchOptions = {
  method?: 'POST' | 'GET'
  retries?: number
  initialTimeoutDelay?: number
}

export const fetchRetry = async <T>(url: string, options: FetchOptions = {}): Promise<T> => {
  const retries = options.retries || 1
  const method = options.method || 'GET'
  let timeoutDelay = options.initialTimeoutDelay || 4000
  let lastError

  for (let n = 0; n < retries; n += 1) {
    if (n > 0) {
      await new Promise((response) => setTimeout(() => response(null), timeoutDelay))
      timeoutDelay *= 2
    }

    try {
      const result = method === 'GET' ? await axios.get<T>(url) : await axios.post<T>(url)

      return result.data
    } catch (err) {
      lastError = err
      console.error(err)
    }
  }

  throw lastError
}
