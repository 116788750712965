export const loadScript = ({ src, id }: { src: string; id?: string }): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.crossOrigin = 'anonymous'

    if (id) {
      script.id = id
    }

    script.onload = () => {
      script.setAttribute('loaded', 'true')
      resolve()
    }

    script.onerror = () => {
      script.remove()
      reject(new Error(`Failed to load script ${src}`))
    }

    script.src = src
    document.body.appendChild(script)
  })
