import { Div, Link, Modal } from '@shared-ui/components'
import { ContactSupportAboutMac } from '@shared-ui/components/Ticket/contact-support'
import { useState } from 'react'

export const AlreadyOpenRma = ({ mac, onClose }: { mac: string; onClose: () => void }) => {
  const [showContactSupport, setContactSupport] = useState(false)

  if (showContactSupport) {
    return (
      <ContactSupportAboutMac
        mac={mac}
        onClose={() => {
          setContactSupport(false)
        }}
        onDone={() => {
          setContactSupport(false)
          onClose()
        }}
      />
    )
  }

  return (
    <>
      <Modal
        isOpen
        size="small"
        title="There is Already an Open RMA for this Item"
        actions={[
          {
            text: <span>Close</span>,
            variant: 'tertiary',
            onClick: () => onClose(),
          },
        ]}
        onRequestClose={() => onClose()}
      >
        <div>
          You cannot submit an RMA request for this item because there&apos;s already an open RMA for it.
          <Div className="mb-16" />
          If you believe this is a mistake, please contact us by clicking the link{' '}
          <Link onClick={() => setContactSupport(true)}>here</Link>. We will review and contact you as soon as possible.
        </div>
      </Modal>
    </>
  )
}
