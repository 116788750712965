import type { ApolloError } from '@apollo/client'
import { TicketStatus } from '@shared/tickets'
import { ManageIconL } from '@ubnt/icons'
import { Button, ButtonGroup, cssVariables, Loader, Text } from '@ubnt/ui-components'
import { useEffect, useMemo, useState } from 'react'
import { TICKET_CUSTOMER_NOTES_MAX_LENGTH, TICKET_FAILURE_DESCRIPTION_MAX_LENGTH } from 'rma-shared/tickets/constants'
import {
  getDeclineStatus,
  getNextTransitionStatus,
  getTicketStatusValidationSchema,
  validateTicketState,
} from 'rma-shared/tickets/ticket-status'
import type { NetsuiteFailureCategoryId } from 'rma-shared/types/brands'
import type { TicketStatusEntry } from 'rma-shared/types/ticket-status'
import type { TicketStatusData } from 'rma-shared/types/tickets'
import styled from 'styled-components'
import { updateCacheItem } from '../../api/apollo'
import { TicketWarrantyStatus } from '../../generated/graphql'
import { useRequest } from '../../hooks'
import { useTicketsStore } from '../../tickets/tickets-store'
import isPortal, { getPortal } from '../../utils/isPortal'
import { useMyCompanyAddressesQuery } from '../__generated__/Common'
import { FailureCategory } from '../failure-category'
import { FileUpload } from '../file-upload'
import { InputWrapper } from '../form'
import { ShipmentInfoInput } from '../form/ShipmentInfoInput'
import { Info } from '../Info'
import { Dropdown, SidePanelCard } from '../SprintShared'
import { TextArea } from '../text-area'
import { useUpdateTicketStatusMutation } from './__generated__/Manage'
import { CompanyDropdown } from './CompanyDropdown'
import type { ManageContextState } from './context/Ticket'
import { useTicket } from './context/Ticket'
import { checkTicketStatusComponent, DisplayTicketStatus } from './DisplayTicketStatus'
import { createAddressOptions } from './TicketService'
import type { Ticket } from './types'
import { WarrantyStatusWidget } from './WarrantyStatusWidget'

const UPDATE_ERROR = 'An error occurred while updating the ticket status.'

const titles: Record<TicketStatus, string> = {
  [TicketStatus.Submitted]: 'Confirm RMA Request',
  [TicketStatus.DistributorIdentified]: 'Confirm RMA Request',
  [TicketStatus.Accepted]: 'Confirm RMA Shipment Receipt',
  [TicketStatus.ReturnCancelled]: 'Warranty Canceled',
  [TicketStatus.Processing]: 'Approve RMA Request',
  [TicketStatus.InFulfilment]: 'Replacement Shipment/Credit Confirmation',
  [TicketStatus.Declined]: 'Return Declined',
  [TicketStatus.Shipped]: 'Replacement Shipped',
  [TicketStatus.Closed]: 'Ticket Closed',
  [TicketStatus.Backordered]: 'Backordered',
  [TicketStatus.ReplacementShippedPendingReceipt]: 'Replacement Shipped, Pending Receipt',
  [TicketStatus.Expired]: 'Expired',
  [TicketStatus.ShippedPendingReceipt]: 'Shipped, Pending Receipt',
  [TicketStatus.Completed]: 'Completed',
  [TicketStatus.InitiatingAdvancement]: 'Initiating Advanacement',
  [TicketStatus.ReceivedInitiatingAdvancement]: 'Initiating Advanacement',
  [TicketStatus.ReceivedFulfillment]: 'Fulfillment',
  [TicketStatus.ReceivedBackordered]: 'Backordered',
  [TicketStatus.CreditIssued]: 'Credit Issued',
}

export function Manage({ ticket }: { ticket: Ticket }) {
  const { state, setup } = useTicket()
  const { updateTicket } = useTicketsStore()

  const [suggestedStatus, setSuggestedStatus] = useState<TicketStatus | undefined>()

  const [updateTicketStatus, { loading, error }] = useUpdateTicketStatusMutation()

  const { status, warrantyStatus } = ticket
  const statuses = ticket.statuses as TicketStatusEntry[]

  const isDirectPortal = isPortal('DIRECT')
  const blockabledWarrantyStatus =
    warrantyStatus === TicketWarrantyStatus.Active || warrantyStatus === TicketWarrantyStatus.Unvr
  const blockedStatus = status === TicketStatus.Submitted || status === TicketStatus.DistributorIdentified
  const cancelBlocked = isDirectPortal && blockabledWarrantyStatus && blockedStatus

  useEffect(() => {
    setup(ticket.id, ticket.status)
  }, [ticket.id, ticket.status])

  let transitionStatus = getNextTransitionStatus(status, ticket.flow)
  if (ticket.status === TicketStatus.InFulfilment && suggestedStatus) {
    transitionStatus = suggestedStatus
  }

  const validationState = createStatusDataFromState(state, transitionStatus)
  const isValid = validateTicketState(transitionStatus, validationState)
  const declineStatus = getDeclineStatus(ticket.status)
  const isDeclineValid = declineStatus && !cancelBlocked ? validateTicketState(declineStatus, validationState) : false
  const isDistributorMissing = isPortal('VAR') && !ticket.distributorId && !ticket.parentId

  const handleConfirm = async () => {
    if (!isValid) {
      return
    }

    try {
      const result = await updateTicketStatus({
        variables: {
          input: {
            ticketId: ticket.id,
            activity: transitionStatus,
            extraData: {
              ...validationState,
            },
            files: state.files.map(({ file }) => file),
          },
        },
      })

      const newTicket = result.data?.updateTicketStatus.ticket
      if (newTicket) {
        updateTicket(ticket.id, {
          status: newTicket.status,
        })
      }

      setSuggestedStatus(undefined)
    } catch (err) {
      //
    }
  }

  const handleDecline = async () => {
    if (!declineStatus) {
      return
    }

    try {
      await updateTicketStatus({
        variables: {
          input: {
            ticketId: ticket.id,
            activity: declineStatus,
            extraData: {
              ...validationState,
            },
            files: [],
          },
        },
      })
    } catch (err) {
      //
    }
  }

  const haveTicketStatusComponent = checkTicketStatusComponent(ticket.status)

  if (error || loading) {
    return <LoadingStatus error={error} />
  }

  if (isDistributorMissing) {
    return <DistributorConfirmation />
  }

  return (
    <Wrap>
      <FormWrap>
        <TicketStatusHeading size="body" weight="bold" centered>
          {titles[suggestedStatus || ticket.status]}
        </TicketStatusHeading>

        {ticket.status === TicketStatus.InFulfilment && (
          <InputWrapper>
            <Dropdown
              options={[
                {
                  label: 'Replacement Shipped',
                  value: TicketStatus.Shipped,
                },
                {
                  label: 'Credit Issued',
                  value: TicketStatus.CreditIssued,
                },
              ]}
              value={suggestedStatus || TicketStatus.Shipped}
              onChange={(event) => {
                const newReturnMethod = event.value.toString() as TicketStatus
                setSuggestedStatus(newReturnMethod)
              }}
              width="100%"
              data-testid="refund-option"
            />
          </InputWrapper>
        )}

        {haveTicketStatusComponent && <DisplayTicketStatus status={status} statuses={statuses} fieldGap="xs" />}

        {!haveTicketStatusComponent && <DefaultSchemaFields transitionStatus={transitionStatus} ticket={ticket} />}
      </FormWrap>

      {!haveTicketStatusComponent && (
        <ActionButtons>
          {declineStatus && !cancelBlocked && (
            <Button full variant="danger" disabled={!isDeclineValid} onClick={handleDecline} data-testid="deny-btn">
              Cancel
            </Button>
          )}

          <Button full variant="primary" disabled={!isValid} onClick={handleConfirm} data-testid="confirm-btn">
            {ticket.status === TicketStatus.Processing ? 'Approve' : 'Confirm'}
          </Button>
        </ActionButtons>
      )}
    </Wrap>
  )
}

const DistributorConfirmation = () => {
  const { state, setDistributorId } = useTicket()

  const [updateDistribor, { loading }] = useRequest('updateDistributor')

  const isValid = !!state.distributorId

  const handleConfirm = async () => {
    try {
      if (!state.distributorId) {
        return
      }

      const result = await updateDistribor({
        ticketId: state.ticketId,
        companyId: state.distributorId,
      })

      updateCacheItem(`Ticket:${state.ticketId}`, {
        distributorId: String(result.companyPartyId),
      })
    } catch (err) {
      setDistributorId(undefined)
    }
  }

  return (
    <Wrap>
      <FormWrap>
        <TicketStatusHeading size="body" weight="bold" uppercase>
          Distributor Confirmation
        </TicketStatusHeading>

        <div>
          <SelectDistributorHeading>
            Please, select which distributor you&#39;ve purchased this item from.
          </SelectDistributorHeading>
        </div>

        <CompanyDropdown value={state.distributorId} onChange={setDistributorId} />
      </FormWrap>

      <ActionButtons>
        <Button
          full
          variant="primary"
          disabled={!isValid}
          loader={loading ? 'dots' : undefined}
          onClick={() => void handleConfirm()}
          data-testid="confirm-btn"
        >
          Confirm
        </Button>
      </ActionButtons>
    </Wrap>
  )
}

const createStatusDataFromState = (state: ManageContextState, status: TicketStatus): TicketStatusData => {
  const createdAt = new Date()

  switch (status) {
    case TicketStatus.Accepted:
      return {
        status,
        createdAt,
        addressId: state.addressId,
        notes: state.notes,
      }

    case TicketStatus.InFulfilment:
      return {
        status,
        createdAt,
        notes: state.notes,
        failureCategory: state.failureCategory,
        failureDescription: state.failureDescription,
      }

    case TicketStatus.Shipped:
    case TicketStatus.ShippedPendingReceipt:
      return {
        status,
        createdAt,
        notes: state.notes,
        shippingCompany: state.shippingCompany,
        trackingNumber: state.trackingNumber,
        // files: state.files,
      }

    default:
      return {
        status,
        createdAt,
        notes: state.notes,
      }
  }
}

interface DefaultSchemaFieldsProps {
  transitionStatus: TicketStatus
  ticket: Ticket
}

const DefaultSchemaFields = ({ transitionStatus, ticket }: DefaultSchemaFieldsProps) => {
  const { state, setFailureCategory, setFailureDescription, setAddressId, setShippingInfo, setFiles } = useTicket()

  const { data: companyData, loading: companyLoading, error: companyError } = useMyCompanyAddressesQuery()

  const fieldsSchema = getTicketStatusValidationSchema(transitionStatus)

  const companyAddressOptions = useMemo(() => {
    const portalType = getPortal()
    const addresses =
      portalType === 'DIRECT' ? companyData?.myCompany?.rmaAddresses : companyData?.myCompany?.addresses || []
    return createAddressOptions(addresses)
  }, [companyData])

  if (!!fieldsSchema.addressId && (companyLoading || companyError)) {
    return <LoadingStatus error={companyError} />
  }

  return (
    <>
      {!!fieldsSchema.failureCategory && (
        <>
          <Text style={{ marginBottom: '10px' }}>
            When approving an RMA request, please select the issue category and provide additional details below. We
            will use this information to evaluate further.
          </Text>

          <InputWrapper>
            <FailureCategory
              label="Issue Category"
              failureCategoryId={state.failureCategory}
              onChange={(option) => {
                setFailureCategory(option.value as NetsuiteFailureCategoryId)
              }}
            />
          </InputWrapper>
        </>
      )}

      {!!fieldsSchema.failureDescription && (
        <InputWrapper style={{ marginTop: -26 }}>
          <TextArea
            label="Description"
            placeholder="Describe the problem here"
            required
            maxLength={TICKET_FAILURE_DESCRIPTION_MAX_LENGTH}
            value={state.failureDescription}
            onChange={(_, value) => setFailureDescription(value)}
            data-testid="failure-description"
          />
        </InputWrapper>
      )}

      {!!fieldsSchema.addressId && ticket.warrantyStatus && (
        <WarrantyStatusWidget warrantyStatus={ticket.warrantyStatus} />
      )}

      {!!fieldsSchema.addressId && (
        <>
          <div className="flex py-6">
            <Text size="body" weight="bold">
              Send RMA Item to:
            </Text>
            <Info
              description={
                'User will be instructed to send his item to this address. \nYou can manage your RMA addresses in the "Settings" section.'
              }
            />
          </div>

          <InputWrapper>
            <Dropdown
              options={companyAddressOptions}
              placeholder="Select Address"
              value={state.addressId}
              onChange={(event) => setAddressId(event.value)}
              test-dataid="address-field"
              width="auto"
            />
          </InputWrapper>
        </>
      )}

      {(!!fieldsSchema.shippingCompany || !!fieldsSchema.trackingNumber) && (
        <ShipmentInfoInput
          value={{
            shippingCompany: state.shippingCompany,
            trackingNumber: state.trackingNumber,
          }}
          onChange={(newValue) => setShippingInfo(newValue.shippingCompany, newValue.trackingNumber)}
        />
      )}

      {!!fieldsSchema.notes && <NotesField />}

      {!!fieldsSchema.files && <FileUpload files={state.files} setFiles={setFiles} />}
    </>
  )
}

function NotesField() {
  const { state: data, setNotes } = useTicket()

  useEffect(() => {
    return () => {
      setNotes('')
    }
  }, [])

  return (
    <div className="rma-customer-notes">
      <TextArea
        label="Additional RMA Notes/Instructions for Customer"
        placeholder="Add a note here."
        maxLength={TICKET_CUSTOMER_NOTES_MAX_LENGTH}
        value={data.notes}
        onChange={(_, value) => setNotes(value)}
        data-testid="additional-notes-customer"
      />
    </div>
  )
}

interface LoadingStatusProps {
  error?: ApolloError
}

const LoadingStatus = ({ error }: LoadingStatusProps) => {
  return (
    <div className="flex justify-center align-center flex-1">
      {error ? (
        <Text color="danger" size="body">
          {error.message || UPDATE_ERROR}
        </Text>
      ) : (
        <div className="flex justify-center align-center p-20">
          <Loader size="small" />
        </div>
      )}
    </div>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
`

const ActionButtons = styled(ButtonGroup)`
  margin-top: ${(props) => props.theme.spacing.xxl};
  padding-top: ${(props) => props.theme.spacing.l};
`

export const ManageTab = {
  id: 'manage',
  path: '/manage',
  icon: <ManageIconL size="navigation" omitSvgMargin />,
  component: Manage,
}

const FormWrap = styled.div`
  flex-grow: 1;
`

const Wrap = styled(SidePanelCard)`
  padding: ${cssVariables['spacing-l']};

  .rma-customer-notes {
    margin-top: -26px;
  }
`

const SelectDistributorHeading = styled(Text)`
  margin-bottom: ${cssVariables['spacing-m']};
`

const TicketStatusHeading = styled(Text)`
  margin-bottom: ${cssVariables['spacing-l']};

  & + .rma-customer-notes {
    margin-top: 0;
  }
`
