import { cssVariables, TextArea as UiTextArea } from '@ubnt/ui-components'
import type { ComponentProps } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Text } from './Text'
import { CustomLabel } from './custom-label'

type UiTextAreaProps = ComponentProps<typeof UiTextArea>

type TextAreaProps = UiTextAreaProps & {
  customLabel?: string
  required?: boolean
  '$maxLength-data-testid'?: string
}

export function TextArea({ maxLength, customLabel, required, ...rest }: TextAreaProps) {
  const [value, setValue] = useState(rest.value || '')

  return (
    <div className="flex column">
      {customLabel && (
        <CustomLabel label={customLabel} extra={required ? '(required)' : ''}>
          <Text size="caption" color="tertiary">
            {value.length} / {maxLength} characters
          </Text>
        </CustomLabel>
      )}

      <TextAreaStyled
        labelClassName="label"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        maxLength={maxLength}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>, v: string) => {
          setValue(v)
          if (rest.onChange) {
            rest.onChange(e, v)
          }
        }}
      />

      {!customLabel && (
        <div className="flex justify-end">
          <Text block color="tertiary">
            {value.length} / {maxLength} characters
          </Text>
        </div>
      )}
    </div>
  )
}

const TextAreaStyled = styled(UiTextArea).attrs({
  width: '100%' as UiTextAreaProps['width'],
  rows: 4 as number,
})<TextAreaProps>`
  .label {
    font-size: 12px;
  }

  textarea {
    resize: none;
    overflow: auto;
    padding-top: ${(props) => props.theme.spacing.m};
    padding-left: ${(props) => props.theme.spacing.m};
    font-size: ${cssVariables['font-size-body']};
    box-sizing: border-box;
  }

  textarea[class*='textarea-secondary'] {
    border: 1px solid rgba(0, 0, 0, 0) !important;
  }

  textarea:-moz-ui-invalid {
    box-shadow: none;
  }
`
