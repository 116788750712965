import { AppHeader, designToken, NavigationGroup, NavigationItem } from '@ubnt/ui-components'
import styled, { css } from 'styled-components'
import { UbiquitiLogo } from '@ubnt/icons'
import { Link as RouterLink } from 'react-router-dom'
import { useAuthStore } from '../../auth/auth-store'
import isPortal from '../../utils/isPortal'
import { AccountSection } from '../account-section'
import { NotificationsHeaderButton } from '../notifications/NotificationsHeaderButton'
import { Link } from '../Link'

const LogoWrapper = styled(RouterLink).attrs<{ to?: string }>((props) => (props.to ? { as: RouterLink } : {}))`
  background: none;
  color: ${designToken['desktop-color-neutral-10-light']};

  ${({ to }) =>
    to &&
    css`
      &:hover {
        color: ${designToken['desktop-color-ublue-06-light']};
      }
    `}
`

export const Header = () => {
  const { status, user } = useAuthStore()

  const isUiCarePages = document.location.pathname.indexOf('/ui-care') === 0

  const showNotifications = isPortal('RMA') && !!user && status === 'connected' && !isUiCarePages

  return (
    <>
      <AppHeader
        title={<LeftContainer />}
        size="classic"
        icon={<UbiquitiLogo variant="flat" size={32} />}
        logoWrapper={<LogoWrapper to="/" />}
      >
        <RightContainerHolder>
          {showNotifications && <NotificationsHeaderButton />}
          {user && <AccountSection />}
        </RightContainerHolder>
      </AppHeader>
    </>
  )
}

const LeftContainer = () => {
  const isUiCarePages = document.location.pathname.indexOf('/ui-care') === 0

  return (
    <NavigationGroup orientation="horizontal" style={{ marginLeft: -8 }}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment */}
      <NavigationItemStyled component={Link} href="https://store.ui.com/" icon={<>Store</>} />
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment */}
      <NavigationItemStyled component={Link} href="https://community.ui.com/" icon={<>Community</>} />
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment */}
      {isUiCarePages ? <NavigationItemStyled component={Link} href="https://rma.ui.com/" icon={<>RMA</>} /> : <></>}
    </NavigationGroup>
  )
}

const RightContainerHolder = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

const NavigationItemStyled = styled(NavigationItem)`
  && {
    text-transform: uppercase;
    font-size: ${(props) => props.theme.font.size.m};
    letter-spacing: ${(props) => props.theme.spacing.xxs};

    a > span {
      color: ${(props) => props.theme.colors.white2};
    }
  }
`
