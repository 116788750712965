import type { ApolloError } from '@apollo/client'
import { cssVariables, Loader, StatusBall, Text } from '@ubnt/ui-components'
import type { TabValue } from '@ubnt/ui-components/PanelTab/PanelTab'
import type { ComponentType } from 'react'
import { createElement, useMemo } from 'react'
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import type { TicketTabPermission } from 'rma-shared/types/permissions'
import useNestedPath from '../../hooks/useNestedPath'
import { Vertical } from '../Containers'
import { MessageContainer } from '../MessageContainer'
import { FixedSidePanel } from '../SprintShared'
import type { Shared_TicketFragment as TicketFragment } from './__generated__/Ticket'
import { getStatusBallVariant, PulsatingStatus } from './utils'

export type TicketTab<P, E> = {
  id: string
  path: string
  icon: JSX.Element
  component: ComponentType<P & E>
  props?: E
  permission?: TicketTabPermission | null
}

interface HeaderProps {
  ticket?: TicketFragment
}

const Header = ({ ticket }: HeaderProps) => (
  <>
    {ticket && (
      <>
        <StatusBall
          variant={getStatusBallVariant(ticket.status)}
          pulsating={PulsatingStatus.includes(ticket.status)}
          style={{ display: 'inline-block', marginRight: cssVariables['spacing-s'] }}
        />
        Ticket #<span data-testid="side-panel-ticket-no">{ticket.id}</span>
      </>
    )}
  </>
)

// TODO: Look into route animations. SidePanel isn't animated currently.
export const Ticket = <
  P extends { ticket: T; url?: string; refetchTicket?: any; onClose?: () => void },
  T extends TicketFragment,
  E
>({
  tabs,
  loading,
  error,
  ticket,
  onClose,
  refetchTicket,
}: {
  tabs: TicketTab<P, E>[]
  loading: boolean
  error?: ApolloError
  ticket?: T
  onClose: () => void
  refetchTicket?: any
}) => {
  const nested = useNestedPath()
  const location = useLocation()
  const history = useHistory()

  const panelTabs = useMemo(
    () =>
      tabs.map((tab) => ({
        value: nested(tab.path),
        icon: tab.icon,
        label: tab.id[0].toUpperCase().concat(tab.id.substring(1)),
      })),
    [tabs, ticket],
  )

  const baseUrl = nested('')
  const activeTab = panelTabs.find((tab) => !!matchPath(location.pathname, { path: tab.value }))

  return (
    <FixedSidePanel
      in
      onClose={onClose}
      header={<Header ticket={ticket} />}
      tabs={panelTabs}
      tabValue={activeTab?.value || panelTabs[0].value}
      onTabChange={(tabPath: TabValue) => {
        return history.push(String(tabPath) + window.location.search)
      }}
    >
      {(() => {
        if (loading || error || !ticket) {
          return (
            <>
              {(() => {
                if (loading) {
                  return (
                    <Vertical $flex $centerH $centerV>
                      <Loader />
                    </Vertical>
                  )
                }

                if (error) {
                  return (
                    <MessageContainer>
                      <Text centered>{error.message}</Text>
                    </MessageContainer>
                  )
                }

                if (!ticket) {
                  return (
                    <MessageContainer>
                      <Text>Ticket not found.</Text>
                    </MessageContainer>
                  )
                }

                return null
              })()}
            </>
          )
        }

        return (
          <Switch>
            {tabs.map((tab) => (
              <Route key={tab.path} path={nested(tab.path)}>
                {createElement(tab.component, {
                  ticket,
                  url: baseUrl,
                  onClose,
                  refetchTicket,
                  ...tab.props,
                } as P & E)}
              </Route>
            ))}
            <Route exact path={nested('')}>
              <Redirect to={nested(tabs[0].path) + window.location.search} />
            </Route>
          </Switch>
        )
      })()}
    </FixedSidePanel>
  )
}
