import { ApolloProvider } from '@apollo/client'
import { Authenticated, history } from '@shared-ui/auth'
import { AuthService } from '@shared-ui/auth/auth-service'
import { useAuthStore } from '@shared-ui/auth/auth-store'
import { GlobalStyle, Loader, LoaderContainer, Title, Vertical } from '@shared-ui/components'
import {
  AppContainer,
  HeaderContainer,
  MediaContainer,
  ScrollContainer,
  ScrollContent,
  shouldForwardProp,
} from '@shared-ui/components/Containers'
import { ErrorBoundary } from '@shared-ui/components/ErrorBoundary'
import { Header } from '@shared-ui/components/header/Header'
import { ScreenSizeProvider } from '@shared-ui/contexts'
import { setPortal } from '@shared-ui/utils/isPortal'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import styled, { StyleSheetManager, css } from 'styled-components'
import { useRequest } from '@shared-ui/hooks'
import { setMacSpecialStrings } from '@shared/lib'
import { getApolloClient } from '../apollo'
import { WildcardRoute } from './WildcardRoute'
import { LoginModal } from './components/SubmitRma/components/LoginModal'
import { SearchHardwareHeading } from './components/SubmitRma/components/search-hardware-heading'
import { SubmitRma, SubmitRmaContainer } from './components/SubmitRma/components/submit-rma'
import { SubmitRmaBackground } from './components/SubmitRma/components/submit-rma-background'
import { Dashboard } from './components/dashboard'
import Footer from './components/dashboard/Footer'
import { RMA_REDIRECT_EMPTY_DASHBOARD_TO_SUBMIT_KEY } from './constants'
import { UICareAddView } from './ui-care/ui/ui-care-add-view'
import { UICareView } from './ui-care/ui/ui-care-view'

setPortal('RMA', ' - RMA Portal | Ubiquiti')

const AppRoutes = () => {
  const [fetchOuiMacs, { loading: loadingOuiMacs, error: ouiMacsError }] = useRequest('ouiMacs')

  useEffect(() => {
    if (!window.location.pathname.startsWith('/dashboard/tickets')) {
      localStorage.setItem(RMA_REDIRECT_EMPTY_DASHBOARD_TO_SUBMIT_KEY, '1')

      setTimeout(() => {
        localStorage.removeItem(RMA_REDIRECT_EMPTY_DASHBOARD_TO_SUBMIT_KEY)
      }, 1000)
    }

    fetchOuiMacs({})
      .then(({ data }) => setMacSpecialStrings(data))
      .catch((e) => console.error(e))
  }, [])

  if (loadingOuiMacs || ouiMacsError) {
    return (
      <LoaderContainer>
        <Loader type={ouiMacsError ? 'error' : undefined} />
      </LoaderContainer>
    )
  }

  return (
    <Switch>
      <Route path="/submit">
        <AppBase inline>
          <Title title="Submit" />
          <SubmitRma />
        </AppBase>
      </Route>

      <Route path="/dashboard">
        <AppBase>
          <Title title="Dashboard" />
          <Dashboard />
        </AppBase>
      </Route>

      <Route path="/ui-care">
        <AppBase>
          <Title title="UI Care Protection Program" />
          <UICareView />
        </AppBase>
      </Route>

      <Route path="/ui-care-add">
        <AppBase inline>
          <Title title="Add Your UI Care covered products" />
          <UICareAddView />
        </AppBase>
      </Route>

      <Route path="/cookies-policy">
        <AppBase inline>
          <Title title="Cookies Policy" />
          <Vertical style={{ padding: '30px 60px' }}>
            <div id="ot-sdk-cookie-policy" />
          </Vertical>
        </AppBase>
      </Route>

      <Route path="*">
        <WildcardRoute />
      </Route>
    </Switch>
  )
}

const AppLogin = () => {
  const { user } = useAuthStore()

  if (!user) {
    return (
      <AppBase>
        <Switch>
          <Route path="/ui-care">
            <Title title="UI Care Protection Program" />
            <UICareView />
          </Route>

          <Route path="/ui-care-add">
            <ForceLogin />
          </Route>

          <Route path="*">
            <ScreenSizeProvider>
              <SubmitRmaBackground />
              <SubmitRmaContainer>
                <SearchHardwareHeading />
                <LoginModal />
              </SubmitRmaContainer>
            </ScreenSizeProvider>
          </Route>
        </Switch>
      </AppBase>
    )
  }

  return <AppRoutes />
}

export const App = () => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <AppContainer>
        <GlobalStyle />
        <ErrorBoundary>
          <Authenticated>
            <Router history={history}>
              <AppLogin />
            </Router>
          </Authenticated>
        </ErrorBoundary>
      </AppContainer>
    </StyleSheetManager>
  )
}

const AppBase = ({ children, inline }: { inline?: boolean; children: ReactNode }) => {
  return (
    <ApolloProvider client={getApolloClient()}>
      <Workspace $inline={inline}>
        <HeaderContainer>
          <Header />
        </HeaderContainer>

        <MediaContainer>
          <ScrollContainer>
            <ScrollContent $inline={inline}>
              <Holder>{children}</Holder>
              <Footer />
            </ScrollContent>
          </ScrollContainer>
        </MediaContainer>
      </Workspace>
    </ApolloProvider>
  )
}

const ForceLogin = () => {
  const { user } = useAuthStore()

  useEffect(() => {
    async function handleLogin() {
      if (!user) {
        await AuthService.login()
      }
    }

    void handleLogin()
  }, [])

  return null
}

interface WorkspaceProps {
  $inline?: boolean
}
const Workspace = styled.div<WorkspaceProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${(props) =>
    props.$inline &&
    css`
      min-height: initial;
      height: 100%;
    `}
`

const Holder = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`
