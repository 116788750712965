import { CheckIcon, ClosePrimaryIcon, SearchIcon } from '@ubnt/icons'
import type { DropdownOption } from '@ubnt/ui-components'
import { DropdownArrow, Input, Loader, Text } from '@ubnt/ui-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickOutside } from '../utils/use-click-outside'
import { Image } from './Image'

interface MultiDropdownProps {
  value?: DropdownOption[]
  label?: React.ReactNode | string
  placeholder?: string
  disabled?: boolean
  searchable?: boolean
  options?: DropdownOption[]
  optionsPlaceholder?: string
  loading?: boolean
  onChange?: (optionsAll: DropdownOption[], option: DropdownOption | null) => void
  onInputChange?: (newValue: string) => void
}

export function MultiDropdown({
  value,
  label,
  placeholder = 'Select',
  disabled,
  searchable,
  options,
  optionsPlaceholder,
  loading,
  onChange,
  onInputChange,
}: MultiDropdownProps) {
  const [active, setActive] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selected] = useState(new Map<string, DropdownOption>())
  const [updateIndex, setUpdateIndex] = useState(0)
  const ref = useRef<HTMLInputElement | null>(null)

  function handleClickOutside() {
    setActive(false)
    setInputValue('')
    ref.current?.blur()

    if (onInputChange) {
      onInputChange('')
    }
  }

  const clickRef = useClickOutside<HTMLDivElement>(handleClickOutside)

  useEffect(() => {
    if (value) {
      selected.clear()
      for (const entry of value) {
        selected.set(String(entry.value), entry)
      }
    } else {
      selected.clear()
    }

    setUpdateIndex(updateIndex + 1)
  }, [value])

  const selectedValues = useMemo(() => {
    return [...selected.values()]
  }, [updateIndex])

  const selectedListValues = useMemo(() => {
    if (!options || options.length <= 0) {
      return []
    }

    const query = inputValue.toLocaleLowerCase()
    const newValues = selectedValues.filter((entry) => entry.label.toLocaleLowerCase().indexOf(query) !== -1)
    return newValues
  }, [selectedValues, inputValue, options])

  const filteredOptions = useMemo(() => {
    if (!options || !inputValue) {
      return options
    }

    const query = inputValue.toLocaleLowerCase()
    const newOptions = options.filter((entry) => entry.label.toLocaleLowerCase().indexOf(query) !== -1)
    return newOptions
  }, [inputValue, options])

  const showSelectedCount = (!searchable || !active) && selected.size > 0

  const handleOptionDeselected = (option: DropdownOption) => {
    const optionKey = String(option.value)

    if (!selected.has(optionKey)) {
      return
    }

    selected.delete(optionKey)
    setUpdateIndex(updateIndex + 1)

    if (onChange) {
      onChange([...selected.values()], null)
    }
  }

  const handleOptionSelected = (option: DropdownOption) => {
    const optionKey = String(option.value)
    selected.set(optionKey, option)
    setUpdateIndex(updateIndex + 1)

    if (onChange) {
      onChange([...selected.values()], option)
    }
  }

  if (loading) {
    return (
      <div className="flex flex-column">
        {label && (
          <Text size="caption" color={active ? 'info' : 'tertiary'}>
            {label}
          </Text>
        )}

        <div className="relative border-b">
          <Input
            placeholder={placeholder}
            iconBefore={searchable ? <SearchIcon /> : undefined}
            iconAfter={<AnimatedDropdownArrow className={active ? 'active' : ''} />}
            width="100%"
            disabled
          />
        </div>

        <Loader size="small" />
      </div>
    )
  }

  return (
    <div className="flex flex-column" ref={clickRef}>
      {label && (
        <Text size="caption" color={active ? 'info' : 'tertiary'}>
          {label}
        </Text>
      )}

      <div className="relative border-b">
        <Input
          variant="secondary"
          placeholder={placeholder}
          iconBefore={searchable ? <SearchIcon /> : undefined}
          iconAfter={<AnimatedDropdownArrow className={active ? 'active' : ''} onClick={() => setActive(!active)} />}
          width="100%"
          value={showSelectedCount ? `${selected.size} selected` : inputValue}
          spellCheck={false}
          disabled={disabled}
          onClick={() => {
            setActive(true)
          }}
          ref={ref}
          onChange={(_, newValue) => {
            const newInputValue = String(newValue)
            setInputValue(newInputValue)
            if (onInputChange) {
              onInputChange(newInputValue)
            }
          }}
        />

        {active && (
          <>
            <div className="absolute width-100 z-999 max-height-150 scroll-y flex flex-column bg-white border-bl-radius-4 border-br-radius-4 box-shadow">
              {selectedListValues.map((option, index) => {
                return (
                  <DropdownItem
                    key={`selected-${index}`}
                    option={option}
                    onChange={handleOptionDeselected}
                    isSelected
                  />
                )
              })}

              {filteredOptions &&
                filteredOptions.map((option, index) => {
                  const optionKey = String(option.value)

                  if (selected.has(optionKey)) {
                    return null
                  }

                  return <DropdownItem key={index} option={option} onChange={handleOptionSelected} isSelected={false} />
                })}

              {(!options || !options.length) && (
                <div className="flex align-center justify-center height-34px min-height-34px">
                  <Text size="body" color="primary" centered>
                    {optionsPlaceholder}
                  </Text>
                </div>
              )}
            </div>
          </>
        )}

        {selectedValues.length > 0 && (
          <div className="flex flex-wrap align-center mt-10">
            {selectedValues.map((option) => {
              return (
                <div key={option.value} className="flex align-center pl-8 mr-8 mb-8 bg-dark-gray border-radius-4">
                  {option.image && <Image src={String(option.image)} style={{ width: '20px', height: '20px' }} />}
                  <div className="flex align-center p-2">
                    <Text size="body" color="secondary">
                      {option.label}
                    </Text>
                  </div>
                  <div
                    className="flex align-center pl-2 width-20px height-20px pointer"
                    onClick={() => {
                      handleOptionDeselected(option)

                      if (onChange) {
                        onChange([...selected.values()], option)
                      }
                    }}
                  >
                    <ClosePrimaryIcon size="small" />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

interface DropdownItemProps {
  option: DropdownOption
  onChange: (option: DropdownOption) => void
  isSelected: boolean
}

const DropdownItem = ({ option, onChange, isSelected }: DropdownItemProps) => {
  return (
    <div
      className={`flex align-center height-34px min-height-34px pointer ${isSelected ? 'active-gray' : 'hover-gray'}`}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
        onChange(option)
      }}
    >
      <div className="flex align-center justify-center width-24px">{isSelected && <CheckIcon size="small" />}</div>

      {option.image && (
        <Image src={String(option.image)} style={{ width: '24px', height: '24px', marginRight: '4px' }} />
      )}

      <div className="flex flex-1">
        <Text size="body" color="primary">
          {option.label}
        </Text>
      </div>

      <div className="flex align-center justify-center width-24px">
        {isSelected && <ClosePrimaryIcon size="small" />}
      </div>
    </div>
  )
}

const AnimatedDropdownArrow = styled(DropdownArrow).attrs({ size: 'small' })`
  &.active {
    transform: rotate(90deg);
  }
`
