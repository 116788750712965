import styled from 'styled-components'
import BaseFlex from 'styled-flex-component'

export type FlexProps = {
  flex?: number | string
  scroll?: boolean
  minHeight?: boolean // bool - whether to use the minimum possible height (used together with scroll)
  fullWidth?: boolean
  fullHeight?: boolean
}

// Use Flex when you just need a row or flex alignment
// without taking up the remaining space by default.
export const Flex = styled(BaseFlex)<FlexProps>`
  flex: ${({ flex }) => (flex === undefined ? 'none' : flex)};
  ${({ scroll }) => scroll && `overflow: auto;`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
  ${({ minHeight }) =>
    minHeight &&
    `
      height: min-content;
      max-height: min-content;
    `}
`

// Use FlexItem when you need to take up the remaining space or
// proportionally share it between other FlexItems.
export const FlexItem = styled(Flex).attrs({ column: true })<FlexProps>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
`
