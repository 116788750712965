import { Button, Buttons, Text } from '@shared-ui/components'
import { ProductsDropdown } from '@shared-ui/components/products-dropdown'
import { useRequest } from '@shared-ui/hooks'
import type { DeviceId, NetsuiteProductId } from '@shared/types/brands'
import { SubmitRmaService } from '../submit-rma-service'
import { useSubmitRmaStore } from '../submit-rma-store'
import { ReturnConfirmation } from './return-confirmation'
import { StepHeading } from './step-heading'

export function NotMyDevice({ onBack }: { onBack: () => void }) {
  const { device, kit, kitDevices, kitReturnType } = useSubmitRmaStore()

  const [createDeviceFrom, { loading: createDeviceFromLoading, error }] = useRequest('createDeviceFrom')
  const [updateDeviceKit, { loading: updateDeviceKitLoading }] = useRequest('updateDeviceKit')

  const isLoading = createDeviceFromLoading || updateDeviceKitLoading
  const isNextDisabled = isLoading || SubmitRmaService.canStartFlow()

  const handleProductSelected = async (productId: NetsuiteProductId) => {
    if (!device) {
      return
    }

    try {
      const response = await createDeviceFrom({
        deviceId: device.id,
        productId,
      })

      SubmitRmaService.processDevice(response.device, { supportsUICare: response.supportUICare })
      SubmitRmaService.startFlow()
    } catch (err) {
      //
    }
  }

  const handleNext = async () => {
    if (!device) {
      return
    }

    if (kit) {
      try {
        const result = await updateDeviceKit({
          deviceId: device.id,
          kitSku: kit.sku,
          kitDevices: kitDevices.filter((entry) => !entry.deviceId).map((entry) => entry.deviceId) as DeviceId[],
          kitReturnType,
        })
        SubmitRmaService.processDevice(result.device, { supportsUICare: result.supportUICare })
        SubmitRmaService.startFlow()
      } catch (err) {
        //
      }
    } else {
      SubmitRmaService.startFlow()
    }
  }

  return (
    <div className="flex column p-16 mt-6 relative">
      <StepHeading showStepper heading="Enter and select your product's model." />

      <div className="mt-12">
        <ProductsDropdown
          variant="secondary"
          onChange={(option) => {
            void handleProductSelected(option.value)
          }}
        />
      </div>

      {!isLoading && <ReturnConfirmation />}

      {error && (
        <Text color="danger" size="body">
          {error}
        </Text>
      )}

      <Buttons>
        <Button variant="tertiary" onClick={onBack} $minWidth="0px">
          Back
        </Button>

        <Button
          variant="primary"
          onClick={() => void handleNext()}
          disabled={!isNextDisabled}
          $minWidth="0px"
          loader={isLoading ? 'dots' : undefined}
        >
          Next
        </Button>
      </Buttons>
    </div>
  )
}
