import type { DeviceId } from '@shared/types/brands'
import { Button, cssVariables } from '@ubnt/ui-components'
import { useEffect, useState } from 'react'
import formatSerial from 'rma-shared/lib/utils/format/serial'
import type { Device } from 'rma-shared/types/devices'
import styled from 'styled-components'
import UnifiCareLogoHq from '../assets/u-care-big.png'
import UnifiCareLogo from '../assets/u-care.png'
import { useRequest } from '../hooks'
import isPortal from '../utils/isPortal'
import { Grid } from './Grid'
import { RemoteImage } from './Image'
import { Loading } from './Loading'
import { Retry } from './Retry'
import { Column, Row } from './SprintShared'
import { Text } from './Text'
import type { Shared_DeviceFragment as DeviceFragment } from './__generated__/Device'
import {
  useShared_DeviceLazyQuery as useDeviceLazyQuery,
  useShared_DeviceQuery as useDeviceQuery,
} from './__generated__/Device'

export { useDeviceLazyQuery, useDeviceQuery }

export const sanitizeDevice = ({ serial, mac, device }: { serial?: string; mac?: string; device: DeviceFragment }) => ({
  serial: device.mac ? undefined : serial,
  mac: device.mac ? device.mac : mac || undefined,
  isUnifiCare: device.isUnifiCare,
  isSellerInactive: device.isSellerInactive,
  name: device.name,
  imageUrl: device.productImage,
  warrantyStatus: device.warrantyStatus,
  dateCode: device.dateCode,
})

export const sanitizeRequestDevice = ({ serial, mac, device }: { serial?: string; mac?: string; device: Device }) => ({
  serial: device.mac ? undefined : serial,
  mac: device.mac ? device.mac : mac || undefined,
  isUnifiCare: device.isUnifiCare,
  isSellerInactive: device.isSellerInactive,
  name: device.name,
  imageUrl: device.productImage,
  warrantyStatus: device.warrantyStatus,
  dateCode: device.dateCode,
})

export interface DeviceInfo {
  mac?: string
  serial?: string
  name: string
  imageUrl: string | null
  isUnifiCare?: boolean
  dateCode?: string | null
  parent?: { name: string } | null
}

interface DeviceInformationProps {
  deviceId: DeviceId
  serial?: string
  mac?: string
}
export const DeviceInformation = ({ deviceId, serial, mac }: DeviceInformationProps) => {
  const { data, loading, error, refetch } = useDeviceQuery({
    variables: { deviceId },
  })
  const device = data?.device

  return (
    <>
      {loading && <Loading />}
      {error && <Retry text="Could not load device." retry={refetch} />}
      {device && <DeviceInfoPanel device={sanitizeDevice({ device, serial, mac })} />}
    </>
  )
}

export const DeviceInformationNew = ({ deviceId, serial, mac }: DeviceInformationProps) => {
  const { data, loading, error, refetch } = useDeviceQuery({
    variables: { deviceId },
  })
  const device = data?.device

  return (
    <>
      {loading && <Loading />}
      {error && <Retry text="Could not load device." retry={refetch} />}
      {device && <DeviceNew device={sanitizeDevice({ device, serial, mac })} />}
    </>
  )
}

interface DeviceInfoPanelProps {
  device: DeviceInfo
  soldBy?: string
  nameTextTestId?: string
  macTextTestId?: string
  soldByTestId?: string
  colored?: boolean
}

export const DeviceInfoPanel: React.FC<DeviceInfoPanelProps> = ({
  device,
  soldBy,
  nameTextTestId,
  macTextTestId,
  soldByTestId,
  colored,
}) => {
  const [fetch, { loading }] = useRequest('updateDevice')

  const [dateCode, setDateCode] = useState<string | null | undefined>(device.dateCode)

  const isFae = isPortal('FAE')

  useEffect(() => {
    if (!isFae || dateCode || !device.mac) {
      return
    }

    void fetch({
      mac: device.mac,
    }).then((response) => {
      const newDateCode = response.device?.dateCode
      setDateCode(newDateCode || '')
    })
  }, [])

  return (
    <DeviceContainer colored={colored}>
      <ImageContainer>
        <RemoteImage url={device.imageUrl} alt={device.name} width="48px" height="48px" />
      </ImageContainer>

      <Grid $item $xs={9}>
        <Grid $container direction="column" gap="xs" height="100%" justify="center">
          <Grid $item>
            <Grid $container direction="column">
              <Grid $item>
                <Text $size="l2" weight="bold" data-testid={nameTextTestId}>
                  {device.name}
                </Text>

                {isPortal('FAE') && device.parent && (
                  <Text $size="l2" color="secondary" style={{ marginLeft: '5px' }}>
                    ({device.parent.name})
                  </Text>
                )}
              </Grid>

              {(device.serial || device.mac) && (
                <Grid $item>
                  <Text $size="l2" color="secondary" data-testid={macTextTestId}>
                    {formatSerial(device.serial || device.mac || '')}
                  </Text>

                  {isFae && dateCode && !loading && (
                    <Text $size="l2" color="secondary" style={{ marginLeft: '5px' }}>
                      ({dateCode})
                    </Text>
                  )}
                </Grid>
              )}

              {device.isUnifiCare && (
                <Row style={{ marginTop: '12px' }}>
                  <UniFiCareSmall />
                  <Text size="body" color="secondary">
                    Covered by UI Care
                  </Text>
                </Row>
              )}

              {isFae && (
                <>
                  {loading && (
                    <Row>
                      <Button loader={loading ? 'dots' : undefined} full={false} size="small" type="button">
                        .
                      </Button>
                    </Row>
                  )}
                </>
              )}
            </Grid>
          </Grid>

          {soldBy && (
            <Grid $item>
              <Text truncate color="secondary" data-testid={soldByTestId}>
                Sold by {soldBy}
              </Text>
            </Grid>
          )}
        </Grid>
      </Grid>
    </DeviceContainer>
  )
}

export function SubmitDevice({ device }: { device: Device }) {
  return (
    <Row>
      <Column>
        <ImageContainerNew>
          <RemoteImage url={device.productImage} alt={device.name} />
        </ImageContainerNew>
      </Column>

      <Column>
        <Text size="body" weight="bold">
          {device.name}
        </Text>
        <Text size="body" color="secondary">
          {formatSerial(device.mac)}
        </Text>

        {device.seller && (
          <Text truncate color="secondary">
            Sold by {device.seller.name}
          </Text>
        )}

        {device.isUnifiCare && (
          <Row style={{ marginTop: '12px' }}>
            <UniFiCareSmall />
            <Text size="body" color="secondary">
              Covered by UI Care
            </Text>
          </Row>
        )}
      </Column>
    </Row>
  )
}

export function DeviceNew({
  device,
  skuOverride,
  testId,
}: {
  device: DeviceInfo
  skuOverride?: string
  testId?: string
}) {
  return (
    <div className="flex">
      <div className="flex column">
        <ImageContainerNew>
          <RemoteImage url={device.imageUrl} alt={device.name} />
        </ImageContainerNew>
      </div>

      <div className="flex column justify-center">
        <Text size="body" weight="bold" data-testid={`${testId}-name`}>
          {skuOverride || device.name}
        </Text>

        {(device.serial || device.mac) && (
          <Text size="body" color="secondary" data-testid={`${testId}-mac`}>
            {formatSerial(device.serial || device.mac || '')}
          </Text>
        )}

        {device.isUnifiCare && (
          <div className="flex" style={{ marginTop: '12px' }}>
            <UniFiCareSmall />
            <Text size="body" color="secondary">
              Covered by UI Care
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

interface DeviceContainerProper {
  colored?: boolean
}
export const DeviceContainer = styled.div<DeviceContainerProper>`
  display: flex;
  height: 55px;
  align-items: center;

  ${(props) =>
    props.colored &&
    `
    flex: 0 0 90px;
    height: 90px;
    padding: 20px 30px;
    margin: 0 0 ${cssVariables['spacing-l']};
    border-radius: 8px; 
    background-color: ${cssVariables.motifs.light.white};
  `}
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  height: 100%;

  & > img {
    max-width: 50px;
    max-height: 50px;
  }
`

const ImageContainerNew = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  margin-right: 12px;

  & > img {
    max-width: 50px;
    max-height: 50px;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
`

export const UCareLogoBadge = styled(Image).attrs({ src: UnifiCareLogoHq })`
  width: 24px;
  height: 24px;
`

export const UniFiCareLogo = styled(Image).attrs({ src: UnifiCareLogoHq })`
  height: 100%;
`

export const UniFiCareSmall = styled(Image).attrs({ src: UnifiCareLogo })`
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 6px;
`
