import * as yup from 'yup'

import type { NetsuiteProductId } from '../../../types/brands'
import { DeviceSerialSchema, NetsuiteProductIdSchema } from './common'
import { REQUIRED } from './const'

export const CreateDeviceInputSchema = yup
  .object()
  .shape({
    mac: DeviceSerialSchema.defined().ensure(),
    productId: NetsuiteProductIdSchema.required(REQUIRED),
  })
  .required()

export type CreateDeviceInput = Omit<yup.InferType<typeof CreateDeviceInputSchema>, 'productId'> & {
  productId: NetsuiteProductId
}

export const validateCreateDeviceInput = (input: CreateDeviceInput, validateMacSerialFormat = true) =>
  CreateDeviceInputSchema.validate(input, { context: { validateMacSerialFormat } })
