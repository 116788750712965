import type { CountryCode } from 'libphonenumber-js'
import { EuropeanUnionCountries } from '../../lib/constants'

export const uCareSupportedCountries: Set<CountryCode> = new Set([
  'US',
  'CA',
  'GB',
  'NO',
  'CH',
  ...EuropeanUnionCountries,
])

export const doesCountrySupportUCare = (countryStr?: CountryCode | string) => {
  if (!countryStr) {
    return false
  }

  return uCareSupportedCountries.has(countryStr as CountryCode)
}
