import type { FC } from 'react'
import styled from 'styled-components'
import Flex from 'styled-flex-component'

export const Count: FC = ({ children }) => <Container>{children}</Container>

const Container = styled(Flex).attrs({ center: true })`
  width: 30px;
  height: 30px;

  font-size: 15px;
  text-align: center;

  color: #7e8190;
  background: #f8f8f9;
  border-radius: 50%;
`
