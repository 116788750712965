import create from 'zustand'
import type { ComparisonButtonType, TableItem } from './ui-care-types'

type UICareState = {
  tableItems: TableItem[]
  activeButton: ComparisonButtonType
}

type UICareStateFunctions = {
  reset: () => void
  set: (values: Partial<UICareState>) => void
  removeId: (id: number) => void
}

const createInitialState = (): UICareState => {
  return {
    tableItems: [],
    activeButton: 'nav-ui-care-protection',
  }
}

export const useUICareStore = create<UICareState & UICareStateFunctions>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },

  set(values) {
    return set((state) => {
      const newState = { ...state, ...values }

      return newState
    })
  },

  removeId(id: number) {
    return set((state) => {
      const indexToRemove = state.tableItems.findIndex((entry) => entry.id === id)
      if (indexToRemove === -1) {
        return state
      }

      const newTableItems = [...state.tableItems]
      newTableItems.splice(indexToRemove, 1)

      return { tableItems: newTableItems }
    })
  },
}))
