import styled from 'styled-components'

export const UCareGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(164deg, #e5f1ff -1.97%, #ffffff 65.74%);
  border-radius: 12px;
`
