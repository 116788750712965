// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../generated/graphql'

export type TicketsWithUnsentItemsQueryVariables = Types.Exact<{ [key: string]: never }>

export type TicketsWithUnsentItemsQuery = { readonly __typename?: 'Query' } & {
  readonly ticketsWithUnsentItems: ReadonlyArray<
    { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id' | 'customerSentItemAt'> & {
        readonly device: { readonly __typename?: 'Device' } & Pick<Types.Device, 'id' | 'name' | 'mac'>
      }
  >
}

export type MarkTicketItemSentMutationVariables = Types.Exact<{
  ticketId: Types.Scalars['TicketId']
}>

export type MarkTicketItemSentMutation = { readonly __typename?: 'Mutation' } & {
  readonly markTicketItemSent: { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id' | 'customerSentItemAt'>
}

export const TicketsWithUnsentItemsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TicketsWithUnsentItems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticketsWithUnsentItems' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerSentItemAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'device' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useTicketsWithUnsentItemsQuery__
 *
 * To run a query within a React component, call `useTicketsWithUnsentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsWithUnsentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsWithUnsentItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTicketsWithUnsentItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<TicketsWithUnsentItemsQuery, TicketsWithUnsentItemsQueryVariables>,
) {
  return Apollo.useQuery<TicketsWithUnsentItemsQuery, TicketsWithUnsentItemsQueryVariables>(
    TicketsWithUnsentItemsDocument,
    baseOptions,
  )
}
export function useTicketsWithUnsentItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketsWithUnsentItemsQuery, TicketsWithUnsentItemsQueryVariables>,
) {
  return Apollo.useLazyQuery<TicketsWithUnsentItemsQuery, TicketsWithUnsentItemsQueryVariables>(
    TicketsWithUnsentItemsDocument,
    baseOptions,
  )
}
export type TicketsWithUnsentItemsQueryHookResult = ReturnType<typeof useTicketsWithUnsentItemsQuery>
export type TicketsWithUnsentItemsLazyQueryHookResult = ReturnType<typeof useTicketsWithUnsentItemsLazyQuery>
export type TicketsWithUnsentItemsQueryResult = Apollo.QueryResult<
  TicketsWithUnsentItemsQuery,
  TicketsWithUnsentItemsQueryVariables
>
export const MarkTicketItemSentDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkTicketItemSent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ticketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markTicketItemSent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ticketId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ticketId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerSentItemAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type MarkTicketItemSentMutationFn = Apollo.MutationFunction<
  MarkTicketItemSentMutation,
  MarkTicketItemSentMutationVariables
>

/**
 * __useMarkTicketItemSentMutation__
 *
 * To run a mutation, you first call `useMarkTicketItemSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTicketItemSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTicketItemSentMutation, { data, loading, error }] = useMarkTicketItemSentMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useMarkTicketItemSentMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkTicketItemSentMutation, MarkTicketItemSentMutationVariables>,
) {
  return Apollo.useMutation<MarkTicketItemSentMutation, MarkTicketItemSentMutationVariables>(
    MarkTicketItemSentDocument,
    baseOptions,
  )
}
export type MarkTicketItemSentMutationHookResult = ReturnType<typeof useMarkTicketItemSentMutation>
export type MarkTicketItemSentMutationResult = Apollo.MutationResult<MarkTicketItemSentMutation>
export type MarkTicketItemSentMutationOptions = Apollo.BaseMutationOptions<
  MarkTicketItemSentMutation,
  MarkTicketItemSentMutationVariables
>
