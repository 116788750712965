import type { sanitizeCompanyAddress } from '@shared-ui/components'

export type SubmitRmaStep =
  | 'searchHardware'
  | 'selectSeller'
  | 'describeIssue'
  | 'confirmInformation'
  | 'selectShippingAddress'
  | 'addSeller'
  | 'addShippingAddress'
  | 'editShippingAddress'
  | 'verifyShippingAddress'

export type SubmitSearchType = 'no-serial' | 'unknown' | 'custom'

export type SubmitFlow = 'default' | 'withoutSeller'

export const SubmitRmaFlow: Record<SubmitFlow, SubmitRmaStep[]> = {
  default: ['selectSeller', 'describeIssue', 'selectShippingAddress', 'confirmInformation'],
  withoutSeller: ['describeIssue', 'selectShippingAddress', 'confirmInformation'],
}

export type CompanyAddress = ReturnType<typeof sanitizeCompanyAddress>
