import type { FC } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { useWindowDimensions } from '../hooks'
import { theme } from '../styles/theme'

type Context = {
  isMobile: boolean
  isDesktop: boolean
}

const ScreenSizeContext = createContext<Context | undefined>(undefined)

export const ScreenSizeProvider: FC = ({ children }) => {
  const { width } = useWindowDimensions()

  const isMobile = useMemo(() => width < theme.media.viewport.s, [width])
  const isDesktop = useMemo(() => width > theme.media.viewport.xl, [width])

  const context: Context = {
    isMobile,
    isDesktop,
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => context, Object.values(context))

  return <ScreenSizeContext.Provider value={value}>{children}</ScreenSizeContext.Provider>
}

export const useScreenSize = () => {
  const context = useContext(ScreenSizeContext)
  if (context === undefined) {
    throw new Error('useScreenSize must be used within a ScreenSizeProvider')
  }

  return context
}
