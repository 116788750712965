// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../../generated/graphql'

import type { Shared_DeviceFragment } from '../../../../../../../../../packages/rma-frontend-shared/components/__generated__/Device'
import type { Shared_Address_CompanyFragment } from '../../../../../../../../../packages/rma-frontend-shared/components/__generated__/CompanyAddress'

export type SelectSeller_DeviceQueryVariables = Types.Exact<{
  deviceId: Types.Scalars['DeviceId']
}>

export type SelectSeller_DeviceQuery = { readonly __typename?: 'Query' } & {
  readonly device: Types.Maybe<{ readonly __typename?: 'Device' } & Shared_DeviceFragment>
}

export type SelectSeller_CompanyLookupQueryVariables = Types.Exact<{
  search: Types.Scalars['String']
}>

export type SelectSeller_CompanyLookupQuery = { readonly __typename?: 'Query' } & {
  readonly companyLookup: ReadonlyArray<{ readonly __typename?: 'Company' } & Shared_Address_CompanyFragment>
  readonly myCompanies: ReadonlyArray<
    { readonly __typename?: 'Company' } & Pick<Types.Company, 'id'> & Shared_Address_CompanyFragment
  >
  readonly ubiquitiCompany: { readonly __typename?: 'Company' } & Pick<
    Types.Company,
    'id' | 'name' | 'nameLegal' | 'website' | 'logo'
  >
}

export type ValidateFilesQueryVariables = Types.Exact<{
  files: ReadonlyArray<Types.Scalars['Upload']> | Types.Scalars['Upload']
  filesOptional: ReadonlyArray<Types.Scalars['Upload']> | Types.Scalars['Upload']
}>

export type ValidateFilesQuery = { readonly __typename?: 'Query' } & Pick<Types.Query, 'validateFiles'>

export const SelectSeller_DeviceDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectSeller_Device' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deviceId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'device' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deviceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deviceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Device' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Device' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Device' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isUnifiCare' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSellerInactive' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRefurbished' } },
          { kind: 'Field', name: { kind: 'Name', value: 'warrantyStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useSelectSeller_DeviceQuery__
 *
 * To run a query within a React component, call `useSelectSeller_DeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectSeller_DeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectSeller_DeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useSelectSeller_DeviceQuery(
  baseOptions: Apollo.QueryHookOptions<SelectSeller_DeviceQuery, SelectSeller_DeviceQueryVariables>,
) {
  return Apollo.useQuery<SelectSeller_DeviceQuery, SelectSeller_DeviceQueryVariables>(
    SelectSeller_DeviceDocument,
    baseOptions,
  )
}
export function useSelectSeller_DeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectSeller_DeviceQuery, SelectSeller_DeviceQueryVariables>,
) {
  return Apollo.useLazyQuery<SelectSeller_DeviceQuery, SelectSeller_DeviceQueryVariables>(
    SelectSeller_DeviceDocument,
    baseOptions,
  )
}
export type SelectSeller_DeviceQueryHookResult = ReturnType<typeof useSelectSeller_DeviceQuery>
export type SelectSeller_DeviceLazyQueryHookResult = ReturnType<typeof useSelectSeller_DeviceLazyQuery>
export type SelectSeller_DeviceQueryResult = Apollo.QueryResult<
  SelectSeller_DeviceQuery,
  SelectSeller_DeviceQueryVariables
>
export const SelectSeller_CompanyLookupDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectSeller_CompanyLookup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyLookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Address_Company' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompanies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shared_Address_Company' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ubiquitiCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nameLegal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shared_Address_Company' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Company' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nameLegal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultShipping' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDefaultBilling' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useSelectSeller_CompanyLookupQuery__
 *
 * To run a query within a React component, call `useSelectSeller_CompanyLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectSeller_CompanyLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectSeller_CompanyLookupQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSelectSeller_CompanyLookupQuery(
  baseOptions: Apollo.QueryHookOptions<SelectSeller_CompanyLookupQuery, SelectSeller_CompanyLookupQueryVariables>,
) {
  return Apollo.useQuery<SelectSeller_CompanyLookupQuery, SelectSeller_CompanyLookupQueryVariables>(
    SelectSeller_CompanyLookupDocument,
    baseOptions,
  )
}
export function useSelectSeller_CompanyLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectSeller_CompanyLookupQuery, SelectSeller_CompanyLookupQueryVariables>,
) {
  return Apollo.useLazyQuery<SelectSeller_CompanyLookupQuery, SelectSeller_CompanyLookupQueryVariables>(
    SelectSeller_CompanyLookupDocument,
    baseOptions,
  )
}
export type SelectSeller_CompanyLookupQueryHookResult = ReturnType<typeof useSelectSeller_CompanyLookupQuery>
export type SelectSeller_CompanyLookupLazyQueryHookResult = ReturnType<typeof useSelectSeller_CompanyLookupLazyQuery>
export type SelectSeller_CompanyLookupQueryResult = Apollo.QueryResult<
  SelectSeller_CompanyLookupQuery,
  SelectSeller_CompanyLookupQueryVariables
>
export const ValidateFilesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'files' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filesOptional' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Upload' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validateFiles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'files' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'files' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filesOptional' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filesOptional' } },
              },
            ],
          },
        ],
      },
    },
  ],
}

/**
 * __useValidateFilesQuery__
 *
 * To run a query within a React component, call `useValidateFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateFilesQuery({
 *   variables: {
 *      files: // value for 'files'
 *      filesOptional: // value for 'filesOptional'
 *   },
 * });
 */
export function useValidateFilesQuery(
  baseOptions: Apollo.QueryHookOptions<ValidateFilesQuery, ValidateFilesQueryVariables>,
) {
  return Apollo.useQuery<ValidateFilesQuery, ValidateFilesQueryVariables>(ValidateFilesDocument, baseOptions)
}
export function useValidateFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateFilesQuery, ValidateFilesQueryVariables>,
) {
  return Apollo.useLazyQuery<ValidateFilesQuery, ValidateFilesQueryVariables>(ValidateFilesDocument, baseOptions)
}
export type ValidateFilesQueryHookResult = ReturnType<typeof useValidateFilesQuery>
export type ValidateFilesLazyQueryHookResult = ReturnType<typeof useValidateFilesLazyQuery>
export type ValidateFilesQueryResult = Apollo.QueryResult<ValidateFilesQuery, ValidateFilesQueryVariables>
