import { useEffect } from 'react'
import type { ChatId } from 'rma-shared/types/brands'
import type { OnMessageSentSubscription, OnMessageSentSubscriptionVariables } from './__generated__/Chat'
import { OnMessageSentDocument } from './__generated__/Chat'
import { useShared_Chat_MessagesQuery as useMessagesQuery } from './__generated__/useMessagesWithSubscribe'

export function useMessagesWithSubscribe(chatId: ChatId) {
  const messagesQuery = useMessagesQuery({
    variables: { chatId },
    fetchPolicy: 'cache-and-network',
    skip: !chatId,
  })
  const { subscribeToMore } = messagesQuery

  useEffect(() => {
    if (!chatId) {
      return undefined
    }

    return subscribeToMore<OnMessageSentSubscription, OnMessageSentSubscriptionVariables>({
      document: OnMessageSentDocument,
      variables: { chatId },
      updateQuery: (previousQueryResult, { subscriptionData }) => {
        const newMessage = subscriptionData.data.messageSent
        if (!newMessage) {
          return previousQueryResult
        }

        const exists = previousQueryResult.chat?.messages?.find((message) => message?.id === newMessage?.id)
        if (exists) {
          return previousQueryResult
        }

        return {
          chat: {
            id: chatId,
            ...previousQueryResult.chat,
            messages: [...(previousQueryResult.chat?.messages ?? []), newMessage],
          },
        }
      },
    })
  }, [subscribeToMore, chatId])

  return messagesQuery
}
