let selectedPortalType: PortalType = ''
let portalTitle = ''

export type PortalType = 'RMA' | 'VAR' | 'DIRECT' | 'FAE' | ''

export const setPortal = (portalType: PortalType, title: string) => {
  selectedPortalType = portalType
  portalTitle = title
}

export const getPortal = () => selectedPortalType

export const getPortalTitle = () => {
  return portalTitle
}

export default function isPortal(portalType: PortalType) {
  return selectedPortalType === portalType
}
