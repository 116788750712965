export const NotPartOfKitOption = 'not-part-of-kit'
export const WholeKitOption = 'whole-kit-option'

export interface KitEntry {
  sku: string
  mac: string
}

export function createKitEntry(sku: string, mac: string | null): KitEntry {
  return { sku: sku.toLocaleLowerCase(), mac: mac || '' }
}
