import { HeadingContainer, HeadingDescription, HeadingTitle, Link, Text } from '@shared-ui/components'

const STORE_LINK = 'https://www.ui.com/distributors/'
const LINK_HELP = 'https://help.ui.com/hc/en-us/requests/new?ticket_form_id=7929786354839'
const LINK_WARRANTY = 'https://www.ui.com/support/warranty/'

export function SearchHardwareHeading() {
  return (
    <HeadingContainer>
      <HeadingTitle data-testid="submit-rma-heading-title">
        Welcome to our Return Merchandise Authorization (RMA) Center!
      </HeadingTitle>

      <HeadingDescription>
        <Text size="body">
          RMA services are only provided for Ubiquiti products purchased through{' '}
          <Link href={STORE_LINK}>official channels</Link>. Visit our <Link href={LINK_WARRANTY}>Limited Warranty</Link>{' '}
          page for more details.
          <br />
          <br />
          This platform is solely intended to process warranty claims, such as requests to replace malfunctioning
          devices.{' '}
          <b>
            If you would like to be refunded for a product purchased in the Ubiquiti Store, click{' '}
            <Link href={LINK_HELP}>here</Link> to contact our store support team and a member will respond to you
            shortly.
          </b>
        </Text>
      </HeadingDescription>
    </HeadingContainer>
  )
}
