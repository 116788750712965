import { Link } from '@shared-ui/components'
import { useRequest } from '@shared-ui/hooks'
import type { ShippingAddress } from '@shared/types/address'
import { Modal, Radio, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { SubmitRmaService } from '../submit-rma-service'
import { useSubmitRmaStore } from '../submit-rma-store'

export function SelectableShippingAddressPanel({
  shippingAddress,
  disabled = false,
  selected = false,
  onSelect = () => {
    // noop
  },
}: {
  disabled?: boolean
  shippingAddress: ShippingAddress
  selected?: boolean
  onSelect?: () => void
}) {
  const { removeAddress } = useSubmitRmaStore()

  const [showModal, setShowModal] = useState(false)
  const [deleteAddress] = useRequest('deleteAddress')

  const handleClick = () => {
    if (disabled) {
      return
    }

    if (onSelect) {
      onSelect()
    }
  }

  const handleRemove = async () => {
    try {
      if (shippingAddress.id > 0) {
        await deleteAddress({
          addressId: shippingAddress.id,
        })
      }

      removeAddress(shippingAddress.id)
    } catch (err) {
      //
    }
  }

  return (
    <AddressContainer $selectable onClick={handleClick} className={selected ? 'selected' : ''}>
      <ShippingAddressContent shippingAddress={shippingAddress} disabled={disabled}>
        <div className="flex mt-8 line-height">
          <Link
            className="mr-16 color-blue"
            onClick={() => {
              useSubmitRmaStore.setState({
                shippingAddressEdit: shippingAddress,
              })

              SubmitRmaService.setView('editShippingAddress')
            }}
          >
            Edit
          </Link>

          <Link
            className="color-danger"
            onClick={() => {
              setShowModal(true)
            }}
          >
            Remove
          </Link>
        </div>
      </ShippingAddressContent>

      <Radio
        readOnly
        onChange={() => {
          // noop
        }}
        id={String(shippingAddress.id)}
        checked={selected}
        disabled={disabled}
      />

      {showModal && (
        <Modal
          size="small"
          isOpen={showModal}
          title="Address Removal"
          actions={[
            {
              onClick: () => {
                setShowModal(false)
              },
              text: 'Cancel',
            },
            {
              onClick: handleRemove,
              text: 'Yes',
              variant: 'primary',
            },
          ]}
        >
          Are you sure that you want to delete this address?
        </Modal>
      )}
    </AddressContainer>
  )
}

export function ShippingAddressPanel({ shippingAddress }: { shippingAddress: ShippingAddress }) {
  return (
    <AddressContainer>
      <ShippingAddressContent shippingAddress={shippingAddress} disabled={false} />
    </AddressContainer>
  )
}

function ShippingAddressContent({
  shippingAddress,
  disabled,
  children,
}: {
  shippingAddress: ShippingAddress
  disabled: boolean
  children?: React.ReactNode
}) {
  const name = shippingAddress.companyName
    ? shippingAddress.companyName
    : `${shippingAddress.firstName} ${shippingAddress.lastName}`

  const addressLocation = [shippingAddress.country, shippingAddress.state, shippingAddress.city]
    .filter(Boolean)
    .join(', ')

  return (
    <div className="flex column flex-1 mr-12 overflow-hidden">
      <div className="flex column">
        <Text size="body" title={name} truncate className="line-height" style={disabled ? { color: '#A2A4B1' } : {}}>
          {name}
        </Text>
        <Text
          size="body"
          color="tertiary"
          title={shippingAddress.address1}
          truncate
          className="line-height"
          style={disabled ? { color: '#A2A4B1' } : {}}
        >
          {shippingAddress.address1}, {addressLocation}, {shippingAddress.zipcode}
        </Text>
      </div>

      {children}
    </div>
  )
}

interface AddressContainerProps {
  $selectable?: boolean
}

export const AddressContainer = styled.div<AddressContainerProps>`
  display: flex;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #d6d9e2;
  box-sizing: border-box;

  &.selected {
    border-color: #006fff;
  }

  ${(props) =>
    props.$selectable &&
    css`
      & {
        cursor: pointer;
      }

      &:hover {
        border-color: #006fff;
      }
    `}
`
