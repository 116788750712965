import { Button } from './Button'
import { Grid } from './Grid'
import { Text } from './Text'

export type RetryProps = {
  text: string
  retry: () => void
}

export const Retry = ({ text, retry }: RetryProps) => (
  <Grid $container direction="column" gap="xs">
    <Grid $item $xs={12}>
      <Text $center color="danger" size="body">
        {text}
      </Text>
    </Grid>
    <Grid $item $xs={12}>
      <Button variant="link" onClick={retry}>
        Retry
      </Button>
    </Grid>
  </Grid>
)
