import { forwardRef, Fragment } from 'react'

import type { TextProps } from './Text'
import { Text } from './Text'

type Children = (string | null | undefined)[] | string
export type ParagraphTextProps = Omit<TextProps, 'children'> & { children: Children }

export const ParagraphText = forwardRef<HTMLSpanElement, ParagraphTextProps>(({ children, ...props }, ref) => {
  const content = children as Children

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/jsx-props-no-spreading, @typescript-eslint/no-unsafe-assignment
    <Text {...props} forwardedRef={ref as any}>
      {(Array.isArray(content) ? content : content.replace(/\n{2,}/g, '\n\n').split('\n\n'))
        .filter((val): val is string => !!val)
        .map((section, sectionIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={sectionIndex}>
            {section.split('\n').map((paragraph, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {paragraph}
                <br />
              </Fragment>
            ))}
            <br />
          </Fragment>
        ))}
    </Text>
  )
})
