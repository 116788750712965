import { Rating } from '@shared-ui/components/Rating'
import { RequestError } from '@shared-ui/components/RequestError'
import { Text } from '@shared-ui/components/Text'
import { TextArea } from '@shared-ui/components/text-area'
import constraintsFromSchema from '@shared-ui/utils/form/constraintsFromSchema'
import type { RateExperienceInput } from '@shared/lib/validation/schemas'
import { RateExperienceInputSchema, STAR_COUNT } from '@shared/lib/validation/schemas'
import { TicketStatus } from '@shared/tickets'
import { Button, PanelCard, cssVariables } from '@ubnt/ui-components'
import { Formik, useFormikContext } from 'formik'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import type { RateTicketFragment } from './__generated__/Rate'
import { useRateExperienceMutation } from './__generated__/Rate'

interface Props {
  ticket: RateTicketFragment
}

type FormType = RateExperienceInput

const constraints = constraintsFromSchema(RateExperienceInputSchema)

export function Rate({ ticket }: Props) {
  const location = useLocation<{ rating?: number | undefined }>()
  const dashboardRating = location.state?.rating
  const [initialRating, setInitialRating] = useState(dashboardRating || ticket.rating?.rating || 0)
  const [rate, { loading, error }] = useRateExperienceMutation()
  const [isEdit, setEdit] = useState(!ticket.rating || !!dashboardRating)

  const handleCancel = () => {
    setEdit(false)
  }

  const handleSubmit = async (values: RateExperienceInput) => {
    try {
      const result = await rate({
        variables: { input: values },
      })

      setInitialRating(result.data?.rateExperience?.rating?.rating || 0)
      setEdit(false)
    } catch {
      //
    }
  }

  if (isEdit) {
    const initialValues: FormType = {
      ticketId: ticket.id,
      rating: initialRating,
      feedback: ticket.rating?.feedback || '',
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={RateExperienceInputSchema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        <RateForm ticket={ticket} loading={loading} handleCancel={handleCancel} />
      </Formik>
    )
  }

  return (
    <>
      <div className="mr-16">
        <PanelCard header="Rate your experience" defaultIsOpen>
          <Description>Your overall RMA experience with &quot;{ticket.supportByName}&quot;.</Description>
          <RatingStyled
            size="large"
            count={STAR_COUNT}
            value={ticket.rating?.rating}
            onChange={(rating: number) => {
              setInitialRating(rating)
              setEdit(true)
            }}
          />
        </PanelCard>

        <PanelCard header="Feedback (optional)" defaultIsOpen style={{ marginTop: cssVariables['spacing-l'] }}>
          <div className="flex column">
            <Text size="body" color="secondary">
              Your feedback:
            </Text>
            <Text size="body" color="primary">
              {ticket.rating?.feedback || ''}
            </Text>
          </div>

          <div className="flex justify-end mt-20 gap-8">
            <Button variant="primary" onClick={() => setEdit(true)}>
              Edit
            </Button>
          </div>
        </PanelCard>
      </div>

      {error && <RequestError error={error} />}
    </>
  )
}

const RateForm = ({ ticket, loading, handleCancel }: Props & { loading: boolean; handleCancel: () => void }) => {
  const { values, isValid, setFieldValue, submitForm } = useFormikContext<FormType>()

  const isDisabled = ticket.status === TicketStatus.Closed || loading

  return (
    <div className="mr-16">
      <PanelCard header="Rate your experience" defaultIsOpen>
        <Description>Please rate your overall RMA experience with &quot;{ticket.supportByName}&quot;.</Description>
        <RatingStyled
          size="large"
          count={STAR_COUNT}
          value={values.rating}
          onChange={(rating: number) => {
            if (rating === values.rating) return

            setFieldValue('rating', rating)
          }}
        />
      </PanelCard>
      <PanelCard header="Feedback (optional)" defaultIsOpen style={{ marginTop: cssVariables['spacing-l'] }}>
        <Description>Add any additional feedback about your RMA experience.</Description>
        <TextArea
          maxLength={constraints.feedback.max?.param}
          value={values.feedback}
          onChange={(e) => setFieldValue('feedback', e.currentTarget.value)}
        />

        <div className="flex justify-end mt-20 gap-8">
          <Button variant="tertiary" disabled={loading} onClick={handleCancel}>
            Cancel
          </Button>

          <Button variant="primary" disabled={!isValid || isDisabled} type="submit" onClick={submitForm}>
            Apply
          </Button>
        </div>
      </PanelCard>
    </div>
  )
}

const RatingStyled = styled(Rating).attrs<{ strokeWidth?: string }>({ strokeWidth: '0.5' })`
  > div > div {
    justify-content: flex-start;
    > svg {
      margin-right: ${(props) => props.theme.spacing.xl};
    }
  }
`

const Description = styled(Text).attrs({ block: true, size: 'body', marginBottom: 'm' })``
