// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type NotificationDataFragment = { readonly __typename?: 'Notification' } & Pick<
  Types.Notification,
  'id' | 'createdAt' | 'seenAt' | 'ticketId' | 'data'
>

export type NotificationsFetchQueryVariables = Types.Exact<{ [key: string]: never }>

export type NotificationsFetchQuery = { readonly __typename?: 'Query' } & {
  readonly notificationsFetch: ReadonlyArray<{ readonly __typename?: 'Notification' } & NotificationDataFragment>
}

export type NotificationsMarkAsReadMutationVariables = Types.Exact<{
  ids: ReadonlyArray<Types.Scalars['NotificationId']> | Types.Scalars['NotificationId']
}>

export type NotificationsMarkAsReadMutation = { readonly __typename?: 'Mutation' } & {
  readonly notificationsMarkAsRead: ReadonlyArray<{ readonly __typename?: 'Notification' } & NotificationDataFragment>
}

export type NotificationsRemoveMutationVariables = Types.Exact<{
  id: Types.Scalars['NotificationId']
}>

export type NotificationsRemoveMutation = { readonly __typename?: 'Mutation' } & {
  readonly notificationsRemove: Types.Maybe<{ readonly __typename?: 'Notification' } & NotificationDataFragment>
}

export type NotificationsMarkAsUnreadMutationVariables = Types.Exact<{
  id: Types.Scalars['NotificationId']
}>

export type NotificationsMarkAsUnreadMutation = { readonly __typename?: 'Mutation' } & {
  readonly notificationsMarkAsUnread: Types.Maybe<{ readonly __typename?: 'Notification' } & NotificationDataFragment>
}

export type NotificationAddedSubscriptionVariables = Types.Exact<{ [key: string]: never }>

export type NotificationAddedSubscription = { readonly __typename?: 'Subscription' } & {
  readonly notificationAdded: { readonly __typename?: 'Notification' } & NotificationDataFragment
}

export const NotificationDataFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
}
export const NotificationsFetchDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NotificationsFetch' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationsFetch' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
}

/**
 * __useNotificationsFetchQuery__
 *
 * To run a query within a React component, call `useNotificationsFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsFetchQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsFetchQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsFetchQuery, NotificationsFetchQueryVariables>,
) {
  return Apollo.useQuery<NotificationsFetchQuery, NotificationsFetchQueryVariables>(
    NotificationsFetchDocument,
    baseOptions,
  )
}
export function useNotificationsFetchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsFetchQuery, NotificationsFetchQueryVariables>,
) {
  return Apollo.useLazyQuery<NotificationsFetchQuery, NotificationsFetchQueryVariables>(
    NotificationsFetchDocument,
    baseOptions,
  )
}
export type NotificationsFetchQueryHookResult = ReturnType<typeof useNotificationsFetchQuery>
export type NotificationsFetchLazyQueryHookResult = ReturnType<typeof useNotificationsFetchLazyQuery>
export type NotificationsFetchQueryResult = Apollo.QueryResult<
  NotificationsFetchQuery,
  NotificationsFetchQueryVariables
>
export const NotificationsMarkAsReadDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NotificationsMarkAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationId' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationsMarkAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
}
export type NotificationsMarkAsReadMutationFn = Apollo.MutationFunction<
  NotificationsMarkAsReadMutation,
  NotificationsMarkAsReadMutationVariables
>

/**
 * __useNotificationsMarkAsReadMutation__
 *
 * To run a mutation, you first call `useNotificationsMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsMarkAsReadMutation, { data, loading, error }] = useNotificationsMarkAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useNotificationsMarkAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<NotificationsMarkAsReadMutation, NotificationsMarkAsReadMutationVariables>,
) {
  return Apollo.useMutation<NotificationsMarkAsReadMutation, NotificationsMarkAsReadMutationVariables>(
    NotificationsMarkAsReadDocument,
    baseOptions,
  )
}
export type NotificationsMarkAsReadMutationHookResult = ReturnType<typeof useNotificationsMarkAsReadMutation>
export type NotificationsMarkAsReadMutationResult = Apollo.MutationResult<NotificationsMarkAsReadMutation>
export type NotificationsMarkAsReadMutationOptions = Apollo.BaseMutationOptions<
  NotificationsMarkAsReadMutation,
  NotificationsMarkAsReadMutationVariables
>
export const NotificationsRemoveDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NotificationsRemove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationsRemove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
}
export type NotificationsRemoveMutationFn = Apollo.MutationFunction<
  NotificationsRemoveMutation,
  NotificationsRemoveMutationVariables
>

/**
 * __useNotificationsRemoveMutation__
 *
 * To run a mutation, you first call `useNotificationsRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsRemoveMutation, { data, loading, error }] = useNotificationsRemoveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationsRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<NotificationsRemoveMutation, NotificationsRemoveMutationVariables>,
) {
  return Apollo.useMutation<NotificationsRemoveMutation, NotificationsRemoveMutationVariables>(
    NotificationsRemoveDocument,
    baseOptions,
  )
}
export type NotificationsRemoveMutationHookResult = ReturnType<typeof useNotificationsRemoveMutation>
export type NotificationsRemoveMutationResult = Apollo.MutationResult<NotificationsRemoveMutation>
export type NotificationsRemoveMutationOptions = Apollo.BaseMutationOptions<
  NotificationsRemoveMutation,
  NotificationsRemoveMutationVariables
>
export const NotificationsMarkAsUnreadDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'NotificationsMarkAsUnread' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'NotificationId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationsMarkAsUnread' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
}
export type NotificationsMarkAsUnreadMutationFn = Apollo.MutationFunction<
  NotificationsMarkAsUnreadMutation,
  NotificationsMarkAsUnreadMutationVariables
>

/**
 * __useNotificationsMarkAsUnreadMutation__
 *
 * To run a mutation, you first call `useNotificationsMarkAsUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsMarkAsUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsMarkAsUnreadMutation, { data, loading, error }] = useNotificationsMarkAsUnreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationsMarkAsUnreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationsMarkAsUnreadMutation,
    NotificationsMarkAsUnreadMutationVariables
  >,
) {
  return Apollo.useMutation<NotificationsMarkAsUnreadMutation, NotificationsMarkAsUnreadMutationVariables>(
    NotificationsMarkAsUnreadDocument,
    baseOptions,
  )
}
export type NotificationsMarkAsUnreadMutationHookResult = ReturnType<typeof useNotificationsMarkAsUnreadMutation>
export type NotificationsMarkAsUnreadMutationResult = Apollo.MutationResult<NotificationsMarkAsUnreadMutation>
export type NotificationsMarkAsUnreadMutationOptions = Apollo.BaseMutationOptions<
  NotificationsMarkAsUnreadMutation,
  NotificationsMarkAsUnreadMutationVariables
>
export const NotificationAddedDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'NotificationAdded' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationAdded' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NotificationData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NotificationData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Notification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seenAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ticketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
}

/**
 * __useNotificationAddedSubscription__
 *
 * To run a query within a React component, call `useNotificationAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<NotificationAddedSubscription, NotificationAddedSubscriptionVariables>,
) {
  return Apollo.useSubscription<NotificationAddedSubscription, NotificationAddedSubscriptionVariables>(
    NotificationAddedDocument,
    baseOptions,
  )
}
export type NotificationAddedSubscriptionHookResult = ReturnType<typeof useNotificationAddedSubscription>
export type NotificationAddedSubscriptionResult = Apollo.SubscriptionResult<NotificationAddedSubscription>
