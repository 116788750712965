import { isValidMac, parseMacOrSerialInput } from '../../validation/mac-serial'

const formatSerial = (serial: string | null) => {
  if (!serial) {
    return ''
  }

  return isValidMac(serial)
    ? parseMacOrSerialInput(serial)
        .toUpperCase()
        .match(/.{1,2}/g)
        ?.join(':') ?? serial
    : serial
}

export default formatSerial
