import { useCallback, useState, useEffect, useReducer } from 'react'
import { loadScript } from '../../utils/loadScript'

// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
const { SendSafelyDropzone } = require('./source') as { SendSafelyDropzone: SendSafelyDropzoneType }

const SEND_SAFELY_HOST = 'https://www.sendsafely.com'
const SEND_SAFELY_DZ_ID = 'nWQ7ax5UceOGxoivGouPhhOnkDjyMulwX8lsI4C_K8s'
const JQUERY_DEPENDENCY_ID = 'jquery-dependency'

type JQuery = any

type SendSafelyDropzoneType = {
  new (apiKey: string, domElement: JQuery): Widget
}

type Widget = {
  url: string
  initialize: () => void
  finalizePackage: (cb: (attachmentsURL: string) => void) => void
  disableAutoSubmit: boolean
  setUnconfirmedSender: (email: string | undefined) => void
  nbrOfFilesAttached: number
  addFrameListener: (event: string, cb: (data: any) => void) => void
  iframe: HTMLIFrameElement
  createStaticURL: (url: string) => string
}

const initialAttachmentsState = { files: [], preparingToAdd: false, error: '' }

type AttachmentsState = {
  files: { name: string; fileId: string; packageCode: string; progress: number; error?: string }[]
  preparingToAdd: boolean
  error: string
}
function attachmentsReducer(
  state: AttachmentsState,
  action:
    | { type: 'add'; name: string; fileId: string; packageCode: string }
    | { type: 'remove'; fileId: string }
    | { type: 'preparing-to-add' }
    | { type: 'file-progress'; fileId: string; progress: number }
    | { type: 'file-uploaded'; fileId: string }
    | { type: 'file-error'; fileId: string; error: string }
    | { type: 'error'; error: string }
    | { type: 'reset' },
): AttachmentsState {
  const updateFile = (
    files: AttachmentsState['files'],
    fileId: string,
    data: Partial<AttachmentsState['files'][number]>,
  ) => files.map((file) => (file.fileId === fileId ? { ...file, ...data } : file))

  switch (action.type) {
    case 'preparing-to-add':
      return {
        ...state,
        preparingToAdd: true,
      }
    case 'add':
      return {
        ...state,
        preparingToAdd: false,
        files: [
          ...state.files,
          { name: action.name, fileId: action.fileId, packageCode: action.packageCode, progress: 0 },
        ],
      }
    case 'remove':
      return {
        ...state,
        preparingToAdd: false,
        files: state.files.filter((file) => file.fileId !== action.fileId),
      }
    case 'file-progress':
      return {
        ...state,
        files: updateFile(state.files, action.fileId, { progress: action.progress }),
      }
    case 'file-uploaded':
      return {
        ...state,
        files: updateFile(state.files, action.fileId, { progress: 100 }),
      }
    case 'file-error':
      return {
        ...state,
        files: updateFile(state.files, action.fileId, { error: action.error }),
      }
    case 'error':
      return {
        ...state,
        error: action.error,
      }
    case 'reset':
      return initialAttachmentsState
    default:
      return state
  }
}

export const useSendSafelyDropZone = ({
  attachEl,
  userEmail,
}: {
  attachEl: React.RefObject<HTMLDivElement | null>
  userEmail: string
}) => {
  const [widget, setWidget] = useState<Widget>()
  const [attachments, dispatch] = useReducer(attachmentsReducer, initialAttachmentsState)
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)

  const initWidget = useCallback(() => {
    if (!widget && attachEl.current) {
      try {
        const jQuery: JQuery = window['jQuery' as any] as any
        const newWidget = new SendSafelyDropzone(SEND_SAFELY_DZ_ID, jQuery(attachEl.current))
        newWidget.url = SEND_SAFELY_HOST
        newWidget.disableAutoSubmit = true
        newWidget.initialize()

        newWidget.addFrameListener(
          'file-attached-placeholder',
          (data: { name: string; fileId: string; packageCode: string }) => {
            dispatch({ type: 'preparing-to-add' })
          },
        )
        newWidget.addFrameListener('file-attached', (data: { name: string; fileId: string; packageCode: string }) => {
          dispatch({ type: 'add', ...data })
        })
        newWidget.addFrameListener('file-progress', (data: { fileId: string; progress: number }) => {
          dispatch({ type: 'file-progress', fileId: data.fileId, progress: data.progress })
        })
        newWidget.addFrameListener('file-uploaded', (data: { fileId: string }) => {
          dispatch({ type: 'file-uploaded', fileId: data.fileId })
        })
        newWidget.addFrameListener('file-removed', (data: { fileId: string }) => {
          dispatch({ type: 'remove', fileId: data.fileId })
        })
        newWidget.addFrameListener('file-remove-error', (data: { fileId: string }) => {
          dispatch({ type: 'file-error', fileId: data.fileId, error: 'Could not remove file' })
        })
        newWidget.addFrameListener('error', (data: { message: string }) => {
          dispatch({ type: 'error', error: data.message })
        })
        setWidget(newWidget)
      } catch (e) {
        console.error(e)
      }
    }
  }, [widget, dispatch])

  useEffect(() => {
    const isUploading = attachments.preparingToAdd || !attachments.files.every(({ progress }) => progress === 100)

    setIsUploadingFiles(isUploading)
  }, [attachments, isUploadingFiles])

  const initialize = useCallback(async () => {
    if (document.getElementById(JQUERY_DEPENDENCY_ID)?.getAttribute('loaded') === 'true') {
      initWidget()

      return
    }

    await loadScript({
      src: '//code.jquery.com/jquery-3.6.3.min.js',
      id: JQUERY_DEPENDENCY_ID,
    })

    initWidget()
  }, [initWidget])

  const destroy = useCallback(() => {
    setWidget(undefined)

    if (attachEl.current) {
      // eslint-disable-next-line no-param-reassign
      attachEl.current.innerHTML = ''
    }

    dispatch({ type: 'reset' })
  }, [attachEl])

  const finalize = () =>
    new Promise<string | null>((resolve, reject) => {
      try {
        if (widget && widget.nbrOfFilesAttached) {
          widget.setUnconfirmedSender(userEmail)
          widget.finalizePackage((url) => {
            resolve(url)
          })
        } else {
          resolve(null)
        }
      } catch (e) {
        reject(new Error('Problem uploading file.'))
      }
    })

  return {
    initialize,
    removeFile: (fileId: string) => {
      if (widget) {
        const win = widget.iframe.contentWindow
        win?.postMessage({ command: 'remove-file', fileId }, widget.createStaticURL(widget.url))
      }
    },
    destroy,
    finalize,
    attachments,
    isUploadingFiles,
  }
}
