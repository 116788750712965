import { ManageIconL } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'
import { Vertical } from '../../components'

export function PageServiceUnavailable() {
  return (
    <Vertical $centerH $centerV $flex>
      <Icon />
      <Text size="header-s" weight="bold" color="primary" style={{ marginBottom: '10px', lineHeight: '24px' }}>
        Service is not available at the moment
      </Text>
      <Text size="header-xs" color="primary">
        RMA Portal is undergoing maintenance.
      </Text>
      <Text size="header-xs" color="primary">
        We will be back up again shortly.
      </Text>
    </Vertical>
  )
}

const Icon = styled(ManageIconL)`
  margin-bottom: 34px;

  width: 54px;
  height: 54px;
  color: #777;
`
