import type { NetsuiteFailureCategoryId } from '@shared/types/brands'
import type { Key } from 'react'
import { FailureCategories } from 'rma-shared/types/tickets'
import { Dropdown } from './SprintShared'
import { CustomLabel } from './custom-label'

interface FailureCategoryProps {
  sku?: string | null
  failureCategoryId?: NetsuiteFailureCategoryId | null
  disabled?: boolean
  onChange: (
    option: {
      label: string
      value: unknown
    },
    id: Key,
  ) => void
  label?: string
  customLabel?: string
  required?: boolean
}

export function FailureCategory({
  sku,
  failureCategoryId,
  disabled,
  onChange,
  label,
  customLabel,
  required,
}: FailureCategoryProps) {
  const isSpecial = sku && (sku.indexOf('UAP') === 0 || sku.indexOf('U6') === 0)
  const categories = FailureCategories.filter((entry) => (entry.special ? isSpecial : true))

  return (
    <div className="flex column">
      {customLabel && <CustomLabel label={customLabel} extra={required ? '(required)' : ''} />}

      <Dropdown
        variant="secondary"
        label={label}
        width="100%"
        options={categories.map((category) => {
          return {
            label: category.name,
            value: category.id,
          }
        })}
        value={failureCategoryId || ''}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}
