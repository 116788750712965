// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type FetchMyCompanyQueryVariables = Types.Exact<{ [key: string]: never }>

export type FetchMyCompanyQuery = { readonly __typename?: 'Query' } & {
  readonly myCompany: Types.Maybe<{ readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'entityId' | 'name'>>
  readonly myCompanies: ReadonlyArray<
    { readonly __typename?: 'Company' } & Pick<Types.Company, 'id' | 'name' | 'entityId' | 'entityType'>
  >
}

export const FetchMyCompanyDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchMyCompany' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myCompanies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useFetchMyCompanyQuery__
 *
 * To run a query within a React component, call `useFetchMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMyCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchMyCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchMyCompanyQuery, FetchMyCompanyQueryVariables>,
) {
  return Apollo.useQuery<FetchMyCompanyQuery, FetchMyCompanyQueryVariables>(FetchMyCompanyDocument, baseOptions)
}
export function useFetchMyCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchMyCompanyQuery, FetchMyCompanyQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchMyCompanyQuery, FetchMyCompanyQueryVariables>(FetchMyCompanyDocument, baseOptions)
}
export type FetchMyCompanyQueryHookResult = ReturnType<typeof useFetchMyCompanyQuery>
export type FetchMyCompanyLazyQueryHookResult = ReturnType<typeof useFetchMyCompanyLazyQuery>
export type FetchMyCompanyQueryResult = Apollo.QueryResult<FetchMyCompanyQuery, FetchMyCompanyQueryVariables>
