export type ShippingCompany = {
  id: string
  name: string
}

export type ShippingCompanyType = 'FedEx' | 'UPS'

export const ShippingCompanies: readonly string[] = ['FedEx', 'UPS', 'DHL', 'DPD', 'TNT Express', 'Other']

export const getShippingCompanies = (): readonly ShippingCompany[] =>
  ShippingCompanies.map((entry) => {
    return {
      id: entry === 'Other' ? '__other' : entry,
      name: entry,
    }
  })

export const getShippingCompany = (id: string) => getShippingCompanies().find((company) => company.id === id)
