import type { FieldPolicy, Reference } from '@apollo/client/cache'
import type { FieldFunctionOptions, KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

declare type KeySpecifier = string[]
type KeyArgs = KeySpecifier | KeyArgsFunction | false
type Options = {
  keyArgs?: KeyArgs
  shouldReset?: <T, TArgs = Record<string, unknown>, TVars = Record<string, unknown>>(
    existing: T | undefined,
    incoming: T,
    options: FieldFunctionOptions<TArgs, TVars>,
  ) => boolean
}

export const dedupedConcatPagination = <T = Reference>({ keyArgs, shouldReset }: Options = {}): FieldPolicy<T[]> => ({
  keyArgs,
  merge: (existing, incoming, options) => {
    if (shouldReset && shouldReset(existing, incoming, options)) {
      return incoming
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const newRefs = incoming.map((entry) => JSON.stringify(options.toReference(entry as any)))

    return [
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...(existing || []).filter((entry) => !newRefs.includes(JSON.stringify(options.toReference(entry as any)))),
      ...incoming,
    ]
  },
  read: undefined,
})
