// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../../../generated/graphql'

export type ConfirmInformation_CreateTicketMutationVariables = Types.Exact<{
  input: Types.CreateTicketInput
}>

export type ConfirmInformation_CreateTicketMutation = { readonly __typename?: 'Mutation' } & {
  readonly createTicket: { readonly __typename?: 'CreateTicketResponse' } & {
    readonly ticket: Types.Maybe<{ readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'>>
  }
}

export const ConfirmInformation_CreateTicketDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmInformation_CreateTicket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateTicketInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTicket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ticket' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type ConfirmInformation_CreateTicketMutationFn = Apollo.MutationFunction<
  ConfirmInformation_CreateTicketMutation,
  ConfirmInformation_CreateTicketMutationVariables
>

/**
 * __useConfirmInformation_CreateTicketMutation__
 *
 * To run a mutation, you first call `useConfirmInformation_CreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmInformation_CreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmInformationCreateTicketMutation, { data, loading, error }] = useConfirmInformation_CreateTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmInformation_CreateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmInformation_CreateTicketMutation,
    ConfirmInformation_CreateTicketMutationVariables
  >,
) {
  return Apollo.useMutation<ConfirmInformation_CreateTicketMutation, ConfirmInformation_CreateTicketMutationVariables>(
    ConfirmInformation_CreateTicketDocument,
    baseOptions,
  )
}
export type ConfirmInformation_CreateTicketMutationHookResult = ReturnType<
  typeof useConfirmInformation_CreateTicketMutation
>
export type ConfirmInformation_CreateTicketMutationResult = Apollo.MutationResult<ConfirmInformation_CreateTicketMutation>
export type ConfirmInformation_CreateTicketMutationOptions = Apollo.BaseMutationOptions<
  ConfirmInformation_CreateTicketMutation,
  ConfirmInformation_CreateTicketMutationVariables
>
