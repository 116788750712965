import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

const useNavigate = () => {
  const history = useHistory()

  return useCallback(
    (path: string, state?: unknown, replace = false) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      replace ? history.replace(path, state as any) : history.push(path, state as any),
    [history],
  )
}

export default useNavigate
