import type { ComponentProps, FC, MouseEvent, ReactNode } from 'react'
import { useState } from 'react'
import styled from 'styled-components'

import { StarIcon } from './icons/StarIcon'

const HeightMap = {
  small: '12px',
  medium: '16px',
  large: '24px',
}

const StyledStarIcon = styled(StarIcon)<{ actionable?: boolean }>`
  ${(props) =>
    props.actionable &&
    `
    && {
      &:hover {
        color: ${props.theme.colors.primary};
      }
    }`}
`

interface RatingsProps {
  className?: string
  readonly?: boolean
  width?: string
  height?: string
  size?: 'small' | 'medium' | 'large'
  placeholder?: ReactNode
  Icon?: FC<ComponentProps<typeof StarIcon>>
  count?: number
  value?: number
  onHover?: (isHovered: boolean) => void
  onRatingHover?: (value: number) => void
  onChange?: (value: number) => void
}

export const Rating = ({
  className,
  readonly = false,
  width = '98px',
  height = '100%',
  size = 'medium',
  placeholder,
  Icon = StyledStarIcon,
  count = 5,
  value = 0,
  onHover = () => null,
  onRatingHover = () => null,
  onChange = () => null,
}: RatingsProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredRating, setHoveredRating] = useState(0)
  const [rating, setRating] = useState(value)

  const onMouseEnter = () => {
    if (!readonly) {
      setIsHovered(true)
      onHover(true)
    }
  }

  const onMouseLeave = () => {
    if (!readonly) {
      setIsHovered(false)
      onHover(false)
    }
  }

  const onRatingMouseLeave = () => {
    if (!readonly) {
      setHoveredRating(0)
      onRatingHover(0)
    }
  }

  const onRatingClick = (e: MouseEvent<SVGElement>) => {
    if (!readonly) {
      e.stopPropagation()

      const newValue = Number(e.currentTarget.dataset.value || '0')
      setRating(newValue)

      if (!readonly) {
        onChange(newValue)
      }
    }
  }

  const onRatingMouseEnter = (e: MouseEvent<SVGElement>) => {
    if (!readonly) {
      const newValue = Number(e.currentTarget.dataset.value || '0')

      setHoveredRating(newValue)
      onRatingHover(newValue)
    }
  }

  const isRatingVisible = !placeholder || isHovered

  return (
    <RatingStyled
      className={className}
      $height={(size && HeightMap[size]) || height}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {placeholder && <PlaceholderWrapper $visible={!isRatingVisible}>{placeholder}</PlaceholderWrapper>}
      <IconsWrapper $visible={isRatingVisible}>
        <IconsStyled $actionable={!readonly} onMouseLeave={onRatingMouseLeave}>
          {Array(count)
            .fill(0)
            .map((_, i) => {
              const isActive = isHovered && !readonly ? hoveredRating > i : i < rating

              return (
                <Icon
                  key={i}
                  actionable={!readonly}
                  size={size}
                  data-value={i + 1}
                  isActive={isActive}
                  onClick={onRatingClick}
                  onMouseEnter={onRatingMouseEnter}
                />
              )
            })}
        </IconsStyled>
      </IconsWrapper>
    </RatingStyled>
  )
}

const RatingStyled = styled.div<{ $height: string }>`
  position: relative;
  height: ${({ $height }) => $height};
  display: flex;
  align-items: center;
`

// Using opacity as ui-components <Table /> otherwise changes cell width (look into this).
// This probably unnecessarily triggers onHover (instead of just onRatingHover).
const PlaceholderWrapper = styled.div<{ $visible: boolean }>`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
`

const IconsWrapper = styled.div<{ $visible: boolean }>`
  position: absolute;
  height: 100%;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
`

const IconsStyled = styled.div<{ $actionable?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  cursor: ${({ $actionable }) => ($actionable ? 'pointer' : 'default')};
`
