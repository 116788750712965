import { withIcon } from './withIcon'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InboxIcon = withIcon(({ forwardedRef, isActive, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg viewBox="0 0 24 24" ref={forwardedRef} {...props} fill="none">
      <path
        d="M21.885 11.781L18.071 4.534C17.898 4.206 17.557 4 17.186 4H6.814C6.443 4 6.102 4.206 5.929 4.534L2.115 11.781C2.04 11.925 2 12.085 2 12.247V19C2 19.552 2.448 20 3 20H21C21.552 20 22 19.552 22 19V12.247C22 12.085 21.96 11.925 21.885 11.781ZM6.814 5H17.186L20.87 12H17.035C16.532 12 16.066 12.25 15.787 12.668L14.381 14.777C14.288 14.917 14.132 15 13.965 15H10.021C9.858 15 9.705 14.92 9.611 14.787L8.108 12.64C7.828 12.239 7.368 12 6.879 12H3.13L6.814 5ZM21 19H3V13H6.879C7.042 13 7.195 13.08 7.289 13.213L8.792 15.36C9.072 15.761 9.532 16 10.021 16H13.965C14.468 16 14.934 15.75 15.213 15.332L16.619 13.223C16.712 13.083 16.868 13 17.035 13H21V19Z"
        fill={isActive ? '#006FFF' : '#838691'}
      />
    </svg>
  )
})
