import type { CountryCallingCode, CountryCode} from 'libphonenumber-js/min';
import { getCountryCallingCode } from 'libphonenumber-js/min'

import { countries } from './countries'

export const countryCallingCodes = countries.reduce((codes, country) => {
  codes[country] = getCountryCallingCode(country)

  return codes
}, {} as Record<CountryCode, CountryCallingCode>)
