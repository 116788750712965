import { withIcon } from './withIcon'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UploadIcon = withIcon(({ forwardedRef, fill = '#565657', ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" ref={forwardedRef} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 15L11 14L14 14C14.5523 14 15 13.5523 15 13L15 2C15 1.44772 14.5523 1 14 1L2 0.999999C1.44772 0.999999 1 1.44771 1 2L1 13C1 13.5523 1.44772 14 2 14L5 14L5 15L2 15C0.895431 15 1.65704e-07 14.1046 2.62268e-07 13L1.22392e-06 2C1.32048e-06 0.895429 0.895432 -1.32048e-06 2 -1.22392e-06L14 -1.74846e-07C15.1046 -7.8281e-08 16 0.89543 16 2L16 13C16 14.1046 15.1046 15 14 15L11 15ZM7.50711 16L7.50711 5.53567L3.75736 9.28542L3.03418 8.56224L7.98393 3.61249L8.01709 3.64565L8.05025 3.61249L13 8.56224L12.2768 9.28542L8.50711 5.5157L8.50711 16L7.50711 16Z"
      fill={fill}
    />
  </svg>
))
