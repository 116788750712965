import { useAuthStore } from '@shared-ui/auth/auth-store'
import { Buttons, DropdownField2, Fieldset, Image, InputField, Text } from '@shared-ui/components'
import { phoneCodeList as phoneCodeListShared } from '@shared-ui/constants'
import { useRequest } from '@shared-ui/hooks'
import { getFlagPath } from '@shared-ui/utils/flags'
import { uCareSupportedCountries } from '@shared/lib'
import { canadaStates, countryList, haveStatesEnabled, usaStates } from '@shared/lib/constants'
import countriesNames from '@shared/lib/constants/countriesNames'
import type { ShippingAddressInput } from '@shared/lib/validation/schemas/ShippingAddressInputSchema'
import { ShippingAddressInputSchema } from '@shared/lib/validation/schemas/ShippingAddressInputSchema'
import type { ShippingAddress } from '@shared/types/address'
import type { DropdownOption } from '@ubnt/ui-components'
import { Button } from '@ubnt/ui-components'
import { config } from 'config'
import { Field, Formik, useFormikContext } from 'formik'
import type { CountryCode } from 'libphonenumber-js/min'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/min'
import type { ChangeEvent, MutableRefObject } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { SubmitContainer } from '../submit-container'

type AddAddressFormType = ShippingAddressInput & { phoneCode: string }

const phoneCodeList = phoneCodeListShared.map((item) => ({
  ...item,
  image: <Image width="100%" src={getFlagPath(item.value, config.publicUrl)} />,
}))

const INITIAL_VALUES: AddAddressFormType = {
  firstName: '',
  lastName: '',
  companyName: '',
  phoneCode: '',
  phoneNumber: '',
  email: '',
  country: '',
  address1: '',
  city: '',
  state: '',
  zipcode: '',
}

export const AddShippingAddress = () => {
  const { device, view, shippingAddressEdit, replaceAddress } = useSubmitRmaStore()

  const [requestEditAddress, { loading }] = useRequest('editAddress')

  const submitFormRef = useRef<() => Promise<void>>()

  const handleSubmit = async (values: AddAddressFormType) => {
    const shippingAddressNewId = shippingAddressEdit ? shippingAddressEdit.id : -1
    const shippingAddressNew: ShippingAddress = {
      ...values,
      id: shippingAddressNewId,
      state: values.state || '',
      phoneNumber: (parsePhoneNumberFromString(values.phoneNumber)?.number ?? '') as string,
    }

    if (view.curr === 'editShippingAddress') {
      if (shippingAddressNew.id !== -1) {
        const result = await requestEditAddress({ address: shippingAddressNew })
        replaceAddress(shippingAddressNewId, result.address)
      } else {
        replaceAddress(shippingAddressNewId, shippingAddressNew)
      }

      SubmitRmaService.backToFlow()
    } else {
      useSubmitRmaStore.setState({
        shippingAddress: shippingAddressNew,
        shippingAddressNew,
        shippingAddressId: -1,
        isInvalidShippingAddress: false,
      })

      if (SubmitRmaService.isExternalAddressValidation()) {
        SubmitRmaService.setView('verifyShippingAddress')
      } else {
        SubmitRmaService.next()
      }
    }
  }

  const isUnifiCare = !!device?.isUnifiCare

  return (
    <SubmitContainer>
      <div className="flex column relative p-16 mt-6 gap-24">
        <div>
          <Text size="header-xs" weight="bold" className="mb-4">
            Add a new shipping address
          </Text>
          <Text size="body" color="secondary">
            Tell us where we should send your replacement product.
          </Text>
        </div>

        <AddAddressForm
          setSubmitForm={(submitForm) => {
            submitFormRef.current = submitForm
          }}
          handleSubmit={handleSubmit}
          isUnifiCare={isUnifiCare}
        />

        <Footer submitFormRef={submitFormRef} loading={loading} />
      </div>
    </SubmitContainer>
  )
}

type AddAddressFormProps = {
  setSubmitForm: (submitForm: () => Promise<void>) => void
  handleSubmit: (values: AddAddressFormType) => void
  isUnifiCare: boolean
}

export const AddAddressForm = ({ setSubmitForm, handleSubmit, isUnifiCare }: AddAddressFormProps) => {
  const { user } = useAuthStore()
  const { shippingAddressEdit } = useSubmitRmaStore()

  const initialValues = {
    ...INITIAL_VALUES,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    ...shippingAddressEdit,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ShippingAddressInputSchema}
      onSubmit={handleSubmit}
      validateOnMount={!!shippingAddressEdit}
      initialTouched={
        shippingAddressEdit ? Object.fromEntries(Object.keys(initialValues).map((key) => [key, true])) : {}
      }
    >
      <Form setSubmitForm={setSubmitForm} isUnifiCare={isUnifiCare} />
    </Formik>
  )
}

const Form = ({ isUnifiCare, setSubmitForm }: Omit<AddAddressFormProps, 'handleSubmit'>) => {
  const { values, setFieldValue, submitForm } = useFormikContext<AddAddressFormType>()

  const asYouType = useMemo(() => new AsYouType(), [])
  const stateListUS = useMemo(() => Object.keys(usaStates).map((state) => ({ value: state, label: state })), [])
  const stateListCA = useMemo(() => Object.keys(canadaStates).map((state) => ({ value: state, label: state })), [])

  const availableCountryList = useMemo(() => {
    if (isUnifiCare) {
      return [...uCareSupportedCountries].map<DropdownOption>((entry) => {
        return {
          label: countriesNames[entry] || '',
          value: entry,
        }
      })
    }

    return countryList
  }, [isUnifiCare])

  useEffect(() => {
    setSubmitForm(submitForm)
  }, [submitForm, setSubmitForm])

  return (
    <form>
      <Fieldset>
        <Field label="First Name (Required)" name="firstName" component={InputField} />

        <Field label="Last Name (Required)" name="lastName" component={InputField} />

        <Field label="Company Name (Optional)" name="companyName" component={InputField} />

        <Field
          label="Phone Number (Required)"
          name="phoneNumber"
          type="phone"
          component={InputField}
          onChange={(e: ChangeEvent, input: string) => {
            const parsed = parsePhoneNumberFromString(input)
            if (parsed) {
              const phoneCode = `+${parsed.countryCallingCode as string}`
              const oldPhoneCode = parsePhoneNumberFromString(values.phoneNumber)?.countryCallingCode ?? ''
              // If matching phone area code, don't reset the dropdown to the first matching index (shared area codes).
              if (phoneCode !== oldPhoneCode) {
                const phoneCodeOption = phoneCodeList.find((item) => item.phoneCode === phoneCode)
                if (phoneCodeOption) {
                  setFieldValue('phoneCode', phoneCodeOption.value)
                }
              }
            }
            setFieldValue('phoneNumber', input)
          }}
          onFocus={() => {
            const phoneNumber = values.phoneNumber.replaceAll(' ', '')
            setFieldValue('phoneNumber', phoneNumber)
          }}
          onBlur={() => {
            asYouType.reset()
            const phoneNumber = asYouType.input(values.phoneNumber)
            setFieldValue('phoneNumber', phoneNumber)
          }}
        />

        <Field label="Shipping Address (Required)" name="address1" component={InputField} />

        <Field label="City (Required)" name="city" component={InputField} />

        {isUnifiCare && (
          <Text color="tertiary" style={{ marginBottom: '12px' }}>
            UI Care is only available in US, CA, EU and UK.
          </Text>
        )}

        <Field
          label="Country (Required)"
          name="country"
          width="100%"
          options={availableCountryList}
          component={DropdownField2}
          onChange={(option: DropdownOption) => {
            if (values.country !== option.value) {
              setFieldValue('state', '')
            }
            setFieldValue('country', option.value)
          }}
        />

        <Field
          label="State/Province (US and CA only)"
          name="state"
          width="100%"
          options={values.country === 'CA' ? stateListCA : stateListUS}
          component={DropdownField2}
          disabled={!haveStatesEnabled(values.country as CountryCode)}
        />

        <Field label="ZIP Code (Required)" name="zipcode" component={InputField} />
      </Fieldset>
    </form>
  )
}

const Footer = ({
  submitFormRef,
  loading,
}: {
  loading: boolean
  submitFormRef: MutableRefObject<(() => Promise<void>) | undefined>
}) => {
  const { view } = useSubmitRmaStore()

  const handleNext = () => {
    if (submitFormRef.current) {
      void submitFormRef.current()
    }
  }

  const submitButtonText = view.curr === 'editShippingAddress' ? 'Save' : 'Next'

  return (
    <Buttons>
      <Button variant="tertiary" onClick={SubmitRmaService.backToFlow}>
        Back
      </Button>

      <Button variant="primary" onClick={handleNext} loader={loading ? 'dots' : undefined}>
        {submitButtonText}
      </Button>
    </Buttons>
  )
}
