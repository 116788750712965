import axios from 'axios'
import type { NetsuiteCompanyId } from 'rma-shared/types/brands'
import type { UserPermission } from 'rma-shared/types/permissions'
import type { User } from 'rma-shared/types/users'
import type { SsoUserRole } from '../generated/graphql'
import { fetchRetry } from '../utils/fetchRetry'
import isPortal from '../utils/isPortal'
import { useAuthStore } from './auth-store'
import { history } from './history'

type LoginResponseData = {
  user: User
  accessToken: string
  refreshToken: string
}

type JwtAccessToken = {
  exp: number
  permissions: UserPermission[]
  isSuperUser: boolean
}

type TokensResponse = {
  accessToken: string
  refreshToken: string
  nsCompanyId: NetsuiteCompanyId
}

const baseUrl = process.env.REACT_APP_API_URL ?? ''
if (!baseUrl) {
  throw new Error('REACT_APP_API_URL env variable required')
}

const SSO_LOGOUT_URL = process.env.REACT_APP_ACCOUNT_URL
  ? `${process.env.REACT_APP_ACCOUNT_URL}/logout`
  : 'https://account.ui.com/logout'

const ssoRegisterUrl = process.env.REACT_APP_ACCOUNT_URL
  ? `${process.env.REACT_APP_ACCOUNT_URL}/register`
  : 'https://account.ui.com/register'

const USER_KEY = 'user'
const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const NS_COMPANY_ID_KEY = 'ns_company_id'
const AUTH_CODE_KEY = 'auth_code'
const AUTH_STATE_KEY = 'auth_state'
const REDIRECT_URL_KEY = 'redirect_url'
const ATTEMPT_COUNT_KEY = 'attempt_count'

const emptyCallback = () => {
  //
}

let isFetchingToken = false
let callbackOnConnected = emptyCallback
let requiredSsoRole: SsoUserRole | '' = ''

export const AuthService = {
  load: async (ssoRole?: SsoUserRole, autoLogin = true, onConnected?: () => void, nsCompanyId?: NetsuiteCompanyId) => {
    callbackOnConnected = onConnected || emptyCallback
    if (ssoRole) {
      requiredSsoRole = ssoRole
    }

    const userJson = localStorage.getItem(USER_KEY)
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || undefined
    const prevNsCompanyId = localStorage.getItem(NS_COMPANY_ID_KEY)

    const accessData = accessToken ? getAccessTokenData(accessToken) : null
    const permissions = accessData ? accessData.permissions : []
    const isSuperUser = accessData ? accessData.isSuperUser : false

    useAuthStore.setState({
      user: userJson ? JSON.parse(userJson) : undefined,
      accessToken,
      refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY) || undefined,
      nsCompanyId: prevNsCompanyId || nsCompanyId,
      permissions,
      isSuperUser,
    })

    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    if (code && state) {
      localStorage.setItem(AUTH_CODE_KEY, code)
      localStorage.setItem(AUTH_STATE_KEY, state)
      searchParams.delete('code')
      searchParams.delete('state')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)

      const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY)
      if (redirectUrl) {
        localStorage.removeItem(REDIRECT_URL_KEY)

        if (redirectUrl.indexOf('http') === 0) {
          window.location.href = redirectUrl
        } else {
          history.replace(redirectUrl)
        }
      }

      await AuthService.login()

      return
    }

    if (nsCompanyId && prevNsCompanyId && nsCompanyId !== prevNsCompanyId) {
      await AuthService.authorize()
      return
    }

    if (userJson) {
      await AuthService.login()
    } else if (autoLogin) {
      await AuthService.authorize()
    } else {
      useAuthStore.setState({
        status: 'connected',
      })
    }
  },

  authorize: async () => {
    clear()

    useAuthStore.setState({
      status: 'login',
    })

    try {
      const { redirectUrl } = await fetchRetry<{ redirectUrl: string }>(`${baseUrl}/login`, {
        retries: 3,
        method: 'GET',
      })

      storeRedirectUrl()

      localStorage.removeItem(ATTEMPT_COUNT_KEY)

      window.location.href = redirectUrl
    } catch (err) {
      const attemptCount = Number(localStorage.getItem(ATTEMPT_COUNT_KEY))
      if (attemptCount >= 2) {
        localStorage.removeItem(ATTEMPT_COUNT_KEY)

        useAuthStore.setState({
          status: 'unavailable',
        })
        return
      }

      localStorage.setItem(ATTEMPT_COUNT_KEY, `${attemptCount + 1}`)

      await AuthService.authorize()
    }
  },

  login: async () => {
    const code = localStorage.getItem(AUTH_CODE_KEY)
    const state = localStorage.getItem(AUTH_STATE_KEY)
    if (code && state) {
      localStorage.removeItem(AUTH_CODE_KEY)
      localStorage.removeItem(AUTH_STATE_KEY)

      await performLogin(code, state)
      return
    }

    if (AuthService.isAccessTokenExpired()) {
      await AuthService.authorize()
      return
    }

    const { user } = useAuthStore.getState()

    const haveRole = requiredSsoRole && user ? user.ssoRoles.includes(requiredSsoRole) : true

    useAuthStore.setState({ status: haveRole ? 'connected' : 'forbidden' })

    callbackOnConnected()
  },

  logout: async () => {
    const authorization = await AuthService.getValidAccessToken()

    try {
      await axios.post(`${baseUrl}/logout`, {}, { headers: { authorization } })
    } finally {
      clear()
    }

    window.location.assign(SSO_LOGOUT_URL)
  },

  changeCompany(selectedNsCompanyId: NetsuiteCompanyId) {
    return waitForNewTokens('/me/change-company', selectedNsCompanyId)
  },

  redirectToRegister() {
    window.location.href = ssoRegisterUrl

    return new Promise((resolve) => setTimeout(resolve, 1000))
  },

  getValidAccessToken: async () => {
    if (AuthService.isAccessTokenExpired()) {
      await waitForNewTokens('/refresh')
    }

    return useAuthStore.getState().accessToken || ''
  },

  isAccessTokenExpired() {
    const { accessToken } = useAuthStore.getState()

    if (!accessToken) {
      return true
    }

    const data = getAccessTokenData(accessToken)
    return new Date(data.exp * 1000) < new Date()
  },

  haveCompany() {
    const userJson = localStorage.getItem(USER_KEY)
    if (!userJson) {
      return false
    }

    try {
      const user = JSON.parse(userJson) as User
      return !!user.companyId
    } catch (err) {
      return false
    }
  },
}

const clear = () => {
  useAuthStore.getState().reset()

  localStorage.removeItem(USER_KEY)
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

const save = () => {
  const { user, accessToken, refreshToken, nsCompanyId } = useAuthStore.getState()

  if (user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user))
  }
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  }
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }
  if (nsCompanyId) {
    localStorage.setItem(NS_COMPANY_ID_KEY, nsCompanyId)
  }
}

const performLogin = async (code: string, state: string) => {
  useAuthStore.setState({
    status: 'login',
  })

  if (isFetchingToken) {
    await waitForTokens()
    return
  }

  const { nsCompanyId } = useAuthStore.getState()

  try {
    isFetchingToken = true

    const { data } = await axios.post<LoginResponseData>(`${baseUrl}/login`, {
      code,
      state,
      nsCompanyId,
    })

    const accessTokenData = getAccessTokenData(data.accessToken)

    useAuthStore.setState({
      user: data.user,
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      nsCompanyId: data.user.nsCompanyId,
      permissions: accessTokenData.permissions,
      isSuperUser: accessTokenData.isSuperUser,
    })

    save()
    callbackOnConnected()

    isFetchingToken = false

    const haveRole = requiredSsoRole ? data.user.ssoRoles.includes(requiredSsoRole) : true

    useAuthStore.setState({
      status: haveRole ? 'connected' : 'forbidden',
    })
  } catch (err) {
    if (axios.isAxiosError(err) && err.response && err.response.status === 403) {
      useAuthStore.setState({
        status: 'forbidden',
      })
      storeRedirectUrl()
      return
    }

    await AuthService.authorize()
  }
}

const getAccessTokenData = (accessToken: string): JwtAccessToken => {
  return JSON.parse(atob(accessToken.split('.')[1]))
}

const waitForNewTokens = async (url: string, selectedNsCompanyId?: NetsuiteCompanyId) => {
  if (isFetchingToken) {
    await waitForTokens()
    return
  }

  const { refreshToken, nsCompanyId } = useAuthStore.getState()

  if (!refreshToken) {
    await AuthService.authorize()
    return
  }

  try {
    isFetchingToken = true

    useAuthStore.setState({
      nsCompanyId: selectedNsCompanyId,
    })

    save()

    const { data } = await axios.post<TokensResponse>(`${baseUrl}${url}`, {
      refreshToken,
      nsCompanyId: selectedNsCompanyId || nsCompanyId,
    })

    useAuthStore.setState({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      nsCompanyId: data.nsCompanyId,
    })

    save()

    isFetchingToken = false
  } catch (err) {
    await AuthService.authorize()
  }
}

const waitForTokens = async () => {
  const tEnd = Date.now() + 60 * 1000

  await new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (!isFetchingToken || Date.now() >= tEnd) {
        clearInterval(interval)
        resolve()
      }
    }, 100)
  })
}

const storeRedirectUrl = () => {
  let prevUrl = `${window.location.pathname}${window.location.search}`
  if (isPortal('DIRECT')) {
    prevUrl = prevUrl.replace('/rma', '')
  }
  if (prevUrl === '/403' || prevUrl === '/404') {
    prevUrl = '/'
  }

  localStorage.setItem(REDIRECT_URL_KEY, prevUrl)
}
