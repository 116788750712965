import { FileIcon, TrashIcon } from '@ubnt/icons'
import { AugmentText, Button, cssVariables, Loader, Text } from '@ubnt/ui-components'
import formatBytes from '@shared/lib/utils/formatBytes'
import styled from 'styled-components'
import { useState } from 'react'
import type { LoaderProps } from '@ubnt/ui-components/Loader/Loader'

export const Attachment = ({
  attachment,
  onRemoveFile,
  compact = true,
}: {
  attachment: { name: string; size?: number; url?: string; progress?: number | 'loading'; error?: string | null }
  onRemoveFile?: () => void
  compact?: boolean
}) => {
  const [isRemoving, setIsRemoving] = useState(false)

  const loaderType: LoaderProps['type'] =
    // eslint-disable-next-line no-nested-ternary
    isRemoving || attachment.progress === 'loading'
      ? 'spinner'
      : // eslint-disable-next-line no-nested-ternary
      attachment.error
      ? 'error'
      : attachment.progress === 100
      ? 'success'
      : 'progress'

  // eslint-disable-next-line no-nested-ternary
  const loaderColor = attachment.error
    ? cssVariables.motifs.light.red06
    : attachment.progress === 100
    ? cssVariables.motifs.light.green06
    : cssVariables.motifs.light.uBlue06

  return (
    <Wrap $spacing={compact ? cssVariables['spacing-xs'] : cssVariables['spacing-s']}>
      <FileWrap $hasError={!!attachment.error}>
        <FileIcon variant="fill" color={cssVariables.motifs.light.neutral08} />
        <FileName title={attachment.name}>{attachment.name}</FileName>
        {attachment.size && <FileSize>{formatBytes(attachment.size)}</FileSize>}
        <Actions>
          {(attachment.progress !== undefined || attachment.error !== undefined) && (
            <StyledLoader
              size="tiny"
              variant="currentColor"
              type={loaderType}
              $color={loaderColor}
              // cannot pass "progress={undefined}" because it'll throw `Received NaN for the `strokeDashoffset``
              {...(attachment.progress === 'loading' ? {} : { progress: attachment.progress })}
            />
          )}
          {attachment.url && (
            <Button component="a" variant="link" href={attachment.url} target="_blank" rel="noreferrer">
              View
            </Button>
          )}
          {onRemoveFile && (
            <Button
              Icon={TrashIcon}
              onClick={() => {
                onRemoveFile()
                setIsRemoving(true)
              }}
              variant="tertiary"
              size="small"
              disabled={isRemoving}
              style={{ padding: `0 ${cssVariables['spacing-xs']}`, height: 24 }}
            />
          )}
        </Actions>
      </FileWrap>
      {attachment.error && <Text color="danger">{attachment.error}</Text>}
    </Wrap>
  )
}

const Wrap = styled.div<{ $spacing: string }>`
  margin-bottom: ${({ $spacing }) => $spacing};

  &:last-child {
    margin-bottom: 0;
  }
`

const FileWrap = styled.div<{ $hasError?: boolean }>`
  display: flex;
  background-color: ${({ $hasError }) =>
    $hasError ? cssVariables.motifs.light.red01 : cssVariables.motifs.light.neutral01};
  padding: ${cssVariables['spacing-xs']};
  border-radius: 4px;
  align-items: center;
  height: 32px;
`

const FileName = styled(AugmentText).attrs({ truncate: true })`
  margin-left: ${cssVariables['spacing-xs']};
  margin-right: ${cssVariables['spacing-xs']};
`

const FileSize = styled(Text).attrs({ color: 'tertiary' })`
  white-space: nowrap;
  flex-grow: 1;
`

const Actions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: right;
  align-items: center;
`

const StyledLoader = styled(Loader)<{ $color: string }>`
  color: ${({ $color }) => $color};
  width: 16px;
  height: 16px;
  margin-right: ${cssVariables['spacing-xs']};
  margin-left: ${cssVariables['spacing-xs']};

  svg[class*='cross'],
  svg[class*='checkmark'] {
    width: 10px;
    height: 10px;
    stroke-width: 1.5;
  }
`
