import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import { getCountryName } from 'rma-shared/lib/constants'

export type CustomerAddressProps = {
  address: CustomerAddress
  displayName?: boolean
}

export type CustomerAddress = Partial<{
  name: Maybe<string>
  address1: Maybe<string>
  address2: Maybe<string>
  country: Maybe<string>
  city: Maybe<string>
  state: Maybe<string>
  zipcode: Maybe<string>
}>

export const customerAddress = ({
  address: { name, address1, address2, country, city, state, zipcode },
  displayName = true,
}: CustomerAddressProps) =>
  [
    displayName ? `${name ?? ''}` : '',
    `${address1 ?? ''} ${address2 ?? ''}`,
    `${[country && getCountryName(country), city, state].filter(Boolean).join(', ')} ${zipcode ?? ''}`,
  ]
    .map((v) => v.trim())
    .filter(Boolean)

export const phoneNumber = (number: string) => {
  const parsed = parsePhoneNumberFromString(number)

  return parsed ? parsed.formatInternational() : ''
}

export const resolveDefaultAddress = <
  T extends {
    isDefaultBilling: boolean
    isDefaultShipping: boolean
  }
>(
  addresses: readonly T[],
): T | null =>
  addresses.find((address) => address.isDefaultShipping) ||
  addresses.find((address) => address.isDefaultBilling) ||
  addresses[0] ||
  null
