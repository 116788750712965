import { reach } from 'yup'
import type { Schema, SchemaDescription } from 'yup'

type Constraint<T> = {
  param: T
  message?: string
}

type FieldConstraints = {
  required?: Constraint<boolean>
  max?: Constraint<number>
}

export type Constraints<Fields extends string> = Record<Fields, FieldConstraints>

export default <V extends Record<string, unknown>, T extends Schema<V>>(schema: T, value?: V, context?: unknown) => {
  const constraints = Object.fromEntries(
    Object.entries(schema.describe().fields).map(([field]) => {
      const fieldSchema = reach(schema, field, value, context)
      // Resolved is not public API but we need it resolve correct tests for fields when using `when`.
      // Fortunately we have tests for this so we should be able to catch any issues.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      const description = (fieldSchema as any).resolve({ value, context }).describe() as SchemaDescription
      if ('tests' in description) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const fieldConstraints: FieldConstraints = Object.fromEntries(
          description.tests
            .map((test) => {
              switch (test.name) {
                case 'max':
                  return [test.name, { param: test.params.max as number } as Constraint<number>]
                case 'required':
                  return [test.name, { param: true } as Constraint<boolean>]
                default:
                  return []
              }
            })
            .filter((constraint) => constraint.length),
        )

        return [field, fieldConstraints]
      }

      return [field, {}]
    }),
  )

  return constraints as Constraints<Exclude<keyof V, symbol | number>>
}
