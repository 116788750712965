import { useRequest } from '@shared-ui/hooks'
import { didUserAbortedError } from '@shared-ui/hooks/useRequest'
import { countItems, MAC_LENGTH, parseMacOrSerialInput, validateMacSerial } from '@shared/lib'
import { CheckmarkIconL } from '@ubnt/icons'
import { Button, cssVariables, Input, Radio, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import { NotPartOfKitOption, WholeKitOption } from 'rma-shared/lib/devices/kit-devices'
import { KitReturnService } from '../kit-return-service'
import { SubmitRmaService } from '../submit-rma-service'
import type { KitReturnDevice } from '../submit-rma-store'
import { useSubmitRmaStore } from '../submit-rma-store'

export function ReturnConfirmation() {
  const { device, kits, kit } = useSubmitRmaStore()

  if (!kits.length || !device) {
    return null
  }

  return (
    <div className="flex column s-8 mt-18">
      {(kits.length > 1 || kits[0].sku !== device.name) && (
        <>
          <Text size="body" weight="bold" className="my-8">
            Is this product part of a kit?
          </Text>

          <div className="inline-flex">
            <Radio
              id="kit_option_no"
              variant="primary"
              checked={kit?.sku === NotPartOfKitOption}
              onChange={() => SubmitRmaService.selectKit(NotPartOfKitOption)}
            >
              No
            </Radio>
          </div>

          {kits.map((entry) => {
            return (
              <div key={entry.sku} className="inline-flex">
                <Radio
                  id={`kit_option_${entry}`}
                  key={entry.sku}
                  variant="primary"
                  checked={kit?.sku === entry.sku}
                  onChange={() => SubmitRmaService.selectKit(entry.sku)}
                >
                  Yes ({entry.sku})
                </Radio>
              </div>
            )
          })}
        </>
      )}

      {kit && (
        <>
          <CustomMacSection />
          <ReplaceSection />
        </>
      )}
    </div>
  )
}

function CustomMacSection() {
  const { device, kit, kitDevices } = useSubmitRmaStore()

  const skipMacs = device?.isUnifiCare
  const kitDevicesShown = countItems(kitDevices, (entry) => !entry.hidden)
  const showMacInputs = !skipMacs && kit && kit.sku !== NotPartOfKitOption && kitDevicesShown > 0

  if (!showMacInputs) {
    return null
  }

  return (
    <>
      <Text size="body" className="mt-12">
        Please enter the MAC ID(s) of the other kit item(s) so we can gather info on them.
      </Text>

      <div key={`macs_${kit.sku}`} className="gap-8">
        {kitDevices.map((entry, index) => {
          return <CustomMac key={index} kitSku={kit.sku} kitDevice={entry} />
        })}
      </div>
    </>
  )
}

function CustomMac({ kitSku, kitDevice }: { kitSku: string; kitDevice: KitReturnDevice }) {
  const [mac, setMac] = useState('')
  const [validationError, setValidationError] = useState('')

  const [validateMacExternal, { loading, error }] = useRequest('validateKitMac', {
    abortTimeout: 60 * 1000,
    retries: 0,
  })

  if (kitDevice.hidden) {
    return null
  }

  const showCheckmark = !kitDevice.loading && kitDevice.deviceId && !kitDevice.errorBe

  const handleSetMac = (element: HTMLInputElement, newMac: string) => {
    setMac(newMac)
    setValidationError('')

    const parsedMac = parseMacOrSerialInput(newMac)
    if (parsedMac.length >= MAC_LENGTH || validateMacSerial(newMac)) {
      element.blur()
    }
  }

  const handleMacSearch = async (newMac: string) => {
    if (loading || kitDevice.mac === newMac) {
      return
    }

    KitReturnService.updateKitDevice(kitDevice.index, {
      deviceId: null,
      sku: '',
      mac: newMac,
      errorBe: '',
      loading: false,
    })

    if (!newMac) {
      return
    }

    const hadError = KitReturnService.revalidateAllMacs(kitDevice.index)
    if (hadError) {
      return
    }

    try {
      KitReturnService.updateKitDevice(kitDevice.index, {
        mac: newMac,
        loading: true,
      })

      const result = await validateMacExternal({
        mac: newMac,
        kitSku,
      })

      KitReturnService.updateKitDevice(kitDevice.index, {
        deviceId: result.device?.id || 0,
        sku: result.device?.sku || '',
        loading: false,
      })

      KitReturnService.revalidateAllDevices()
    } catch (err) {
      const userAborted = didUserAbortedError(err)
      if (userAborted) {
        KitReturnService.updateKitDevice(kitDevice.index, {
          loading: false,
        })
      } else {
        KitReturnService.updateKitDevice(kitDevice.index, {
          errorBe: err instanceof Error ? err.message : 'Unknown Error',
          loading: false,
        })
      }
    }
  }

  return (
    <div>
      <div className="relative height-32">
        <div className="flex flex-1 align-center">
          <Input
            variant="tertiary"
            value={mac}
            onChange={(event, value) => {
              handleSetMac(event.currentTarget, String(value))
            }}
            disabled={loading}
            inputContainerClassName="my-6 bg-gray border-radius"
            width="100%"
            placeholder="MAC ID"
            onBlur={(event) => void handleMacSearch(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                event.currentTarget.blur()
              }
            }}
          />

          <div className="absolute right top height-100 flex flex-basis align-center px-8 pointer-events-none">
            {loading && (
              <>
                <Text size="body" color="tertiary" className="px-8">
                  Searching
                </Text>
                <Button loader="loading" />
              </>
            )}

            {showCheckmark && (
              <CheckmarkIconL color={cssVariables['color-success']} variant="fill" width="20" height="20" />
            )}
          </div>
        </div>
      </div>

      {!kitDevice.loading && (validationError || kitDevice.errorBe || kitDevice.errorFe || error) && (
        <Text size="body" color="danger">
          {validationError || kitDevice.errorFe || kitDevice.errorBe || error}
        </Text>
      )}
    </div>
  )
}

function ReplaceSection() {
  const { kit, kitReturnType } = useSubmitRmaStore()

  if (!kit || !kit.displayReplaceSection) {
    return null
  }

  const areMacsValid = KitReturnService.areMacsValid()
  if (!areMacsValid) {
    return null
  }

  const handleSelectReplacement = (option: string) => {
    useSubmitRmaStore.setState({
      kitReturnType: option,
    })
  }

  return (
    <div className="flex column s-8">
      <Text size="body" weight="bold" className="my-8">
        What do you want to replace?
      </Text>

      <div className="inline-flex">
        <Radio
          id="kit_option_no"
          variant="primary"
          checked={kitReturnType === WholeKitOption}
          onChange={() => handleSelectReplacement(WholeKitOption)}
        >
          Whole kit
        </Radio>
      </div>

      {kit.products.map((entry) => {
        return (
          <div key={`re_${entry.sku}`} className="inline-flex">
            <Radio
              id={`kit_replacement_${entry}`}
              key={entry.sku}
              variant="primary"
              checked={kitReturnType === entry.sku}
              onChange={() => handleSelectReplacement(entry.sku)}
            >
              {entry.sku}
            </Radio>
          </div>
        )
      })}
    </div>
  )
}
