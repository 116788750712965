import type { BrowserOptions } from '@sentry/browser'
import { init, setUser as setSentryUser } from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export const initSentry = (opts: BrowserOptions) => {
  init({
    debug: opts.environment === 'dev',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    ...opts,
  })
}

export const setUser = (id: string) => {
  setSentryUser({ id })
}
