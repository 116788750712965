import type { Spacing } from '@ubnt/ui-components'
import { Button as UiButton } from '@ubnt/ui-components'
import type React from 'react'
import styled from 'styled-components'

import { truncate } from '../styles/styles'

type UiButtonProps = React.ComponentProps<typeof UiButton>

type ButtonStyleProps = {
  $radius?: Spacing | string
  $active?: boolean // Used together with <ButtonGroup $switch />.
  $center?: boolean
}

export type ButtonProps = UiButtonProps & ButtonStyleProps

export const Button = styled(UiButton).attrs((props) => ({
  // truncate style contains 'width: 100%', but added to make it explicit.
  full: props.full || props.variant === 'link',
}))<ButtonProps>`
  /* Override defaults, add truncate. */
  ${(props) =>
    props.variant &&
    props.variant === 'link' &&
    `
      ${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        truncate
      }
      padding: 0px;
      text-align: ${props.$center ? 'center' : 'start'};
      font-size: ${props.theme.font.size.l};

      & > span {
        justify-content: ${props.$center ? 'center' : 'initial'} !important;
      }
    `}

  ${(props) => props.$radius && `border-radius: ${props.theme.spacing[props.$radius as Spacing] ?? props.$radius};`}
  ${(props) => props.$active && `border: 1px solid ${props.theme.colors.blue3}!important;`}
`

type RmaButtonStyleProps = {
  $bold?: boolean
  $minWidth?: string
  background?: string
}

export type RmaButtonProps = ButtonProps & RmaButtonStyleProps

export const RmaButton = styled(Button).attrs((props) => {
  const style = props as RmaButtonStyleProps

  return {
    $radius: props.theme.border.radius.s,
    $minWidth: style.$minWidth || '120px',
    $bold: style.$bold || props.variant === 'primary',
    background:
      style.background || (props.variant === 'primary' && props.theme.colors.blue3) || props.theme.colors.white,
  }
})`
  min-width: ${(props) => props.$minWidth};
  background: ${(props) => props.background};

  ${(props) => props.$bold && 'font-weight: bold;'}

  ${(props) =>
    props.variant === 'primary' &&
    `
      border: 0;
      color: ${props.theme.colors.white};

      &:hover, &:active {
        border-color: ${props.theme.colors.blue3};
        background-color: ${props.theme.colors.blue3};
      }
    `}
`
