import styled from 'styled-components'
import type { Breakpoint, Spacing as SharedSpacing, Theme } from '../styles/theme'

type Spacing = SharedSpacing | string | 0 | null | undefined | false
type Spacings = Spacing | [Spacing, Spacing] | [Spacing, Spacing, Spacing] | [Spacing, Spacing, Spacing, Spacing]

export type ContainerProps = {
  $width?: string
  $minWidth?: Breakpoint | string
  $maxWidth?: Breakpoint | string

  $height?: string
  $minHeight?: string
  $maxHeight?: string

  $margin?: Spacings
  $padding?: Spacings

  $expand?: boolean
  $center?: boolean
  $scroll?: boolean
  $hidden?: boolean
}

const getSpacing = (theme: Theme, spacing: Spacings) => {
  const asArray: Spacing[] = Array.isArray(spacing) ? spacing : [spacing]

  return asArray
    .map((size) => (theme.spacing[size as SharedSpacing] ? theme.spacing[size as SharedSpacing] : size || '0'))
    .join(' ')
}

const getDimension = (theme: Theme, size: Breakpoint | string) => {
  const breakpoint = theme.media.viewport[size as Breakpoint]

  return breakpoint ? `${breakpoint}px` : size
}

export const Container = styled.div<ContainerProps>`
  ${(props) => props.$width && `width: ${props.$width};`}
  ${(props) => props.$minWidth && `min-width: ${getDimension(props.theme, props.$minWidth)};`}
  ${(props) => props.$maxWidth && `max-width: ${getDimension(props.theme, props.$maxWidth)};`}

  ${(props) => props.$height && `height: ${props.$height};`}
  ${(props) => props.$minHeight && `min-height: ${props.$minHeight};`}
  ${(props) => props.$maxHeight && `max-height: ${props.$maxHeight};`}

  ${(props) => props.$margin && `margin: ${getSpacing(props.theme, props.$margin)};`}
  ${(props) => props.$padding && `padding: ${getSpacing(props.theme, props.$padding)};`}

  ${(props) => props.$center && 'margin: 0 auto;'}
  ${(props) => props.$scroll && 'overflow: auto;'}
  ${(props) => props.$hidden && 'visibility: hidden;'}
`

export type PaddedContainerProps = ContainerProps & { $selectable?: boolean }

export const PaddedContainer = styled(Container).attrs((props: PaddedContainerProps) => ({
  $padding: props.$padding || 'xl',
}))<PaddedContainerProps>`
  border: 1px solid ${(props) => props.theme.colors.border.darker};
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.95);

  ${(props) => props.$selectable && 'cursor: pointer;'};
`

interface ExpandedContainerProps {
  $centered?: boolean
}

export const ExpandedContainer = styled.div<ExpandedContainerProps>`
  display: flex;
  flex: 1;
  ${(props) => props.$centered && 'justify-content: center; align-items: center; '}
`

export const TableContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0 20px;
`
