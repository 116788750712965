import type { TicketId } from 'rma-shared/types/brands'
import type { IncomingTicket, IncomingTicketTotals, PageInfo } from 'rma-shared/types/tickets'
import create from 'zustand'

type TicketsState = {
  tickets: IncomingTicket[]
  search: string
  pageInfo: PageInfo
  totals: IncomingTicketTotals
}

type TicketsStateFunction = {
  reset: () => void
  updateTicket: (ticketId: TicketId, props: Partial<IncomingTicket>) => void
}

const createInitialState = (): TicketsState => {
  return {
    tickets: [],
    search: '',
    pageInfo: {
      limit: 100,
      offset: 0,
      total: 0,
    },
    totals: {
      All: 0,
      Closed: 0,
      Declined: 0,
      InProgress: 0,
      Open: 0,
      Pending: 0,
      Shipped: 0,
    },
  }
}

export const useTicketsStore = create<TicketsState & TicketsStateFunction>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },

  updateTicket(ticketId: TicketId, props: Partial<IncomingTicket>) {
    set((state) => {
      const ticketIndex = state.tickets.findIndex((entry) => entry.id === ticketId)
      if (ticketIndex === -1) {
        return state
      }

      const ticket = state.tickets[ticketIndex]
      const newTickets = [...state.tickets]
      newTickets[ticketIndex] = { ...ticket, ...props }

      return {
        ...state,
        tickets: newTickets,
      }
    })
  },
}))
