import { TICKET_ATTACHMENT_MAX_FILE_SIZE, TICKET_ATTACHMENT_VALID_FILE_TYPES } from '@shared/tickets/constants'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FileUploadItemV2 } from './FileUploadItemV2'
import { Spacer } from './SprintShared'
import { Text } from './Text'

const FileUploadContainer = styled.div`
  display: flex;
`

const ErrorText = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: #f03a3e;
  display: block;
`

export type UploadFileType = 'default' | 'images'

// todo, should get rid of this type and only expose File[] to the outer wold
export type FileUploadFile = {
  file: File
  errors: string[] | null
}

export type FileUploadPlaceholder = {
  key: number
  file?: File
  errors: string[] | null
}

interface FileUploadProps {
  fileType?: UploadFileType
  style?: React.CSSProperties
  files?: readonly FileUploadFile[]
  setFiles: (files: FileUploadFile[]) => void
  append?: boolean
  maxFiles?: number
  justView?: boolean
}

export const FileUploadV2 = ({
  fileType,
  style,
  files,
  setFiles,
  justView,
  append = false,
  maxFiles = 1,
}: FileUploadProps) => {
  const [placeholders, setPlaceholders] = useState<FileUploadPlaceholder[]>([])
  const [errors, setErrors] = useState<string[]>([])

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors: string[] = []

    if (!e.currentTarget.files) {
      return
    }

    const attachedFiles: FileUploadFile[] = [...e.currentTarget.files].map((file: File) => {
      return {
        file,
        errors: null,
      }
    })

    if (attachedFiles.length) {
      const filesToSet = append ? [...(files ?? []), ...attachedFiles] : attachedFiles

      if (filesToSet.length > maxFiles) {
        newErrors.push(`Too many files. Only ${maxFiles} files are allowed.`)
      }

      const reducedToMax = filesToSet.slice(0, maxFiles).filter((file: FileUploadFile) => {
        // todo, create constant
        if (!TICKET_ATTACHMENT_VALID_FILE_TYPES.includes(file.file.type)) {
          newErrors.push('Invalid file type')
          return false
        }

        if (file.file.size > TICKET_ATTACHMENT_MAX_FILE_SIZE) {
          newErrors.push(`The file size exceeds the limit allowed`)
          return false
        }

        return true
      })

      setFiles(reducedToMax)
      e.currentTarget.value = ''
    }
    setErrors(newErrors)
  }

  useEffect(() => {
    const newPlaceholders: FileUploadPlaceholder[] = (files ?? []).map((file: FileUploadFile) => {
      const found = placeholders.find((placeholder) => placeholder.file === file.file)
      if (found) {
        return found
      }
      return {
        key: Math.random(),
        file: file.file,
        errors: null,
      }
    })
    if (newPlaceholders.length < maxFiles) {
      const missing = maxFiles - newPlaceholders.length
      for (let ii = 0; ii < missing; ii += 1) {
        newPlaceholders.push({
          key: Math.random(),
          file: undefined,
          errors: null,
        })
      }
    }
    setPlaceholders(newPlaceholders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, maxFiles, setPlaceholders])

  return (
    <>
      <FileUploadContainer style={style}>
        {placeholders.map((item: FileUploadPlaceholder) => {
          if (justView && !item.file) {
            return null
          }

          return (
            <FileUploadItemV2
              key={item.key}
              maxFiles={maxFiles}
              handleFileChange={handleFileChange}
              file={item}
              fileType={fileType}
              justView={justView}
              onRemove={() => {
                setErrors([])
                setFiles((files ?? []).filter((file) => file.file !== item?.file))
              }}
            />
          )
        })}
      </FileUploadContainer>

      {errors && errors.length > 0 && (
        <div>
          <Spacer />
          {errors.map((err: string) => (
            <ErrorText>{err}</ErrorText>
          ))}
        </div>
      )}
    </>
  )
}
