import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, cssVariables, EmptyResult, Loader, Text } from '@ubnt/ui-components'
import type { Requests } from '@shared/requests'
import { isEqual } from 'lodash'
import { MAX_ZD_COMMENT_LENGTH } from '@shared/lib/constants'
import { AttachmentIcon, ChatIcon } from '@ubnt/icons'
import type { TicketId, UserId } from '@shared/types/brands'
import { useRequest } from '../../hooks'
import { useAuthStore } from '../../auth/auth-store'

import isPortal from '../../utils/isPortal'
import { Message } from '../chat/Message'
import { useSendSafelyDropZone } from '../send-safely/useSendSafelyDropZone'
import { Attachment } from './Attachment'
import UiAvatarSVG from './ui-avatar.svg'
import {
  AttachmentsWrap,
  ChatWrap,
  ComposeActions,
  ComposeWrap,
  LoadingStatus,
  MessagesWrap,
  TextAreaStyled,
} from '../chat/layout'

const DefaultElement = document.createElement('div')
const REFRESH_INTERVAL = 5000

export const CustomerSupport = ({
  ticketId,
  sender,
  fetch,
  data,
  loading,
  error,
}: {
  ticketId: string
  sender: { id: UserId; name: string; email: string }
  fetch: (params: { ticketId: TicketId }) => Promise<unknown>
  data?: { messages: Requests['customerSupportMessages']['output']['messages'] }
  loading: boolean
  error?: string
}) => {
  const [body, setBody] = useState('')
  const [submitComment, { error: errorSubmit }] = useRequest('sendSupportComment', { retries: 1 })
  const messagesEl = useRef<HTMLDivElement>(DefaultElement)
  const sendSafelyAttachEl = useRef<HTMLDivElement | null>(null)
  const textAreaEl = useRef<HTMLTextAreaElement | null>(null)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const previousMessages = useRef<null | Requests['customerSupportMessages']['output']['messages']>(null)
  const { user } = useAuthStore()
  const isFaePortal = isPortal('FAE')
  const {
    finalize: finalizeSendSafelyUpload,
    initialize: initializeDropzone,
    destroy: destroyDropzone,
    attachments,
    removeFile,
    isUploadingFiles,
  } = useSendSafelyDropZone({
    attachEl: sendSafelyAttachEl,
    userEmail: user?.email || '',
  })
  const [reinitWidget, setReinitWidget] = useState(false)

  useEffect(() => {
    if (sendSafelyAttachEl.current) {
      void initializeDropzone()
    }

    return () => {
      destroyDropzone()
    }
  }, [sendSafelyAttachEl, sendSafelyAttachEl])

  useEffect(() => {
    if (data?.messages && !isEqual(previousMessages.current, data.messages)) {
      messagesEl.current.scrollTop = messagesEl.current.scrollHeight

      previousMessages.current = data.messages
    }
  }, [data?.messages])

  useEffect(() => {
    const refreshMessages = setInterval(() => {
      void fetch({ ticketId })
    }, REFRESH_INTERVAL)

    return () => {
      clearInterval(refreshMessages)
    }
  }, [ticketId])

  useEffect(() => {
    if (reinitWidget) {
      void initializeDropzone()
      setReinitWidget(false)
    }
  }, [reinitWidget])

  const onSubmitComment = async () => {
    setIsLoadingSubmit(true)
    const sendSafelyAttachmentsURL = (await finalizeSendSafelyUpload()) || undefined

    try {
      await submitComment({ message: body, ticketId, sendSafelyAttachmentsURL })
      await fetch({ ticketId })
      setBody('')

      if (sendSafelyAttachmentsURL) {
        destroyDropzone()
        setReinitWidget(true)
      }
    } catch (e) {
      console.error(e)
    }

    setIsLoadingSubmit(false)
  }

  if (!user) {
    throw new Error('User missing')
  }

  // use previous messages for the error state - so that no blank view shows up on messages refresh when authorization expires
  const messages = data?.messages || previousMessages.current || []

  const isInitialLoading = loading && data?.messages === undefined && !previousMessages.current

  return (
    <ChatWrap $hasTabsAbove>
      <MessagesWrap ref={messagesEl}>
        {(isInitialLoading || error) && (
          <LoadingStatus>
            <Loader
              unbordered={!error}
              size="small"
              type={error ? 'error' : 'spinner'}
              style={{ marginBottom: cssVariables['spacing-s'] }}
            />
            {error && (
              <Text color="danger" size="body">
                Failed to load messages. Please try again later.
              </Text>
            )}
          </LoadingStatus>
        )}
        {!isInitialLoading && messages.length === 0 && (
          <EmptyResult
            icon={ChatIcon}
            title="No messages yet"
            description="Start conversation with UI below"
            style={{ justifyContent: 'flex-start' }}
          />
        )}
        {!error &&
          messages.map((message, idx) => (
            <Message
              sender={
                message.direction === 'out'
                  ? { name: sender.name, email: sender.email, isYou: sender.id === user.id }
                  : { name: 'Ubiquiti', picture: UiAvatarSVG }
              }
              body={message.body}
              submittedAt={message.submittedAt}
              attachments={
                message.attachments || message.sendSafelyAttachments
                  ? [
                      ...(message.attachments || []),
                      ...(message.sendSafelyAttachments
                        ? [{ name: 'Attachments', url: message.sendSafelyAttachments }]
                        : []),
                    ]
                  : undefined
              }
              key={idx}
            />
          ))}

        {!isInitialLoading && !error && (messages.length || 0) > 0 && (
          <ResponseTimeInfo>
            <Text size="caption" color="tertiary">
              Ubiquiti Support typically replies within 24h.
            </Text>
          </ResponseTimeInfo>
        )}
      </MessagesWrap>

      {!isFaePortal && (
        <ComposeWrap>
          <TextAreaStyled
            disabled={isLoadingSubmit}
            value={body}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setBody(e.currentTarget.value)}
            maxLength={MAX_ZD_COMMENT_LENGTH}
            variant="secondary"
            full
            placeholder="Type your message here"
            onKeyUp={() => {
              if (textAreaEl.current && textAreaEl.current.scrollHeight > textAreaEl.current.clientHeight) {
                textAreaEl.current.style.height = `${textAreaEl.current.scrollHeight}px`
              }
            }}
            ref={textAreaEl}
          />
          <AttachmentsWrap>
            {attachments.files.map((attachment) => (
              <Attachment
                attachment={{ name: attachment.name, progress: attachment.progress, error: attachment.error }}
                key={attachment.fileId}
                onRemoveFile={() => removeFile(attachment.fileId)}
                compact={false}
              />
            ))}
            {attachments.preparingToAdd && <Attachment attachment={{ name: 'Preparing to upload...' }} />}
          </AttachmentsWrap>
          <ComposeActions>
            <Button
              variant="primary"
              size="small"
              onClick={onSubmitComment}
              disabled={
                (!body.trim().length && !attachments.files.length) ||
                isLoadingSubmit ||
                isInitialLoading ||
                !!error ||
                isUploadingFiles
              }
              loader={isLoadingSubmit ? 'loading' : undefined}
            >
              Send
            </Button>
            <AttachSendSafely>
              <div>
                <AttachmentIcon size="original" />
                <SendSafelyIframeContainer>
                  <div ref={sendSafelyAttachEl} />
                </SendSafelyIframeContainer>
              </div>
            </AttachSendSafely>

            <Text color="tertiary" size="caption">
              {body.length} / {MAX_ZD_COMMENT_LENGTH} characters
            </Text>
          </ComposeActions>
          {(errorSubmit || attachments.error) && (
            <Text color="danger" size="body" style={{ marginTop: cssVariables['spacing-s'] }}>
              {errorSubmit ? 'Message sending failed. Please try again later.' : attachments.error}
            </Text>
          )}
        </ComposeWrap>
      )}
    </ChatWrap>
  )
}

const ResponseTimeInfo = styled.div`
  padding: ${cssVariables['spacing-xs']} 0;
  background-color: ${cssVariables['white-3']};
  text-align: center;
  margin-bottom: ${cssVariables['spacing-s']};
`

const AttachSendSafely = styled.div`
  flex-grow: 1;

  & > div {
    margin-left: ${cssVariables['spacing-xs']};
    position: relative;
    width: 24px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &:hover {
      background-color: ${cssVariables['white-3']};
    }
  }
`

const SendSafelyIframeContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  overflow: hidden;
`
