// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type UploadChatFilesMutationVariables = Types.Exact<{
  chatId: Types.Scalars['ChatId']
  messageId: Types.Scalars['ChatMessageId']
  files: ReadonlyArray<Types.ChatFileUpload> | Types.ChatFileUpload
}>

export type UploadChatFilesMutation = { readonly __typename?: 'Mutation' } & {
  readonly chat: { readonly __typename?: 'ChatMutations' } & {
    readonly message: { readonly __typename?: 'MessageMutations' } & Pick<Types.MessageMutations, 'uploadFiles'>
  }
}

export const UploadChatFilesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadChatFiles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatMessageId' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'files' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ChatFileUpload' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chat' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'chatId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'message' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'messageId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploadFiles' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'files' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'files' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UploadChatFilesMutationFn = Apollo.MutationFunction<
  UploadChatFilesMutation,
  UploadChatFilesMutationVariables
>

/**
 * __useUploadChatFilesMutation__
 *
 * To run a mutation, you first call `useUploadChatFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadChatFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadChatFilesMutation, { data, loading, error }] = useUploadChatFilesMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      messageId: // value for 'messageId'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadChatFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadChatFilesMutation, UploadChatFilesMutationVariables>,
) {
  return Apollo.useMutation<UploadChatFilesMutation, UploadChatFilesMutationVariables>(
    UploadChatFilesDocument,
    baseOptions,
  )
}
export type UploadChatFilesMutationHookResult = ReturnType<typeof useUploadChatFilesMutation>
export type UploadChatFilesMutationResult = Apollo.MutationResult<UploadChatFilesMutation>
export type UploadChatFilesMutationOptions = Apollo.BaseMutationOptions<
  UploadChatFilesMutation,
  UploadChatFilesMutationVariables
>
