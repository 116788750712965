import styled from 'styled-components'

export const Div = styled.div`
  &.relative {
    position: relative;
  }
  &.absolute {
    position: absolute;
  }

  &.width-20 {
    width: 20px;
  }
  &.width-24 {
    width: 24px;
  }
  .width-980px {
    width: 980px;
  }

  &.height-20 {
    height: 20px;
  }

  &.width-100 {
    width: 100%;
  }
  &.height-100 {
    height: 100%;
  }

  &.width-100-child > * {
    width: 100%;
  }

  &.height-34 {
    height: 34px;
    min-height: 34px;
  }
  &.max-height-150 {
    max-height: 150px;
  }

  &.z-999 {
    z-index: 999;
  }

  &.flex {
    display: flex;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-column {
    flex-direction: column;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.flex-1 {
    flex: 1;
  }
  &.justify-center {
    justify-content: center;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: end;
  }

  &.border-1 {
    border: 1px solid ${(props) => props.theme.colors.border.normal};
  }
  &.border-bottom-1 {
    border-bottom: 1px solid ${(props) => props.theme.colors.border.normal};
  }
  &.border-left-1 {
    border-left: 1px solid ${(props) => props.theme.colors.border.normal};
  }
  .border-left-childs > * {
    border-left: 1px solid ${(props) => props.theme.colors.border.normal};

    &:last-child {
      border-left: 0;
    }
  }

  &.border-radius-4 {
    border-radius: 4px;
  }
  &.border-bl-radius-4 {
    border-bottom-left-radius: 4px;
  }
  &.border-br-radius-4 {
    border-bottom-right-radius: 4px;
  }
  .border-radius {
    border-radius: 6px;
  }

  &.p-2 {
    padding: 2px;
  }
  &.p-16 {
    padding: 16px;
  }
  &.p-20 {
    padding: 20px;
  }
  &.p-40 {
    padding: 40px;
  }
  &.px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  &.px-56 {
    padding-left: 56px;
    padding-right: 56px;
  }
  &.py-28 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  &.pl-2 {
    padding-left: 2px;
  }
  &.pl-8 {
    padding-left: 8px;
  }
  .pl-24 {
    padding-left: 24px;
  }

  &.m-4 {
    margin: 4px;
  }
  .mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  &.mt-10 {
    margin-top: 10px;
  }
  &.mt-12 {
    margin-top: 12px;
  }
  &.mr-8 {
    margin-right: 8px;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  &.mr-15 {
    margin-right: 15px;
  }
  &.ml-1 {
    margin-left: 1rem;
  }
  .ml-12 {
    margin-left: 12px;
  }

  &.mb-8 {
    margin-bottom: 8px;
  }
  &.mb-12 {
    margin-bottom: 12px;
  }
  &.mb-16 {
    margin-bottom: 16px;
  }
  &.mb-20 {
    margin-bottom: 20px;
  }
  &.mb-32 {
    margin-bottom: 32px;
  }
  &.mb-40 {
    margin-bottom: 40px;
  }
  &.mb-44 {
    margin-bottom: 44px;
  }
  &.mb-60 {
    margin-bottom: 60px;
  }

  .s-4 > * {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .s-12 > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .s-flex > * {
    flex: 1;

    &:last-child {
      flex: 0;
    }
  }

  .sr-30 > * {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
  .sr-40 > * {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.bg-white {
    background-color: white;
  }
  &.bg-gray {
    background-color: rgba(34, 34, 37, 0.06);
  }
  .bg-neutral {
    background-color: rgba(249, 250, 250, 1);
  }

  &.text-right {
    text-align: right;
  }
  &.text-center {
    text-align: center;
  }

  &.pointer {
    cursor: pointer;
  }
  &.hover-gray:hover {
    background-color: ${(props) => props.theme.colors.hover};
  }
  &.active-gray {
    background-color: ${(props) => props.theme.colors.lightNeutral};
  }

  &.box-shadow {
    box-shadow: 0 12px 48px rgb(0 0 0 / 15%);
  }

  &.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.scroll-y {
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d6d9e2;
      border-radius: 6px;
    }
  }
`
