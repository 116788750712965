import { Input as OriginalInput, cssVariables } from '@ubnt/ui-components'
import type { FieldProps } from 'formik'
import { getIn } from 'formik'
import type { ChangeEvent, ComponentType, FC } from 'react'
import styled from 'styled-components'

type InputType = 'default' | 'phone'

interface InputProps {
  className?: string
  name: string
  type?: InputType
  label: string
  placeholder?: string
  value: string
  error?: string
  onChange: (e: ChangeEvent, value: string | number) => void
  onBlur?: () => void
  onFocus?: () => void
  variant?: 'default' | 'compact'
  iconBefore?: ComponentType
  disabled: boolean
  trackCursor?: boolean
  'data-testid': string
}
const Input: FC<InputProps> = ({
  className,
  name,
  type,
  label,
  value,
  error,
  onChange,
  onBlur,
  onFocus,
  iconBefore,
  disabled,
  placeholder,
  'data-testid': testId,
}) => {
  const handleChange = (event: ChangeEvent, valueNew: string | number) => {
    let valueHandled

    switch (type) {
      case 'phone':
        valueHandled = valueNew.toString().replace(/(?!^[+])[^0-9]/g, '')
        break

      default:
        valueHandled = valueNew
        break
    }

    if (onChange) {
      onChange(event, valueHandled)
    }
  }

  return (
    <InputWrapper className={error ? 'error' : className}>
      <InputComponent
        data-testid={testId}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value || ''}
        invalid={error}
        full
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        iconBefore={iconBefore}
        disabled={disabled}
        className="width-100"
      />
    </InputWrapper>
  )
}

type InputFieldProps = {
  disabled?: boolean
  type?: InputType
  restrictInputValue?: (value: string, done: () => void) => void
  multi?: boolean
  clearable?: boolean
  searchable?: boolean
  'data-testid'?: string
  onFocus?: () => void
  onBlur?: () => void
} & InputProps &
  FieldProps<string>
export const InputField: FC<InputFieldProps> = ({
  className,
  field,
  type,
  form: { errors, touched, values },
  label,
  variant = 'default',
  disabled = false,
  restrictInputValue,
  onChange,
  onFocus,
  onBlur,
  iconBefore,
  'data-testid': testId,
}) => {
  const error = getIn(touched, field.name) && getIn(errors, field.name) ? (getIn(errors, field.name) as string) : ''
  const value = (getIn(values, field.name) as string) ?? ''

  return (
    <Input
      type={type}
      data-testid={testId}
      className={className}
      iconBefore={iconBefore}
      value={value}
      error={error}
      label={label}
      name={field.name}
      variant={variant}
      onChange={(e, newValue) => {
        if (disabled) {
          return
        }
        if (restrictInputValue) {
          restrictInputValue(String(newValue), () => {
            if (onChange) {
              onChange(e, newValue)
            } else {
              field.onChange(e)
            }
          })
        } else if (onChange) {
          onChange(e, newValue)
        } else {
          field.onChange(e)
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}

const InputComponent = styled(OriginalInput)`
  &&& {
    input {
      color: ${(props) => props.theme.colors.text.primary};

      ::placeholder,
      ::-webkit-input-placeholder {
        color: #fff;
        font-size: 0px;
      }
      :-ms-input-placeholder {
        color: #fff;
        font-size: 0px;
      }
    }
  }
`

export const InputWrapper = styled.div<{ width?: string }>`
  margin-bottom: calc(${cssVariables['spacing-xl']} + 20px); // +20px, because label is absolutely offset by -20px

  &.error {
    margin-bottom: 25px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    color: #637281;
  }
`
