import type { AnchorHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface LinkProps {
  className?: string
  href?: string
  size?: number
  truncate?: boolean
  openTab?: boolean
  onClick?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
  'data-testid'?: string
  style?: React.CSSProperties
}
export const Link: React.FC<LinkProps> = ({
  children,
  className,
  href,
  onClick,
  openTab = true,
  'data-testid': testId,
  truncate,
  size,
  style,
}) => {
  const target = openTab && href ? '_blank' : undefined

  return (
    <StyledLink
      className={className}
      data-testid={testId}
      truncate={truncate}
      href={href}
      title={href}
      rel="noopener noreferrer"
      target={target}
      size={size}
      style={style}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.stopPropagation()

        if (onClick) {
          onClick(e)
        }
      }}
    >
      {children}
    </StyledLink>
  )
}

const StyledLink = styled.a<LinkProps>`
  ${(props) =>
    props.size &&
    css`
      font-size: ${props.size}px;
    `}

  ${(props) =>
    props.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  &.block {
    display: block;
  }

  &.p-8 {
    padding-bottom: 8px;
  }
`
