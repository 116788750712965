import { cssVariables, EmptyResult, Loader, Text } from '@ubnt/ui-components'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { ChatId, ChatMessageId } from 'rma-shared/types/brands'
import { ChatIcon } from '@ubnt/icons'
import { isEqual } from 'lodash'
import isPortal from '../../utils/isPortal'
import { ChatForm } from './ChatForm'
import { useMessagesWithSubscribe } from './useMessagesWithSubscribe'
import {
  useOnMessageSentSubscription,
  useOnMessageUpdatedSubscription,
  useReadMessagesMutation,
} from './__generated__/Chat'
import { Message } from './Message'
import { ChatWrap, LoadingStatus, MessagesWrap } from './layout'

const DefaultElement = document.createElement('div')

type Props = {
  chatId: ChatId
  hasTabsAbove: boolean
}

export type UploadingFile = {
  name: string
  loading: boolean
}

export type UploadingFiles = {
  id: ChatMessageId
  files: Array<UploadingFile>
}

const getFilesInProgress = (id: string, files: UploadingFiles[]) => {
  const uploadingFilesForCurrentMessage = files.find((entry) => entry.id === id)

  return (uploadingFilesForCurrentMessage?.files ?? [])
    .filter((file) => file.loading)
    .map((file) => ({
      filename: file.name,
      url: undefined,
      progress: 'loading',
    }))
}

export const Chat: FC<Props> = ({ chatId, hasTabsAbove }) => {
  const { data, error, loading, refetch } = useMessagesWithSubscribe(chatId)
  const messages = data?.chat?.messages ?? []
  const isFaePortal = isPortal('FAE')

  const [readMessages] = useReadMessagesMutation()

  const { data: onMessageSubscriptionData } = useOnMessageSentSubscription({ variables: { chatId }, skip: !chatId })
  useOnMessageUpdatedSubscription({ variables: { chatId }, skip: !chatId })

  const [uploadingFiles, setUploadingFiles] = useState<UploadingFiles[]>([])
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)

  const readChatMessages = useCallback(async () => {
    try {
      if (chatId) {
        await readMessages({ variables: { chatId, parentId: null } })
      }
    } catch (e) {
      // ---
    }
  }, [readMessages, chatId])

  useEffect(() => {
    void readChatMessages()
  }, [readChatMessages, onMessageSubscriptionData])

  // Refetch messages when closing chat
  useEffect(() => {
    return () => {
      // Refetch fails internally when chat is open and side panel is closed
      try {
        void refetch()
      } catch {
        // ---
      }
    }
  }, [refetch])

  useEffect(() => {
    if (uploadingFiles.length) {
      setIsUploadingFiles(true)
    } else if (isUploadingFiles) {
      setIsUploadingFiles(false)
      // hack for ensuring that all files appear once uploading has finished
      void refetch()
    }
  }, [uploadingFiles, isUploadingFiles])

  const messagesEl = useRef<HTMLDivElement>(DefaultElement)
  const previousMessages = useRef<typeof messages>([])
  const isInitialLoading = loading && messages.length === 0

  useEffect(() => {
    if (messages && !isEqual(previousMessages.current, messages)) {
      messagesEl.current.scrollTop = messagesEl.current.scrollHeight

      previousMessages.current = messages
    }
  }, [messages])

  return (
    <ChatWrap $hasTabsAbove={hasTabsAbove}>
      <MessagesWrap ref={messagesEl}>
        {(isInitialLoading || error) && (
          <LoadingStatus>
            <Loader
              unbordered={!error}
              size="small"
              type={error ? 'error' : 'spinner'}
              style={{ marginBottom: cssVariables['spacing-s'] }}
            />
            {error && (
              <Text color="danger" size="body">
                Failed to load messages. Please try again later.
              </Text>
            )}
          </LoadingStatus>
        )}
        {!isInitialLoading && messages.length === 0 && (
          <EmptyResult
            icon={ChatIcon}
            title="No messages yet"
            description="Start conversation with distributor below"
            style={{ justifyContent: 'flex-start' }}
          />
        )}
        {!error &&
          (messages || []).map((message, idx) => (
            <Message
              sender={{ ...message.sentBy, isYou: message.sentByMe }}
              body={message.content}
              submittedAt={new Date(message.sentAt)}
              attachments={[...message.files, ...getFilesInProgress(message.id, uploadingFiles)].map((file) => ({
                name: file.filename,
                url: file.url,
                progress: 'progress' in file ? file.progress : undefined,
              }))}
              key={idx}
            />
          ))}
      </MessagesWrap>

      {!isFaePortal && (
        <ChatForm chatId={chatId} setUploadingFiles={setUploadingFiles} isInitialLoading={isInitialLoading} />
      )}
    </ChatWrap>
  )
}
