import type { VideoSegment } from '@shared-ui/components'
import { Container, rewindVideo, SegmentedVideo, Video } from '@shared-ui/components'
import { useScreenSize } from '@shared-ui/contexts'
import { useWindowDimensions } from '@shared-ui/hooks'
import { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import AccessoryVideo from '../assets/Accessory.mp4'
import IssueToConfirm from '../assets/IssueToConfirm.mp4'
import SellerToIssue from '../assets/SellerToIssue.mp4'
import SubmitProductVideo from '../assets/SubmitProduct.mp4'
import { useSubmitRmaStore } from '../submit-rma-store'

export const VIDEO_WIDTH = 1600

export function SubmitRmaBackground() {
  const { view } = useSubmitRmaStore()

  const { isMobile, isDesktop } = useScreenSize()
  const { width } = useWindowDimensions()

  if (isMobile) return null

  const transitionAnimationWidth = width < VIDEO_WIDTH ? '100vw' : '60vw'

  const searchOrIdentify = view.curr === 'searchHardware'
  const isSeller = view.curr === 'selectSeller'
  const isAddingSeller = view.curr === 'addSeller'
  const isDescribeIssue = view.curr === 'describeIssue'
  const isSelectShippingAddress = view.curr === 'selectShippingAddress'
  const isConfirmInformation = view.curr === 'confirmInformation'
  const sellerToIssue = view.prev === 'selectSeller' && view.curr === 'describeIssue'
  const searchToIssue = view.prev === 'searchHardware' && view.curr === 'describeIssue'
  const issueToConfirm = view.prev === 'describeIssue' && view.curr === 'confirmInformation'
  const confirmToIssue = view.prev === 'confirmInformation' && view.curr === 'describeIssue'
  const editingShippingAddress = view.prev === 'selectShippingAddress'

  const hideSellerToIssue = !(isSeller || isAddingSeller || isDescribeIssue || sellerToIssue)
  const hideIssueToConfirmation = !(
    searchToIssue ||
    issueToConfirm ||
    confirmToIssue ||
    editingShippingAddress ||
    isSelectShippingAddress ||
    isConfirmInformation
  )

  return (
    <Background>
      {isDesktop && searchOrIdentify && (
        <div className="absolute right width-100vw height-100vh">
          <HardwareAnimation />
        </div>
      )}

      {!hideSellerToIssue && (
        <VideoContainer $width={transitionAnimationWidth}>
          <SellerToIssueAnimation />
        </VideoContainer>
      )}

      {!hideIssueToConfirmation && (
        <VideoContainer $width={transitionAnimationWidth}>
          <IssueToConfirmAnimation />
        </VideoContainer>
      )}
    </Background>
  )
}

function HardwareAnimation() {
  const { isInputMac, searchType } = useSubmitRmaStore()

  const showDefault = isInputMac && (!searchType || searchType !== 'custom')
  const segments = useMemo((): VideoSegment[] => {
    return [
      {
        start: 0,
        end: 5,
      },
      {
        start: 5,
        end: -1,
      },
    ]
  }, [])

  return (
    <>
      <FadeIn />

      {isInputMac && (
        <div className="absolute width-100 height-100" style={{ right: '-20%' }}>
          <SegmentedVideo src={SubmitProductVideo} segments={segments} segmentIndex={showDefault ? 0 : 1} />
        </div>
      )}

      {!isInputMac && <Video src={AccessoryVideo} pauseBefore={1} autoPlay muted right="-25%" />}
    </>
  )
}

function SellerToIssueAnimation() {
  const { view } = useSubmitRmaStore()

  const videoRef = useRef<HTMLVideoElement>(null)

  const shouldPlay = view.prev === 'selectSeller' && view.curr === 'describeIssue'
  const shouldRewind = view.prev === 'describeIssue' && view.curr === 'selectSeller'

  useEffect(() => {
    if (shouldPlay) {
      if (videoRef.current) void videoRef.current.play()
    }

    if (shouldRewind) {
      rewindVideo({ videoRef, fromEnd: true })
    }
  }, [shouldPlay, shouldRewind, videoRef])

  return <Video ref={videoRef} src={SellerToIssue} muted />
}

const IssueToConfirmAnimation = () => {
  const { view } = useSubmitRmaStore()

  const videoRef = useRef<HTMLVideoElement>(null)

  const shouldPlay = view.prev === 'describeIssue' && view.curr === 'confirmInformation'
  const shouldRewind = view.prev === 'confirmInformation' && view.curr === 'describeIssue'

  useEffect(() => {
    if (shouldPlay) {
      if (videoRef.current) void videoRef.current.play()
    }

    if (shouldRewind) {
      rewindVideo({ videoRef, fromEnd: true })
    }
  }, [shouldPlay, shouldRewind, videoRef])

  return <Video ref={videoRef} src={IssueToConfirm} muted />
}

const Background = styled.div`
  position: fixed;
  z-index: -1;
  top: ${(props) => props.theme.height.header};
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`

const VideoContainer = styled(Container)`
  position: absolute;
  height: 100vh;
  right: 0;
`

const FadeIn = styled.div`
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
`
