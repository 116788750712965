import type { ImgHTMLAttributes } from 'react'
import styled from 'styled-components'
import { PlaceholderProductImage } from './placeholder-product-image'

const formatSize = (size: string | number | undefined) => (typeof size === 'number' ? `${size}px` : size)

export const ImagePlaceholder = styled.div<{ width?: string | number; height?: string | number; margin?: string }>`
  width: ${(props) => formatSize(props.width)};
  min-width: ${(props) => formatSize(props.width)};
  min-height: ${(props) => formatSize(props.width)};
  height: ${(props) => formatSize(props.height)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.margin && `margin: ${props.margin}`};
`

interface RemoteProps {
  $url?: string | null
  $width?: string | number
  $height?: string | number
  $margin?: string
  alt?: string
}

const RemoteImageStyled = styled.div<RemoteProps>`
  flex-shrink: 0;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url('${(props) => props.$url}');
  width: ${(props) => formatSize(props.$width)};
  height: ${(props) => formatSize(props.$height)};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
`

export function RemoteImage({
  className,
  url,
  width = '100%',
  height = '100%',
  margin,
  alt,
}: {
  className?: string
  url?: string | null
  width?: string | number
  height?: string | number
  margin?: string
  alt?: string
}) {
  if (!url) {
    return <PlaceholderProductImage alt={alt} />
  }

  return (
    <RemoteImageStyled className={className} $url={url} $width={width} $height={height} $margin={margin} alt={alt} />
  )
}

type LocalProps = ImgHTMLAttributes<HTMLImageElement>

export const Image = styled.img<LocalProps>`
  max-width: 100%;
  height: auto;
`
