import { TicketStatus } from '@shared/tickets'
import type { TicketStatusEntry } from 'rma-shared/types/ticket-status'
import { formatDate } from '../../utils/time'
import type { GridSpacing } from '../Grid'
import { Grid } from '../Grid'
import { Text } from '../Text'

export const Shipped = ({ status, fieldGap }: { status: TicketStatusEntry; fieldGap: GridSpacing }) => (
  <Grid $container gap={fieldGap}>
    <Grid $item $xs={12}>
      <Text block>Item shipped on {formatDate(status.createdAt)}.</Text>
    </Grid>
    {/* {(status.trackingNumber || status.company) && (
      <Grid item xs={12}>
        <Text block>
          Shipping number: {status.trackingNumber && status.trackingNumber}
          {!status.trackingNumber && <Text color="secondary">Unspecified</Text>}
          {status.company && ` (${status.company})`}.
        </Text>
      </Grid>
    )} */}
    {status.notes && (
      <Grid $item $xs={12}>
        <Text block>Notes: {status.notes}</Text>
      </Grid>
    )}
  </Grid>
)

function CreditIssued({ status, fieldGap }: { status: TicketStatusEntry; fieldGap: GridSpacing }) {
  return (
    <Grid $container gap={fieldGap}>
      <Grid $item $xs={12}>
        <Text block>Credit issued on on {formatDate(status.createdAt)}.</Text>
      </Grid>

      {status.notes && (
        <Grid $item $xs={12}>
          <Text block>Notes: {status.notes}</Text>
        </Grid>
      )}
    </Grid>
  )
}

export const ReturnCancelled = ({ status, fieldGap }: { status: TicketStatusEntry; fieldGap: GridSpacing }) => {
  return (
    <Grid $container gap={fieldGap}>
      <Grid $item $xs={12}>
        <Text block>Date: {formatDate(status.createdAt)}.</Text>
      </Grid>
      {status.notes && (
        <Grid $item $xs={12}>
          <Text block>Notes: {status.notes}</Text>
        </Grid>
      )}
    </Grid>
  )
}

export const Declined = ({ status, fieldGap }: { status: TicketStatusEntry; fieldGap: GridSpacing }) => {
  return (
    <Grid $container gap={fieldGap}>
      <Grid $item $xs={12}>
        <Text block>Date: {formatDate(status.createdAt)}.</Text>
      </Grid>
      {status.notes && (
        <Grid $item $xs={12}>
          <Text block>
            Notes:
            {status.notes}
          </Text>
        </Grid>
      )}
    </Grid>
  )
}

export const Closed = ({ status, fieldGap }: { status: TicketStatusEntry; fieldGap: GridSpacing }) => {
  return (
    <Grid $container gap={fieldGap}>
      <Grid $item $xs={12}>
        <Text block>Date: {formatDate(status.createdAt)}.</Text>
      </Grid>
    </Grid>
  )
}

const components = {
  [TicketStatus.Shipped]: Shipped,
  [TicketStatus.CreditIssued]: CreditIssued,
  [TicketStatus.ReturnCancelled]: ReturnCancelled,
  [TicketStatus.Declined]: Declined,
  [TicketStatus.Closed]: Closed,
}

type TicketStatusType = keyof typeof components

type TicketStatusProps = {
  status: TicketStatus
  statuses: TicketStatusEntry[]
  fieldGap: GridSpacing
}

export const checkTicketStatusComponent = (ticketStatus: TicketStatus) => {
  return !!components[ticketStatus as TicketStatusType]
}

export const DisplayTicketStatus = ({ status: ticketStatus, statuses, fieldGap }: TicketStatusProps) => {
  const Component = components[ticketStatus as TicketStatusType]
  if (!Component) {
    return null
  }

  const status = statuses.find((entry) => entry.status === ticketStatus)
  if (!status) {
    return null
  }

  return <Component status={status} fieldGap={fieldGap} />
}
