import { isValidMac, parseMacOrSerialInput, validateMacSerial } from '@shared/lib'
import type { KitReturnDevice } from './submit-rma-store'
import { useSubmitRmaStore } from './submit-rma-store'

export const KitReturnService = {
  updateKitDevice(index: number, updatedData: Partial<KitReturnDevice>) {
    const { kitDevices } = useSubmitRmaStore.getState()

    const kitDevice = kitDevices[index]
    if (!kitDevice) {
      return
    }

    useSubmitRmaStore.getState().updateKitReturnDevice({
      ...kitDevice,
      ...updatedData,
    })
  },

  revalidateAllMacs(resultOfIndex: number) {
    const { kitDevices } = useSubmitRmaStore.getState()

    let resultOfIndexErrored = false

    for (let n = 0; n < kitDevices.length; n += 1) {
      const kitDevice = kitDevices[n]
      if (!kitDevice.mac || kitDevice.loading || (kitDevice.mac && kitDevice.deviceId)) {
        continue
      }

      const errorFe = validateKitMac(kitDevice.mac)
      if (errorFe) {
        KitReturnService.updateKitDevice(kitDevice.index, { errorFe })

        if (resultOfIndex === kitDevice.index) {
          resultOfIndexErrored = true
        }
      } else if (kitDevice.errorFe) {
        KitReturnService.updateKitDevice(kitDevice.index, { errorFe: '' })
      }
    }

    return resultOfIndexErrored
  },

  revalidateAllDevices() {
    const { kitDevices } = useSubmitRmaStore.getState()

    for (const entry of kitDevices) {
      if (!entry.mac) {
        return true
      }
    }

    for (const kitDevice of kitDevices) {
      if (kitDevice.errorFe) {
        const errorFe = validateKitMac(kitDevice.mac)
        if (errorFe) {
          continue
        }

        KitReturnService.updateKitDevice(kitDevice.index, { errorFe: undefined })
      }
    }

    return null
  },

  canStartFlow(): boolean {
    const { device, kit, kits, kitReturnType } = useSubmitRmaStore.getState()

    if (!device || (kits.length && !kit)) {
      return false
    }

    if (device.isUnifiCare) {
      return true
    }

    if (!KitReturnService.areMacsValid()) {
      return false
    }

    if (kit?.displayReplaceSection && !kitReturnType) {
      return false
    }

    return true
  },

  areMacsValid() {
    const { device, kitDevices } = useSubmitRmaStore.getState()

    if (device?.isUnifiCare) {
      return true
    }

    const macsValid = kitDevices.filter(
      (entry) => entry && entry.mac && !entry.errorFe && !entry.errorBe && !entry.loading,
    ).length

    return macsValid >= kitDevices.length
  },
}

function validateKitMac(mac: string): string | null {
  const { device } = useSubmitRmaStore.getState()

  const parsedMac = parseMacOrSerialInput(mac)
  const parsedDeviceMac = parseMacOrSerialInput(device?.mac || '')

  if (parsedMac === parsedDeviceMac) {
    return 'This MAC ID is the same as the product above. Please enter the MAC ID(s) of the other kit item(s).'
  }

  if (!validateMacSerial(mac)) {
    return 'Invalid MAC ID'
  }

  if (!isValidMac(mac)) {
    return "It seems that you have entered a Serial Number instead of a MAC ID. Make sure you're entering the number highlighted to the right and try again."
  }

  if (!isMacUnique(mac)) {
    return 'You can not enter the same MAC ID twice'
  }

  return null
}

function isMacUnique(mac: string) {
  const { kitDevices } = useSubmitRmaStore.getState()
  const parsedMac = parseMacOrSerialInput(mac)

  let entries = 0

  for (const kitDevice of kitDevices) {
    if (parseMacOrSerialInput(kitDevice.mac) === parsedMac) {
      entries += 1

      if (entries > 1) {
        return false
      }
    }
  }

  return true
}
