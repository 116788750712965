import { Button, cssVariables, Modal } from '@ubnt/ui-components'
import { useState } from 'react'
import styled from 'styled-components'
import { updateCacheItem } from '../../api/apollo'
import { useToast } from '../../contexts/ToastContext'
import { useRequest } from '../../hooks'
import { Text } from '../Text'
import type { Shared_Ticket_DetailsFragment as Ticket } from './__generated__/Details'

export function Cancel({ ticket }: { ticket: Ticket }) {
  const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false)
  const [cancelTicket, { loading }] = useRequest('cancelTicket')
  const { showNotification, removeNotification } = useToast()

  const onClose = () => setConfirmModalVisibility(false)

  const handleCancel = async () => {
    try {
      const result = await cancelTicket({
        ticketId: ticket.id,
      })

      updateCacheItem(`Ticket:${ticket.id}`, {
        status: result.status,
        statuses: JSON.stringify(result.statuses),
        offerDispute: false,
      })

      showNotification({
        id: 'ticket-canceled',
        title: 'Ticket Canceled',
        details: ``,
        stateIndicator: 'success',
        duration: 10000,
        primaryButton: {
          label: 'OK',
          onClick: () => removeNotification('ticket-canceled'),
        },
      })

      updateCacheItem(`Ticket:${ticket.id}`, { canCancelTicket: false })
      onClose()
    } catch (err) {
      //
    }
  }

  return (
    <>
      <Text>Click below to cancel this RMA submission and end its return process.</Text>

      <ButtonWrap>
        <Button variant="tertiary" color="danger" size="small" onClick={() => setConfirmModalVisibility(true)}>
          Cancel Ticket
        </Button>
      </ButtonWrap>

      <Modal
        isOpen={isConfirmModalVisible}
        size="small"
        onRequestClose={onClose}
        title={<span data-testid="confirm-cancel-ticket-title">Cancel Ticket #{ticket.id}</span>}
        actions={[
          {
            text: 'Keep Ticket Active',
            variant: 'tertiary',
            onClick: onClose,
          },
          {
            text: 'Cancel Ticket',
            variant: 'primary',
            onClick: () => void handleCancel(),
            loader: loading ? 'loading' : undefined,
          },
        ]}
      >
        <Text size="header-xs">Are you sure you want to cancel this ticket and end its return process?</Text>
      </Modal>
    </>
  )
}

const ButtonWrap = styled.div`
  display: flex;
  margin-top: ${cssVariables['spacing-l']};
`
