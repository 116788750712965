import { getCountryCallingCode } from 'libphonenumber-js/min'

import { countries } from '@shared/lib/constants'

const countryCallingCodes = Object.fromEntries(
  countries.map((country) => [country, getCountryCallingCode(country) as string]),
)

export const phoneCodeList = Object.entries(countryCallingCodes).map(([code, phoneCode]) => ({
  value: code,
  phoneCode: `+${phoneCode}`,
}))
