import { Link as LinkStyled, TextArea } from '@shared-ui/components'
import { SuccessIcon } from '@shared-ui/components/CommonIcons'
import { AlertIcon } from '@shared-ui/components/icons'
import { useRequest } from '@shared-ui/hooks'
import { beautifyMac, isValidMac, parseMacOrSerialInput } from '@shared/lib'
import { CloseTertiaryIcon } from '@ubnt/icons'
import type { TableColumnConfig } from '@ubnt/ui-components'
import { Button, Checkbox, Table, Text, Tooltip } from '@ubnt/ui-components'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUICareStore } from '../ui-care-store'
import type { TableItem } from '../ui-care-types'

export const UICareAddView = () => {
  const store = useUICareStore()

  const [acceptedTos, setAcceptedTos] = useState(false)
  const [input, setInput] = useState('')
  const [hasSubmitted, setHasSubmitted] = useState(false)

  const history = useHistory()

  const [submit, { loading }] = useRequest('submitUICareCovered')

  useEffect(() => {
    if (!input) {
      store.set({ tableItems: [] })
      return
    }

    const entries = input.split(/[,\s\n\t]+/).filter((entry) => entry.length > 0)
    if (entries.length > 100) {
      entries.length = 100
    }

    const items = entries.map(
      (entry, index): TableItem => {
        const isValid = isValidMac(parseMacOrSerialInput(entry))

        return {
          id: index + 1,
          mac: isValid ? beautifyMac(entry) : entry,
          valid: isValid,
          removeFunc: () => useUICareStore.getState().removeId(index + 1),
        }
      },
    )

    store.set({ tableItems: items })
  }, [input])

  const allItemsAreValid = useMemo(() => store.tableItems.filter((entry) => !entry.valid).length === 0, [
    store.tableItems,
  ])
  const disabled = !acceptedTos || !allItemsAreValid || !store.tableItems.length

  const handleSubmit = async () => {
    try {
      const macs = store.tableItems.map((entry) => entry.mac)

      await submit({
        macs,
      })

      setHasSubmitted(true)
    } catch (err) {
      //
    }
  }

  if (hasSubmitted) {
    return (
      <div className="flex flex-column height-100 justify-center align-center">
        <div className="mb-32">
          <SuccessIcon width={60} height={60} />
        </div>

        <div className="mb-8">
          <Text size="header-s" color="primary" weight="bold" centered>
            Registration Successful
          </Text>
        </div>

        <Text size="body" color="primary" centered>
          Your device(s) are now protected with UI Care.
        </Text>
      </div>
    )
  }

  return (
    <div className="flex flex-column justify-center align-center height-100 mt-32 mx-20">
      <div className="flex flex-column max-width-520 height-100">
        <Text size="header-s" weight="bold" color="primary" className="mb-20">
          Register Products
        </Text>

        <Text size="body" className="mb-20">
          <div>Please enter the MAC IDs of your insured devices to activate their coverage.</div>
          <div>You can enter up to 100, separated by either a comma, space, or line break.</div>
        </Text>

        <div className="min-height-230 mb-20 border-1 border-radius">
          <div className="mb-20">
            <Text size="body" color="tertiary">
              MAC IDs
            </Text>
            <TextArea
              full
              variant="secondary"
              value={input}
              onChange={(_, newInput) => setInput(newInput)}
              autoCorrect="false"
            />
          </div>

          <Text size="body" color="primary" weight="bold" className="mb-12">
            Devices to Register
          </Text>

          <Table
            columns={tableColumns}
            items={store.tableItems}
            disableColumnFilters
            hideCellOverflow={false}
            renderPlaceholder={() => <Text size="inherit">No MAC IDs added.</Text>}
            className="hide-thead"
          />
        </div>

        <div className="flex flex-column sm:row justify-center align-center mb-20">
          <div className="flex flex-1">
            <Checkbox
              id="accepted-ui-care-terms"
              checked={acceptedTos}
              onChange={(event) => setAcceptedTos(event.currentTarget.checked)}
              className="flex flex-none justify-center align-center mb-20 sm:mb-0"
            >
              <div style={{ marginTop: '3px' }}>
                I agree to the{' '}
                <LinkStyled href="https://assets.ecomm.ui.com/static/terms/UI_Care_Membership_Terms_and_Conditions_US.pdf?v=1">
                  Terms and Conditions
                </LinkStyled>
              </div>
            </Checkbox>
          </div>

          <div className="flex flex-1 width-100 flex-column sm:row-reverse sm:sl-4">
            <Button
              variant="primary"
              disabled={disabled}
              loader={loading ? 'dots' : undefined}
              onClick={() => void handleSubmit()}
              wrapClassName="width-100 mb-8 sm:mb-0"
            >
              Register
            </Button>

            <Button variant="tertiary" onClick={() => history.push('/ui-care')} wrapClassName="width-100">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const tableColumns: TableColumnConfig<TableItem>[] = [
  {
    id: 'id',
    label: 'Nr.',
    maxWidth: 40,
  },
  {
    id: 'mac',
    label: 'MAC ID',
    growthFactor: 1,
    renderCell: (item) => {
      return (
        <>
          <div className="flex align-center mr-8">
            {item.valid ? (
              <SuccessIcon />
            ) : (
              <Tooltip
                description="Incorrect format. Please ensure you've entered it correctly."
                width={250}
                position="bottom"
                className="flex align-center"
              >
                <AlertIcon width={20} height={20} variant="fill" />
              </Tooltip>
            )}
          </div>
          <Text size="body" truncate title={item.mac}>
            {item.mac}
          </Text>
        </>
      )
    },
  },
  {
    id: 'removeFunc',
    label: '',
    maxWidth: 30,
    renderCell: (item) => {
      return (
        <Button
          variant="inline"
          onClick={item.removeFunc}
          style={{ width: '100%', height: '100%' }}
          wrapClassName="width-100 height-100"
        >
          <CloseTertiaryIcon width="22" height="22" color="#808893" />
        </Button>
      )
    },
  },
]
