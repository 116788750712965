import { createApolloClient, dedupedConcatPagination } from '@shared-ui/api'
import { config } from './config'
import fragments from './generated/fragments.json'

const hasId = (input: unknown): input is { id: string } => {
  return typeof input === 'object' && input !== null && typeof (input as { id?: unknown }).id === 'string'
}

const hasDeviceId = (input: unknown): input is { deviceId: string } => {
  return typeof input === 'object' && input !== null && typeof (input as { deviceId?: unknown }).deviceId === 'string'
}

type ApolloClient = ReturnType<typeof createApolloClient>

let client: ApolloClient = {} as ApolloClient
let clientLoaded = false

export const loadApolloClient = () => {
  if (clientLoaded) {
    return
  }

  clientLoaded = true

  client = createApolloClient({
    uri: `${config.baseUrl}/graphql`,
    wsUri: `${config.websocketsBaseUrl}/graphql`,
    possibleTypes: fragments.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          ticket: (existing: unknown, { args, toReference }) =>
            hasId(args) ? toReference({ __typename: 'Ticket', id: args.id }) : existing,
          company: (existing: unknown, { args, toReference }) =>
            hasId(args) ? toReference({ __typename: 'Company', id: args.id }) : existing,
          device: (existing: unknown, { args, toReference }) =>
            hasDeviceId(args) ? toReference({ __typename: 'Device', id: args.deviceId }) : existing,
          failureCategory: (existing: unknown, { args, toReference }) =>
            hasId(args) ? toReference({ __typename: 'FailureCategory', id: args.id }) : existing,
          tickets: {
            keyArgs: (args) => (args?.query ? ['query'] : []),
            merge: false,
          },
          chat: {
            keyArgs: ['chatId'],
            merge: false,
          },
        },
      },
      Mutation: {
        fields: {
          chat: {
            merge: false,
          },
        },
      },
      ChatMutation: {
        fields: {
          message: {
            merge: false,
          },
        },
      },
      Ticket: {
        fields: {
          rating: {
            merge: true,
          },
        },
      },
      TicketsResult: {
        fields: {
          pageInfo: {
            merge: false,
          },
          result: dedupedConcatPagination({
            shouldReset: (existing: unknown, incoming: unknown, options: { variables?: { cursor?: string } }) =>
              !options.variables?.cursor,
          }),
        },
      },
    },
  })
}

export const getApolloClient = () => {
  return client
}
