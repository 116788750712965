export * from './phoneCodes'

export enum CompanyType {
  Distributor = 'distributor',
  Reseller = 'reseller',
  Ubiquiti = 'ubiquiti',
}

// copied from https://github.com/jfairbank/revalidate#redux-form
//  and top-level-domain length adjusted to max allowed length: 63
//  https://en.wikipedia.org/wiki/Domain_Name_System#cite_ref-rfc1034_1-2
export const isValidEmail = (value: string) => value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value)
