import { Link } from '@shared-ui/components'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { Dropdown, NotificationBase, NotificationBaseItem, Text } from '@ubnt/ui-components'
import { CustomLabel } from '@shared-ui/components/custom-label'
import type { RebootIssue } from '../context/SubmitRma'
import { useSubmitRma } from '../context/SubmitRma'

interface RebootIssueOption {
  label: string
  value: RebootIssue
}

const RebootOptionTexts: Record<RebootIssue, string> = {
  'poe-adapter': 'Prior to submitting your request, please verify you are using a PoE+ adapter.',
  'poe-switch': 'Prior to submitting your request, please ensure your PoE Switch has sufficient power.',
}

const RebootOptionUrls: Record<RebootIssue, string> = {
  'poe-adapter': 'https://store.ui.com/collections/operator-accessories/products/u-poe-at',
  'poe-switch': 'https://help.ui.com/hc/en-us/articles/115000263008',
}

export function RebootIssueDropdown() {
  const { rebootIssue, setRebootIssue } = useSubmitRma()

  const options: RebootIssueOption[] = [
    {
      label: 'PoE Switch',
      value: 'poe-switch',
    },
    {
      label: 'PoE Adapter',
      value: 'poe-adapter',
    },
  ]

  return (
    <div>
      <CustomLabel label="How are you powering your device?" extra="(required)" />

      <Dropdown
        value={rebootIssue}
        variant="secondary"
        width="100%"
        options={options}
        onChange={(option) => {
          setRebootIssue(option.value)
        }}
      />

      {rebootIssue && (
        <NotificationBase variant="secondary" style={{ marginTop: '12px' }}>
          <ExclamationMarkIcon size="large" />

          <NotificationBaseItem>
            <Text size="body">{RebootOptionTexts[rebootIssue]}</Text>

            <Link href={RebootOptionUrls[rebootIssue]} style={{ paddingTop: '12px', display: 'block' }}>
              Click here for an example.
            </Link>
          </NotificationBaseItem>
        </NotificationBase>
      )}
    </div>
  )
}
