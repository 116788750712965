import { Text } from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import styled from 'styled-components'
import useNestedPath from '../hooks/useNestedPath'
import { useTicketsStore } from '../tickets/tickets-store'
import { debounce } from '../utils/debounce'
import { NavigationTabs } from './NavigationTabs'
import { SearchBar } from './SearchBar'

export interface NavigationFilter {
  id: string
  label: string
}

interface OldStyleSubNavigationProps {
  className?: string
  left?: ReactNode
  center?: ReactNode
  right?: ReactNode
}

export const OldStyleSubNavigation = ({ className, left, center, right }: OldStyleSubNavigationProps) => (
  <Nav className={className}>
    {left && <LeftGroup>{left}</LeftGroup>}
    {center}
    {right && <RightGroup>{right}</RightGroup>}
  </Nav>
)

interface NavigationProps {
  title: string
  filters?: NavigationFilter[]
  onSearch?: (search: string) => void
}

export const Navigation = ({ title, filters, onSearch }: NavigationProps) => {
  return (
    <Nav>
      <NavLeft>
        <NavTitle>{title}</NavTitle>
        {filters && <NavigationTabs tabs={filters} />}
      </NavLeft>
      {onSearch && (
        <div>
          <SearchBar onChange={onSearch} />
        </div>
      )}
    </Nav>
  )
}

export const TicketsNavigation = ({ title, onSearch }: { title: string; onSearch: (search: string) => void }) => {
  const { totals } = useTicketsStore()
  const nested = useNestedPath()

  const onSearchDebouncedExtended = useMemo(
    () =>
      debounce((search: string) => {
        onSearch(search.replace(/:/g, '')) // B4:FB:E4:2B:40:66 -> B4FBE42B4066
      }, 750),
    [onSearch],
  )

  const filters = useMemo(
    () => [
      { id: nested('/pending'), label: totals ? `Pending (${totals.Pending})` : 'Pending' },
      { id: nested('/in-progress'), label: totals ? `In Progress (${totals.InProgress})` : 'In Progress' },
      { id: nested('/shipped'), label: totals ? `Shipped (${totals.Shipped})` : 'Shipped' },
      { id: nested('/declined'), label: totals ? `Declined (${totals.Declined})` : 'Declined' },
      { id: nested('/closed'), label: totals ? `Closed (${totals.Closed})` : 'Closed' },
      { id: nested('/all'), label: totals ? `All (${totals.All})` : 'All' },
    ],
    [nested, totals],
  )

  return <Navigation title={title} filters={filters} onSearch={onSearchDebouncedExtended} />
}

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  min-height: 50px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.white6};
  position: sticky;
  top: 0;
  z-index: 2;
`

const NavLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
`

const NavTitle = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  padding-right: 20px;
  font-weight: bold;
  border-right: 1px solid #d6d9e2;
`

const LeftGroup = styled.span`
  ${({ theme }) =>
    theme.media.up.m(`
      position: absolute;
      left: 0;
      border-right: 1px solid ${theme.colors.border.normal};
      padding: 0 ${theme.spacing.xl};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: ${theme.colors.grey0};
    `)}
`

const RightGroup = styled.span`
  ${({ theme }) =>
    theme.media.up.m(`
      position: absolute;
      right: ${theme.spacing.xl};
  `)}
`
