import cssVariables from '@ubnt/ui-components/styles/variables'

type Style = string

const mediaUp = (minWidth: string, style: Style) => `@media (min-width: ${minWidth}) { ${style} }`
const mediaDown = (maxWidth: string, style: Style) => `@media (max-width: ${maxWidth}) { ${style} }`

const viewports = {
  xs: 0,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

type Viewport = keyof typeof viewports

export const upViewports = Object.fromEntries(
  Object.entries(viewports).map(([size, width]) => [size as Viewport, `${width}px`] as const),
)
export const downViewports = Object.fromEntries(
  Object.entries(viewports).map(([size, width]) => [size as Viewport, `${width - 0.02}px`] as const),
)

type ViewportUpFn = (style: Style) => ReturnType<typeof mediaUp>
type ViewportDownFn = (style: Style) => ReturnType<typeof mediaDown>

export const theme = {
  colors: {
    black: cssVariables['black-0'],
    black1: cssVariables['black-1'],
    black2: cssVariables['black-2'],

    white: cssVariables['white-0'],
    white2: cssVariables['white-2'],
    white3: cssVariables['white-3'],
    white4: cssVariables['white-4'],
    white5: cssVariables['white-5'],
    white6: cssVariables['white-6'],
    white7: cssVariables['white-7'],

    grey0: cssVariables['grey-0'],
    grey1: cssVariables['grey-1'],
    grey2: cssVariables['grey-2'],
    grey3: cssVariables['grey-3'],
    grey4: cssVariables['grey-4'],

    blue3: cssVariables['blue-3'],

    primary: cssVariables['color-primary'],

    link: cssVariables['blue-3'],

    lightNeutral: '#F1F1F1',
    neutral: '#A4A7B5',
    success: '#39CC65',
    warning: '#F5A623',
    danger: cssVariables['color-danger'],
    gradientBlue: 'linear-gradient(90deg, rgb(0, 111, 255) 0%, rgb(0, 168, 255) 100%)',

    // background: {
    //   primary: cssVariables['color-light-background-primary'],
    //   secondary: cssVariables['color-light-background-secondary'],
    //   tertiary: cssVariables['color-light-background-tertiary'],
    // },

    // Maybe VAR specific? Or not-RMA.
    statuses: {
      approved: '#006fff',
      inProgress: '#46c7fd',
      declined: '#f5a623',
      cancelled: '#f03a3e',
    },

    text: {
      primary: cssVariables['color-light-text-primary'],
      secondary: cssVariables['grey-2'],
      tertiary: cssVariables['color-light-text-tertiary'],
      link: cssVariables['color-text-link'],
      hover: cssVariables['color-text-link-hover'],
      inputLabel: '#637281',
    },

    border: {
      normal: cssVariables['white-6'],
      darker: cssVariables['white-7'],
    },

    background: {
      primary: cssVariables['color-light-background-primary'],
      secondary: cssVariables['color-light-background-secondary'],
      tertiary: cssVariables['color-light-background-tertiary'],
      disabled: '#F9F9FC',
      error: cssVariables['red-0'],
      black_transparent_0: cssVariables['black-transparent-0'],
      blue_0: cssVariables['blue-0'],
      blue_link: cssVariables['color-text-link'],
      green_0: cssVariables['green-0'],
      green_1: cssVariables['green-1'],
    },

    hover: cssVariables['white-2'],
    active: cssVariables['white-2'],
    stroke: cssVariables['white-6'],
    shadow: cssVariables['color-drop-shadow'],
  },
  spacing: {
    none: '0',
    xxs: cssVariables['spacing-xxs'],
    xs: cssVariables['spacing-xs'],
    s: cssVariables['spacing-s'],
    m: cssVariables['spacing-m'],
    l: cssVariables['spacing-l'],
    xl: cssVariables['spacing-xl'],
    xxl: cssVariables['spacing-xxl'],
    xxxl: cssVariables['spacing-xxxl'],
    xxxxl: cssVariables['spacing-xxxxl'],
    xxxxxl: cssVariables['spacing-xxxxxl'],
  },
  font: {
    weight: {
      light: '300',
      medium: '500',
      bold: '700',
    },
    size: {
      xxs: '9px', // 9px
      xs: '10px', // 10px
      s: '11px', // 11px
      m: '12px', // 12px
      l: '13px', // 13px
      l2: '14px', // 14px
      l3: '16px',
      xl: '20px', // 20px
      xl2: '22px',
      xxl: '28px', // 28px
    },
  },
  media: {
    viewport: viewports,
    up: Object.fromEntries(
      Object.keys(upViewports).map((key) => [key, (style: Style) => mediaUp(upViewports[key], style)]),
    ) as Record<Viewport, ViewportUpFn>,
    down: Object.fromEntries(
      Object.keys(upViewports).map((key) => [key, (style: Style) => mediaDown(upViewports[key], style)]),
    ) as Record<Viewport, ViewportDownFn>,
    _up: (minWidth: string, style: Style) => mediaUp(minWidth, style),
    _down: (maxWidth: string, style: Style) => mediaDown(maxWidth, style),
  },
  height: {
    header: '50px',
  },
  border: {
    radius: {
      s: '3px',
      m: '6px',
    },
  },
  tooltip: {
    width: 'max-content',
  },
}

export type Theme = typeof theme
export type Spacing = keyof Theme['spacing']
export type FontSize = keyof Theme['font']['size']
export type Breakpoint = keyof Theme['media']['viewport']
