import UCareImg1 from '@shared-ui/assets/u-care-img-1.png'
import UCareImg2 from '@shared-ui/assets/u-care-img-2.png'
import UCareImg3 from '@shared-ui/assets/u-care-img-3.png'
import UCareServerImage from '@shared-ui/assets/u-care-server.png'
import { AuthService } from '@shared-ui/auth/auth-service'
import { Image, Link, UniFiCareLogo } from '@shared-ui/components'
import { Button, Text } from '@ubnt/ui-components'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getCountryFromTimezone } from 'rma-shared/lib/utils/timezones'
import type { CountryCode } from 'libphonenumber-js'
import { isEuropeanUnion } from '@shared/lib/constants'
import { useUICareStore } from '../ui-care-store'
import type { ComparisonButtonType } from '../ui-care-types'
import type { CustomStepperStep } from './custom-stepper'
import { CustomStepper } from './custom-stepper'

export const UICareView = () => {
  const { activeButton } = useUICareStore()

  const history = useHistory()
  const isLogged = !AuthService.isAccessTokenExpired()

  const handleRegister = () => {
    history.push('/ui-care-add')
  }

  useEffect(() => {
    const parentId = '#comparison-carousel'

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const currActiveButton = useUICareStore.getState().activeButton

          if (entry.isIntersecting) {
            useUICareStore.setState({ activeButton: entry.target.id as ComparisonButtonType })
          } else if (entry.target.id === currActiveButton) {
            useUICareStore.setState({
              activeButton:
                currActiveButton === 'nav-ui-care-protection' ? 'nav-regular-rma' : 'nav-ui-care-protection',
            })
          }
        })
      },
      {
        root: document.querySelector(parentId),
        rootMargin: '0px',
        threshold: 0.5,
      },
    )

    document.querySelectorAll(`${parentId} > *`).forEach((item) => {
      observer.observe(item)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const timezoneCountry = getCountryFromTimezone()

  return (
    <div className="flex flex-column sm:align-center">
      <div className="max-width-980">
        <div className="flex flex-column align-center mt-32 mx-20">
          <UniFiCareLogo className="mb-20" />
          <Text size="header-xl" weight="bold" color="primary" centered className="mb-20 line-48">
            <div>Protect Your Devices with UI Care</div>
          </Text>
          <Text size="header-xs" centered className="mb-32">
            <div className="flex flex-wrap justify-center wrap-pretty">
              <div>
                With UI Care, you can extend the replacement protection period of covered products to five years and
                enjoy exclusive benefits.
              </div>
              <div>If you already have UI Care-covered products, register them to activate their coverage.</div>
            </div>
          </Text>

          <div className="flex flex-column justify-center width-100 px-40 mb-64 sm:row sm:max-width-276px">
            <UICareButton variant="primary" onClick={handleRegister} wrapClassName=" p-4">
              {isLogged ? 'Register a Device' : 'Log In to Register a Device'}
            </UICareButton>

            <UICareButton variant="secondary" wrapClassName="p-4">
              <Link href={getTermsOfServiceUrl(timezoneCountry)}>Terms of Service</Link>
            </UICareButton>
          </div>
        </div>

        <div className="mb-64 bg-gray lg:border-radius">
          <div id="white-blocks" className="flex flex-column p-20 lg:px-68 lg:py-40 grid-container">
            <WhiteBlock>
              <UCareImage1 />

              <Text size="header-xs" color="primary" weight="bold" className="mb-8">
                Expedited Replacement
              </Text>

              <Text size="body" centered className="flex flex-wrap justify-center line-height">
                Receive your replacement before we receive your returned item.
              </Text>
            </WhiteBlock>

            <WhiteBlock className="white-block">
              <UCareImage2 />

              <Text size="header-xs" color="primary" weight="bold" className="mb-8">
                Free Return Shipping
              </Text>

              <Text size="body" centered className="flex wrap-pretty justify-center line-height">
                Your replacement includes a prepaid shipping label.
              </Text>
            </WhiteBlock>

            <WhiteBlock className="white-block">
              <UCareImage3 />

              <Text size="header-xs" color="primary" weight="bold" className="mb-8">
                Extended Coverage
              </Text>

              <Text size="body" centered className="flex justify-center line-height">
                Enjoy complete peace of mind with five-year replacement protection.
              </Text>
            </WhiteBlock>
          </div>

          <div className="flex flex-1 justify-center" style={{ marginTop: '-30px' }}>
            <UCareServer />
          </div>
        </div>

        <div className="flex flex-column mb-32">
          <Text
            size="header-m"
            color="primary"
            weight="bold"
            centered
            className="flex flex-wrap justify-center mx-20 mb-32"
          >
            How UI Care Compares
          </Text>

          <div className="flex justify-center mb-32 lg:hide">
            <div className="mx-5">
              <UICareButton variant={activeButton === 'nav-ui-care-protection' ? 'primary' : 'tertiary'}>
                <Link
                  href="#nav-ui-care-protection"
                  openTab={false}
                  style={{ color: activeButton === 'nav-ui-care-protection' ? 'white' : 'gray' }}
                >
                  UI Care Protection
                </Link>
              </UICareButton>
            </div>
            <div className="mx-5">
              <UICareButton variant={activeButton === 'nav-regular-rma' ? 'primary' : 'tertiary'}>
                <Link
                  href="#nav-regular-rma"
                  openTab={false}
                  style={{ color: activeButton === 'nav-regular-rma' ? 'white' : 'gray' }}
                >
                  Regular RMA
                </Link>
              </UICareButton>
            </div>
          </div>

          <div className="py-20 mx-20 mt-20 bg-gray border-radius-8 lg:mx-0" style={{ height: '342px' }}>
            <div
              id="comparison-carousel"
              className="flex height-100 scroll-x scroll-snap-x scroll-smooth scroll-snap-x scroll-hide overflow-y-hidden "
            >
              <div id="nav-ui-care-protection" className="flex flex-column flex-100 px-20 lg:flex-1">
                <div className="flex mb-20 pre">
                  <Text size="header-s" color="primary" weight="bold">
                    UI Care{' '}
                  </Text>
                  <Text size="header-s" color="primary">
                    Protection
                  </Text>
                </div>
                <CustomStepper steps={UICareProtectionSteps} variant="primary" />
              </div>

              <div id="nav-regular-rma" className="flex flex-column flex-100 px-20 lg:flex-1">
                <div className="mb-20">
                  <Text size="header-s" color="primary">
                    Normal RMA
                  </Text>
                </div>
                <CustomStepper steps={NormalRmaSteps} variant="secondary" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const getTermsOfServiceUrl = (country: CountryCode | null) => {
  if (country) {
    if (isEuropeanUnion(country)) {
      return 'https://assets.ecomm.ui.com/static/terms/UI_Care_Membership_Terms_and_Conditions_EUv4.pdf?v=1'
    }

    if (country === 'CA') {
      return 'https://assets.ecomm.ui.com/static/terms/UI_Care_Membership_Terms_and_Conditions_CA.pdf?v=1'
    }
  }

  return 'https://assets.ecomm.ui.com/static/terms/UI_Care_Membership_Terms_and_Conditions_US.pdf?v=1'
}

const UICareProtectionSteps: CustomStepperStep[] = [
  {
    title: 'Request Replacement',
    text: '',
  },
  {
    title: 'Your Replacement is Shipped Immediately',
    text: 'Receive it as soon as the next business day.',
  },
  {
    title: 'Return the Original Device for Free',
    text: 'We include a prepaid shipping label with your replacement.',
  },
]

const NormalRmaSteps: CustomStepperStep[] = [
  {
    title: 'Request Replacement',
    text: '',
  },
  {
    title: 'Return the Original',
    text: 'You cover the shipping cost.',
  },
  {
    title: 'Await Device Inspection',
    text: 'Our team verifies the issue in your ticket.',
  },
  {
    title: 'Your Replacement is Shipped',
    text: '',
  },
]

const UICareButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 20px;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

const WhiteBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-width: 260px;
  min-height: 242px;
  background-color: white;
  border-radius: 8px;
`

const UCareServer = styled(Image).attrs({ src: UCareServerImage })`
  max-height: 450px;
  display: block;
`

const UCareImage1 = styled(Image).attrs({ src: UCareImg1 })`
  display: block;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`

const UCareImage2 = styled(Image).attrs({ src: UCareImg2 })`
  display: block;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`

const UCareImage3 = styled(Image).attrs({ src: UCareImg3 })`
  display: block;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
`
