/**
 * Based off https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Grid
 *
 * Grid components uses 12 column layout with different spacing for each breakpoint defined in theme.
 * Grid items should be wrapped in container
 *
 *    <Grid container>
 *      <Grid item xs={12}>12 columns always</Grid>
 *    </Grid>
 *
 *    <Grid container>
 *      <Grid item xs={12} m={6}>12 columns by default, 6 columns after "m" breakpoint</Grid>
 *    </Grid>
 *
 * Grid items can act both as container and as the item but, at the moment,
 * nested items need separate container if gap property is different.
 *
 *    <Grid container gap="s">
 *      <Grid item container>
 *        <Grid item>This is ok</Grid>
 *      </Grid>
 *    </Grid>
 *
 *    <Grid container gap="s">
 *      <Grid item>
 *        <Grid container gap="l">
 *          <Grid item>This needs extra container</Grid>
 *        </Grid
 *      </Grid>
 *    </Grid>
 */

import type * as React from 'react'
import styled, { css } from 'styled-components'
import is from 'styled-is'

import type { Breakpoint, Theme } from '../styles/theme'

export type GridItemsAlignment = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
export type GridContentAlignment = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around'
export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'
export type GridJustification = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
export type GridWrap = 'nowrap' | 'wrap' | 'wrap-reverse'
export type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export type GridSpacing = keyof Theme['spacing'] | 'none' | number
type FlexValue = 'grow' | 'shrink' | 'no-grow' | 'no-shrink' | [number, number] | [number, number, string]

export type GridProps = {
  as?: React.ElementType<{ className?: string }>
  className?: string
  /**
   * Defines the `align-content` style property.
   * It's applied for all screen sizes.
   */
  alignContent?: GridContentAlignment
  /**
   * Defines the `align-items` style property.
   * It's applied for all screen sizes.
   */
  $alignItems?: GridItemsAlignment
  /**
   * The content of the component.
   */
  children?: React.ReactNode
  /**
   * If `true`, the component will have the flex *container* behavior.
   * You should be wrapping *items* with a *container*.
   */
  $container?: boolean
  /**
   * Defines the `flex-direction` style property.
   * It is applied for all screen sizes.
   */
  direction?: GridDirection
  /**
   * If `true`, the component will have the flex *item* behavior.
   * You should be wrapping *items* with a *container*.
   */
  $item?: boolean
  /**
   * Defines the `justify-content` style property.
   * It is applied for all screen sizes.
   */
  justify?: GridJustification
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `lg` breakpoint and wider screens if not overridden.
   */
  l?: boolean | GridSize
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `md` breakpoint and wider screens if not overridden.
   */
  m?: boolean | GridSize
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `sm` breakpoint and wider screens if not overridden.
   */
  s?: boolean | GridSize
  /**
   * Defines the `flex-wrap` style property.
   * It's applied for all screen sizes.
   */
  wrap?: GridWrap
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for the `xl` breakpoint and wider screens.
   */
  xl?: boolean | GridSize
  /**
   * Defines the number of grids the component is going to use.
   * It's applied for all the screen sizes with the lowest priority.
   */
  $xs?: boolean | GridSize
  /**
   * If `true`, it sets `min-width: 0` on the item.
   * Refer to the limitations section of the documentation to better understand the use case.
   */
  zeroMinWidth?: boolean
  /**
   * Defines the space between the type `item` component.
   * It can only be used on a type `container` component.
   */
  gap?: GridSpacing
  overflow?: 'auto' | 'unset' | 'scroll' | 'hidden'
  minWidth?: 'min-content' | 'max-content' | 'auto'
  height?: string
  flex?: FlexValue
}

const GRID_SIZES = ['auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const

const generateGrid = (breakpoint: Breakpoint, size: typeof GRID_SIZES[number]) => {
  if (size === true) {
    // For the auto layout
    const styles = css`
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    `

    if (breakpoint === 'xs') {
      return styles
    }

    return css`
      ${({ theme }) => theme.media.up[breakpoint]((styles as unknown) as string)}
    `
  }

  if (size === 'auto') {
    return css`
      flex-basis: auto;
      flex-grow: 0;
      max-width: none;
    `
  }

  // Keep 7 significant numbers.
  const width = `${Math.round((size / 12) * 10e7) / 10e5}%`

  // Close to the bootstrap implementation:
  // https://github.com/twbs/bootstrap/blob/8fccaa2439e97ec72a4b7dc42ccc1f649790adb0/scss/mixins/_grid.scss#L41

  const gridCss = `
    flex-basis: ${width};
    flex-grow: 0;
    max-width: ${width};
  `

  if (breakpoint === 'xs') {
    return css`
      ${gridCss}
    `
  }

  return css`
    ${({ theme }) => theme.media.up[breakpoint](gridCss)}
  `
}

const renderGap = (theme: Theme, gapClass: GridSpacing) => {
  if (gapClass === 'none') {
    return undefined
  }

  const gap = typeof gapClass === 'string' && gapClass in theme.spacing ? theme.spacing[gapClass] : `${gapClass}px`

  return css`
    &&& {
      margin-top: -${gap};
      margin-left: -${gap};
      width: calc(100% + ${gap});

      & > * {
        padding-top: ${gap};
        padding-left: ${gap};
      }
    }
  `
}

const resolveFlex = (flex: FlexValue): string => {
  switch (flex) {
    case 'grow':
      return '1'
    case 'no-grow':
      return '0 1 auto'
    case 'shrink':
      return '0 1 auto'
    case 'no-shrink':
      return '1 0 auto'
    default:
      return flex.join(' ')
  }
}

// Default CSS values
// flex: '0 1 auto',
// flexDirection: 'row',
// alignItems: 'flex-start',
// flexWrap: 'nowrap',
// justifyContent: 'flex-start',
export const Grid = styled.div<GridProps>`
  ${is<GridProps, unknown>('$container')`
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `}
  ${is<GridProps, unknown>('$item')`
    box-sizing: border-box;
    margin: 0; // For instance, it's useful when used with a \`figure\` element.
  `}
  ${is<GridProps, unknown>('zeroMinWidth')`
    min-width: 0;
  `}
  ${({ theme, gap = 'none', $container: container }) =>
    container && gap !== 'none' ? renderGap(theme, gap) : undefined};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => wrap};
  align-items: ${({ $alignItems: alignItems }) => alignItems};
  align-content: ${({ alignContent }) => alignContent};
  justify-content: ${({ justify }) => justify};
  ${({ $xs: xs = false }) => xs !== false && generateGrid('xs', xs)};
  ${({ s = false }) => s !== false && generateGrid('s', s)};
  ${({ m = false }) => m !== false && generateGrid('m', m)};
  ${({ l = false }) => l !== false && generateGrid('l', l)};
  ${({ xl = false }) => xl !== false && generateGrid('xl', xl)};
  ${({ overflow, direction }) =>
    overflow
      ? `overflow-${['column', 'reverse-column'].includes(direction || 'row') ? 'y' : 'x'}: ${overflow}`
      : undefined};
  min-width: ${({ minWidth }) => minWidth};
  ${({ height }) => height && `height: ${height}`}
  ${({ flex }) => (flex ? `flex: ${resolveFlex(flex)}` : undefined)}
`
