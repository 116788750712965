const {
  REACT_APP_ENV: env = '',
  REACT_APP_API_URL: baseUrl = '',
  REACT_APP_API_WEBSOCKETS_URL: websocketsBaseUrl = '',
  PUBLIC_URL: publicUrl = '',
  // REACT_APP_RMA_DOMAIN: rmaDomain = '',
  // REACT_APP_ACCOUNT_URL: accountUrl = '',
  REACT_APP_SENTRY_DSN: sentryDSN,
  REACT_APP_RELEASE: release = 'dev',
  REACT_APP_SSO_LOGOUT_URL: ssoLogoutUrl = 'https://account.ui.com/logout',
} = process.env

export const config = {
  env,
  sentryDSN,
  baseUrl,
  websocketsBaseUrl,
  publicUrl,
  doNotSkipSteps: env === 'local' && false,
  release,
  ssoLogoutUrl,
}
