import { withIcon } from './withIcon'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MailIcon = withIcon(({ forwardedRef, isActive, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg viewBox="0 0 20 14" ref={forwardedRef} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1079.000000, -502.000000)">
        <g transform="translate(1021.000000, 160.000000)">
          <g transform="translate(30.000000, 323.000000)">
            <g transform="translate(29.000000, 20.000000)">
              <rect fill="#A4A7B5" x="0.551693339" y="0.725708008" width="16" height="12" rx="1" />
              <polyline
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="-2.78078866e-13 0 8.55169334 8.41699219 17.8079834 0"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
))
