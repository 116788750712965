import styled from 'styled-components'

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 420px;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 14px 48px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media screen and (max-width: 440px) {
    padding: 0;
    box-shadow: none;
  }
`
