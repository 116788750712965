export function SubmitStepper({ step, maxSteps }: { step: number; maxSteps: number }) {
  return (
    <div className="flex width-100 align-center relative">
      <div className="absolute width-100 height-px bg-blue" />

      <div className="flex space-between width-100">
        {Array(maxSteps)
          .fill(true)
          .map((_, index) => {
            return (
              <div
                key={index}
                className={`width-7px height-7px border-radius-7 z-index ${
                  index > step ? `bg-white border-blue` : `bg-blue`
                }`}
              />
            )
          })}
      </div>
    </div>
  )
}
