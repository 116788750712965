import { UbiquitiLogo } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'

export function TicketDetailsSupportPanel({ title, message }: { title: JSX.Element; message: JSX.Element }) {
  return (
    <div className="flex flex-column p-20 mb-16 bg-white border-radius-6">
      <div className="flex mb-8">
        <StyledLogoInnerContainer>
          <UbiquitiLogo variant="bgRoundedSquare" />
        </StyledLogoInnerContainer>

        <Text size="body" weight="bold" color="primary">
          {title}
        </Text>
      </div>

      <div className="pl-24">{message}</div>
    </div>
  )
}

const StyledLogoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`
