// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../generated/graphql'

export type Shared_Messaging_TicketChatQueryVariables = Types.Exact<{
  ticketId: Types.Scalars['TicketId']
}>

export type Shared_Messaging_TicketChatQuery = { readonly __typename?: 'Query' } & {
  readonly ticket: Types.Maybe<
    { readonly __typename?: 'Ticket' } & Pick<Types.Ticket, 'id'> & {
        readonly chat: { readonly __typename?: 'Chat' } & Pick<Types.Chat, 'id'>
      }
  >
}

export const Shared_Messaging_TicketChatDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Shared_Messaging_TicketChat' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ticketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketId' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ticket' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ticketId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chat' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useShared_Messaging_TicketChatQuery__
 *
 * To run a query within a React component, call `useShared_Messaging_TicketChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useShared_Messaging_TicketChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShared_Messaging_TicketChatQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useShared_Messaging_TicketChatQuery(
  baseOptions: Apollo.QueryHookOptions<Shared_Messaging_TicketChatQuery, Shared_Messaging_TicketChatQueryVariables>,
) {
  return Apollo.useQuery<Shared_Messaging_TicketChatQuery, Shared_Messaging_TicketChatQueryVariables>(
    Shared_Messaging_TicketChatDocument,
    baseOptions,
  )
}
export function useShared_Messaging_TicketChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Shared_Messaging_TicketChatQuery,
    Shared_Messaging_TicketChatQueryVariables
  >,
) {
  return Apollo.useLazyQuery<Shared_Messaging_TicketChatQuery, Shared_Messaging_TicketChatQueryVariables>(
    Shared_Messaging_TicketChatDocument,
    baseOptions,
  )
}
export type Shared_Messaging_TicketChatQueryHookResult = ReturnType<typeof useShared_Messaging_TicketChatQuery>
export type Shared_Messaging_TicketChatLazyQueryHookResult = ReturnType<typeof useShared_Messaging_TicketChatLazyQuery>
export type Shared_Messaging_TicketChatQueryResult = Apollo.QueryResult<
  Shared_Messaging_TicketChatQuery,
  Shared_Messaging_TicketChatQueryVariables
>
