export function countItems<T>(arr: Array<T>, checkFunc: (entry: T) => boolean) {
  let numItems = 0

  for (const item of arr) {
    if (checkFunc(item)) {
      numItems += 1
    }
  }

  return numItems
}
