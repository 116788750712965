import { Navigation as UiNavigation, NavigationGroup, NavigationItem } from '@ubnt/ui-components'
import styled from 'styled-components'
import { history } from '../auth/history'

export interface NavigationButton {
  label: string
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  path: string
}

interface NavigationBarProps {
  nav: NavigationButton[]
  bottom?: NavigationButton[]
}

export default function NavigationBar({ nav, bottom = [] }: NavigationBarProps) {
  return (
    <Nav>
      <NavigationGroup>
        {nav.map((entry) => {
          const publicUrl = process.env.PUBLIC_URL || ''
          const path = entry.path.replace(publicUrl, '')

          return (
            <NavigationItem
              key={path}
              active={isOpen(entry.path)}
              label={entry.label}
              icon={entry.icon}
              onClick={() => history.push(path)}
              data-testid={`btn-${entry.label.toLowerCase()}`}
            />
          )
        })}
      </NavigationGroup>

      <BottomGroup>
        {bottom.map((entry) => {
          const publicUrl = process.env.PUBLIC_URL || ''
          const path = entry.path.replace(publicUrl, '')

          return (
            <NavigationItem
              key={path}
              active={isOpen(entry.path)}
              label={entry.label}
              icon={entry.icon}
              onClick={() => history.push(path)}
            />
          )
        })}
      </BottomGroup>
    </Nav>
  )
}

const isOpen = (path: string) => {
  return window.location.pathname.startsWith(path)
}

const Nav = styled(UiNavigation)`
  height: auto;
  justify-content: space-between;
  padding: 0;
  z-index: 100;

  ul > li {
    height: 50px;
    > a > svg {
      height: 24px;
      width: 24px;
    }
  }
`

const BottomGroup = styled(NavigationGroup)`
  margin-bottom: 15px;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`
