import type { ReactNode } from 'react'
import styled from 'styled-components'

import { Flex } from './Flex'

export type Props = {
  className?: string
  rows: {
    icon?: JSX.Element
    text: ReactNode
  }[]
  marginTop?: string
}

export const Card = ({ className, rows, ...styles }: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CardContainer className={className} {...styles}>
    {rows.map((row, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <CardRow key={i}>
        {row.icon && <CardIconContainer>{row.icon}</CardIconContainer>}
        {row.text}
      </CardRow>
    ))}
  </CardContainer>
)

const CardContainer = styled(Flex).attrs({ column: true })<{ $marginTop?: string }>`
  border: 1px solid ${(props) => props.theme.colors.border.darker};
  border-radius: ${(props) => props.theme.spacing.s};
  padding: 0 5%;

  ${(props) => (props.$marginTop ? `margin-top: ${props.$marginTop}` : '')}
`

const CardRow = styled(Flex).attrs({ alignCenter: true })`
  padding: ${(props) => props.theme.spacing.m} ${(props) => props.theme.spacing.s};
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darker};

  &:last-child {
    border-bottom: none;
  }
`

const CardIconContainer = styled(Flex).attrs({ justifyCenter: true, alignCenter: true })`
  width: 24px;
  height: 24px;

  margin-right: ${(props) => props.theme.spacing.l};
`
