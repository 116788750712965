import { Text } from '@shared-ui/components'
import { Button } from '@shared-ui/components/Button'
import { Container } from '@shared-ui/components/Container'
import { LoaderContainer } from '@shared-ui/components/Containers'
import { Grid } from '@shared-ui/components/Grid'
import { RemoteImage } from '@shared-ui/components/Image'
import { Rating } from '@shared-ui/components/Rating'
import { Device, Status, SupportBy, TicketIdRow, TimeElapsed } from '@shared-ui/components/table-columns'
import { useNestedPath } from '@shared-ui/hooks'
import formatSerial from '@shared/lib/utils/format/serial'
import type { TicketStatus } from '@shared/tickets'
import type { TableColumnConfig, TableItem } from '@ubnt/ui-components'
import { Loader } from '@ubnt/ui-components'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { TableWithStickyHeader } from './layout'
import { useClosedTicketsQuery } from './__generated__/History'

export interface Row {
  id: string
  model: {
    name: string
    image: string | null
  }
  macIdOrSerial: string
  closedAt?: string
  status: TicketStatus
  supportByName: string
  isUnifiCare: boolean
  rating?: number
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'id',
    label: 'Ticket no.',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => <TicketIdRow ticketId={row.id} isUnifiCare={row.isUnifiCare} />,
  },
  {
    id: 'model',
    label: 'Device',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => (
      <Device name={row.model.name} image={<RemoteImage url={row.model.image} width={24} height={24} />} />
    ),
  },
  {
    id: 'macIdOrSerial',
    label: 'MAC ID / Serial',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => formatSerial(row.macIdOrSerial),
  },
  {
    id: 'closedAt',
    label: 'Completion time',
    growthFactor: 1,
    sortable: true,
    renderCell: (row) => row.closedAt && <TimeElapsed from={row.closedAt} />,
  },
  {
    id: 'status',
    label: 'Status',
    growthFactor: 1,
    renderCell: (row) => <Status id={row.id} status={row.status} />,
  },
  {
    id: 'supportByName',
    label: 'Support by',
    growthFactor: 1,
    renderCell: (row) => <SupportBy name={row.supportByName} />,
  },
  {
    id: 'rating',
    label: 'Rating',
    growthFactor: 1,
    renderCell: (row) => <Rating height="32px" width="112px" readonly value={row.rating} />,
  },
]

export const History = () => {
  const history = useHistory()
  const nested = useNestedPath()
  const { loading, error, data, fetchMore } = useClosedTicketsQuery({
    notifyOnNetworkStatusChange: true,
  })

  const cursor = data?.tickets?.pageInfo.endCursor
  const hasMore = data?.tickets?.pageInfo.hasNextPage ?? true
  const tickets = data?.tickets?.result

  const items = useMemo(
    () =>
      (tickets || []).map(
        (ticket): TableItem<Row> => ({
          id: ticket.id,
          model: {
            name: ticket.device.name,
            image: ticket.device.productImage,
          },
          macIdOrSerial: ticket.device.mac || '',
          closedAt: ticket.closedAt ?? undefined,
          status: ticket.status,
          supportByName: ticket.supportByName || '',
          rating: ticket.rating?.rating,
          isUnifiCare: ticket.device.isUnifiCare,
        }),
      ),
    [tickets],
  )

  const handleFetchMore = useCallback(async () => {
    if (!loading) {
      await fetchMore({ variables: { cursor } })
    }
  }, [loading, fetchMore, cursor])

  return (
    <StyledGrid $item $xs>
      {(() => {
        if (error || (loading && !data)) {
          return (
            <LoaderContainer>
              {error ? <Text size="inherit">Error loading data.</Text> : <Loader size="large" />}
            </LoaderContainer>
          )
        }

        return (
          <TableWithStickyHeader
            rowHeight={50}
            headerHeight={50}
            initialSortBy="closedAt"
            disableColumnFilters
            columns={columns}
            items={items}
            onRowClick={(item) => {
              history.push(nested(`/${item.id}`))
            }}
            renderPlaceholder={() => (
              <Container $padding={['m', 0]}>
                <Text size="inherit">No closed tickets.</Text>
              </Container>
            )}
            renderFooter={() => {
              if (loading) {
                return (
                  <Container $padding={['m', 0]}>
                    <LoaderContainer>
                      <Loader />
                    </LoaderContainer>
                  </Container>
                )
              }

              if (!hasMore) {
                return null
              }

              return (
                <Container $padding={['m', 0]}>
                  <Button variant="secondary" onClick={handleFetchMore}>
                    Fetch more
                  </Button>
                </Container>
              )
            }}
          />
        )
      })()}
    </StyledGrid>
  )
}

const StyledGrid = styled(Grid)`
  padding: 0 5%;
`
