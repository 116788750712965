import { Text } from '@ubnt/ui-components'
import { createBrowserHistory } from 'history'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Loader, LoaderContainer } from '../../components'
import { useAuthStore } from '../auth-store'
import { PageForbidden } from './PageForbidden'
import { PageServiceUnavailable } from './PageServiceUnavailable'

export const Authenticated = ({ children }: { children: ReactNode }) => {
  const { status } = useAuthStore()

  useEffect(() => {
    if (status !== 'login') {
      return undefined
    }

    const func = () => {
      const history = createBrowserHistory()
      history.go(0)
    }

    window.addEventListener('popstate', func)

    return () => {
      window.removeEventListener('popstate', func)
    }
  }, [status])

  if (status === 'login') {
    return (
      <LoaderContainer>
        <Loader />
        <Text size="header-xs" color="primary" style={{ marginTop: '8px' }}>
          Logging in...
        </Text>
      </LoaderContainer>
    )
  }

  if (status === 'unavailable') {
    return <PageServiceUnavailable />
  }

  if (status === 'forbidden') {
    return <PageForbidden />
  }

  return <>{children}</>
}
