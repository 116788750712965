import type { Spacing } from '@ubnt/ui-components'
import { ButtonGroup as UiButtonGroup } from '@ubnt/ui-components'
import type React from 'react'
import styled from 'styled-components'

const BUTTON_SELECTOR = '> div > button'

type UiButtonGroupProps = React.ComponentProps<typeof UiButtonGroup>

type StyleProps = {
  $radius?: Spacing | string | 0
  $switch?: boolean
}

export const ButtonGroup = styled(UiButtonGroup)<UiButtonGroupProps & StyleProps>`
  ${(props) =>
    !props.$switch &&
    props.$radius &&
    `
      ${BUTTON_SELECTOR} {
        border-radius: ${props.$radius};
      }
  `}

  ${(props) => {
    const $radius = props.$radius as string

    return (
      props.$switch &&
      `
      ${BUTTON_SELECTOR} {
        border-radius: 0;
      }

      > div:first-child > button {
        border-right: 0;
        border-top-left-radius: ${$radius};
        border-bottom-left-radius:${$radius};
      }

      > div:last-child > button {
        border-left: 0;
        border-top-right-radius: ${$radius};
        border-bottom-right-radius: ${$radius};
      }
  `
    )
  }}
`
