import type { ApolloError } from '@apollo/client'

import type { TextProps } from './Text'
import { Text } from './Text'

export type RequestErrorProps = TextProps & {
  error: ApolloError
  networkError?: string
}

export const RequestError = ({ error, networkError, ...rest }: RequestErrorProps) => {
  // Allows handling network errors.
  const errors: readonly { message: string }[] = error.networkError
    ? [{ message: networkError || error.message }]
    : (error.graphQLErrors.length && error.graphQLErrors) || [error]

  return (
    <div>
      {errors.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key,react/jsx-props-no-spreading
        <Text {...rest} key={i} block scroll>
          {message}
        </Text>
      ))}
    </div>
  )
}
