import { theme } from '@shared-ui/styles/theme'
import { FocusMonitorProvider, ToastProvider } from '@ubnt/ui-components'
import type { FC } from 'react'
import { ThemeProvider } from 'styled-components'

export const Providers: FC = ({ children }) => (
  <FocusMonitorProvider>
    <ThemeProvider theme={theme}>
      <ToastProvider>{children}</ToastProvider>
    </ThemeProvider>
  </FocusMonitorProvider>
)
