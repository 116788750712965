import { withIcon } from './withIcon'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhoneIcon = withIcon(({ forwardedRef, isActive, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg viewBox="0 0 15 15" ref={forwardedRef} {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1081.000000, -553.000000)" fill="#A4A7B5" fillRule="nonzero">
        <g transform="translate(1021.000000, 160.000000)">
          <g transform="translate(30.000000, 323.000000)">
            <path d="M32.9611452,76.4960958 C34.1390593,78.8532015 36.0368098,80.7771994 38.3517382,81.9849037 L40.1513292,80.1525247 C40.3721881,79.9276419 40.6993865,79.8526809 40.9856851,79.9526288 C41.9018405,80.2608017 42.8916155,80.4273816 43.9059305,80.4273816 C44.3558282,80.4273816 44.7239264,80.8021864 44.7239264,81.2602811 L44.7239264,84.1671005 C44.7239264,84.6251952 44.3558282,85 43.9059305,85 C36.2249489,85 30,78.6616346 30,70.840708 C30,70.3826132 30.3680982,70.0078084 30.8179959,70.0078084 L33.6809816,70.0078084 C34.1308793,70.0078084 34.4989775,70.3826132 34.4989775,70.840708 C34.4989775,71.8818324 34.6625767,72.8813118 34.9652352,73.8141593 C35.0552147,74.1056741 34.9897751,74.4305049 34.7607362,74.6637168 L32.9611452,76.4960958 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
))
