import type { CompanyAddressType } from '@shared-ui/components'
import { Link } from '@shared-ui/components'
import type { CountryCode } from '@shared/lib/constants'
import { haveStatesEnabled } from '@shared/lib/constants'
import { UbiquitiLogo } from '@ubnt/icons'
import { Text } from '@ubnt/ui-components'

export function SellerInfo({ seller }: { seller: CompanyAddressType }) {
  let countryInfo = `${seller.country || ''}`
  if (haveStatesEnabled((seller.country || '') as CountryCode)) {
    countryInfo += `, ${seller.state}`
  }
  if (seller.city) {
    countryInfo += `, ${seller.city}`
  }

  const isUbiquiti = seller.name === 'Ubiquiti'
  if (isUbiquiti) {
    return <SellerInfoUbiquiti />
  }

  let { website } = seller
  if (website && website.indexOf('//') === -1) {
    website = `//${website}`
  }

  return (
    <div className="flex column border border-radius p-16">
      <div className="flex">
        <Text size="body" color="primary" className="break-word">
          {seller.nameLegal || seller.name || ''}
        </Text>
      </div>
      <div className="flex" color="secondary">
        <Text size="body">{seller.address1}</Text>
      </div>
      <div className="flex" color="secondary">
        <Text size="body">{countryInfo}</Text>
      </div>
      <div className="flex" color="secondary">
        <Text size="body">{seller.zipcode}</Text>
      </div>

      {website && (
        <div className="flex" color="secondary">
          <Link href={website}>{seller.website}</Link>
        </div>
      )}
    </div>
  )
}

export function SellerInfoUbiquiti() {
  return (
    <div className="flex border border-radius p-16">
      <div className="mr-16">
        <UbiquitiLogo size="logo" variant="bgRoundedSquare" style={{ width: '40px', height: '40px' }} />
      </div>

      <div className="flex column">
        <Text size="body" color="primary" className="break-word">
          Ubiquiti Inc.
        </Text>

        <Link href="https://ui.com">https://ui.com</Link>
      </div>
    </div>
  )
}
