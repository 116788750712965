import { Tabs as UiTabs } from '@ubnt/ui-components'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useNavigate from '../hooks/useNavigate'
import type { Tab, TabsProps } from './Tabs'

export function NavigationTabsTertiary({ tabs }: TabsProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const selected = useMemo(() => (tabs || []).find((tab) => matchPath(pathname, tab.id))?.id ?? null, [pathname, tabs])

  return (
    <UiTabsStyled
      variant="tertiary"
      tabs={tabs}
      selectedTab={selected}
      onSelectTab={(tab: Tab) => {
        navigate(tab.id)
      }}
      selectedTabClassName="selected"
    />
  )
}

export function NavigationTabs({ tabs }: TabsProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const selected = useMemo(() => (tabs || []).find((tab) => matchPath(pathname, tab.id))?.id ?? null, [pathname, tabs])

  return (
    <UiTabs
      variant="primary"
      tabs={tabs}
      selectedTab={selected}
      onSelectTab={(tab) => {
        navigate(tab.id)
      }}
    />
  )
}

export const UiTabsStyled = styled(UiTabs)`
  margin: 0 8px;

  & > div > button {
    margin: 0 2px;
  }

  .selected {
    background-color: rgba(0, 111, 255, 1) !important;

    span {
      color: white !important;
    }
  }
`
