import { Redirect } from 'react-router-dom'

import useSearchParams from '@shared-ui/hooks/useQuery'

import { ROOT_URL } from './constants'

export const WildcardRoute = () => {
  const query = useSearchParams()

  const mac = query.get('mac')
  if (mac !== null) {
    return <Redirect to={`/submit?mac=${mac}`} />
  }

  return <Redirect to={ROOT_URL} />
}
