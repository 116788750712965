import { RemoteImage } from '@shared-ui/components'
import { isProbablyMac } from '@shared/lib'
import formatSerial from '@shared/lib/utils/format/serial'
import { Text } from '@ubnt/ui-components'
import type { Device } from 'rma-shared/types/devices'

export function RmaDevicePanel({ device, skuOverride }: { device: Device; skuOverride?: string }) {
  const mac = formatSerial(device.mac || '')
  const isDevice = !mac || isProbablyMac(mac)
  const sku = skuOverride || device.name

  return (
    <div className="flex border border-radius p-8 mt-24">
      <div className="width-80">
        <RemoteImage url={device.productImage} alt={sku} />
      </div>

      <div className="flex flex-1 mx-16 my-16">
        <div className="flex flex-1 column justify-center gap-4">
          <div className="flex">
            <div className="flex flex-1">
              <Text size="body" color="secondary">
                Product
              </Text>
            </div>
            <Text size="body" color="tertiary">
              {sku}
            </Text>
          </div>

          <div className="flex">
            <div className="flex flex-1">
              <Text size="body" color="secondary">
                {isDevice ? 'MAC ID' : 'Serial'}
              </Text>
            </div>
            <Text size="body" color="tertiary">
              {mac || 'None'}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
