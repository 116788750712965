import { useAuthStore } from '@shared-ui/auth/auth-store'
import { LoaderIcon } from '@shared-ui/components/icons'
import { useNavigate, useRequest, useSearchParams } from '@shared-ui/hooks'
import { timeElapsedFromEx } from '@shared-ui/utils/time'
import {
  MAC_LENGTH,
  MAC_SPECIAL_STRING_LENGTH,
  TemplateMAC,
  beautifyMac,
  hasMacSupportedCharacters,
  isProbablyMac,
  parseMacOrSerialInput,
  validateMacSerial,
} from '@shared/lib'
import { isProofOfPurchaseRequired } from '@shared/lib/utils/checks/is-proof-of-purchase-required'
import { WarrantyStatus } from '@shared/types/enums'
import { Button, Input, Text } from '@ubnt/ui-components'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useSubmitRma } from '../../context/SubmitRma'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { AlreadyOpenRma } from '../AlreadyOpenRma'
import { LoginModal } from '../LoginModal'
import { OriginalNotReceivedModal } from '../OriginalNotReceived'
import { CodeInput } from '../code-input'
import { DeviceFound } from '../device-found'
import { MacNotFoundModal } from '../mac-not-found-modal'
import { SearchHardwareHeading } from '../search-hardware-heading'
import { StepHeading } from '../step-heading'
import { ChooseHardware } from './choose-hardware'

export function SearchHardware() {
  const { view, mac, device, isOutOfWarranty, isInputMac, searchType } = useSubmitRmaStore()

  const { user } = useAuthStore()
  const query = useSearchParams()
  const location = useLocation()
  const history = useHistory()
  const navigate = useNavigate()

  const [lookupFunc, { data: searchData, loading, reset: resetSearch }] = useRequest('searchDevice', {
    abortTimeout: 60 * 1000,
    minCompletionTime: 2000,
    retries: 0,
  })

  const { setCompanyId, resetContext } = useSubmitRma()
  const [isMacOrSerialInputError, setIsMacOrSerialInputError] = useState(false)

  const cameBackRef = useRef(view.curr !== 'searchHardware')

  const isNextValid = mac && ((isInputMac && !isOutOfWarranty) || !isMacOrSerialInputError) && validateMacSerial(mac)

  const resetDisplayComponents = () => {
    SubmitRmaService.setDevice(null)
    SubmitRmaService.setSeller(null, true)
  }

  const resetErrors = () => {
    setIsMacOrSerialInputError(false)
  }

  const resetState = () => {
    SubmitRmaService.reset()

    resetDisplayComponents()
    resetErrors()
    resetContext()
  }

  const handleDeviceSelection = (newIsDevice: boolean) => {
    resetState()
    useSubmitRmaStore.setState({ isInputMac: newIsDevice })
  }

  const handleNoSerialNumber = () => {
    SubmitRmaService.reset()

    useSubmitRmaStore.setState({
      searchType: 'no-serial',
    })
  }

  const handleMacChange = (newMac: string) => {
    useSubmitRmaStore.setState({
      searchType: null,
    })

    resetDisplayComponents()

    const parsed = parseMacOrSerialInput(newMac)
    const value = parsed.slice(0, MAC_LENGTH)

    if (value.length >= MAC_SPECIAL_STRING_LENGTH) {
      const isInvalidMac = !isProbablyMac(newMac)
      setIsMacOrSerialInputError(isInvalidMac)
    } else {
      const isInvalidMac = !hasMacSupportedCharacters(newMac)
      setIsMacOrSerialInputError(isInvalidMac)
    }
  }

  const handleSubmit = async (macIdOrSerial: string, doResetContext = true) => {
    resetErrors()

    if (!validateMacSerial(macIdOrSerial)) {
      setIsMacOrSerialInputError(true)
      return
    }

    if (doResetContext) {
      resetContext()
    }

    if (isInputMac) {
      SubmitRmaService.setMac(macIdOrSerial)
    }

    try {
      const data = await lookupFunc({
        mac: macIdOrSerial,
      })

      if (data.alreadyOpenRma || data.originalNotReceived) {
        return
      }

      const deviceNew = data.device
      if (!deviceNew?.dateCode) {
        startUnknownFlow()
        return
      }

      const { seller } = deviceNew

      SubmitRmaService.processDevice(deviceNew, {
        supportsUICare: data.supportsUICare,
        kits: data.kits,
      })

      SubmitRmaService.setSeller(seller, true)

      setCompanyId(seller?.id)

      if (seller && seller.id && deviceNew.isHandledByUbiquiti) {
        const isPopRequired = isProofOfPurchaseRequired(deviceNew)

        useSubmitRmaStore.setState({
          isOutOfWarranty: deviceNew.warrantyStatus === WarrantyStatus.Expired && isPopRequired,
        })
      }
    } catch (err) {
      startUnknownFlow()
    }
  }

  const handleBack = () => {
    SubmitRmaService.reset()
    navigate('/')
  }

  const handleMacComplete = (macId: string) => {
    const doResetContext = !cameBackRef.current
    cameBackRef.current = false

    SubmitRmaService.setMac(macId)

    void handleSubmit(macId, doResetContext)
  }

  const handleEditDevice = () => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('mac')

    history.replace({
      search: queryParams.toString(),
    })

    resetState()
  }

  const startUnknownFlow = () => {
    setCompanyId(null)
    SubmitRmaService.setDevice(null)
    useSubmitRmaStore.setState({
      searchType: 'unknown',
    })
  }

  // Set initial state.
  useEffect(() => {
    resetContext()
    SubmitRmaService.reset()

    if (!mac) {
      const uriMac = query.get('mac')
      if (uriMac) {
        SubmitRmaService.setMac(uriMac)
        if (validateMacSerial(uriMac)) {
          void handleSubmit(uriMac)
        }
      }
      return
    }

    const doResetContext = false
    void handleSubmit(mac, doResetContext)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deviceInformationEndRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const el = deviceInformationEndRef.current
    if (!el) return

    el.scrollIntoView({ behavior: 'smooth' })
  }, [device])

  if (!user) {
    return (
      <>
        <SearchHardwareHeading />
        <LoginModal />
      </>
    )
  }

  if (device && !isOutOfWarranty) {
    return <DeviceFound />
  }

  const hasAnIssue = searchData?.alreadyOpenRma || searchData?.originalNotReceived

  if (loading) {
    return (
      <>
        <SearchHardwareHeading />

        <SubmitContainer>
          <LookupLoading />
        </SubmitContainer>
      </>
    )
  }

  return (
    <>
      <SearchHardwareHeading />

      <SubmitContainer>
        <div>
          <StepHeading heading="What are you returning?" />

          <Text size="body" className="mt-8 line-height">
            Select the category of the product you&apos;d like to return, then enter its MAC ID or serial number.
          </Text>
        </div>

        <div className="flex p-2 gap-2 bg-gray height-32 border-radius">
          <ToggleButton name="Device" isActive={isInputMac} onClick={() => handleDeviceSelection(true)} />
          <ToggleButton name="Accessory" isActive={!isInputMac} onClick={() => handleDeviceSelection(false)} />
        </div>

        <div>
          {isInputMac ? (
            <CodeInput
              value={mac}
              autoFocus={!mac}
              length={MAC_LENGTH}
              invalid={isMacOrSerialInputError}
              disabled={loading}
              onKeyDown={resetErrors}
              onChange={handleMacChange}
              onComplete={handleMacComplete}
            />
          ) : (
            <SerialInput
              autoFocus
              variant="secondary"
              value={mac}
              size="body"
              placeholder="e.g. K17082700543"
              disabled={loading}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  void handleSubmit(mac)
                }
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                resetErrors()
                SubmitRmaService.setMac(e.currentTarget.value)
              }}
            />
          )}

          <Button variant="inline" onClick={handleNoSerialNumber} wrapClassName="mt-4">
            Trouble with your MAC ID / Serial Number?
          </Button>
        </div>

        {isOutOfWarranty && (
          <Text color="danger" date-testid="submit-rma-step-1-out-of-warranty-text" size="caption">
            Your device is out of warranty and no longer eligible for replacement.
          </Text>
        )}

        {isMacOrSerialInputError && !searchType && (
          <Text color="danger" size="caption">
            {parseMacOrSerialInput(mac) === TemplateMAC &&
              `You entered the MAC ID from the image on the right. Please enter the MAC ID from the label on your device, located on its back or underside.`}
            {parseMacOrSerialInput(mac) !== TemplateMAC &&
              `We don't recognize that ${isInputMac ? 'MAC ID' : 'serial number'}. Make sure you're entering the
            number highlighted to the right and try again.`}
          </Text>
        )}

        {!loading && !hasAnIssue && (device || searchType) && (
          <>
            {searchType === 'unknown' && <MacNotFoundModal onClose={handleEditDevice} />}

            {searchType === 'no-serial' && <ChooseHardware />}

            <div style={{ display: 'none' }} ref={deviceInformationEndRef} />
          </>
        )}

        <div className="flex justify-end gap-8">
          <Button variant="tertiary" onClick={handleBack}>
            Go to Dashboard
          </Button>

          <Button
            variant="primary"
            loader={loading ? 'dots' : undefined}
            disabled={!isNextValid}
            onClick={() => {
              void handleSubmit(mac)
            }}
          >
            Next
          </Button>
        </div>

        {searchData?.alreadyOpenRma && (
          <AlreadyOpenRma
            mac={mac}
            onClose={() => {
              resetState()
              resetSearch()
            }}
          />
        )}

        {searchData?.originalNotReceived && (
          <OriginalNotReceivedModal
            mac={mac}
            onClose={() => {
              resetState()
              resetSearch()
            }}
          />
        )}
      </SubmitContainer>
    </>
  )
}

function SubmitContainer({ children }: { children: React.ReactNode }) {
  return <div className="px-16 pt-24 pb-16 s-24 bg-white border-radius box-shadow line-height">{children}</div>
}

function ToggleButton({ isActive, name, onClick }: { isActive: boolean; name: string; onClick: () => void }) {
  return (
    <Button
      onClick={onClick}
      className="height-32"
      wrapClassName={`flex-1 ${isActive ? 'bg-white' : ''}`}
      contentClassName={isActive ? 'color-blue' : ''}
    >
      {name}
    </Button>
  )
}

function LookupLoading() {
  const { mac, isInputMac } = useSubmitRmaStore()
  const [elapsedTime, setElapsedTime] = useState('')

  useEffect(() => {
    const timeFrom = new Date()
    const interval = setInterval(() => {
      setElapsedTime(timeElapsedFromEx(timeFrom.toISOString()))
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex column pt-56 pb-40 justify-center align-center">
      <LoaderIcon />
      <Text size="header-xs" weight="bold" className="mt-24 mb-16">
        Identifying Device...
      </Text>

      {mac && (
        <Text size="body" color="tertiary">
          {isInputMac && `MAC ID: ${beautifyMac(mac)}`}
          {!isInputMac && `Serial: ${mac}`}
        </Text>
      )}

      <Text size="body" color="tertiary">
        Time elapsed: {elapsedTime}
      </Text>
    </div>
  )
}

const SerialInput = styled(Input)`
  width: 100% !important;

  * {
    height: 42px;
  }

  input {
    text-align: center;
  }
  input:disabled {
    color: rgba(0, 0, 0, 0.85) !important;
  }
`
