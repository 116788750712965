import type { DisputeType, Requests } from '@shared/requests'
import { CheckmarkIcon } from '@ubnt/icons'
import { Button, Modal, Text } from '@ubnt/ui-components'
import React, { useState } from 'react'
import type { TicketId } from 'rma-shared/types/brands'
import styled from 'styled-components'
import { TextArea } from '..'
import { updateCacheItem } from '../../api/apollo'
import { useToast } from '../../contexts/ToastContext'
import { useRequest } from '../../hooks'

type ContactSupportData = Requests['contactSupport']['input']

type ContactSupportProps = {
  data: ContactSupportData
  onClose: () => void
  onDone: () => void
  children: React.ReactNode
}

type ContactSupportAboutMacProps = {
  mac: string
  onDone: () => void
  onClose: () => void
}

type ContactSupportAboutTicketProps = {
  ticketId: TicketId
  supportedByName: string
  disputeType?: DisputeType
  isUbiquiti?: boolean
  onDone: () => void
  onClose: () => void
}

const ContactSupport = ({ data, children, onClose, onDone }: ContactSupportProps) => {
  const [message, setMessage] = useState('')
  const [submit, { loading, error }] = useRequest('contactSupport')
  const { showNotification, removeNotification } = useToast()

  const handleSubmit = async () => {
    try {
      await submit({
        ...data,
        message,
      })

      showNotification({
        id: 'customer-support-sent',
        title: 'Thank you for reaching out!',
        details: 'We will respond via email as soon as possible.',
        icon: CheckmarkIcon,
        duration: 10000,
        primaryButton: {
          label: 'Got it!',
          onClick: () => removeNotification('customer-support-sent'),
        },
      })

      onDone()
    } catch (err) {
      //
    }
  }

  return (
    <StyledModel isOpen title="Contact Ubiquiti RMA Support" onRequestClose={onClose}>
      <div className="flex column gap-8">
        <Text size="body">{children}</Text>

        <TextAreaStyled
          placeholder="Type your message here."
          height={132}
          width={340}
          onChange={(element: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(element.currentTarget.value)}
          maxLength={400}
          required
          size="body"
        />

        {error && (
          <Text size="label" color="danger">
            {error}
          </Text>
        )}

        <div className="flex justify-end gap-8 mt-12">
          <Button variant="tertiary" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            loader={loading ? 'dots' : undefined}
            disabled={message.length === 0}
            onClick={handleSubmit}
          >
            Submit Ticket
          </Button>
        </div>
      </div>
    </StyledModel>
  )
}

export const ContactSupportAboutMac = ({ mac, onDone, onClose }: ContactSupportAboutMacProps) => {
  const data: ContactSupportData = { type: 'mac', mac, message: '' }

  return (
    <ContactSupport data={data} onClose={onClose} onDone={onDone}>
      Please provide a brief description of your issue and we will contact you as soon as possible.
    </ContactSupport>
  )
}

export const ContactSupportAboutTicket = ({
  ticketId,
  supportedByName,
  onDone,
  onClose,
  disputeType,
  isUbiquiti,
}: ContactSupportAboutTicketProps) => {
  const data: ContactSupportData = { type: 'ticket', ticketId, disputeType, message: '' }

  const customHandleDone = () => {
    updateCacheItem(`Ticket:${ticketId}`, {
      offerDispute: false,
    })

    onDone()
  }

  return (
    <ContactSupport data={data} onClose={onClose} onDone={customHandleDone}>
      {isUbiquiti && (
        <>
          Please provide a brief description of your issue or question, and we will get back to you as soon as possible.
        </>
      )}

      {!isUbiquiti && (
        <>Tell us what&apos;s occurred to this point so we can reach out to {supportedByName} and resolve this delay.</>
      )}
    </ContactSupport>
  )
}

const TextAreaStyled = styled(TextArea)`
  margin-top: 12px;

  & > * > textarea {
    resize: none;
  }
`

const StyledModel = styled(Modal)`
  max-width: 404px;
`
