/* eslint-disable @typescript-eslint/restrict-template-expressions */
import type { FC } from 'react'
import { createElement } from 'react'
import styled, { css } from 'styled-components'

import { truncate } from '../styles/styles'
import type { TextProps } from './Text'
import { Text } from './Text'

type Props = {
  className?: string
  level?: 1 | 2
} & StyleProps

type StyleProps = TextProps & {
  underline?: boolean
  maxWidth?: string
  truncate?: boolean
}

const commonCss = css<StyleProps>`
  font-size: ${(props) => props.theme.font.size.xl};
  font-weight: ${(props) => props.theme.font.weight.medium};

  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : undefined)};

  ${(props) =>
    props.underline &&
    `
      padding-bottom: ${props.theme.spacing.s};
      border-bottom: 1px solid ${props.theme.colors.white7};
  `}

  ${(props) =>
    props.truncate &&
    `
      ${truncate}
      max-width: ${props.maxWidth || '100%'};
  `}
`

const H1 = styled(Text).attrs({ as: 'h1' })<StyleProps>`
  ${commonCss}
`

const H2 = styled(Text).attrs({ as: 'h2' })<StyleProps>`
  ${commonCss}
`

const TypeMap = {
  1: H1,
  2: H2,
}

export const Heading: FC<Props> = ({ level = 1, children, ...props }) => {
  return createElement(TypeMap[level], props, children)
}
