import type { NetsuiteCompanyId } from 'rma-shared/types/brands'
import type { UserPermission } from 'rma-shared/types/permissions'
import type { UserContext } from 'rma-shared/types/users'
import create from 'zustand'

type AuthStatus = 'login' | 'unavailable' | 'connected' | 'forbidden'

type AuthState = {
  user?: UserContext
  accessToken?: string
  refreshToken?: string
  nsCompanyId?: NetsuiteCompanyId
  permissions: UserPermission[]
  status: AuthStatus
  isSuperUser: boolean
}

type AuthStateFunctions = {
  reset: () => void
}

const createInitialState = (): AuthState => {
  return {
    permissions: [] as UserPermission[],
    status: 'login',
    isSuperUser: false,
  }
}

export const useAuthStore = create<AuthState & AuthStateFunctions>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },
}))
