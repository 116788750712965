import { Tooltip } from '@ubnt/ui-components'
import styled from 'styled-components'

export function Info({
  description,
  title,
  color = '#838691',
}: {
  description: React.ReactChild
  title?: string
  color?: string
}) {
  return (
    <InfoContainer>
      <Tooltip
        title={title}
        description={description}
        width={406}
        style={{ maxWidth: '100px' }}
        portal
        position="bottom"
        color="red"
      >
        <Circle style={{ borderColor: color, color }}>i</Circle>
      </Tooltip>
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-radius: 48px;
  font-size: 10px;
  font-weight: bold;
  color: #838691;
  cursor: pointer;
`
