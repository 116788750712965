import {
  Button,
  StatusBall,
  Dropdown as UIDropdown,
  SidePanel as UISidePanel,
  UploadButton,
  cssVariables,
} from '@ubnt/ui-components'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Link } from './Link'
import { Text } from './Text'

export const ContentHolder3 = styled.div`
  & > * {
    padding-bottom: 11px;
  }

  & > *:last-child {
    padding-bottom: 0;
  }
`

export const InputHolder = styled.div`
  padding-bottom: 12px;
`

export const InnerTitle = styled(Text)`
  margin-bottom: ${cssVariables['spacing-xs']};
`

InnerTitle.defaultProps = {
  size: 'header-xs',
  weight: 'bold',
  color: 'primary',
}

interface SeparatorLineProps {
  hideIfFirst?: boolean
}

export const SeparatorLine = styled.div<SeparatorLineProps>`
  padding: 0;
  margin: 12px 0 24px 0;
  height: 1px;
  background: #f1f2f4;

  ${(props) =>
    props.hideIfFirst &&
    css`
      &:first-child {
        display: node;
      }
    `}
`

export const SeparatorLine3 = styled(SeparatorLine)`
  padding: 0;
  margin: 20px 0 20px;
`

export const HeadingContainer = styled.div`
  margin-bottom: 32px;
`

export const HeadingTitle = styled(Text).attrs({ color: 'primary' })`
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 12px;
`

export const HeadingTitle2 = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 12px;
`

export const HeadingDescription = styled(Text)`
  font-size: 16px;
  line-height: 22px;
`

export const Buttons = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;

  & > * {
    margin-left: 8px;
  }
`

interface RowProps extends React.HTMLProps<HTMLDivElement> {
  $centerH?: boolean
  $centerV?: boolean
  $stretch?: boolean
  $truncate?: boolean
  $width?: number
}
export const Row = styled.div<RowProps>`
  display: flex;

  ${({ $truncate: truncate }) =>
    truncate
      ? css`
          & > span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        `
      : undefined}

  ${(props) =>
    props.$centerH &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.$centerV &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.$stretch &&
    css`
      flex: 1;
    `}

  ${(props) =>
    props.$width &&
    css`
      flex: 0 0 ${props.$width}px;
    `}
`

Row.defaultProps = {
  $truncate: true,
}

interface ColumnProps {
  $centerH?: boolean
  $centerV?: boolean
  $stretch?: boolean
  $relative?: boolean
}
export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$centerH &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.$centerV &&
    css`
      justify-content: center;
    `}

  ${(props) => (props.$stretch ? 'flex: 1;' : '')}

  ${(props) => (props.$relative ? 'position: relative;' : '')}
`

export const CompanyLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #333333;
  border-radius: 3px;
  color: #fff;

  & > img {
    width: 20px;
    height: 20px;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 0;
`

export const ReverseTertiaryButton = styled(Button).attrs({ variant: 'tertiary' })`
  background-color: #f6f6f8;

  &:hover {
    background-color: transparent;
  }
`

export const Spacer = styled.div`
  height: 12px;
`

export const CategoryTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  color: #525461;
  > span {
    text-transform: none;
  }
`

export const CategoryTitle2 = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #525461;
`

export const Text13 = styled(Text)`
  font-size: 13px;
  line-height: 18px;
`

export const Text15 = styled(Text)``

Text15.defaultProps = {
  size: 'body',
}

export const DescriptionList = styled.dl`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: visible;
  margin-top: 0;

  > dt {
    flex: 0 0 46%;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }
  > dd {
    flex: 0 0 54%;
    margin-left: auto;
    text-align: right;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }
`

const ProofOfPurchaseContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background: #ebfaf0;
  border-radius: 6px;

  & > div {
    margin: 12px;
  }

  & > span {
    color: #0e6245;
  }
`
export const ProofOfPurchase = () => (
  <ProofOfPurchaseContainer>
    <StatusBall variant="success" />
    <Text size="body" weight="bold">
      Not required.
    </Text>
  </ProofOfPurchaseContainer>
)

export const Dropdown = UIDropdown

interface InputNewProps {
  children?: ReactNode
  label: string
}
export const InputNew = ({ children, label }: InputNewProps) => (
  <div>
    <Text block color="tertiary">
      {label}
    </Text>

    {children}
  </div>
)

export const UploadButtonNew = styled(UploadButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 123px;
  height: 32px;
  margin-right: 12px;
  border: 1px solid #f6f6f8;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid #555;
  }

  &:hover > div > span {
    background: 0;
  }
  & > div:active,
  & > div:focus {
    background: 0;
  }
  & > div > span:active,
  & > div > span:focus {
    background: 0;
    box-shadow: none !important;
  }
`

export const FixedSidePanel = styled(UISidePanel).attrs({ position: 'fixed' })`
  top: ${cssVariables['app-header-height-classic']};
  bottom: 0;
  height: auto;
  z-index: 2; // enforces side panel shadow to be under header

  header [class*='title'] {
    overflow-wrap: anywhere;
  }
`

export const SidePanelCard = styled.div`
  border-radius: 8px;
  background-color: ${cssVariables.motifs.light.white};
`
