import { UCareLogoBadge } from '@shared-ui/components'
import { Text } from '@ubnt/ui-components'
import styled from 'styled-components'

export function UCareBadge() {
  return (
    <div
      className="flex align-center p-4 mx-6 mt-6 border-radius-4 gap-4 z-index"
      style={{ backgroundColor: '#006FFF0D' }}
    >
      <UCareLogoBadge />
      <UCareText size="body">UI Care RMA Request</UCareText>
    </div>
  )
}

const UCareText = styled(Text)`
  background: linear-gradient(96.1deg, #005ed9 0.54%, #3ca9d7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
