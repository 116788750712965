interface ReplacementDevice {
  name: string
  isHandledByUbiquiti: boolean
  isUnifiCare: boolean
}

export function getReplacementPartSku(device: ReplacementDevice): string | null {
  if (!device.isHandledByUbiquiti || device.isUnifiCare) {
    return null
  }

  const PartReplacements: Record<string, string[]> = {
    'SP-HDD-Tray': ['unvr', 'udm-pro', 'udm-se', 'unvr-pro'],
    'SP-RME': [
      'usw-pro-max-48-poe',
      'usw-pro-max-24-poe',
      'usw-pro-48-poe',
      'usw-pro-24-poe',
      'usw-enterprise-48-poe',
      'usw-enterprise-24-poe',
      'usw-48-poe',
      'usw-24-poe ',
      'usw-16-poe',
      'usw-enterprisexg-24',
      'usw-pro-aggregation',
      'usw-pro-max-16-poe',
      'usw-pro-max-48',
      'usw-pro-max-24',
      'usw-pro-48',
      'usw-pro-24',
      'usw-48',
      'usw-24',
    ],
  }

  const sku = device.name.toLowerCase()

  for (const partSku in PartReplacements) {
    const coreSkus = PartReplacements[partSku]
    for (const coreSku of coreSkus) {
      if (sku.indexOf(coreSku) === 0) {
        return partSku
      }
    }
  }

  return null
}
