/** Check if string starts with any of the given values */
export const startWithAnyOf = (strings: string[], input: string): boolean =>
  strings.some((string) => input.startsWith(string))

/** Check if string consists only of numbers/digits */
export const isNumbers = (string: string): boolean => /^\d+$/.test(string)

/** Check if string consists only of letters */
export const isLetters = (string: string): boolean => /^[a-zA-Z]+$/.test(string)

/**
 * Check if string is one of the allowed week strings
 * Valid week - 01 - 53
 */
export const isValidWeek = (week: string): boolean => /^(5[0-3]|[1-4][0-9]|0[1-9])$/.test(week)

/**
 * Check if string is one of the allowed month strings
 * Valid month - 01 - 12
 * */
export const isValidMonth = (month: string): boolean => /^(1[0-2]|0[1-9])$/.test(month)

/** Check if string is one of the allowed short year strings */
export const isValidShortYear = (year: string): boolean => {
  // All years starting from 2015 (15) up till current year are valid years
  const startFromYear = 15
  const currentYear = parseInt(`${new Date().getFullYear()}`.slice(-2), 10)

  const yearCount = currentYear - startFromYear + 1
  const validYears = Array.from(Array(yearCount), (_, i) => `${startFromYear + i}`.padStart(2, '0'))

  return validYears.includes(year)
}

/** Check if string is one of the allowed long year strings */
export const isValidFullYear = (year: string): boolean => {
  // All years starting from 2015 up till current year are valid years
  const startFromYear = 2015
  const currentYear = new Date().getFullYear()

  const yearCount = currentYear - startFromYear + 1
  const validYears = Array.from(Array(yearCount), (_, i) => `${startFromYear + i}`)

  return validYears.includes(year)
}

/** Check if given date is valid */
export const isValidDate = (year: string, month: string, date: string): boolean => {
  const dateObj = new Date(`${year}-${month}-${date}`)

  // Check if valid date object was created
  if (!(dateObj instanceof Date) || (dateObj instanceof Date && isNaN(dateObj.getTime()))) {
    return false
  }

  // If date is something like 2020-02-31 - new valid date object
  // with date 2020-03-02 will be created, so we have to check if the
  // month actually matches
  return `${dateObj.getMonth() + 1}`.padStart(2, '0') === month
}

/** Check if string is one of the old CM codes */
export const isValidOldCMCode = (CMCode: string): boolean => {
  const validCMCodes = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]

  return validCMCodes.includes(CMCode.toLowerCase())
}

/** Check if string is one of the new CM codes */
export const isValidNewCMCode = (CMCode: string): boolean => {
  const validCMCodes = [
    'aa',
    'ab',
    'ac',
    'ad',
    'ae',
    'af',
    'ag',
    'ah',
    'ai',
    'aj',
    'ak',
    'al',
    'am',
    'an',
    'ao',
    'ap',
    'aq',
    'ar',
    'as',
    'at',
    'au',
    'av',
    'aw',
    'ax',
    'ay',
    'az',
    'ba',
    'bb',
    'bc',
    'bd',
    'be',
    'bf',
    'bg',
    'bh',
    'bi',
    'bj',
    'bk',
    'bl',
    'bm',
    'bn',
    'bo',
    'bp',
    'bq',
    'br',
    'bs',
    'bt',
    'bu',
    'bv',
    'bw',
    'bx',
    'by',
    'bz',
    'ca',
    'cb',
    'ft',
  ]

  return validCMCodes.includes(CMCode.toLowerCase())
}
