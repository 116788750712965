import * as yup from 'yup'
import type { ValidationInput } from '../utils'
import { DEFAULT_VARCHAR_MAX_LENGTH, NAME_MAX_LENGTH_60, REQUIRED, TOO_LONG, VALID_EMAIL } from './const'

export const ResellerSettingsSchema = yup
  .object({
    name: yup.string().max(60, NAME_MAX_LENGTH_60).required(REQUIRED),
    nameLegal: yup.string().max(60, NAME_MAX_LENGTH_60).required(REQUIRED),
    website: yup.string().max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG).notRequired(),
    email: yup.string().email(VALID_EMAIL).required(REQUIRED),
    suggestedAddress: yup.string().max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG).notRequired(),
    logo: yup.string().notRequired().default(''),
  })
  .required()

export type ResellerSettings = yup.InferType<typeof ResellerSettingsSchema>

export const validateResellerSettings = (input: ValidationInput<ResellerSettings>) =>
  ResellerSettingsSchema.validate(input)
