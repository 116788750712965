import type { ClipboardEvent, KeyboardEvent, ReactNode } from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { cssVariables } from '@ubnt/ui-components'

export const getPrevId = (focusedId: number) => Math.max(0, focusedId - 1)
export const getNextId = (focusedId: number, length: number) => (length === 0 ? 0 : Math.min(focusedId + 1, length - 1))

const IGNORED_META_KEYS = ['Alt', 'Control', 'Enter', 'Meta', 'Shift', 'Tab', 'Dead']

interface CodeInputProps {
  testId?: string
  autoFocus?: boolean
  length: number
  disabled?: boolean
  invalid?: ReactNode | boolean
  value?: string
  validate?: (value: string) => boolean
  format?: (value: string) => string
  onChange?: (value: string) => void
  onComplete?: (value: string) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  setReset?: (reset: () => void) => void
}

export function CodeInput({
  autoFocus = true,
  length,
  disabled,
  invalid,
  value,
  validate = (key: string) => key.length === 1 && !!/^[a-z0-9]+$/i.exec(key)?.join(''),
  format = (key: string) => key.toUpperCase(),
  onChange,
  onComplete,
  onKeyDown,
  setReset,
}: CodeInputProps) {
  const [focusedId, setFocusedId] = useState(0)
  const [isComplete, setIsComplete] = useState(false)
  const refs = useRef<HTMLInputElement[]>([])

  const setInputValue = (id: number, v: string) => {
    if (!refs.current[id]) return
    refs.current[id].value = v
  }
  const focusInput = (id: number) => {
    if (!refs.current[id]) return
    refs.current[id].focus()
  }
  const blurInput = (id: number) => {
    if (!refs.current[id]) return
    refs.current[id].blur()
  }
  const blurOrFocusNext = () => {
    if (focusedId === length - 1) {
      blurInput(focusedId)
    } else {
      const nextId = getNextId(focusedId, length)
      focusInput(nextId)
    }
  }

  const handleChange = () => {
    const code = refs.current.map((ref) => ref.value.trim()).join('')

    if (onChange) {
      onChange(code)
    }

    const complete = code.length === length
    setIsComplete(complete)

    if (complete) {
      if (onChange) onChange(code)
      if (onComplete) onComplete(code)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const isPaste = (e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'v'
    if (isPaste) {
      // Allow handlePaste to be triggered.

      return
    }

    e.preventDefault()

    if (onKeyDown) onKeyDown(e)

    switch (e.key) {
      case 'Delete':
      case 'Backspace': {
        const prevVal = refs.current[focusedId].value
        setInputValue(focusedId, '')

        if (!prevVal) {
          const prevId = getPrevId(focusedId)
          focusInput(prevId)
          setInputValue(prevId, '')
        }

        break
      }

      case 'Tab': {
        blurOrFocusNext()

        break
      }

      default: {
        const isValidKey =
          !IGNORED_META_KEYS.includes(e.key) && !e.ctrlKey && !e.altKey && !e.metaKey && (!validate || validate(e.key))
        if (!isValidKey) return

        const formatted = !format ? e.key : format(e.key)
        setInputValue(focusedId, formatted)
        blurOrFocusNext()

        break
      }
    }

    handleChange()
  }

  const handleKeyUp = () => {
    const key = refs?.current[focusedId].value
    const isValidKey = validate(key)
    if (!isValidKey) {
      setInputValue(focusedId, '')
    }
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()

    const paste = e.clipboardData
      .getData('Text')
      .trim()
      .split('')
      .filter((key) => (!validate ? key : validate(key)))
      .map((key) => (!format ? key : format(key)))
      .join('')

    const pasteLen = Math.min(paste.length, length - focusedId)
    Array(pasteLen)
      .fill(0)
      .forEach((_, i) => {
        setInputValue(i + focusedId, paste[i])
      })

    const nextId = getNextId(pasteLen + focusedId - 1, length)
    if (nextId !== length - 1) focusInput(nextId)
    else blurInput(focusedId)

    handleChange()
  }

  useLayoutEffect(() => {
    // Another option is to allow to ref the whole component and call it's method.
    if (setReset) {
      setReset(() => {
        Array(length)
          .fill(0)
          .forEach((_, i) => {
            setInputValue(i, '')
          })

        handleChange()
      })
    }
  })

  useEffect(() => {
    if (!value) {
      for (let n = 0; n < length; n += 1) {
        setInputValue(n, '')
      }
      return
    }

    value
      .trim()
      .split('')
      .forEach((char, i) => setInputValue(i, char))
  }, [value])

  return (
    <CodeInputContainer>
      {Array(length)
        .fill(0)
        .map((_, i) => (
          <Input
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            type="text"
            maxLength={1}
            disabled={disabled || (!invalid && isComplete)}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus && i === focusedId}
            aria-label={`Input #${i + 1}`}
            ref={(ref: HTMLInputElement | null) => {
              if (!ref) return

              refs.current[i] = ref
            }}
            onFocus={() => setFocusedId(i)}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onChange={handleChange}
            onPaste={handlePaste}
          />
        ))}
    </CodeInputContainer>
  )
}

const CodeInputContainer = styled.div`
  display: flex;
  gap: 1px;
`

const Input = styled.input<{ complete?: boolean }>`
  width: 31.42px;
  height: 42px;

  text-align: center;
  color: ${cssVariables.motifs.light.text01};
  font-size: ${cssVariables['font-size-body']};

  border: 0;
  background: #f4f5f6;

  &:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`
