// This file is generated. Do not edit this file manually.
// This file should be regenerated any time queries, mutations or subscriptions change
// using `yarn generate:graphql`

import type { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
import type * as Types from '../../../../generated/graphql'

export type OpenTicketsQueryVariables = Types.Exact<{
  cursor: Types.Maybe<Types.Scalars['Cursor']>
}>

export type OpenTicketsQuery = { readonly __typename?: 'Query' } & {
  readonly tickets: { readonly __typename?: 'TicketsResult' } & {
    readonly pageInfo: { readonly __typename?: 'PageInfo' } & Pick<
      Types.PageInfo,
      'endCursor' | 'hasNextPage' | 'total'
    >
    readonly result: ReadonlyArray<
      { readonly __typename?: 'Ticket' } & Pick<
        Types.Ticket,
        'id' | 'createdAt' | 'status' | 'customerTrackingNumber' | 'supportByName'
      > & {
          readonly device: { readonly __typename?: 'Device' } & Pick<
            Types.Device,
            'id' | 'name' | 'mac' | 'productImage' | 'isUnifiCare'
          >
          readonly rating: Types.Maybe<{ readonly __typename?: 'Rating' } & Pick<Types.Rating, 'rating'>>
        }
    >
  }
}

export const OpenTicketsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OpenTickets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tickets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mac' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productImage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isUnifiCare' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerTrackingNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportByName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'rating' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'rating' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useOpenTicketsQuery__
 *
 * To run a query within a React component, call `useOpenTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenTicketsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useOpenTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<OpenTicketsQuery, OpenTicketsQueryVariables>,
) {
  return Apollo.useQuery<OpenTicketsQuery, OpenTicketsQueryVariables>(OpenTicketsDocument, baseOptions)
}
export function useOpenTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpenTicketsQuery, OpenTicketsQueryVariables>,
) {
  return Apollo.useLazyQuery<OpenTicketsQuery, OpenTicketsQueryVariables>(OpenTicketsDocument, baseOptions)
}
export type OpenTicketsQueryHookResult = ReturnType<typeof useOpenTicketsQuery>
export type OpenTicketsLazyQueryHookResult = ReturnType<typeof useOpenTicketsLazyQuery>
export type OpenTicketsQueryResult = Apollo.QueryResult<OpenTicketsQuery, OpenTicketsQueryVariables>
