import type { Requests } from 'rma-shared/requests'
import create from 'zustand'

type TicketStoreState = Requests['rmaTicketDetails']['output']

type TicketStoreFunctions = {
  reset: () => void
}

const createInitialState = (): TicketStoreState => {
  return {
    ticketSupport: null,
  }
}

export const useTicketStore = create<TicketStoreState & TicketStoreFunctions>((set) => ({
  ...createInitialState(),

  reset() {
    set(createInitialState())
  },
}))
