import { Checkbox, Text, ValidationMessage } from '@ubnt/ui-components'
import type { FieldProps } from 'formik'
import { getIn } from 'formik'
import type { FC } from 'react'
import styled from 'styled-components'
import { theme } from '../../styles/theme'

type Props = {
  label: string
  'data-testid': string
} & FieldProps

// TODO: Handle validation errors.
export const CheckboxFormField: FC<Props> = ({
  field: { name, value },
  form: { setFieldValue },
  label,
  'data-testid': testId,
}) => {
  return (
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Checkbox data-testid={testId} id={name} checked={!!value} onChange={() => setFieldValue(name, !value)}>
        {label}
      </Checkbox>
    </div>
  )
}

export const CheckboxFormFieldExt: FC<Props> = ({
  field: { name, value },
  form: { setFieldValue, setSubmitting },
  label,
  'data-testid': testId,
}) => {
  const handleChange = () => {
    setSubmitting(true)
    setFieldValue(name, !value)
  }

  return (
    <Checkbox data-testid={testId} id={name} checked={!!value} onChange={handleChange}>
      {label}
    </Checkbox>
  )
}

export const CheckboxValidationMessage = styled(ValidationMessage)`
  margin-left: 25px;
  margin-top: 6px;
  padding-top: 3px;
  border-top: 1px solid #f03a3e;
  font-size: 12px;
`

type CheckboxGroupFieldProps = {
  groupLabel: string
  values: { value: string; label: string }[]
} & FieldProps<string[], { string: unknown }>

export const CheckboxGroupField: FC<CheckboxGroupFieldProps> = ({
  field: { name, value: currentValue = [] },
  form: { handleChange, setFieldValue, errors, touched },
  groupLabel,
  values,
}) => {
  const error = getIn(touched, name) && getIn(errors, name) ? (getIn(errors, name) as string) : ''

  return (
    <div>
      <div style={{ margin: '1rem 0' }}>
        <Text style={{ color: theme.colors.text.tertiary }}>{groupLabel}</Text>
        {error && (
          <div style={{ marginTop: '0.5rem' }}>
            <Text color="danger">{error}</Text>
          </div>
        )}
      </div>
      {values.map(({ label, value }) => (
        <div key={[label, value].join(':')} style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
          <Checkbox id={name} value={value} checked={currentValue.includes(value)} onChange={handleChange} />
          <Text
            style={{ color: theme.colors.text.tertiary, cursor: 'pointer' }}
            onClick={() => {
              if (currentValue.includes(value)) {
                setFieldValue(
                  name,
                  currentValue.filter((val: string) => val !== value),
                )
              } else {
                setFieldValue(name, [...currentValue, value])
              }
            }}
          >
            {label}
          </Text>
        </div>
      ))}
    </div>
  )
}
