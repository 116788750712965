import type { CompanyId } from '@shared/types/brands'
import { useEffect } from 'react'
import styled from 'styled-components'
import { resolveDefaultAddress } from '../utils/format'
import { Grid } from './Grid'
import { Link } from './Link'
import { Loading } from './Loading'
import { Retry } from './Retry'
import { Text as SharedText } from './Text'
import type { Shared_Address_CompanyFragment as CompanyFragment } from './__generated__/CompanyAddress'
import {
  useShared_Address_CompanyQuery as useCompanyQuery,
  useShared_Address_CompanyLazyQuery as useLazyCompanyQuery,
} from './__generated__/CompanyAddress'

export { useCompanyQuery, useLazyCompanyQuery }

export interface CompanyAddressType {
  logo?: Maybe<string>
  name?: Maybe<string>
  nameLegal?: Maybe<string>
  address1?: Maybe<string>
  address2?: Maybe<string>
  country?: Maybe<string>
  city?: Maybe<string>
  state?: Maybe<string>
  zipcode?: Maybe<string>
  phone?: Maybe<string>
  email?: Maybe<string>
  website?: Maybe<string>
  isDefaultBilling?: Maybe<boolean>
}

export const sanitizeCompanyAddress = (company: CompanyFragment) => ({
  ...resolveDefaultAddress(company.addresses || []),
  id: company.id,
  logo: company.logo,
  name: company.name,
  nameLegal: company.nameLegal,
  website: company.website,
  entityType: company.entityType,
})

export const DisplayCompanyAddress = ({
  companyId,
  setLoading,
}: {
  companyId: CompanyId
  setLoading?: (isLoading: boolean) => void
}) => {
  const { data, loading, error, refetch } = useCompanyQuery({
    variables: { companyId },
  })
  const company = data?.company

  useEffect(() => {
    if (setLoading) setLoading(loading)
  }, [loading, setLoading])

  return (
    <>
      {loading && <Loading />}
      {error && <Retry text="Could not load company." retry={refetch} />}
      {company && <CompanyAddress address={sanitizeCompanyAddress(company)} />}
    </>
  )
}

export const CompanyAddress = ({
  testId,
  address: { name, address1, address2, country, city, state, zipcode, phone, email, website },
}: {
  testId?: string
  address: CompanyAddressType
}) => (
  <Grid $container direction="column" data-testid={testId}>
    {name && (
      <Grid $item $xs={12}>
        <Text truncate $size="l" weight="bold">
          {name}
        </Text>
      </Grid>
    )}
    {address1 && (
      <Grid $item $xs={12}>
        <Text>{address1}</Text>
      </Grid>
    )}
    {address2 && (
      <Grid $item $xs={12}>
        <Text>{address2}</Text>
      </Grid>
    )}
    {(country || city || state || zipcode) && (
      <Grid $item $xs={12}>
        <Text>
          {[country, city, state].filter(Boolean).join(', ')} {zipcode ?? ''}
        </Text>
      </Grid>
    )}
    {phone && (
      <Grid $item $xs={12}>
        <Text>{phone}</Text>
      </Grid>
    )}
    {email && (
      <Grid $item $xs={12}>
        <Text>{email}</Text>
      </Grid>
    )}
    {website && (
      <Grid $item $xs={12}>
        <Text>
          <Link href={website}>{website}</Link>
        </Text>
      </Grid>
    )}
  </Grid>
)

const Text = styled(SharedText).attrs({ color: 'secondary' })`
  color: inherit;
`
