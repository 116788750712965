import { Text as UiText } from '@ubnt/ui-components'
import type React from 'react'
import styled from 'styled-components'

import type { FontSize, Spacing } from '../styles/theme'

type UiTextProps = React.ComponentProps<typeof UiText>

type StyleProps = {
  $size?: FontSize
  full?: boolean
  $center?: boolean
  block?: boolean
  scroll?: boolean
  clickable?: boolean
  margin?: Spacing
  marginTop?: Spacing
  marginRight?: Spacing
  marginBottom?: Spacing
  marginLeft?: Spacing
  emphasis?: boolean
  ellipsis?: boolean
  disableShrink?: boolean
}

// Match StyleProps.
const CUSTOM_STYLE_PROPS = [
  'full',
  'center',
  'block',
  'scroll',
  'clickable',
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'emphasis',
  'ellipsis',
]

const HANDLED_TEXT_PROPS = ['centered']

export type TextProps = Omit<UiTextProps, 'centered'> & {
  centered?: boolean
} & {
  className?: string
} & StyleProps

export const Text = styled(UiText as React.FC<TextProps>)
  .attrs((props) => {
    const attrs: TextProps = {}

    if (props.$center || props.centered) {
      attrs.full = true
      attrs.$center = true
    }

    if (props.truncate) {
      attrs.full = true
      attrs.title = props.title || (typeof props.children === 'string' ? props.children.toString() : '') || ''
    }

    return attrs
  })
  .withConfig({
    shouldForwardProp: (prop) => !HANDLED_TEXT_PROPS.includes(prop) && !CUSTOM_STYLE_PROPS.includes(prop),
  })`

  ${(props) => props.$size && `font-size: ${props.theme.font.size[props.$size]};`}
  ${(props) => props.full && `width: 100%;`}
  ${(props) => props.$center && `text-align: center;`}

  ${(props) =>
    (props.block || props.scroll) &&
    `
      display: block;
      overflow: hidden;
    `}
  ${(props) => props.scroll && `overflow: auto;`}
  ${(props) => props.emphasis && `font-style: italic;`}
  ${(props) => props.clickable && `cursor: pointer;`}
  ${(props) => props.margin && `margin: ${props.theme.spacing[props.margin]};`}
  ${(props) => props.marginTop && `margin-top: ${props.theme.spacing[props.marginTop]};`}
  ${(props) => props.marginRight && `margin-right: ${props.theme.spacing[props.marginRight]};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.theme.spacing[props.marginBottom]};`}
  ${(props) => props.marginLeft && `margin-left: ${props.theme.spacing[props.marginLeft]};`}
  ${(props) =>
    props.ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`

Text.defaultProps = {
  size: 'body',
}
