export const getPrettyDate = (dateStr: Date) => {
  const date = new Date(dateStr)

  const monthName = date.toLocaleDateString(undefined, { month: 'short' })
  const day = date.getDate()

  const year = date.getFullYear()
  const time = date.toLocaleTimeString(global.navigator.language, { hour: '2-digit', minute: '2-digit' })

  return `${monthName} ${day}${getOrdinalSuffix(day)}, ${year} ${time}`
}

export const getOrdinalSuffix = (num: number) => {
  const ordinals = ['th', 'st', 'nd', 'rd']
  const reminder = ((num % 100) - 20) % 10

  return ordinals[reminder] || ordinals[num] || ordinals[0]
}
