import * as yup from 'yup'
import type { ValidationInput } from '../utils'
import { DEFAULT_VARCHAR_MAX_LENGTH, NAME_MAX_LENGTH_60, REQUIRED, TOO_LONG } from './const'

export const ResellerCreateSchema = yup
  .object({
    name: yup.string().max(60, NAME_MAX_LENGTH_60).required(REQUIRED),
    nameLegal: yup.string().max(60, NAME_MAX_LENGTH_60).notRequired(),
    suggestedAddress: yup.string().max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG).notRequired(),
    website: yup.string().max(DEFAULT_VARCHAR_MAX_LENGTH, TOO_LONG).notRequired(),
    email: yup.string().email().default(''),
    logo: yup.string().default(''),
  })
  .required()

export type ResellerCreate = yup.InferType<typeof ResellerCreateSchema>

export const validateResellerCreate = (input: ValidationInput<ResellerCreate>) => ResellerCreateSchema.validate(input)
