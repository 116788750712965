import formatSerial from '@shared/lib/utils/format/serial'
import type { TicketId } from '@shared/types/brands'
import type { TableColumnConfig } from '@ubnt/ui-components'
import { Button, cssVariables, Modal, Table, Text } from '@ubnt/ui-components'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RMA_SHOW_UNSENT_ITEMS_POPUP_AFTER } from '../../constants'
import {
  useMarkTicketItemSentMutation,
  useTicketsWithUnsentItemsQuery,
} from './__generated__/TicketsWithUnsentItemsModal'

type Row = {
  ticketId: string
  product: string
  mac: string | null
  actions: JSX.Element
}

const columns: TableColumnConfig<Row>[] = [
  {
    id: 'ticketId',
    growthFactor: 1,
    label: 'Ticket ID',
  },
  {
    id: 'product',
    growthFactor: 1,
  },
  {
    id: 'mac',
    label: 'MAC ID',
    growthFactor: 1,
  },
  {
    id: 'actions',
    label: null,
    renderCell: ({ actions }) => actions,
    maxWidth: 140,
    align: 'right',
  },
]

const MarkAsSent: FC<{ ticketId: TicketId }> = ({ ticketId }) => {
  const [markTicketItemSentMutation, { loading, error }] = useMarkTicketItemSentMutation({
    variables: {
      ticketId,
    },
  })
  const [isSent, setIsSent] = useState(false)

  const onClick = () => markTicketItemSentMutation().then(() => setIsSent(true))

  if (isSent) {
    return (
      <SentText color="tertiary" size="body" weight="bold">
        Product Sent
      </SentText>
    )
  }

  return (
    // eslint-disable-next-line no-nested-ternary
    <Button variant="link" onClick={onClick} loader={loading ? 'loading' : error ? 'error' : undefined}>
      Mark as Sent
    </Button>
  )
}

export const TicketsWithUnsentItemsModal = () => {
  const { data } = useTicketsWithUnsentItemsQuery()
  const [items, setItems] = useState<Row[] | null>(null)

  const [isVisible, setVisibility] = useState(false)

  useEffect(() => {
    if (items || !data?.ticketsWithUnsentItems) {
      return
    }

    setItems(
      data.ticketsWithUnsentItems
        .filter(({ customerSentItemAt }) => !customerSentItemAt)
        .map((item) => ({
          ticketId: item.id,
          product: item.device.name,
          mac: item.device.mac ? formatSerial(item.device.mac) : '',
          actions: (
            <Actions>
              <MarkAsSent ticketId={item.id} />
            </Actions>
          ),
        })),
    )
  }, [data, items, setItems])

  useEffect(() => {
    const showPopupAfter = localStorage.getItem(RMA_SHOW_UNSENT_ITEMS_POPUP_AFTER)

    if ((showPopupAfter === null || Date.now() > parseInt(showPopupAfter, 10)) && items?.length) {
      setVisibility(true)

      const date = new Date()
      const tomorrowTimestamp = date.setDate(date.getDate() + 1)
      localStorage.setItem(RMA_SHOW_UNSENT_ITEMS_POPUP_AFTER, tomorrowTimestamp.toString())
    }
  }, [items])

  return (
    <StyledModal
      isOpen={isVisible}
      title="Awaiting RMA Items"
      footer={() => (
        <FooterContainer>
          <Button onClick={() => setVisibility(false)} variant="primary">
            Go to the Dashboard
          </Button>
        </FooterContainer>
      )}
    >
      <Text size="body">We will continue to process your RMA request(s) once we receive the item(s) below.</Text>
      <TableContainer>
        <Table idField="ticketId" columns={columns} items={items || []} rowHeight={50} disableColumnFilters />
      </TableContainer>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  max-height: calc(100% - 120px);
`

const TableContainer = styled.div`
  margin-top: ${cssVariables['spacing-xxl']};
`

const Actions = styled.div`
  display: flex;
`

const SentText = styled(Text)`
  margin-right: 6px; // to match right margin of "Mark as sent" button
`

const FooterContainer = styled.div`
  display: flex;
  margin-top: ${cssVariables['spacing-xxxxl']};
  margin-bottom: 30px;
  margin-left: 30px;
`
