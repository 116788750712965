import { cssVariables } from '@ubnt/ui-components'
import styled from 'styled-components'
import { theme } from '../styles/theme'
import StyledHref from './StyledHref'

export interface FooterNavLink {
  to: string
  label: string
}

export function FooterContainer({ navLinks }: { navLinks: FooterNavLink[] }) {
  // const isDirect = isPortal('DIRECT')
  // const cookiesPolicyUrl = '/cookies-policy'
  // const managedCookiesPolicyUrl = isDirect ? `/rma${cookiesPolicyUrl}` : cookiesPolicyUrl

  const handleClick = () => {
    document.getElementById('ot-sdk-btn')?.click()
  }

  return (
    <Wrap>
      <div>© {new Date().getFullYear()} Ubiquiti Inc. All rights reserved.</div>
      <Nav>
        {navLinks.map((item) => (
          <NavItem key={item.to}>
            <StyledHref href={item.to}>{item.label}</StyledHref>
          </NavItem>
        ))}

        <NavItem onClick={handleClick}>
          <StyledHref>Cookie Settings</StyledHref>
        </NavItem>

        {/* <NavItem key={managedCookiesPolicyUrl}>
          <StyledHref href={managedCookiesPolicyUrl}>Cookies Policy</StyledHref>
        </NavItem> */}
      </Nav>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid ${theme.colors.border.normal};
  background: #fff;
  font-size: ${cssVariables['font-size-caption']};
  text-align: center;
`

const Nav = styled.nav`
  margin: 0;
  padding: 0;
`

const NavItem = styled.span`
  display: inline-block;
  padding: 0 0.5rem;
  margin: 0.25rem 0;
  border-right: 1px solid ${theme.colors.border.normal};

  &:last-child {
    border-right: 0;
  }
`
