import { getPrettyDate } from '@shared/lib'
import { cssVariables, Popover, Text } from '@ubnt/ui-components'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import type { NotificationId, TicketId } from 'rma-shared/types/brands'
import type { NotificationClosedTicket, NotificationData, NotificationType } from 'rma-shared/types/notifications'
import styled from 'styled-components'
import { Spacer } from '..'
import { Link as LinkClickable } from '../Link'
import { ContactSupportAboutTicket } from '../Ticket/contact-support'
import { useNotificationsMarkAsUnreadMutation, useNotificationsRemoveMutation } from './__generated__/Notifications'
import * as NotificationService from './NotificationsService'

interface NotificationItemProps {
  notificationId: NotificationId
  ticketId: TicketId | null
  createdAt: Date
  seenAt?: Date | null
  data: NotificationData
  baseUrl: string
}

export default function NotificationItem({
  notificationId,
  ticketId,
  createdAt,
  seenAt,
  data,
  baseUrl,
}: NotificationItemProps) {
  const [markAsUnread] = useNotificationsMarkAsUnreadMutation()
  const [removeNotification] = useNotificationsRemoveMutation()

  const [popoverOpen, setPopoverOpen] = useState(false)

  const handleMarkAsUnread = async () => {
    setPopoverOpen(false)
    NotificationService.markUnseen(notificationId)

    try {
      await markAsUnread({ variables: { id: notificationId } })
    } catch (err) {
      //
    }
  }

  const handleRemove = async () => {
    try {
      setPopoverOpen(false)
      await removeNotification({
        variables: { id: notificationId },
        update: (cache) => {
          const normalizedId = cache.identify({ id: notificationId, __typename: 'Notification' })
          cache.evict({
            id: normalizedId,
          })
          cache.gc()
        },
      })
    } catch (err) {
      //
    }
  }

  return (
    <NotificationItemContainer className={seenAt ? undefined : 'new'}>
      <div className="flex">
        <div className="flex column flex-1">
          <div className="flex">
            <Text color="tertiary">{getPrettyDate(createdAt)}</Text>
          </div>
          <div className="flex">
            <Text color="primary" weight="bold" size="body">
              {headers[data.type]}
            </Text>
          </div>
        </div>

        <Popover
          align="bottomRight"
          size="small"
          toggleOffset={0}
          open={popoverOpen}
          onChange={(value) => setPopoverOpen(value)}
          style={{ marginRight: '-8px' }}
        >
          <PopoverList>
            {seenAt && (
              <div className="flex" onClick={handleMarkAsUnread}>
                Mark as unread
              </div>
            )}
            <div className="flex" onClick={handleRemove}>
              Remove
            </div>
          </PopoverList>
        </Popover>
      </div>

      <Spacer />

      <div className="flex">
        <Text color="secondary" size="body">
          Ticket ID: <Link to={`${baseUrl}/${ticketId}/${getTicketUrlCategory(data.type)}`}>{ticketId}</Link>
        </Text>
      </div>

      <Spacer />

      {data.type === 'rma-approved' && (
        <div className="flex">
          <Text color="secondary" size="body">
            Please send your item to the seller as soon as possible to ensure timely processing. Shipping instructions
            have been sent to: <a href={`mailto:${data.email}`}>{data.email}</a>
          </Text>
        </div>
      )}

      {(data.type === 'rma-canceled' || data.type === 'rma-declined') && (
        <div className="flex">
          <Text color="secondary" size="body">
            Click the Ticket ID above to see the reason for this decision.
          </Text>
        </div>
      )}

      {data.type === 'rma-overdue-closed' && (
        <div className="flex">
          <Text color="secondary" size="body">
            Your RMA ticket has been closed because your item wasn’t received within the requested time frame.
          </Text>
        </div>
      )}

      {data.type === 'rma-processing' && (
        <div className="flex">
          <Text color="secondary" size="body">
            Your seller is examining your item. You will be notified when they finish.
          </Text>
        </div>
      )}

      {data.type === 'rma-shipped' && (
        <div className="flex">
          <Text color="secondary" size="body">
            Your replacement item will be shipped shortly.
          </Text>
        </div>
      )}

      {data.type === 'rma-credit-issued' && (
        <div className="flex">
          <Text color="secondary" size="body">
            Credit Issued
          </Text>
        </div>
      )}

      {data.type === 'chat-new-message' && (
        <div className="flex">
          <Text color="secondary" size="body">
            Click the Ticket ID above to read your new message.
          </Text>
        </div>
      )}

      {data.type === 'ns-ticket-reminder' && (
        <div className="flex">
          <Text color="secondary" size="body">
            If your item isn’t received within 7 days, your RMA submission will be canceled. Shipping instructions have
            been sent to: <a href={`mailto:${data.email}`}>{data.email}</a>.
          </Text>
        </div>
      )}

      {data.type === 'closed-ticket' && <ClosedTicketNotification ticketId={ticketId ?? 'N/A'} data={data} />}
    </NotificationItemContainer>
  )
}

interface ClosedTicketNotificationProps {
  ticketId: TicketId
  data: NotificationClosedTicket
}

const ClosedTicketNotification = ({ ticketId, data }: ClosedTicketNotificationProps) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {data.isUbiquiti && (
        <div className="flex">
          <Text color="secondary" size="body">
            Your RMA request was closed due to a technical issue. If you have any questions, please{' '}
            <LinkClickable onClick={() => setOpen(true)}>contact us</LinkClickable>.
          </Text>
        </div>
      )}

      {!data.isUbiquiti && (
        <div className="flex">
          <Text color="secondary" size="body">
            Your RMA request was closed due to your seller failing to approve it within 180 days. Please note if you
            believe this is a mistake and your device was eligible for a replacement, please{' '}
            <LinkClickable onClick={() => setOpen(true)}>contact UI</LinkClickable> who can provide support.
          </Text>
        </div>
      )}

      {open && (
        <ContactSupportAboutTicket
          ticketId={ticketId}
          supportedByName={data.supportedByName}
          isUbiquiti={data.isUbiquiti}
          onDone={() => setOpen(false)}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

const headers: Record<NotificationType, string> = {
  'rma-approved': 'RMA Approved',
  'rma-canceled': 'RMA Canceled',
  'rma-declined': 'RMA Declined',
  'rma-processing': 'Your Item Has Been Received and Is Being Tested',
  'rma-shipped': 'Preparing to Ship Your Replacement Item',
  'rma-credit-issued': 'RMA Credit Issued',
  'chat-new-message': 'New Chat Message',
  'ns-ticket-reminder': "We haven't received your RMA item yet",
  'rma-overdue-closed': 'RMA closed',
  'closed-ticket': 'Your RMA Was Closed',
}

const getTicketUrlCategory = (type: NotificationType) => {
  switch (type) {
    case 'rma-approved':
    case 'rma-canceled':
    case 'rma-declined':
    case 'rma-processing':
    case 'rma-shipped':
    case 'rma-credit-issued':
      return 'log'

    case 'chat-new-message':
      return 'messaging'

    default:
      return 'details'
  }
}

const NotificationItemContainer = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid #d6d9e2;
  font-size: 14px;
  line-height: 20px;
  color: #1c1e2d;

  &.new {
    background: #e5f1ff;
  }
`

const PopoverList = styled.div`
  font-size: ${cssVariables['font-size-body']};
  margin: -10px;

  & > * {
    line-height: 24px;
    border-radius: 6px;
    padding: ${cssVariables['spacing-xs']} 10px;
  }

  & > *:hover {
    background: #f6f6f8;
    font-weight: 600;
    cursor: pointer;
  }
`
